import { RosteringLeaveTypeEnum } from "baseCODEGEN";
import dayjs from "dayjs";

import { ActivityName } from "components/Schedule/hooks/useServicePlansShiftActivities";
import { RosteringTeamRotaQuery } from "components/TeamRota/__generated__/TeamRota.generated";
import { TeamRotaShiftType } from "components/TeamRota/TeamRotaTable/TeamRotaTableFilters.types";
import { formatTimeInTZ } from "helpers/functions";

export type RosteringTeamRotaType = RosteringTeamRotaQuery["rosteringTeamRota"];

export type RosteringOrgRegsType =
  RosteringTeamRotaType[number]["rosteringOrganisationRegistrations"];

export type TeamRotaEventType =
  RosteringOrgRegsType[number]["eventsOnRoster"][number];

export const hasRosterOrgRegEvents = (rosterOrgReg: RosteringOrgRegsType) => {
  return rosterOrgReg.some(
    ({ rosteringLeaveRequests, eventsOnRoster }) =>
      rosteringLeaveRequests.length || eventsOnRoster.length
  );
};

export const isTeamRotaEmpty = (
  teamRota: RosteringTeamRotaType | undefined
) => {
  return !teamRota?.some(({ rosteringOrganisationRegistrations }) =>
    hasRosterOrgRegEvents(rosteringOrganisationRegistrations)
  );
};

export const isWithinDay = ({
  currentDay,
  startDate,
  endDate,
  isLeaveRequest = false,
}: {
  currentDay: Date;
  startDate: Date;
  endDate: Date;
  isLeaveRequest: boolean;
}) => {
  const endOfCurrentDay = dayjs(currentDay).endOf("d");
  const beggingOfCurrentDay = dayjs(currentDay).startOf("d");

  if (isLeaveRequest)
    return (
      dayjs(beggingOfCurrentDay).isBetween(startDate, endDate) ||
      dayjs(startDate).format("YYYY-MM-DD") ===
        dayjs(currentDay).format("YYYY-MM-DD")
    );

  return dayjs(startDate).isBetween(beggingOfCurrentDay, endOfCurrentDay);
};

export const formatTooltipTimeTop = (time: string) =>
  `${formatTimeInTZ(time, "DD/MM/YYYY")} ${formatTimeInTZ(time, "HH:mm")} -`;

export const formatTooltipTimeBottom = (time: string) =>
  `${formatTimeInTZ(time, "DD/MM/YYYY")} ${formatTimeInTZ(time, "HH:mm")}`;

export const formatLeaveType = (leaveType: RosteringLeaveTypeEnum) =>
  `${
    leaveType === RosteringLeaveTypeEnum.Special ||
    leaveType === RosteringLeaveTypeEnum.Paid
      ? "Other"
      : leaveType
  } leave`;

export const getTeamRotaWorkers = (
  teamsRota: RosteringTeamRotaQuery["rosteringTeamRota"] | undefined
) => {
  if (!teamsRota) return [];

  return teamsRota?.flatMap((teamRota) => {
    return teamRota.rosteringOrganisationRegistrations.map(
      ({ id, workerName }) => ({ id, workerName })
    );
  });
};

export const getTeamRotaActivities = (
  teamsRota: RosteringTeamRotaQuery["rosteringTeamRota"] | undefined,
  servicePlanActivities: ActivityName[]
): ActivityName[] => {
  return [
    ...servicePlanActivities,
    ...Array.from(
      new Set(
        teamsRota?.flatMap((teamRota) =>
          teamRota.rosteringOrganisationRegistrations.flatMap((rosterOrgReg) =>
            rosterOrgReg.eventsOnRoster.map(
              (event) => event.scheduleable?.organisationActivity
            )
          )
        )
      )
    )
      .filter(Boolean)
      .map((activity) => ({
        id: activity?.id.toString() || "",
        name: activity?.name || "",
      }))
      .filter((activity) => activity.name !== "" && activity.id !== ""),
  ];
};

export const getTeamRotaShiftTypes = (
  teamsRota: RosteringTeamRotaQuery["rosteringTeamRota"] | undefined
): NonNullable<TeamRotaShiftType>[] => {
  return Array.from(
    new Set(
      teamsRota?.flatMap((teamRota) =>
        teamRota.rosteringOrganisationRegistrations.flatMap((rosterOrgReg) =>
          rosterOrgReg.eventsOnRoster.map(
            (event) => event.scheduleable?.shiftType
          )
        )
      )
    )
  ).filter(Boolean) as NonNullable<TeamRotaShiftType>[]; //Had to cast it to satisfy react-select options ( I think with ts-reset this would work fine without casting )
};

export const getTeamRotaStartingDate = ({
  rosterStartDate,
  rosterEndDate,
}: {
  rosterStartDate: string | Date;
  rosterEndDate: string | Date;
}) => {
  const startDate = new Date(rosterStartDate);
  const endDate = new Date(rosterEndDate);
  const today = new Date();

  const isEndDateInPast = endDate.getTime() < today.getTime();
  const isStartDateInFuture = startDate.getTime() > today.getTime();

  return isEndDateInPast || isStartDateInFuture ? startDate : today;
};
