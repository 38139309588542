import { useState } from "react";
import { CloseButton, Modal, ModalTitle } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";

import { DefaultButton, ModalHeader } from "@patchworkhealth/web-components";

import { ShiftCard } from "components/ShiftsNew/ShiftCard";
import { formatError } from "helpers/formatError";
import { formatTimeInTZ, parseTimeInTZ } from "helpers/momentHelpers";

import { Divider } from "../Style";
import { useWorkerSignOffRequestedMultipleMutation } from "./__generated__/Timesheets.generated";
import {
  BreaksIcon,
  calculateMinimumBreaks,
  ExtendShiftCardFragment,
  ModalShiftType,
  TimesheetsModalFooter,
  ZeroHourDatePicker,
} from "./TimesheetHelpers";
import {
  CheckboxIcon,
  TimeSheetLeftIcon,
  TimeSheetRightIcon,
} from "./TimesheetIcons";

export type TimesheetsInputsType = {
  tab: number;
  checkbox: boolean;
  checkedShifts: ModalShiftType;
  reason: string;
  loading: boolean;
  activeSlide: number;
  fraudDeclaration: boolean;
};

const emptyInputs: TimesheetsInputsType = {
  tab: 1,
  checkbox: false,
  checkedShifts: [],
  reason: "",
  loading: false,
  activeSlide: 1,
  fraudDeclaration: false,
};

type ModalProps = {
  onHide: () => void;
  modalShifts: ModalShiftType;
  refetchSignoff: () => void;
};

const TimesheetsModal = ({
  onHide,
  modalShifts,
  refetchSignoff,
}: ModalProps) => {
  /* State ----------------------------------------------- */

  const [inputs, setInputs] = useState(emptyInputs);
  const [signOffRequestMutation] = useWorkerSignOffRequestedMultipleMutation();

  const { checkedShifts, tab, activeSlide } = inputs;
  const usesFraudDeclaration =
    modalShifts[0].enforceFraudDeclarationStatement ?? false;

  /* Fraud Declaration Functions ----------------------------- */

  const handleFraudToggle = () => {
    setInputs({
      ...inputs,
      fraudDeclaration: !inputs.fraudDeclaration,
    });
  };

  const viewFraudDeclaration = () => {
    setInputs({
      ...inputs,
      tab: 3,
    });
  };

  /* Functions ----------------------------------------------- */

  const handleToggle = () => {
    setInputs((prevState) => ({
      ...prevState,
      checkbox: !prevState.checkbox,
      checkedShifts: !prevState.checkbox ? modalShifts.slice(0, 15) : [],
    }));
  };

  const checkFunction = (shift: ModalShiftType[0]) => {
    setInputs((prevState) => {
      const copy = [...prevState.checkedShifts];
      const index = copy.findIndex((x) => x.id === shift.id);
      index === -1 ? copy.push(shift) : copy.splice(index, 1);
      return {
        ...prevState,
        checkedShifts: copy,
      };
    });
  };

  const checkIcon = (id: number) => checkedShifts.some((el) => el.id === id);

  const resetValues = () => {
    onHide();
    setInputs(emptyInputs);
  };

  const handleSlide = (direction: "prev" | "next") => {
    const slideCount = checkedShifts.length;
    const newActiveSlide =
      direction === "prev"
        ? activeSlide === 1
          ? slideCount
          : activeSlide - 1
        : (activeSlide + 1) % slideCount || slideCount;
    setInputs((prevState) => ({
      ...prevState,
      activeSlide: newActiveSlide,
    }));
  };

  const handleDotClick = (index: number) => {
    setInputs((prevState) => ({
      ...prevState,
      activeSlide: index,
    }));
  };

  const selectTime = (
    shift: ModalShiftType[0],
    time: "startTime" | "endTime",
    value: string | Date | null
  ) => {
    setInputs((prevState) => {
      const shiftsCopy = [...prevState.checkedShifts];
      const index = shiftsCopy.findIndex((x) => x.id === shift.id);
      const selectedDateTime = parseTimeInTZ(value as string);

      shiftsCopy[index][time] = selectedDateTime.toDate();
      shiftsCopy[index].showReason = true;
      return {
        ...prevState,
        checkedShifts: shiftsCopy,
      };
    });
  };

  const handleBreaks = (shift: ModalShiftType[0], value: string) => {
    setInputs((prevState) => {
      const shiftsCopy = [...prevState.checkedShifts];
      const index = shiftsCopy.findIndex((x) => x.id === shift.id);
      shiftsCopy[index]["breakMinutes"] = parseInt(value);
      shiftsCopy[index]["showReason"] = true;
      return {
        ...prevState,
        checkedShifts: shiftsCopy,
      };
    });
  };

  const handleReason = (shift: ModalShiftType[0], value: string) => {
    setInputs((prevState) => {
      const shiftsCopy = [...prevState.checkedShifts];
      const index = shiftsCopy.findIndex((x) => x.id === shift.id);
      shiftsCopy[index]["reason"] = value;
      return {
        ...prevState,
        checkedShifts: shiftsCopy,
      };
    });
  };

  const handleReset = (shift: ModalShiftType[0]) => {
    setInputs((prevState) => {
      const shiftsCopy = [...prevState.checkedShifts];
      const index = shiftsCopy.findIndex((x) => x.id === shift.id);
      shiftsCopy[index].breakMinutes = shiftsCopy[index].originalBreakMinutes;
      shiftsCopy[index].startTime = shiftsCopy[index].originalStartTime;
      shiftsCopy[index].endTime = shiftsCopy[index].originalEndTime;
      shiftsCopy[index].showReason = false;
      shiftsCopy[index].reason = "";
      return {
        ...prevState,
        checkedShifts: shiftsCopy,
      };
    });
  };

  const getSignOffRejectionMessage = (shift: ExtendShiftCardFragment) => {
    const { workerSignOff } = shift;

    if (!workerSignOff) return null;

    if (workerSignOff.rejected) {
      const rejectionReason = workerSignOff.rejectionReason;
      return (
        <div>
          Your previous sign off request for this shift was rejected.
          <br />
          Please resubmit your timesheet.
          <br />
          <br />
          <span style={{ color: "red" }}>
            {" "}
            Rejection reason: {rejectionReason}
          </span>
        </div>
      );
    }

    if (workerSignOff.confirmedEndTime && workerSignOff.confirmedStartTime) {
      return "This shift contains hours based on a previous sign off request that was not accepted";
    }

    return null;
  };

  /* Mutation ----------------------------------------------- */

  const handleSignoff = async () => {
    const shiftsCopy = [...checkedShifts];

    const invalidShifts = shiftsCopy.filter((copy) => {
      return moment(copy.endTime).isBefore(moment(copy.startTime));
    });

    if (invalidShifts.length > 0) {
      toast.error(`Invalid Dates for ${invalidShifts.length} Shift(s)`);
      return;
    }

    const invalidBreaks = shiftsCopy.filter((s) => {
      const minBreaks = calculateMinimumBreaks(s);
      return (
        (s.shouldEnforceBreaks && s.breakMinutes < minBreaks) ||
        isNaN(s.breakMinutes)
      );
    });

    if (invalidBreaks.length > 0) {
      toast.error(`Invalid Breaks on ${invalidBreaks.length} Shift(s)`);
      return;
    }

    const noReasonShifts = shiftsCopy.filter((shift) => {
      return shift.showReason && shift.reason === "";
    });

    if (noReasonShifts.length > 0) {
      toast.error(
        `Please add a reason for ${noReasonShifts.length} of the shift(s) you have amended`
      );
      return;
    }

    shiftsCopy.forEach((shift) => {
      if (shift.shouldEnforceBreaks && shift.reason === "") {
        shift.reason = "Updated from Minimum Breaks";
      }
    });

    const toastId = toast.loading("Loading...");

    try {
      const { data: result } = await signOffRequestMutation({
        variables: {
          shifts: shiftsCopy.map((shift) => {
            return {
              id: shift.id ?? 0,
              startTime: shift.startTime,
              endTime: shift.endTime,
              breaks: shift.breakMinutes,
              reportedNotes: shift.reason,
            };
          }),
        },
      });

      const errors = result?.workerSignOffRequestedMultiple?.errors;

      if (errors && errors?.length > 0) {
        toast.error(formatError(errors), { id: toastId });
        return;
      }

      toast.success("Shift(s) successfully signed off!", { id: toastId });
      resetValues();
      refetchSignoff();
    } catch (error) {
      toast.error("There was an error signing off this shift(s)", {
        id: toastId,
      });
      resetValues();
      refetchSignoff();
    }
  };

  /* Render ----------------------------------------------- */

  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        backdrop="static"
        centered
        size="lg"
        className="timesheets__modal"
      >
        <ModalHeader>
          <ModalTitle className="text-sm sm:text-lg">
            {tab === 1 && "Select shifts for sign-off (Max 15)"}
            {tab === 2 && "Please check all Shifts"}
            {tab === 3 && "Fraud Declaration Statement"}
          </ModalTitle>
          <CloseButton onClick={() => resetValues()} />
        </ModalHeader>

        <div>
          {tab === 1 && modalShifts.length > 0 && (
            <div className="p-4">
              <div className="mb-5">
                <h6>Select the timesheet(s) you wish to sign-off.</h6>
                <p>Simply click on a shift to select or de-select it.</p>
              </div>

              <div
                className="grid"
                style={{
                  gridGap: 26,
                  gridTemplateColumns: "repeat(auto-fill, 330px)",
                }}
              >
                {modalShifts.map((shift) => (
                  <div key={shift.id} className="h-[190px] w-[330px] relative">
                    <ShiftCard
                      shift={shift}
                      key={shift.id}
                      onClick={() => checkFunction(shift)}
                      isMod={false}
                    />
                    {checkIcon(shift.id ?? 0) && (
                      <div className="absolute right-[-12px] top-[-12px] z-10">
                        <CheckboxIcon />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <Divider top="40" bottom="0" line="solid" />
            </div>
          )}

          {tab === 2 && (
            <div className="px-4 py-10 border">
              <p className="hidden font-bold sm:block">
                Please check all Shifts and amend your timesheet(s) if
                necessary.
              </p>
              <p className="hidden mb-10 sm:block">
                Use the arrows to go through to view other timesheets
              </p>

              <div className="w-full">
                <div className="flex items-center">
                  <button
                    className="justify-center hidden w-20 sm:flex"
                    onClick={() => handleSlide("prev")}
                  >
                    <TimeSheetLeftIcon />
                  </button>

                  <div className="w-full min-h-[350px] sm:bg-gray-100 sm:border p-0 sm:p-4 rounded-lg relative">
                    {checkedShifts.map((shift, index) => {
                      if (index === activeSlide - 1) {
                        const minAllowedBreaks = shift.shouldEnforceBreaks
                          ? calculateMinimumBreaks(shift)
                          : shift.breakMinutes;
                        const rejectionMessage =
                          getSignOffRejectionMessage(shift);
                        return (
                          <>
                            <div
                              className="relative flex flex-wrap w-full"
                              key={index}
                            >
                              <div className="flex-1 p-4">
                                <ShiftCard
                                  shift={shift}
                                  key={shift.id}
                                  onClick={() => null}
                                  isMod={false}
                                />
                              </div>

                              {/* Start Time ----------------------------------------------- */}

                              <div className="flex-1 px-4 py-2">
                                <div className="h-[74px] py-2 flex border-b">
                                  <p className="font-semibold w-[120px] mt-3">
                                    Start Time
                                  </p>
                                  <div className="flex flex-col items-center px-4 ">
                                    <ZeroHourDatePicker
                                      shift={shift}
                                      time={formatTimeInTZ(
                                        shift.startTime,
                                        "DD MMM YYYY HH:mm"
                                      )}
                                      value="startTime"
                                      selectTime={selectTime}
                                    />
                                  </div>
                                </div>

                                {/* End Time ----------------------------------------------- */}

                                <div className="h-[74px] py-2 flex border-b">
                                  <p className="font-semibold w-[120px] mt-3">
                                    End Time
                                  </p>
                                  <div className="flex flex-col items-center px-4">
                                    <ZeroHourDatePicker
                                      shift={shift}
                                      time={formatTimeInTZ(
                                        shift.endTime,
                                        "DD MMM YYYY HH:mm"
                                      )}
                                      value="endTime"
                                      selectTime={selectTime}
                                    />
                                  </div>
                                </div>

                                {/* Breaks ----------------------------------------------- */}

                                <div className="h-[74px] py-2 flex border-b">
                                  <p className="font-semibold w-[120px] mt-3">
                                    Breaks
                                  </p>
                                  <div className="flex flex-col items-center justify-center px-4">
                                    <input
                                      className="w-[184px] h-[24px] border rounded-lg text-center font-semibold cursor-pointer p-4 mb-2"
                                      type="number"
                                      min="0"
                                      value={shift?.breakMinutes}
                                      onChange={(e) =>
                                        handleBreaks(shift, e.target.value)
                                      }
                                    />
                                    <p>Mins</p>
                                  </div>
                                </div>

                                {/* Reason ----------------------------------------------- */}

                                {shift.showReason && (
                                  <div className="h-[66px] flex items-center border-b">
                                    <p className="mr-3 font-semibold">Reason</p>
                                    <input
                                      className="w-[170px] mr-4 h-[38px] border border-gray-300 p-2 rounded-lg"
                                      type="input"
                                      placeholder="Reason for Change"
                                      value={shift.reason ?? ""}
                                      onChange={(e) =>
                                        handleReason(shift, e.target.value)
                                      }
                                    />
                                    <DefaultButton
                                      type={"grey"}
                                      text={"Reset"}
                                      onClick={() => handleReset(shift)}
                                    />
                                  </div>
                                )}
                              </div>

                              {/* Shift Info ----------------------------------------------- */}

                              <p className="absolute top-[-4px] font-bold text-lg">
                                Shift {index + 1} of {checkedShifts.length}
                              </p>
                            </div>

                            {shift.shouldEnforceBreaks &&
                              minAllowedBreaks > 0 && (
                                <div className="flex items-start justify-center mt-4">
                                  <BreaksIcon />
                                  <p className="mb-2 ml-2 font-[400]">
                                    You can't submit a break shorter than{" "}
                                    <strong>({minAllowedBreaks})</strong>{" "}
                                    minutes as your organisation has implemented
                                    a minimum break duration.
                                  </p>
                                </div>
                              )}

                            {rejectionMessage && (
                              <p className="font-[600] text-center mt-3 whitespace-pre-wrap">
                                {rejectionMessage}
                              </p>
                            )}
                          </>
                        );
                      }
                      return null;
                    })}
                  </div>

                  <button
                    className="justify-center hidden w-20 sm:flex"
                    onClick={() => handleSlide("next")}
                  >
                    <TimeSheetRightIcon />
                  </button>
                </div>

                <div className="flex justify-center mt-4">
                  {checkedShifts.map((shift, index) => (
                    <button
                      key={shift.id}
                      className={`w-3 h-3 m-2 rounded-full transition  ${
                        index + 1 === activeSlide
                          ? "bg-gray-500"
                          : "bg-gray-200"
                      }`}
                      onClick={() => handleDotClick(index + 1)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Modal Fraud Declaration ---------------------------------------------------- */}

          {tab === 3 && (
            <div className="min-h-[410px] p-8">
              <p>
                {modalShifts[0].organisation.fraudDeclarationStatement ?? ""}
              </p>
            </div>
          )}

          {/* Modal Footer ----------------------------------------------------------- */}

          <TimesheetsModalFooter
            inputs={inputs}
            usesFraudDeclaration={usesFraudDeclaration}
            viewFraudDeclaration={viewFraudDeclaration}
            setInputs={setInputs}
            resetValues={resetValues}
            handleToggle={handleToggle}
            handleFraudToggle={handleFraudToggle}
            handleSignoff={handleSignoff}
          />
        </div>
      </Modal>
    </>
  );
};

export default TimesheetsModal;
