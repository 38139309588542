export const leaveEntitlementDurationString = (
  magnitude: number | undefined | null,
  unit: string | undefined | null,
  hoursInDay: number | undefined | null
): string => {
  if (magnitude === undefined || magnitude === null) return "N/A";
  if (unit?.toLowerCase() === "pas" || unit?.toLowerCase() === "pa")
    return `${magnitude} PAs`;
  if (unit?.toLowerCase() === "days" || unit?.toLowerCase() === "day")
    return daysAndHoursString(magnitude, hoursInDay);
  return "N/A";
};

export const daysAndHoursString = (
  days: number | undefined | null,
  hoursInDay: number | undefined | null
): string => {
  if (!days) return "0 Days (0 Hours)";
  if (days < 0) return "error - end before start";
  if (!hoursInDay) hoursInDay = 8;

  const daysIsInteger = Number.isInteger(days);
  const roundedDays = daysIsInteger ? days : roundTo2DP(days);
  const roundedHours = daysIsInteger
    ? days * hoursInDay
    : roundTo2DP(days * hoursInDay);

  return days === 1
    ? `1 Day (${hoursInDay} Hours)`
    : `${roundedDays} Days (${roundedHours} Hours)`;
};

const roundTo2DP = (decimal: number) => Number(decimal.toFixed(2)).toString();
