import dayjs from "dayjs";
import styled from "styled-components";

import { GREY, Loading, RBBLACK } from "@patchworkhealth/web-components";

import { RosteringLeaveRequestsQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { LeaveRequestsRow } from "components/LeaveManagement/LeaveGridHeader/LeaveGridHeader.styled";
import { LeaveStatus } from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable";
import { leaveEntitlementDurationString } from "helpers/leaveEntitlementDurationString";

import { RosteringLeaveRequestStatusEnum } from "../../../baseCODEGEN";

interface Props {
  leaveRequests: RosteringLeaveRequestsQuery["rosteringLeaveRequests"];
  loading: boolean;
  onOpenRecord: (leaveRequestId: string) => void;
}

const LEAVE_TABLE_DATE_FORMAT = "Do MMM YYYY / hh:mma";

export const LeaveRecordTableColumns = ({
  leaveRequests,
  loading,
  onOpenRecord,
}: Props) => {
  if (loading)
    return (
      <CenteredContainer>
        <Loading />
      </CenteredContainer>
    );
  if (leaveRequests?.length === 0)
    return (
      <CenteredContainer>
        <EmptyStateText>You have no leave records yet.</EmptyStateText>
      </CenteredContainer>
    );

  return (
    <>
      {leaveRequests?.map((leaveRequest) => {
        const measurementType =
          leaveRequest?.rosteringLeaveEntitlement?.measurementType === "days"
            ? "Day"
            : "PA";

        return (
          <LeaveRequestsRow
            data-testid="leave_requests_row"
            key={leaveRequest.id}
          >
            <div>
              <p data-testid="leave_dates">
                {dayjs(leaveRequest.startDate).format(LEAVE_TABLE_DATE_FORMAT)}{" "}
                - {dayjs(leaveRequest.endDate).format(LEAVE_TABLE_DATE_FORMAT)}
              </p>
              <LeaveDeductionName data-testid="leave_deduction_name">
                Deduction:{" "}
                {leaveRequest.leaveFlowType === "regular" ||
                leaveRequest.leaveFlowType === "normal"
                  ? "Rostered"
                  : "Unrostered"}
              </LeaveDeductionName>
            </div>

            <p data-testid="leave_amount">
              {leaveEntitlementDurationString(
                leaveRequest.total,
                measurementType,
                leaveRequest.hoursInDay
              )}
            </p>
            <p data-testid="leave_type">
              {leaveRequest?.rosteringLeaveType?.leaveType?.replace(
                /^./,
                (str) => str.toUpperCase()
              )}{" "}
              Leave
            </p>
            <LeaveStatus
              status={leaveRequest.status as RosteringLeaveRequestStatusEnum}
            >
              {leaveRequest.status}
            </LeaveStatus>
            <LeaveButton
              data-testid="leave_view"
              onClick={() => onOpenRecord(leaveRequest.id)}
            >
              {leaveRequest.status === "Rejected" ? "Re-submit" : "View"}
            </LeaveButton>
          </LeaveRequestsRow>
        );
      })}
    </>
  );
};

const EmptyStateText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${RBBLACK.three};
  padding-top: 10px;
`;

const LeaveButton = styled.div`
  align-items: center;
  color: ${GREY.five};
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-decoration-line: underline;
`;

const LeaveDeductionName = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${GREY.six};
  && {
    margin-top: 5px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;
