export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  ISO8601DateTime: any;
  JSON: any;
  Upload: any;
};

/** Autogenerated return type of AcceptInvite */
export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload';
  account?: Maybe<Account>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of AcceptLeaveRequestApproval */
export type AcceptLeaveRequestApprovalPayload = {
  __typename?: 'AcceptLeaveRequestApprovalPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequestApproval?: Maybe<LeaveRequestApproval>;
};

/** Autogenerated return type of AcceptRequest */
export type AcceptRequestPayload = {
  __typename?: 'AcceptRequestPayload';
  errors: Array<ValidationError>;
  /** The shift swap request */
  rosteringShiftSwapRequest?: Maybe<Request>;
  success: Scalars['Boolean'];
};

export type Account = {
  __typename?: 'Account';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitationAcceptedAt?: Maybe<Scalars['String']>;
  invitationToken?: Maybe<Scalars['String']>;
  productTypes?: Maybe<Array<ProductTypeEnum>>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating an Account */
export type AccountAttributes = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  regBodyNumber?: InputMaybe<Scalars['String']>;
  staffGroup?: InputMaybe<StaffGroupEnum>;
};

export type Action = {
  __typename?: 'Action';
  createdAt: Scalars['DateTime'];
  hubUserName: Scalars['String'];
  id: Scalars['Int'];
};

/** Autogenerated return type of ActionCancellationRequest */
export type ActionCancellationRequestPayload = {
  __typename?: 'ActionCancellationRequestPayload';
  errors: Array<ValidationError>;
  /** Actioned Leave Request Approval */
  rosteringLeaveRequestApproval?: Maybe<LeaveRequestApproval>;
};

/** The connection type for Action. */
export type ActionConnection = {
  __typename?: 'ActionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActionEdge>>>;
  /** A list of nodes. */
  nodes: Array<Action>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ActionEdge = {
  __typename?: 'ActionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Action>;
};

/** Autogenerated return type of ActivateAccount */
export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  accountActive?: Maybe<Scalars['Boolean']>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of ActivateWorker */
export type ActivateWorkerPayload = {
  __typename?: 'ActivateWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** ID and name of Rostering Organisation Activity, with nested Sites and Locations data */
export type ActivityWithNestedAssociations = {
  __typename?: 'ActivityWithNestedAssociations';
  /** Activity ID */
  id: Scalars['ID'];
  /** Activity name */
  name: Scalars['String'];
  /** Sites associated with the Activity */
  sites: Array<SiteOfActivities>;
};

/** Autogenerated return type of AddActivity */
export type AddActivityPayload = {
  __typename?: 'AddActivityPayload';
  /**
   * The newly added Activity, with nested Sites and Locations for rendering\
   *             on Activity by Date view
   */
  activity?: Maybe<ActivityWithNestedAssociations>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of AddEsrNumber */
export type AddEsrNumberPayload = {
  __typename?: 'AddEsrNumberPayload';
  errors: Array<ValidationError>;
  esrNumber?: Maybe<EsrNumber>;
};

/** Autogenerated return type of AddEvent */
export type AddEventPayload = {
  __typename?: 'AddEventPayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of AddMultipleWorkerPatternsToRoster */
export type AddMultipleWorkerPatternsToRosterPayload = {
  __typename?: 'AddMultipleWorkerPatternsToRosterPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Newly created patterns on roster */
  workerPatternsOnRoster?: Maybe<Array<WorkerPatternOnRoster>>;
};

/** Autogenerated return type of AddShifts */
export type AddShiftsPayload = {
  __typename?: 'AddShiftsPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of AddSkillToOrgStaffGroup */
export type AddSkillToOrgStaffGroupPayload = {
  __typename?: 'AddSkillToOrgStaffGroupPayload';
  errors: Array<ValidationError>;
  orgStaffGroupsSkill?: Maybe<OrgStaffGroupSkill>;
};

/** Autogenerated return type of AddToPatternView */
export type AddToPatternViewPayload = {
  __typename?: 'AddToPatternViewPayload';
  errors: Array<ValidationError>;
  /** Newly created Schedules */
  rosteringSchedules?: Maybe<Array<RosteringSchedule>>;
};

/** Autogenerated return type of AddWeek */
export type AddWeekPayload = {
  __typename?: 'AddWeekPayload';
  errors: Array<ValidationError>;
  /** The worker pattern with the new week */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Autogenerated return type of AddWorkerPatternToRoster */
export type AddWorkerPatternToRosterPayload = {
  __typename?: 'AddWorkerPatternToRosterPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Newly created pattern on roster */
  workerPatternOnRoster?: Maybe<WorkerPatternOnRoster>;
};

/** Autogenerated return type of AddWorkerUploadFromHub */
export type AddWorkerUploadFromHubPayload = {
  __typename?: 'AddWorkerUploadFromHubPayload';
  /** returns Document type obj */
  documentType?: Maybe<DocumentType>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of AddWorkerUpload */
export type AddWorkerUploadPayload = {
  __typename?: 'AddWorkerUploadPayload';
  documentType?: Maybe<DocumentType>;
  errors: Array<ValidationError>;
};

/** Enum of receipt status */
export enum AdvancedEscalationMethodEnum {
  /** Escalate on behalf of an authoriser (`Wendy, ok if I escalate if!?`). */
  ApprovalOnBehalf = 'APPROVAL_ON_BEHALF',
  /** Request escalation and wait for an authoriser to approve/reject. */
  Request = 'REQUEST',
  /** Self escalate, without seeking of approval. */
  SelfApproval = 'SELF_APPROVAL'
}

/** Paginated list of Agencies */
export type AgenciesPaginatedList = {
  __typename?: 'AgenciesPaginatedList';
  list?: Maybe<Array<Agency>>;
  pagination?: Maybe<Pagination>;
};

/** Fields to be returnable for an agency through our API */
export type Agency = {
  __typename?: 'Agency';
  /** Agency worker list */
  agenciesLocums?: Maybe<Array<AgencyWorker>>;
  /** Agency tiers list */
  agencyTiers?: Maybe<Array<AgencyTier>>;
  /** Agency users list */
  agencyUsers?: Maybe<Array<AgencyUser>>;
  /** Agency can manage users */
  canManageUsers?: Maybe<Scalars['Boolean']>;
  /** Timestamp when agency was created */
  createdAt?: Maybe<Scalars['String']>;
  /** Agency is no longer active */
  deactivated?: Maybe<Scalars['Boolean']>;
  /** Description of the agency */
  description?: Maybe<Scalars['String']>;
  /** ID of the agency */
  id: Scalars['ID'];
  /** Email that agency is notified at */
  notificationEmail?: Maybe<Scalars['String']>;
  /** Default organisation */
  organisation?: Maybe<Organisation>;
  /** Organisation agencies list */
  organisationAgencies?: Maybe<Array<OrganisationAgency>>;
  /** Associated organisations */
  organisations?: Maybe<Array<Organisation>>;
  /** Shorthand title for full title */
  shortTitle?: Maybe<Scalars['String']>;
  /** Title of the Agency */
  title?: Maybe<Scalars['String']>;
};

export type AgencyAdjustment = {
  __typename?: 'AgencyAdjustment';
  adjustmentReason: Scalars['String'];
  adjustmentType: Scalars['String'];
  endTime: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  shift: Shift;
  startTime: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of AgencyBook */
export type AgencyBookPayload = {
  __typename?: 'AgencyBookPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<AgencyShift>;
};

export type AgencyBooking = {
  __typename?: 'AgencyBooking';
  agency: Agency;
  agencyWorker?: Maybe<AgencyWorker>;
  assignor?: Maybe<Assignor>;
  coreAgencyMargin?: Maybe<Scalars['Float']>;
  coreLocumRate?: Maybe<Scalars['Float']>;
  coreNiContribution?: Maybe<Scalars['Float']>;
  coreTotalRate?: Maybe<Scalars['Float']>;
  directEngagement?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  poNumber?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  requisitionNumber?: Maybe<Scalars['String']>;
  splitRate?: Maybe<Scalars['Boolean']>;
  unsocialAgencyMargin?: Maybe<Scalars['Float']>;
  unsocialLocumRate?: Maybe<Scalars['Float']>;
  unsocialNiContribution?: Maybe<Scalars['Float']>;
  unsocialTotalRate?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of AgencyCancelBooking */
export type AgencyCancelBookingPayload = {
  __typename?: 'AgencyCancelBookingPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<AgencyShift>;
};

/** The connection type for Agency. */
export type AgencyConnection = {
  __typename?: 'AgencyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyEdge>>>;
  /** A list of nodes. */
  nodes: Array<Agency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AgencyEdge = {
  __typename?: 'AgencyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Agency>;
};

/** Autogenerated return type of AgencyInvitation */
export type AgencyInvitationPayload = {
  __typename?: 'AgencyInvitationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type AgencyRateInput = {
  /** The agency margin for the adjustment */
  agencyMargin: Scalars['Float'];
  /** The comparator rate for the adjustment */
  comparatorRate: Scalars['Float'];
  /** The ID of the adjustment */
  id?: InputMaybe<Scalars['ID']>;
  /** The worker base rate for the adjustment */
  workerBaseRate: Scalars['Float'];
  /** The worker rate for the adjustment */
  workerRate: Scalars['Float'];
};

/** AgencyRegistration */
export type AgencyRegistration = {
  __typename?: 'AgencyRegistration';
  agency?: Maybe<Agency>;
  companyAddressLine1?: Maybe<Scalars['String']>;
  companyAddressLine2?: Maybe<Scalars['String']>;
  companyAddressPostcode?: Maybe<Scalars['String']>;
  companyAddressTown?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyNumber?: Maybe<Scalars['String']>;
  companyVatNumber?: Maybe<Scalars['String']>;
  complianceExpirationDate?: Maybe<Scalars['Date']>;
  compliancePackUrl?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['Date']>;
  cvUrl?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  esrNumbers?: Maybe<Array<EsrNumber>>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  invitedAt?: Maybe<Scalars['DateTime']>;
  isSoleTrader?: Maybe<Scalars['Boolean']>;
  isVatRegistered?: Maybe<Scalars['Boolean']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  specialities?: Maybe<Array<Speciality>>;
  staffGroup?: Maybe<StaffGroup>;
  trashedAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

/** The connection type for AgencyRegistration. */
export type AgencyRegistrationConnection = {
  __typename?: 'AgencyRegistrationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyRegistrationEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyRegistration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AgencyRegistrationEdge = {
  __typename?: 'AgencyRegistrationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyRegistration>;
};

/** Agency Registration file upload enums */
export enum AgencyRegistrationFileUploadEnum {
  CompliancePack = 'COMPLIANCE_PACK',
  Cv = 'CV'
}

/** Paginated list of AgencyRegistrations */
export type AgencyRegistrationsPaginatedList = {
  __typename?: 'AgencyRegistrationsPaginatedList';
  /** List of Agency Workers */
  list?: Maybe<Array<AgencyWorker>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Agency Self-Bill Batch */
export type AgencySelfBillBatch = {
  __typename?: 'AgencySelfBillBatch';
  /** The date and time when the Batch was created. */
  createdAt: Scalars['DateTime'];
  /** URL to download Batch CSV file. */
  csvUrl?: Maybe<Scalars['String']>;
  /** Batch creator Details */
  hubUser: HubUser;
  /** Batch ID. */
  id: Scalars['Int'];
  /** Batch Organisation. */
  organisation: Organisation;
  /** Batch Org Staff Group */
  organisationStaffGroup: OrganisationStaffGroup;
  /** Batch Id - For accessing batch with ID */
  reference: Scalars['String'];
  /** number of shifts in the Batch. */
  shiftCount: Scalars['Int'];
  /** Lists of the shifts associated with the Batch. */
  shifts: Array<Shift>;
  /** Batch processing Start Time */
  startProcessingTime: Scalars['DateTime'];
  /** URL to download Batch ZIP file. */
  zipUrl?: Maybe<Scalars['String']>;
};

export type AgencyShift = {
  __typename?: 'AgencyShift';
  actualEndTime?: Maybe<Scalars['DateTime']>;
  actualStartTime?: Maybe<Scalars['DateTime']>;
  adminNote?: Maybe<Scalars['String']>;
  agencyBooking?: Maybe<AgencyBooking>;
  agencyLocked?: Maybe<Scalars['Boolean']>;
  agencyRateCardAttached?: Maybe<Scalars['Boolean']>;
  agencyRateSummary?: Maybe<Scalars['String']>;
  agencyRegistration?: Maybe<AgencyRegistration>;
  agencyTier?: Maybe<AgencyTier>;
  agencyWorkerDocsPresent?: Maybe<Scalars['Boolean']>;
  agencyWorkerProposals: AgencyWorkerProposalConnection;
  agencyWorkerRates?: Maybe<Array<AgencyWorkerProposalRates>>;
  agreedFraudStatementAt?: Maybe<Scalars['DateTime']>;
  appliedBy?: Maybe<Array<OrganisationWorker>>;
  appliedByShiftLimits?: Maybe<Array<OrganisationWorkerShiftLimits>>;
  archived?: Maybe<Scalars['Boolean']>;
  auditEvents?: Maybe<Array<AuditEvent>>;
  autoCascadeTriggers?: Maybe<Array<Trigger>>;
  availableActions: Array<Scalars['String']>;
  availableForAgencyUserActions: Array<Scalars['String']>;
  availableShiftActionsToHubUser?: Maybe<Array<Scalars['String']>>;
  bankRateCardAttached: Scalars['Boolean'];
  batchId?: Maybe<Scalars['Int']>;
  batchReference?: Maybe<Scalars['String']>;
  bookedBy?: Maybe<OrganisationWorker>;
  bookedBySecondaryBankWorker?: Maybe<Scalars['Boolean']>;
  breakMinutes: Scalars['Int'];
  breaks?: Maybe<Scalars['Int']>;
  breaksExceptionNotes?: Maybe<Scalars['String']>;
  bulkPaymentApproveUuid?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  confirmedBy?: Maybe<Scalars['String']>;
  confirmedEmail?: Maybe<Scalars['String']>;
  confirmedPosition?: Maybe<Scalars['String']>;
  confirmedSignature?: Maybe<Scalars['String']>;
  coreHours: Scalars['Float'];
  coreTotalRate?: Maybe<Scalars['Float']>;
  costCentre?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dateSentToAgency?: Maybe<Scalars['DateTime']>;
  deContractVersionAssignment?: Maybe<VersionAssignment>;
  deleted?: Maybe<Scalars['Boolean']>;
  department: Department;
  departmentSpecialties?: Maybe<Array<Speciality>>;
  didNotAttend?: Maybe<DidNotAttend>;
  draftPayElements: Array<PayElement>;
  endTime: Scalars['DateTime'];
  enforceFraudDeclarationStatement?: Maybe<Scalars['Boolean']>;
  enhancements?: Maybe<Scalars['Float']>;
  escalatedBy?: Maybe<Scalars['String']>;
  escalationRequested?: Maybe<Scalars['Boolean']>;
  esrNumberId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalNote?: Maybe<Scalars['String']>;
  externalSkills?: Maybe<Array<ExternalSkill>>;
  externalUnit?: Maybe<ExternalUnit>;
  grade: Grade;
  gradeBreakMinutes?: Maybe<Scalars['Int']>;
  gradeBreakRepeatMinutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Rates include benefits (eg WTD) */
  includesBenefit?: Maybe<Scalars['Boolean']>;
  integrationShift?: Maybe<IntegrationShift>;
  ir35: Scalars['String'];
  limitToPreferred?: Maybe<Scalars['Boolean']>;
  locumFirstName?: Maybe<Scalars['String']>;
  locumGrade?: Maybe<Scalars['String']>;
  locumLastName?: Maybe<Scalars['String']>;
  locumNote?: Maybe<Scalars['String']>;
  locumType?: Maybe<Scalars['Int']>;
  minimumBreakDuration?: Maybe<Scalars['Int']>;
  minimumRequiredBreak?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  onCall: Scalars['Boolean'];
  onCallWithTimeframes?: Maybe<Scalars['Boolean']>;
  organisation: Organisation;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  paid?: Maybe<Scalars['Boolean']>;
  pamAgencyWorker?: Maybe<AgencyWorker>;
  payElements: Array<PayElement>;
  paymentApproved?: Maybe<Scalars['Boolean']>;
  paymentApproverNotes?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Payment>>;
  payrollAdjustment?: Maybe<AgencyAdjustment>;
  positionManagementCode?: Maybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  rate?: Maybe<Scalars['Float']>;
  rateElements?: Maybe<Array<PayElement>>;
  rateRequest?: Maybe<RateChange>;
  reasonForRequest?: Maybe<ReasonForRequest>;
  reasonForRequestId?: Maybe<Scalars['Int']>;
  releasedTo?: Maybe<Array<Scalars['String']>>;
  requestedBy?: Maybe<ShiftRequestor>;
  requiresDeContractToSign?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
  rpaLocked?: Maybe<Scalars['Boolean']>;
  rpaLockedReason?: Maybe<Scalars['String']>;
  samAgencyWorker?: Maybe<SamAgencyWorker>;
  selfBooking?: Maybe<Scalars['Boolean']>;
  sentFromRostering?: Maybe<Scalars['Boolean']>;
  sentToAgency?: Maybe<Scalars['Boolean']>;
  shiftBlock?: Maybe<ShiftBlock>;
  shiftBlockId?: Maybe<Scalars['ID']>;
  shiftGrade?: Maybe<Scalars['String']>;
  shiftType?: Maybe<ShiftTypeEnum>;
  shiftsSeries: ShiftsSeries;
  shouldEnforceBreaks?: Maybe<Scalars['Boolean']>;
  signOffNonAmendedOrRejectedRequests: Scalars['Boolean'];
  signOffRequested?: Maybe<Scalars['Boolean']>;
  site: Site;
  skills: Array<Skill>;
  staffGroup: StaffGroup;
  staffGroupId?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  status: ShiftStatus;
  timeframes?: Maybe<Array<OnCallShiftTimeframe>>;
  timesheetAuthoriser?: Maybe<Scalars['String']>;
  totalAgencyPay?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  totalPay?: Maybe<Scalars['Float']>;
  totalPayPence?: Maybe<Scalars['Int']>;
  totalRateSummary?: Maybe<Scalars['String']>;
  unsocialHours: Scalars['Float'];
  unsocialTotalRate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBookedShift?: Maybe<UserBookedShift>;
  usesFiftyPercentRule: Scalars['Boolean'];
  verified?: Maybe<Scalars['Boolean']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedId?: Maybe<Scalars['Int']>;
  watchingHubUsers?: Maybe<Array<HubUser>>;
  withSecondaryBank: Scalars['Boolean'];
  workerName?: Maybe<Scalars['String']>;
  /** Remote sign off reported and confirmed times */
  workerSignOff?: Maybe<ShiftWorkerSignOff>;
};


export type AgencyShiftAgencyWorkerProposalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AgencyTier = {
  __typename?: 'AgencyTier';
  agencies?: Maybe<Array<Agency>>;
  id: Scalars['ID'];
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  rank?: Maybe<Scalars['Int']>;
  tiersIds?: Maybe<Array<Scalars['Int']>>;
};

/** Graphql agency user response type */
export type AgencyUser = {
  __typename?: 'AgencyUser';
  /** Agency user belongs to */
  agency?: Maybe<Agency>;
  /** Agency user organisations list */
  agencyUserOrganisations?: Maybe<Array<AgencyUserOrganisation>>;
  /** Agency user permissions list */
  agencyUserPermissions?: Maybe<Array<AgencyUserPermission>>;
  /** Permission to create agency workers */
  canCreateAgencyWorkers?: Maybe<Scalars['Boolean']>;
  canManageUsers?: Maybe<Scalars['Boolean']>;
  confirmedAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intercomKey?: Maybe<Scalars['String']>;
  invitationCreatedAt?: Maybe<Scalars['String']>;
  /** Flag to assign super agency user permissions */
  isSuperUser?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organisations?: Maybe<Array<Organisation>>;
  phoneNumber?: Maybe<Scalars['String']>;
  suspended?: Maybe<Scalars['Boolean']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
};

/** Graphql AgencyUserAgency model return type */
export type AgencyUserAgency = {
  __typename?: 'AgencyUserAgency';
  /** Agency that it belongs to */
  agency: Agency;
  /** Agency user that is belongs to */
  agencyUser: AgencyUser;
  /** Permission to create agency works */
  canCreateAgencyWorkers?: Maybe<Scalars['Boolean']>;
  /** Permission to manage users */
  canManageUsers?: Maybe<Scalars['Boolean']>;
  /** ID of model */
  id?: Maybe<Scalars['ID']>;
};

/** The connection type for AgencyUser. */
export type AgencyUserConnection = {
  __typename?: 'AgencyUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyUserEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AgencyUserEdge = {
  __typename?: 'AgencyUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyUser>;
};

/** Agency User Organisation Type */
export type AgencyUserOrganisation = {
  __typename?: 'AgencyUserOrganisation';
  /** Agency User Organisation is active */
  active?: Maybe<Scalars['Boolean']>;
  /** The ID of the Agency User */
  agencyUserId?: Maybe<Scalars['Int']>;
  /** The ID of the Organisation */
  hospitalId?: Maybe<Scalars['Int']>;
  /** The Agency User Organisation ID */
  id: Scalars['ID'];
  /** The associated Organisation record */
  organisation?: Maybe<Organisation>;
};

export type AgencyUserPermission = {
  __typename?: 'AgencyUserPermission';
  agency: Agency;
  agencyUser: AgencyUser;
  canAssignWorker: Scalars['Boolean'];
  canProposeWorker: Scalars['Boolean'];
  id: Scalars['ID'];
  organisation?: Maybe<Organisation>;
  organisationStaffGroup: OrganisationStaffGroup;
};

/** The connection type for AgencyUserPermission. */
export type AgencyUserPermissionConnection = {
  __typename?: 'AgencyUserPermissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyUserPermissionEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyUserPermission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AgencyUserPermissionEdge = {
  __typename?: 'AgencyUserPermissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyUserPermission>;
};

/** Paginated list of AgencyUsers */
export type AgencyUsersPaginatedList = {
  __typename?: 'AgencyUsersPaginatedList';
  list?: Maybe<Array<AgencyUser>>;
  pagination?: Maybe<Pagination>;
};

export type AgencyWorker = {
  __typename?: 'AgencyWorker';
  agency?: Maybe<Agency>;
  compliancePackUrl?: Maybe<Scalars['String']>;
  cvUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employmentType?: Maybe<Scalars['String']>;
  esrNumbers?: Maybe<Array<EsrNumber>>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  isSoleTrader?: Maybe<Scalars['Boolean']>;
  isVatRegistered?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  niNumber?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  regBodyNumber?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  specialities?: Maybe<Array<Speciality>>;
  staffGroup?: Maybe<StaffGroup>;
  user?: Maybe<User>;
};

/** Agency Worker Proposal on a Block */
export type AgencyWorkerBlockProposal = {
  __typename?: 'AgencyWorkerBlockProposal';
  agencyUser?: Maybe<AgencyUser>;
  agencyWorker?: Maybe<AgencyWorker>;
  agencyWorkerDocs?: Maybe<Array<Documentation>>;
  id: Scalars['ID'];
  poNumber: Scalars['String'];
  rates?: Maybe<Array<AgencyWorkerProposalRates>>;
  receiptNumber: Scalars['String'];
  requisitionNumber: Scalars['String'];
  shiftBlock?: Maybe<ShiftBlock>;
  shortlistedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

/** The connection type for AgencyWorker. */
export type AgencyWorkerConnection = {
  __typename?: 'AgencyWorkerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyWorkerEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyWorker>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AgencyWorkerEdge = {
  __typename?: 'AgencyWorkerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyWorker>;
};

export type AgencyWorkerProposal = {
  __typename?: 'AgencyWorkerProposal';
  agencyUser?: Maybe<AgencyUser>;
  agencyWorker?: Maybe<AgencyWorker>;
  agencyWorkerDocs?: Maybe<Array<Documentation>>;
  coreAgencyMargin?: Maybe<Scalars['Float']>;
  coreLocumRate?: Maybe<Scalars['Float']>;
  coreNiContribution?: Maybe<Scalars['Float']>;
  coreTotalRate?: Maybe<Scalars['Float']>;
  directEngagement?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  poNumber?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<AgencyWorkerProposalRates>>;
  receiptNumber?: Maybe<Scalars['String']>;
  requisitionNumber?: Maybe<Scalars['String']>;
  shift?: Maybe<AgencyShift>;
  shortlistedAt?: Maybe<Scalars['DateTime']>;
  splitRate?: Maybe<Scalars['Boolean']>;
  totalAgencyMargin?: Maybe<Scalars['Float']>;
  totalComparatorRate?: Maybe<Scalars['Float']>;
  totalComparatorRateIncVat?: Maybe<Scalars['Float']>;
  unsocialAgencyMargin?: Maybe<Scalars['Float']>;
  unsocialLocumRate?: Maybe<Scalars['Float']>;
  unsocialNiContribution?: Maybe<Scalars['Float']>;
  unsocialTotalRate?: Maybe<Scalars['Float']>;
};

/** Autogenerated return type of AgencyWorkerProposalAccept */
export type AgencyWorkerProposalAcceptPayload = {
  __typename?: 'AgencyWorkerProposalAcceptPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** The connection type for AgencyWorkerProposal. */
export type AgencyWorkerProposalConnection = {
  __typename?: 'AgencyWorkerProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AgencyWorkerProposalEdge>>>;
  /** A list of nodes. */
  nodes: Array<AgencyWorkerProposal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of AgencyWorkerProposalCreate */
export type AgencyWorkerProposalCreatePayload = {
  __typename?: 'AgencyWorkerProposalCreatePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AgencyWorkerProposalDecline */
export type AgencyWorkerProposalDeclinePayload = {
  __typename?: 'AgencyWorkerProposalDeclinePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AgencyWorkerProposalDelete */
export type AgencyWorkerProposalDeletePayload = {
  __typename?: 'AgencyWorkerProposalDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type AgencyWorkerProposalEdge = {
  __typename?: 'AgencyWorkerProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AgencyWorkerProposal>;
};

export type AgencyWorkerProposalInitialRates = {
  __typename?: 'AgencyWorkerProposalInitialRates';
  agencyMargin?: Maybe<Scalars['Float']>;
  comparatorRate?: Maybe<Scalars['Float']>;
  ltdUmbrellaRate?: Maybe<Scalars['Float']>;
  workerBaseRate?: Maybe<Scalars['Float']>;
  workerPayeRate?: Maybe<Scalars['Float']>;
  workerRate?: Maybe<Scalars['Float']>;
};

export type AgencyWorkerProposalRates = {
  __typename?: 'AgencyWorkerProposalRates';
  agencyChargeIncVat?: Maybe<Scalars['Float']>;
  agencyMargin?: Maybe<Scalars['Float']>;
  agencyMarginBreakdown?: Maybe<Scalars['String']>;
  agencyMarginLocked?: Maybe<Scalars['Boolean']>;
  breakHours?: Maybe<Scalars['Float']>;
  canEditChargeRate?: Maybe<Scalars['Boolean']>;
  comparatorRate?: Maybe<Scalars['Float']>;
  comparatorRateBreakdown?: Maybe<Scalars['String']>;
  comparatorRateIncVat?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ValidationError>>;
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  initialRates?: Maybe<AgencyWorkerProposalInitialRates>;
  ltdUmbrellaRate?: Maybe<Scalars['Float']>;
  ltdUmbrellaRateBreakdown?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  niContribution?: Maybe<Scalars['Float']>;
  payElementName?: Maybe<Scalars['String']>;
  workerBaseRate?: Maybe<Scalars['Float']>;
  workerPayeRate?: Maybe<Scalars['Float']>;
  workerPayeRateBreakdown?: Maybe<Scalars['String']>;
  /** Virtual attribute, which depending on context may mean PAYE rate or LTD/Umbrella rate */
  workerRate?: Maybe<Scalars['Float']>;
  workerRateBreakdown?: Maybe<Scalars['String']>;
  wtd?: Maybe<Scalars['Float']>;
};

/** Paginated list of AgencyWorkers */
export type AgencyWorkersPaginatedList = {
  __typename?: 'AgencyWorkersPaginatedList';
  list?: Maybe<Array<AgencyWorker>>;
  pagination?: Maybe<Pagination>;
};

/** Represents list of Signed Agreements for Org and CollabBank */
export type AllSignedAgreement = {
  __typename?: 'AllSignedAgreement';
  /** list CollabBankAgreementType */
  collabBankAgreements?: Maybe<Array<CollabBankSignedAgreement>>;
  /** list of SignedAgreementType */
  signedAgreements?: Maybe<Array<SignedAgreement>>;
};

/**
 * Annual Pay Breakdown calculations for a Worker Pattern, based on\
 *       figures in an associated Rostering Rate Card
 */
export type AnnualPayBreakdown = {
  __typename?: 'AnnualPayBreakdown';
  /** Average hours worked above 40 per week */
  averageAdditionalHours: Scalars['Float'];
  /** Average hours worked in unsocial hours */
  averageUnsocialHours: Scalars['Float'];
  /** Gross annual salary excluding allowances */
  basicAnnualSalary: Scalars['Float'];
  /**
   * Whether on-call allowance\
   *       flat rate is included
   */
  includeOnCallAllowance: Scalars['Boolean'];
  /** Annual flat rate for LTFT workers */
  lessThanFullTimeAllowance?: Maybe<Scalars['Float']>;
  /** Annual flat rate for on-call duties */
  onCallAllowance?: Maybe<Scalars['Float']>;
  /** Extra pay for hours above 40 per week */
  payForAdditionalHours: Scalars['Float'];
  /** Extra pay for unsocial hours worked */
  payForUnsocialHours: Scalars['Float'];
  /** associated Rostering Rate Card */
  rosteringRateCard: RateCard;
  /** Total gross salary including allowances */
  totalSalary: Scalars['Float'];
  /** Allowance for working weekend duties */
  weekendAllowance: Scalars['Float'];
  /** Weekend banding as a percentage */
  weekendBanding: Scalars['Float'];
  /** Frequency weekends worked 1 in X this field is the X */
  weekendFrequency: Scalars['Int'];
  /** associated Worker Pattern */
  workerPattern: WorkerPattern;
};

export type AppliedBy = {
  __typename?: 'AppliedBy';
  id: Scalars['Int'];
};

/** Autogenerated return type of ApplyToOrganisationAndCollaborativeBank */
export type ApplyToOrganisationAndCollaborativeBankPayload = {
  __typename?: 'ApplyToOrganisationAndCollaborativeBankPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
  workerCollaborativeBank?: Maybe<WorkerCollaborativeBank>;
};

/**
 * Used for filtering Rostering Leave Request Approvals\
 *         according to whether they require action
 */
export enum ApprovalActionRequiredEnum {
  /** Leave Request Approval is not pending, OR the associated Request is not pending */
  Actioned = 'actioned',
  /** Leave Request Approval is pending, AND the associated Request is pending */
  RequiresAction = 'requires_action'
}

/** Autogenerated return type of ApproveApplication */
export type ApproveApplicationPayload = {
  __typename?: 'ApproveApplicationPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of ApproveRateChange */
export type ApproveRateChangePayload = {
  __typename?: 'ApproveRateChangePayload';
  errors: Array<ValidationError>;
  /** Rate change */
  rateChange?: Maybe<RateChange>;
  /** Success indicator */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ApproveShiftApplication */
export type ApproveShiftApplicationPayload = {
  __typename?: 'ApproveShiftApplicationPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<Shift>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ArchiveAgency */
export type ArchiveAgencyPayload = {
  __typename?: 'ArchiveAgencyPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ArchiveContract */
export type ArchiveContractPayload = {
  __typename?: 'ArchiveContractPayload';
  errors: Array<ValidationError>;
  /** Result of the operation */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ArchiveGrade */
export type ArchiveGradePayload = {
  __typename?: 'ArchiveGradePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ArchiveLeaveRequest */
export type ArchiveLeaveRequestPayload = {
  __typename?: 'ArchiveLeaveRequestPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequest?: Maybe<LeaveRequest>;
};

/** Autogenerated return type of AssignBankWorkerToShift */
export type AssignBankWorkerToShiftPayload = {
  __typename?: 'AssignBankWorkerToShiftPayload';
  errors: Array<ValidationError>;
  /** Rostering Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of AssignMultipleWorkersToMultipleShifts */
export type AssignMultipleWorkersToMultipleShiftsPayload = {
  __typename?: 'AssignMultipleWorkersToMultipleShiftsPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Assigned events */
  events?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of AssignOrganisationRegistration */
export type AssignOrganisationRegistrationPayload = {
  __typename?: 'AssignOrganisationRegistrationPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of AssignWorker */
export type AssignWorkerPayload = {
  __typename?: 'AssignWorkerPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<Shift>;
};

/** Autogenerated return type of AssignWorkerToMultipleShifts */
export type AssignWorkerToMultipleShiftsPayload = {
  __typename?: 'AssignWorkerToMultipleShiftsPayload';
  errors: Array<ValidationError>;
  /** Assigned shifts */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of AssignWorkerToShift */
export type AssignWorkerToShiftPayload = {
  __typename?: 'AssignWorkerToShiftPayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** The inputs required to assign a worker to multiple shifts */
export type AssignmentInput = {
  /** An array of event ids */
  eventIds: Array<Scalars['Int']>;
  /** Workers unique id */
  rosteringOrganisationRegistrationId: Scalars['Int'];
};

export type Assignor = {
  __typename?: 'Assignor';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type AuditEvent = {
  __typename?: 'AuditEvent';
  /** A raw JSON representation of the audit event data */
  changesData?: Maybe<Scalars['JSON']>;
  /** A HTML rendered version of the audit event */
  description: Scalars['String'];
  event: Scalars['String'];
  id: Scalars['Int'];
  time: Scalars['DateTime'];
  user: Scalars['String'];
  /** Formatted name of user who created audit event */
  userName: Scalars['String'];
};

export type AuditRosteringEvent = {
  __typename?: 'AuditRosteringEvent';
  changesData?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentUser: Scalars['String'];
  event: Scalars['String'];
  id: Scalars['Int'];
  source: Scalars['String'];
};

export type AuditUserEvent = {
  __typename?: 'AuditUserEvent';
  changesData?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  createdByUser?: Maybe<User>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organisation?: Maybe<Organisation>;
  source: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: Worker;
};

export type AuthorisationLevel = {
  __typename?: 'AuthorisationLevel';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lowerLimit: Scalars['Float'];
  lowerLimitType: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
  rank: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** AutoCascade Rule Type */
export type AutoCascadeRule = {
  __typename?: 'AutoCascadeRule';
  createdAt: Scalars['DateTime'];
  fromTier?: Maybe<Scalars['Int']>;
  frontendTimeUnit?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  minutesAfterLastTrigger?: Maybe<Scalars['Int']>;
  range: RuleGroupsRange;
  toTier: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Auto Send To Agency Rule Type */
export type AutoSendToAgency = {
  __typename?: 'AutoSendToAgency';
  activatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  daysBeforeSendingToAgency: Scalars['Int'];
  id: Scalars['ID'];
  ruleGroup: RuleGroup;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** The dates of availability for a RosteringOrganisationRegistration on a specific Roster */
export type AvailabilityOnRoster = {
  __typename?: 'AvailabilityOnRoster';
  /** End Date of RosteringOrganisationRegistration's Availability */
  endDate: Scalars['Date'];
  /** AvailabilityOnRoster model ID */
  id: Scalars['ID'];
  /** RosteringOrganisationRegistration with period of Availability on a Roster */
  rosteringOrganisationRegistration: RosteringOrganisationRegistration;
  /** Roster for which the RosteringOrganisationRegistration is available */
  rosteringRoster: RosteringRoster;
  /** Start Date of RosteringOrganisationRegistration's Availability */
  startDate: Scalars['Date'];
};

/** Availability object to pass in as an argument to RosteringAvailabilityOnRostersUpdate mutation */
export type AvailabilityOnRosterInput = {
  /** Availability updated end date */
  endDate: Scalars['Date'];
  /** Availability id */
  id: Scalars['Int'];
  /** Availability updated start date */
  startDate: Scalars['Date'];
};

export type AvailableRosteringOrganisationRegistration = {
  __typename?: 'AvailableRosteringOrganisationRegistration';
  availableOrganisationRegistrations: Array<AvailableRosteringOrganisationRegistrationsByDate>;
};

export type AvailableRosteringOrganisationRegistrationsByDate = {
  __typename?: 'AvailableRosteringOrganisationRegistrationsByDate';
  date: Scalars['Date'];
  rosteringOrganisationRegistrations: Array<RosteringOrganisationRegistration>;
};

export type BankPermission = {
  __typename?: 'BankPermission';
  budgetHolder: Scalars['Boolean'];
  canAccessAgency: Scalars['Boolean'];
  canAmendRates: Scalars['Boolean'];
  canAuthoriseRateRequests: Scalars['Boolean'];
  canCancelShift: Scalars['Boolean'];
  canCreateAdmins: Scalars['Boolean'];
  canCreateAndEditPpi: Scalars['Boolean'];
  canCreateRateAuthorisers: Scalars['Boolean'];
  canDeletePpi: Scalars['Boolean'];
  canEditDepartmentPinSettings: Scalars['Boolean'];
  canEditShiftCovidRatings: Scalars['Boolean'];
  canEscalateOnBehalfOfRateAuthoriser: Scalars['Boolean'];
  canManageDeContracts: Scalars['Boolean'];
  canManageWorkerCovidRatings: Scalars['Boolean'];
  canManuallySignOff: Scalars['Boolean'];
  canProcessPayments: Scalars['Boolean'];
  canPublishBlocks: Scalars['Boolean'];
  canRequestOnCallShifts: Scalars['Boolean'];
  canRequestSessionalShifts: Scalars['Boolean'];
  canRequestShifts: Scalars['Boolean'];
  canSeeNiNumber: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Input type to create a bank shift from roster */
export type BankRosterShiftInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  breaks?: InputMaybe<Scalars['Int']>;
  /** defaults to Roster Department cost centre */
  costCentre?: InputMaybe<Scalars['String']>;
  /** defaults to false */
  limitToPreferred?: InputMaybe<Scalars['Boolean']>;
  /** defaults to false */
  overrideDefaultShiftRates?: InputMaybe<Scalars['Boolean']>;
  rate?: InputMaybe<Scalars['Float']>;
  rateAuthoriserId?: InputMaybe<Scalars['Int']>;
  rateCore?: InputMaybe<Scalars['Float']>;
  rateUnsocial?: InputMaybe<Scalars['Float']>;
  reasonForRequestId: Scalars['Int'];
  rosteringEventId?: InputMaybe<Scalars['Int']>;
  /** defaults to "inherit department default" */
  selfBooking?: InputMaybe<SelfBookingEnum>;
  /** defaults to "hourly" */
  shiftType?: InputMaybe<ShiftKindEnum>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerNote?: InputMaybe<Scalars['String']>;
};

/** Bank Rate Card Uploaded File */
export type BankUpload = {
  __typename?: 'BankUpload';
  /** Whether this is the active upload for the organisation and staff group */
  active?: Maybe<Scalars['Boolean']>;
  /** Date and time when this record was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Hub user who created this record */
  createdBy?: Maybe<User>;
  /** Id of the record */
  id?: Maybe<Scalars['Int']>;
  /** Organisation */
  organisation?: Maybe<Organisation>;
  /** Path to the rate card file */
  rateCardFilePath?: Maybe<Scalars['String']>;
  /** Staff Group */
  staffGroup?: Maybe<StaffGroup>;
  /** Whether the upload is pending, in progress or finished */
  status?: Maybe<Scalars['String']>;
  /** Errors from the upload process */
  uploadErrors?: Maybe<Scalars['String']>;
};

/** The connection type for BankUpload. */
export type BankUploadConnection = {
  __typename?: 'BankUploadConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BankUploadEdge>>>;
  /** A list of nodes. */
  nodes: Array<BankUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BankUploadEdge = {
  __typename?: 'BankUploadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BankUpload>;
};

/** Basic annual salary according to Nodal Point */
export type BasicAnnualSalaryByNodalPoint = {
  __typename?: 'BasicAnnualSalaryByNodalPoint';
  /** Basic annual salary for a Doctor on Nodal Point 1 */
  nodalPoint1: Scalars['Float'];
  /** Basic annual salary for a Doctor on Nodal Point 2 */
  nodalPoint2: Scalars['Float'];
  /** Basic annual salary for a Doctor on Nodal Point 3 */
  nodalPoint3: Scalars['Float'];
  /** Basic annual salary for a Doctor on Nodal Point 4 */
  nodalPoint4: Scalars['Float'];
  /** Basic annual salary for a Doctor on Nodal Point 5 */
  nodalPoint5: Scalars['Float'];
};

/** PaidPerItem Batch */
export type Batch = {
  __typename?: 'Batch';
  createdAt: Scalars['DateTime'];
  csvUrl?: Maybe<Scalars['String']>;
  hubUser: HubUser;
  id: Scalars['Int'];
  inCollaborativeBank: Scalars['Boolean'];
  organisation: Organisation;
  organisationStaffGroup: OrganisationStaffGroup;
  payrollCycle: Scalars['String'];
  receiptCount: Scalars['Int'];
  receipts: Array<Receipt>;
  reference: Scalars['String'];
  startProcessingTime: Scalars['DateTime'];
  zipUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of BatchCsvUrlSubscription */
export type BatchCsvUrlSubscriptionPayload = {
  __typename?: 'BatchCsvUrlSubscriptionPayload';
  /** URL to download batch csv */
  batchCsvUrl?: Maybe<Scalars['String']>;
  /** Errors */
  errors: Array<ValidationError>;
};

export type BlockShift = {
  __typename?: 'BlockShift';
  adminNote?: Maybe<Scalars['String']>;
  breaks?: Maybe<Scalars['Int']>;
  costCentre: Scalars['String'];
  department?: Maybe<Department>;
  endTime?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  proposedEndTime?: Maybe<Scalars['DateTime']>;
  proposedStartTime?: Maybe<Scalars['DateTime']>;
  reasonForRequest: Scalars['String'];
  reasonForRequestId: Scalars['Int'];
  shiftType: ShiftTypeEnum;
  shiftsSeries: ShiftsSeries;
  site?: Maybe<Site>;
  skills?: Maybe<Array<Skill>>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: ShiftStatus;
  totalHours: Scalars['Float'];
};

/** Autogenerated return type of BookOrApply */
export type BookOrApplyPayload = {
  __typename?: 'BookOrApplyPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<WorkerShift>;
};

/** The booking action available to a worker for a shift */
export enum BookingAction {
  /** Shift can be applied to */
  Apply = 'APPLY',
  /** Shift is available for instant book */
  InstantBook = 'INSTANT_BOOK',
  /** Shift is not eligible for instant book or an application */
  None = 'NONE'
}

/** Compliance Breach */
export type Breach = {
  __typename?: 'Breach';
  /** All Rostering Events contributing to the Breach */
  breachedEvents?: Maybe<RosteringEventConnection>;
  /** Compliance Actions related to Breach with Hub User details */
  complianceActions: ActionConnection;
  /**
   * Name of breached Compliance Rule
   * @deprecated Access Name through complianceRule field instead
   */
  complianceName: Scalars['String'];
  /** Compliance Rule being breached */
  complianceRule: Rule;
  /** Breach ID */
  id: Scalars['Int'];
  /** ID of Worker Profile associated with the Breach */
  workerProfileId: Scalars['Int'];
};


/** Compliance Breach */
export type BreachBreachedEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** Compliance Breach */
export type BreachComplianceActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Breach. */
export type BreachConnection = {
  __typename?: 'BreachConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BreachEdge>>>;
  /** A list of nodes. */
  nodes: Array<Breach>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BreachEdge = {
  __typename?: 'BreachEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Breach>;
};

/** Details of event template that breached compliance rule */
export type BreachEventTemplate = {
  __typename?: 'BreachEventTemplate';
  /** Whether a breach has been caused by pattern repeat */
  causedByPatternRepeat: Scalars['Boolean'];
  /** Worker pattern event template */
  eventTemplate: WorkerPatternEventTemplate;
};

/** Breach Event Templates by Week */
export type BreachedEventTemplatesByWeek = {
  __typename?: 'BreachedEventTemplatesByWeek';
  /** Breach Event Templates */
  breachedEventTemplates: Array<BreachEventTemplate>;
  /** Week of breach */
  week: Scalars['Int'];
};

/** Input type to bulk add site to multiple rostering shifts to be sent to bank */
export type BulkAddSiteInput = {
  /** RosteringEvent to be sent to bank */
  eventId: Scalars['Int'];
  /** Site to be added to bank shifts */
  siteId: Scalars['Int'];
};

/** Autogenerated return type of BulkApproveForPayment */
export type BulkApproveForPaymentPayload = {
  __typename?: 'BulkApproveForPaymentPayload';
  errors: Array<ValidationError>;
  /** Were all receipts approved? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkApprove */
export type BulkApprovePayload = {
  __typename?: 'BulkApprovePayload';
  errors: Array<ValidationError>;
  /** True/False depending if result passed */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkAssignAgencyWorker */
export type BulkAssignAgencyWorkerPayload = {
  __typename?: 'BulkAssignAgencyWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkAssignWorker */
export type BulkAssignWorkerPayload = {
  __typename?: 'BulkAssignWorkerPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkCancelAgencyWorker */
export type BulkCancelAgencyWorkerPayload = {
  __typename?: 'BulkCancelAgencyWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkCancelWorkerBooking */
export type BulkCancelWorkerBookingPayload = {
  __typename?: 'BulkCancelWorkerBookingPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

export type BulkCancelWorkerInput = {
  comments: Scalars['String'];
  reasonForCancellationId: Scalars['Int'];
};

/** Autogenerated return type of BulkCancelWorker */
export type BulkCancelWorkerPayload = {
  __typename?: 'BulkCancelWorkerPayload';
  errors: Array<ValidationError>;
  /** True/False depending if result passed */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkCreateAuthorisers */
export type BulkCreateAuthorisersPayload = {
  __typename?: 'BulkCreateAuthorisersPayload';
  /** Indicate action performed */
  actionsPerformed?: Maybe<Array<Scalars['String']>>;
  errors: Array<ValidationError>;
  /** Whether an operation has been successful or not */
  success: Scalars['Boolean'];
  /**
   * Attributes to create bulk timesheet
   *                                                                                   authorisers
   */
  timesheetAuthorisers?: Maybe<TimesheetAuthoriser>;
};

/** Autogenerated return type of BulkCreateFromServicePlans */
export type BulkCreateFromServicePlansPayload = {
  __typename?: 'BulkCreateFromServicePlansPayload';
  errors: Array<ValidationError>;
  /** ID of Background Job */
  jobId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of BulkCreateFromShiftTemplates */
export type BulkCreateFromShiftTemplatesPayload = {
  __typename?: 'BulkCreateFromShiftTemplatesPayload';
  errors: Array<ValidationError>;
  /** the newly created ShiftEvents */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of BulkCreateOnBlock */
export type BulkCreateOnBlockPayload = {
  __typename?: 'BulkCreateOnBlockPayload';
  errors: Array<ValidationError>;
  /** A smaller version of the shift type for blocks pages */
  shifts?: Maybe<Array<BlockShift>>;
};

/** Autogenerated return type of BulkCreate */
export type BulkCreatePayload = {
  __typename?: 'BulkCreatePayload';
  errors: Array<ValidationError>;
  /** The array of created Rostering::WorkerPatternEventTemplate */
  rosteringWorkerPatternEventTemplates?: Maybe<Array<WorkerPatternEventTemplate>>;
};

/** Autogenerated return type of BulkDelete */
export type BulkDeletePayload = {
  __typename?: 'BulkDeletePayload';
  errors: Array<ValidationError>;
  /** True/False depending if result passed */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkFlag */
export type BulkFlagPayload = {
  __typename?: 'BulkFlagPayload';
  errors: Array<ValidationError>;
  /** Were all receipts flagged? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkLockToAgency */
export type BulkLockToAgencyPayload = {
  __typename?: 'BulkLockToAgencyPayload';
  errors: Array<ValidationError>;
  /** Shifts which have been changed */
  shifts: Array<Shift>;
  /** Returns true/false depending if it worked */
  success: Scalars['Boolean'];
};

/** Input type to create multiple bank shifts from roster */
export type BulkOverrideInput = {
  /** RosteringEvents to be sent to bank */
  eventIds: Array<Scalars['Int']>;
  /** Sessional rate */
  rate?: InputMaybe<Scalars['Float']>;
  /** Reason for Request */
  reasonForRequestId: Scalars['Int'];
  /** defaults to "hourly" */
  shiftType?: InputMaybe<ShiftKindEnum>;
};

/** Autogenerated return type of BulkRecalculateRates */
export type BulkRecalculateRatesPayload = {
  __typename?: 'BulkRecalculateRatesPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkRecallFromAgency */
export type BulkRecallFromAgencyPayload = {
  __typename?: 'BulkRecallFromAgencyPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkRecallFromSecondaryBank */
export type BulkRecallFromSecondaryBankPayload = {
  __typename?: 'BulkRecallFromSecondaryBankPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkRemoveRateEscalation */
export type BulkRemoveRateEscalationPayload = {
  __typename?: 'BulkRemoveRateEscalationPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkRestore */
export type BulkRestorePayload = {
  __typename?: 'BulkRestorePayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkSendToAgency */
export type BulkSendToAgencyPayload = {
  __typename?: 'BulkSendToAgencyPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkSendToAgencyTier */
export type BulkSendToAgencyTierPayload = {
  __typename?: 'BulkSendToAgencyTierPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkSendToSecondaryBank */
export type BulkSendToSecondaryBankPayload = {
  __typename?: 'BulkSendToSecondaryBankPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkSignOff */
export type BulkSignOffPayload = {
  __typename?: 'BulkSignOffPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkSignOffReceipts */
export type BulkSignOffReceiptsPayload = {
  __typename?: 'BulkSignOffReceiptsPayload';
  errors: Array<ValidationError>;
  /** Were all receipts signed off? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUnapproveForPayment */
export type BulkUnapproveForPaymentPayload = {
  __typename?: 'BulkUnapproveForPaymentPayload';
  errors: Array<ValidationError>;
  /** Were all receipts unapproved? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUndoPaymentApproval */
export type BulkUndoPaymentApprovalPayload = {
  __typename?: 'BulkUndoPaymentApprovalPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUndoRpaLock */
export type BulkUndoRpaLockPayload = {
  __typename?: 'BulkUndoRpaLockPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUndoSignOff */
export type BulkUndoSignOffPayload = {
  __typename?: 'BulkUndoSignOffPayload';
  errors: Array<ValidationError>;
  /** return value for success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUnflag */
export type BulkUnflagPayload = {
  __typename?: 'BulkUnflagPayload';
  errors: Array<ValidationError>;
  /** Were all receipts unflagged? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUpdateAgencyTier */
export type BulkUpdateAgencyTierPayload = {
  __typename?: 'BulkUpdateAgencyTierPayload';
  errors: Array<ValidationError>;
  /** Returns true if succsess */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of BulkUpdate */
export type BulkUpdatePayload = {
  __typename?: 'BulkUpdatePayload';
  errors: Array<ValidationError>;
  /** Updated Event Templates */
  eventTemplates?: Maybe<Array<WorkerPatternEventTemplate>>;
};

/** Autogenerated return type of BulkUpdateShifts */
export type BulkUpdateShiftsPayload = {
  __typename?: 'BulkUpdateShiftsPayload';
  errors: Array<ValidationError>;
  /** List of updated shifts */
  shifts?: Maybe<Array<BlockShift>>;
  /** Returns true if operation successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of BulkUploadDepartments */
export type BulkUploadDepartmentsPayload = {
  __typename?: 'BulkUploadDepartmentsPayload';
  errors: Array<ValidationError>;
  /** Indicates where mutation completed successfully */
  success: Scalars['Boolean'];
};

/** Cached Compliance Breach */
export type CachedBreach = {
  __typename?: 'CachedBreach';
  /** All Rostering Events contributing to the Breach */
  breachedEvents?: Maybe<RosteringEventConnection>;
  /** Compliance Rule being breached */
  complianceRule: Rule;
  /**
   * Name of breached Compliance Rule
   * @deprecated Access Name through complianceRule field instead
   */
  complianceRuleName: Scalars['String'];
};


/** Cached Compliance Breach */
export type CachedBreachBreachedEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for CachedBreach. */
export type CachedBreachConnection = {
  __typename?: 'CachedBreachConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CachedBreachEdge>>>;
  /** A list of nodes. */
  nodes: Array<CachedBreach>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CachedBreachEdge = {
  __typename?: 'CachedBreachEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CachedBreach>;
};

export type CachedWorkerBreaches = {
  __typename?: 'CachedWorkerBreaches';
  breaches: CachedBreachConnection;
  canOverrideBreaches?: Maybe<Scalars['Boolean']>;
};


export type CachedWorkerBreachesBreachesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CalculateWorkerPayForRotationPeriod */
export type CalculateWorkerPayForRotationPeriodPayload = {
  __typename?: 'CalculateWorkerPayForRotationPeriodPayload';
  errors: Array<ValidationError>;
  /** Worker pay details for given roster and dates */
  payBreakdown?: Maybe<PayBreakdownForWorker>;
  /** Shift details for worker, pro-rata for rotation period */
  shiftBreakdown?: Maybe<ShiftBreakdownForWorker>;
};

/** A date of a Leave Request and whether it should display as am/pm/day on Leave Calendar */
export type CalendarDay = {
  __typename?: 'CalendarDay';
  /** Date of Leave Day */
  date: Scalars['Date'];
  /** Represents morning, afternoon or full day of leave */
  slot: CalendarSlotEnum;
};

/** Signifies morning, afternoon or full day of Leave to display on Leave Calendar (Rota Hub) */
export enum CalendarSlotEnum {
  /** Partial day: morning */
  Am = 'am',
  /** Full day */
  Day = 'day',
  /** Partial day: afternoon */
  Pm = 'pm'
}

export type CalenderShift = {
  __typename?: 'CalenderShift';
  appliedBy: Array<AppliedBy>;
  id: Scalars['Int'];
  startTime: Scalars['DateTime'];
  status: ShiftStatus;
};

export type CancelAgencyBookingAttributes = {
  reasonForCancellationId?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};

/** Autogenerated return type of CancelAgencyBooking */
export type CancelAgencyBookingPayload = {
  __typename?: 'CancelAgencyBookingPayload';
  errors: Array<ValidationError>;
  /** Returns true if booking cancelled with no errors */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CancelLeaveRequest */
export type CancelLeaveRequestPayload = {
  __typename?: 'CancelLeaveRequestPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequest?: Maybe<LeaveRequest>;
};

/** Autogenerated return type of CancelWorkerApplication */
export type CancelWorkerApplicationPayload = {
  __typename?: 'CancelWorkerApplicationPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of CancelWorker */
export type CancelWorkerPayload = {
  __typename?: 'CancelWorkerPayload';
  errors: Array<ValidationError>;
  /** Whether the worker was successfully cancelled */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ChangeOrganisation */
export type ChangeOrganisationPayload = {
  __typename?: 'ChangeOrganisationPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ChangeSchedule */
export type ChangeSchedulePayload = {
  __typename?: 'ChangeSchedulePayload';
  errors: Array<ValidationError>;
  /** Rostering Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of ChangeStatusOfHubUserAccount */
export type ChangeStatusOfHubUserAccountPayload = {
  __typename?: 'ChangeStatusOfHubUserAccountPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ChangeStatus */
export type ChangeStatusPayload = {
  __typename?: 'ChangeStatusPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

/** Autogenerated return type of CheckComplianceForEditedShift */
export type CheckComplianceForEditedShiftPayload = {
  __typename?: 'CheckComplianceForEditedShiftPayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CheckComplianceForShiftSwap */
export type CheckComplianceForShiftSwapPayload = {
  __typename?: 'CheckComplianceForShiftSwapPayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CheckEmailIsLinkedToAccount */
export type CheckEmailIsLinkedToAccountPayload = {
  __typename?: 'CheckEmailIsLinkedToAccountPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Represents Links Signed Agreements */
export type CollabBankSignedAgreement = {
  __typename?: 'CollabBankSignedAgreement';
  /** CollabBank name */
  collabBank?: Maybe<Scalars['String']>;
  /** link to download agreement pdf */
  signedAgreementUrl?: Maybe<Scalars['String']>;
};

/** Bank Rate Card Uploaded File */
export type CollabBankUpload = {
  __typename?: 'CollabBankUpload';
  /** Whether this is the active upload for the organisation and staff group */
  active?: Maybe<Scalars['Boolean']>;
  /** Collaborative Bank */
  collaborativeBank?: Maybe<CollaborativeBank>;
  /** Collaborative Bank Staff Group */
  collaborativeBankStaffGroup?: Maybe<CollaborativeBankStaffGroup>;
  /** Date and time when this record was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Hub user who created this record */
  createdBy?: Maybe<User>;
  /** Id of the record */
  id?: Maybe<Scalars['Int']>;
  /** Path to the rate card file */
  rateCardFilePath?: Maybe<Scalars['String']>;
  /** Whether the upload is pending, in progress or finished */
  status?: Maybe<Scalars['String']>;
  /** Errors from the upload process */
  uploadErrors?: Maybe<Scalars['String']>;
};

/** The connection type for CollabBankUpload. */
export type CollabBankUploadConnection = {
  __typename?: 'CollabBankUploadConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollabBankUploadEdge>>>;
  /** A list of nodes. */
  nodes: Array<CollabBankUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CollabBankUploadEdge = {
  __typename?: 'CollabBankUploadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CollabBankUpload>;
};

export type CollaborativeBank = {
  __typename?: 'CollaborativeBank';
  collaborativeBankSetting?: Maybe<CollaborativeBankSetting>;
  collaborativeBankStaffGroups: Array<CollaborativeBankStaffGroup>;
  contractText?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  leadEmployerOrganisation?: Maybe<Organisation>;
  name: Scalars['String'];
  organisations: Array<Organisation>;
  organisationsCount?: Maybe<Scalars['Int']>;
  /** A collection of all the sites of the organisations in the collaborative bank */
  sites: Array<Site>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usesLeadEmployerProcess: Scalars['Boolean'];
};

/** The connection type for CollaborativeBank. */
export type CollaborativeBankConnection = {
  __typename?: 'CollaborativeBankConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollaborativeBankEdge>>>;
  /** A list of nodes. */
  nodes: Array<CollaborativeBank>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CollaborativeBankEdge = {
  __typename?: 'CollaborativeBankEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CollaborativeBank>;
};

export type CollaborativeBankSetting = {
  __typename?: 'CollaborativeBankSetting';
  allowsExternalApplicants: Scalars['Boolean'];
  id: Scalars['ID'];
  welcomeEmailBody?: Maybe<Scalars['String']>;
  welcomeEmailSubject?: Maybe<Scalars['String']>;
};

export type CollaborativeBankStaffGroup = {
  __typename?: 'CollaborativeBankStaffGroup';
  id: Scalars['Int'];
  instantBooking?: Maybe<Scalars['Boolean']>;
  instantBookingByDefault?: Maybe<Scalars['Boolean']>;
  rateCardUploaded?: Maybe<Scalars['Boolean']>;
  rateCardUploadedAt?: Maybe<Scalars['DateTime']>;
  staffGroup: StaffGroup;
};

/** Autogenerated return type of ComplianceCheckForAssigningShiftsToWorker */
export type ComplianceCheckForAssigningShiftsToWorkerPayload = {
  __typename?: 'ComplianceCheckForAssigningShiftsToWorkerPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Breaches for shift events to be assigned to the worker */
  workerBreaches?: Maybe<CachedWorkerBreaches>;
};

/** Autogenerated return type of ComplianceCheckForRosteringWorkerPatternUpdated */
export type ComplianceCheckForRosteringWorkerPatternUpdatedPayload = {
  __typename?: 'ComplianceCheckForRosteringWorkerPatternUpdatedPayload';
  /** The worker pattern breaches */
  complianceBreach?: Maybe<Array<WorkerPatternBreach>>;
  /** Errors */
  errors: Array<ValidationError>;
  /** Job completed */
  jobCompleted: Scalars['Boolean'];
};

export type ComplianceDocumentType = {
  __typename?: 'ComplianceDocumentType';
  defaultRenewalPeriod?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Config = {
  __typename?: 'Config';
  /** Whether the Beamer feature is active */
  active: Scalars['Boolean'];
  /** The Beamer product ID */
  productId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ConfirmEmail */
export type ConfirmEmailPayload = {
  __typename?: 'ConfirmEmailPayload';
  accountConfirmed?: Maybe<Scalars['Boolean']>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of ConfirmRegistration */
export type ConfirmRegistrationPayload = {
  __typename?: 'ConfirmRegistrationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type ConflictingEvents = {
  __typename?: 'ConflictingEvents';
  errors?: Maybe<Array<Error>>;
  overlappingLeaveEvents?: Maybe<Array<RosteringEvent>>;
  overlappingShiftEvents?: Maybe<Array<RosteringEvent>>;
  sameDayShiftEvents?: Maybe<Array<RosteringEvent>>;
};

/** DE Contract Type */
export type Contract = {
  __typename?: 'Contract';
  activeVersion?: Maybe<Version>;
  archived?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  contractType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hubUser: HubUser;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organisation: Organisation;
  organisationStaffGroups: Array<StaffGroup>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versions: Array<Version>;
};

export type CostCentre = {
  __typename?: 'CostCentre';
  associatedDepartments?: Maybe<Array<Department>>;
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The connection type for CostCentre. */
export type CostCentreConnection = {
  __typename?: 'CostCentreConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CostCentreEdge>>>;
  /** A list of nodes. */
  nodes: Array<CostCentre>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CostCentreEdge = {
  __typename?: 'CostCentreEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CostCentre>;
};

/** Reference data for EDI questions on Country of qualification */
export type CountryOfQualification = {
  __typename?: 'CountryOfQualification';
  /** ID of database record */
  id: Scalars['Int'];
  /** Used to determine display order */
  order: Scalars['Int'];
  /** Text value for display */
  value: Scalars['String'];
};

/** Department's covid rating */
export enum CovidRatingEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NotApplicable = 'NOT_APPLICABLE'
}

/** Autogenerated return type of CreateAccount */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateAgencyAdjustment */
export type CreateAgencyAdjustmentPayload = {
  __typename?: 'CreateAgencyAdjustmentPayload';
  /** Created Agency Adjustment */
  agencyAdjustment?: Maybe<AgencyAdjustment>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateAgency */
export type CreateAgencyPayload = {
  __typename?: 'CreateAgencyPayload';
  agency?: Maybe<Agency>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateAgencyUser */
export type CreateAgencyUserPayload = {
  __typename?: 'CreateAgencyUserPayload';
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateAuditLog */
export type CreateAuditLogPayload = {
  __typename?: 'CreateAuditLogPayload';
  errors: Array<ValidationError>;
  /** Success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateAutoSendToAgencyTrigger */
export type CreateAutoSendToAgencyTriggerPayload = {
  __typename?: 'CreateAutoSendToAgencyTriggerPayload';
  errors: Array<ValidationError>;
  /** Created Trigger */
  trigger?: Maybe<Trigger>;
};

/** Autogenerated return type of CreateBatch */
export type CreateBatchPayload = {
  __typename?: 'CreateBatchPayload';
  /** Itemised Batch */
  batch?: Maybe<Batch>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateBroken */
export type CreateBrokenPayload = {
  __typename?: 'CreateBrokenPayload';
  account?: Maybe<Account>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateCollaborativeBank */
export type CreateCollaborativeBankPayload = {
  __typename?: 'CreateCollaborativeBankPayload';
  collaborativeBank?: Maybe<CollaborativeBank>;
  errors: Array<ValidationError>;
};

export type CreateCollaborativeBankStaffGroupInput = {
  instantBooking: Scalars['Boolean'];
  instantBookingByDefault?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['Upload']>;
  staffGroupId: Scalars['Int'];
};

/** Autogenerated return type of CreateCollaborativeBankStaffGroup */
export type CreateCollaborativeBankStaffGroupPayload = {
  __typename?: 'CreateCollaborativeBankStaffGroupPayload';
  collaborativeBankStaffGroup?: Maybe<CollaborativeBankStaffGroup>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateContract */
export type CreateContractPayload = {
  __typename?: 'CreateContractPayload';
  /** Created DE Contract */
  deContract?: Maybe<Contract>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateCostCentre */
export type CreateCostCentrePayload = {
  __typename?: 'CreateCostCentrePayload';
  costCentre?: Maybe<CostCentre>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateDepartment */
export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  department?: Maybe<Department>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateDivision */
export type CreateDivisionPayload = {
  __typename?: 'CreateDivisionPayload';
  /** division type */
  division?: Maybe<Division>;
  /** division errors */
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of CreateDocumentations */
export type CreateDocumentationsPayload = {
  __typename?: 'CreateDocumentationsPayload';
  /** Created Documentations */
  documentations?: Maybe<Array<Documentations>>;
  errors: Array<ValidationError>;
  /** The success of the mutation */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateEssentialInfo */
export type CreateEssentialInfoPayload = {
  __typename?: 'CreateEssentialInfoPayload';
  errors: Array<ValidationError>;
  user?: Maybe<User>;
};

/** Input type for creating EventTemplate on a RosteringWorkerPattern */
export type CreateEventTemplateInput = {
  /** The type of event template */
  eventTemplateType: WorkerPatternEventTemplateTypeEnum;
  /** ID of the Rostering::OrganisationActivity */
  rosteringOrganisationActivityId?: InputMaybe<Scalars['Int']>;
  /** ID of the Rostering::ShiftTemplate */
  rosteringShiftTemplateId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateExternalAccount */
export type CreateExternalAccountPayload = {
  __typename?: 'CreateExternalAccountPayload';
  errors: Array<ValidationError>;
  externalAccount?: Maybe<ExternalAccount>;
};

/** Autogenerated return type of CreateExternalCancelReason */
export type CreateExternalCancelReasonPayload = {
  __typename?: 'CreateExternalCancelReasonPayload';
  errors: Array<ValidationError>;
  /** External cancellation reason */
  externalCancelReason?: Maybe<ExternalCancelReason>;
};

/** Autogenerated return type of CreateExternalGrade */
export type CreateExternalGradePayload = {
  __typename?: 'CreateExternalGradePayload';
  errors: Array<ValidationError>;
  /** External grade */
  externalGrade?: Maybe<ExternalGrade>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreateExternalOrganisation */
export type CreateExternalOrganisationPayload = {
  __typename?: 'CreateExternalOrganisationPayload';
  errors: Array<ValidationError>;
  externalOrganisation?: Maybe<ExternalOrganisation>;
};

/** Autogenerated return type of CreateExternalRequestReason */
export type CreateExternalRequestReasonPayload = {
  __typename?: 'CreateExternalRequestReasonPayload';
  errors: Array<ValidationError>;
  /** External request reason */
  externalRequestReason?: Maybe<ExternalRequestReason>;
};

/** Autogenerated return type of CreateExternalUnit */
export type CreateExternalUnitPayload = {
  __typename?: 'CreateExternalUnitPayload';
  errors: Array<ValidationError>;
  /** External unit */
  externalUnit?: Maybe<ExternalUnit>;
};

/** Autogenerated return type of CreateFilterGrade */
export type CreateFilterGradePayload = {
  __typename?: 'CreateFilterGradePayload';
  errors: Array<ValidationError>;
  filterGrade?: Maybe<FilterGrade>;
};

/** Autogenerated return type of CreateFilters */
export type CreateFiltersPayload = {
  __typename?: 'CreateFiltersPayload';
  errors: Array<ValidationError>;
  filters?: Maybe<Filters>;
};

/** Attributes for creating a grade */
export type CreateGradeInput = {
  agencyCap?: InputMaybe<Scalars['Float']>;
  agencySubjectiveCode?: InputMaybe<Scalars['String']>;
  autoApplyUnsocialNightRates: Scalars['Boolean'];
  bankHolidayPayElement?: InputMaybe<Scalars['String']>;
  bankSubjectiveCode?: InputMaybe<Scalars['String']>;
  basicRate: Scalars['Float'];
  basicRateUnsocial: Scalars['Float'];
  breakRepeatMinutes?: InputMaybe<Scalars['Int']>;
  callOutRate?: InputMaybe<Scalars['Float']>;
  colour?: InputMaybe<Scalars['String']>;
  coreWeekendsAndBankHolidays: Scalars['Boolean'];
  defaultBreakMinutes?: InputMaybe<Scalars['Int']>;
  enhancedRate: Scalars['Float'];
  enhancedRateUnsocial: Scalars['Float'];
  nightShiftEndTime?: InputMaybe<Scalars['String']>;
  nightShiftStartTime?: InputMaybe<Scalars['String']>;
  onCallRate?: InputMaybe<Scalars['Float']>;
  saturdayPayElement?: InputMaybe<Scalars['String']>;
  staffGroupId: Scalars['Int'];
  sundayPayElement?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  useDefaultBreaks: Scalars['Boolean'];
  weekdayDayPayElement?: InputMaybe<Scalars['String']>;
  weekdayNightPayElement?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CreateGrade */
export type CreateGradePayload = {
  __typename?: 'CreateGradePayload';
  errors: Array<ValidationError>;
  grade?: Maybe<Grade>;
  success: Scalars['Boolean'];
};

export type CreateHubUserInput = {
  accountType: Scalars['String'];
  authorisationLevelIds?: InputMaybe<Array<Scalars['Int']>>;
  authoriserDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
  budgetHolder?: InputMaybe<Scalars['Boolean']>;
  canAccessAgency?: InputMaybe<Scalars['Boolean']>;
  canAccessEsrTransfer?: InputMaybe<Scalars['Boolean']>;
  canAccessSuperhub?: InputMaybe<Scalars['Boolean']>;
  canAmendRates?: InputMaybe<Scalars['Boolean']>;
  canApproveOverridingRules?: InputMaybe<Scalars['Boolean']>;
  canApproveRosters?: InputMaybe<Scalars['Boolean']>;
  canAuthoriseRateRequests?: InputMaybe<Scalars['Boolean']>;
  canCancelShift?: InputMaybe<Scalars['Boolean']>;
  canControlLocks?: InputMaybe<Scalars['Boolean']>;
  canCreateAdmins?: InputMaybe<Scalars['Boolean']>;
  canCreateAgencyWorkers?: InputMaybe<Scalars['Boolean']>;
  canCreateRateAuthorisers?: InputMaybe<Scalars['Boolean']>;
  canCreateRoster?: InputMaybe<Scalars['Boolean']>;
  canEditShiftCovidRatings?: InputMaybe<Scalars['Boolean']>;
  canEscalateOnBehalfOfRateAuthoriser?: InputMaybe<Scalars['Boolean']>;
  canManageALiveRoster?: InputMaybe<Scalars['Boolean']>;
  canManageAutorostering?: InputMaybe<Scalars['Boolean']>;
  canManageBank: Scalars['Boolean'];
  canManageDaysTemplate?: InputMaybe<Scalars['Boolean']>;
  canManageDemandTemplates?: InputMaybe<Scalars['Boolean']>;
  canManageDeptWorkerList?: InputMaybe<Scalars['Boolean']>;
  canManageExceptionReportApprovers?: InputMaybe<Scalars['Boolean']>;
  canManagePayRules?: InputMaybe<Scalars['Boolean']>;
  canManageRoster: Scalars['Boolean'];
  canManageRosterWorker?: InputMaybe<Scalars['Boolean']>;
  canManageRota?: InputMaybe<Scalars['Boolean']>;
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  canManageWorkerCovidRatings?: InputMaybe<Scalars['Boolean']>;
  canManageWorkerPatterns?: InputMaybe<Scalars['Boolean']>;
  canManuallySignOff?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier1?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier2?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier3?: InputMaybe<Scalars['Boolean']>;
  canOverrideOrganisationRules?: InputMaybe<Scalars['Boolean']>;
  canOverrideRulesOnALiveRoster?: InputMaybe<Scalars['Boolean']>;
  canProcessPayments?: InputMaybe<Scalars['Boolean']>;
  canRequestSessionalShifts?: InputMaybe<Scalars['Boolean']>;
  canRequestShifts?: InputMaybe<Scalars['Boolean']>;
  canSeeNiNumber?: InputMaybe<Scalars['Boolean']>;
  canSendCommsToDeptWorkers?: InputMaybe<Scalars['Boolean']>;
  clinicalSupervisor?: InputMaybe<Scalars['Boolean']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  email: Scalars['String'];
  escalationLevelIds?: InputMaybe<Array<Scalars['Int']>>;
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  /** Password. Used by Cypress */
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  supervisedWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CreateHubUser */
export type CreateHubUserPayload = {
  __typename?: 'CreateHubUserPayload';
  errors: Array<ValidationError>;
  hubUser?: Maybe<HubUser>;
};

/** Autogenerated return type of CreateItem */
export type CreateItemPayload = {
  __typename?: 'CreateItemPayload';
  errors: Array<ValidationError>;
  /** Paid per item Item */
  item?: Maybe<Item>;
};

/** Autogenerated return type of CreateLeaveEntitlement */
export type CreateLeaveEntitlementPayload = {
  __typename?: 'CreateLeaveEntitlementPayload';
  errors: Array<ValidationError>;
  rosteringLeaveEntitlement?: Maybe<LeaveEntitlement>;
};

/** Autogenerated return type of CreateLeaveGroup */
export type CreateLeaveGroupPayload = {
  __typename?: 'CreateLeaveGroupPayload';
  errors: Array<ValidationError>;
  rosteringLeaveGroup?: Maybe<LeaveGroup>;
};

/** Attributes for creating a PA Category */
export type CreateLeavePaCategoryInput = {
  endTime: Scalars['String'];
  hoursPerPa: Scalars['Float'];
  name: Scalars['String'];
  startTime: Scalars['String'];
};

/** Autogenerated return type of CreateLeaveReason */
export type CreateLeaveReasonPayload = {
  __typename?: 'CreateLeaveReasonPayload';
  errors: Array<ValidationError>;
  rosteringLeaveReason?: Maybe<LeaveReason>;
};

/** Autogenerated return type of CreateLeaveRequestFromSuperHub */
export type CreateLeaveRequestFromSuperHubPayload = {
  __typename?: 'CreateLeaveRequestFromSuperHubPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequestType?: Maybe<LeaveRequest>;
};

/** Autogenerated return type of CreateLeaveRequest */
export type CreateLeaveRequestPayload = {
  __typename?: 'CreateLeaveRequestPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequestType?: Maybe<LeaveRequest>;
};

/** Autogenerated return type of CreateLevelGradeRates */
export type CreateLevelGradeRatesPayload = {
  __typename?: 'CreateLevelGradeRatesPayload';
  errors: Array<ValidationError>;
  /** Escalation Level Grade Rate */
  levelGradeRate?: Maybe<LevelGradeRates>;
};

/** Autogenerated return type of CreateLocation */
export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  errors: Array<ValidationError>;
  location?: Maybe<Location>;
};

/** Autogenerated return type of CreateLockConfig */
export type CreateLockConfigPayload = {
  __typename?: 'CreateLockConfigPayload';
  errors: Array<ValidationError>;
  organisationLockConfig?: Maybe<OrganisationLockConfig>;
};

export type CreateMetaActivityInput = {
  name: Scalars['String'];
};

/** Autogenerated return type of CreateMetaActivity */
export type CreateMetaActivityPayload = {
  __typename?: 'CreateMetaActivityPayload';
  errors: Array<ValidationError>;
  metaActivity?: Maybe<MetaActivity>;
};

/** Autogenerated return type of CreateOrganisationAgency */
export type CreateOrganisationAgencyPayload = {
  __typename?: 'CreateOrganisationAgencyPayload';
  errors: Array<ValidationError>;
  organisationAgency?: Maybe<OrganisationAgency>;
};

/** Autogenerated return type of CreateOrganisationComplianceDocument */
export type CreateOrganisationComplianceDocumentPayload = {
  __typename?: 'CreateOrganisationComplianceDocumentPayload';
  errors: Array<ValidationError>;
  organisationComplianceDocument?: Maybe<OrganisationComplianceDocument>;
};

/** Autogenerated return type of CreateOrganisation */
export type CreateOrganisationPayload = {
  __typename?: 'CreateOrganisationPayload';
  errors: Array<ValidationError>;
  organisation?: Maybe<Organisation>;
};

/** Autogenerated return type of CreateOrganisationRegistrationApplication */
export type CreateOrganisationRegistrationApplicationPayload = {
  __typename?: 'CreateOrganisationRegistrationApplicationPayload';
  errors: Array<ValidationError>;
  shiftBlockApplication?: Maybe<ShiftBlockApplication>;
};

/** Autogenerated return type of CreateOrganisationRegistrationNote */
export type CreateOrganisationRegistrationNotePayload = {
  __typename?: 'CreateOrganisationRegistrationNotePayload';
  errors: Array<ValidationError>;
  organisationRegistrationNote?: Maybe<OrganisationRegistrationNote>;
};

/** Autogenerated return type of CreateOrganisationStaffGroup */
export type CreateOrganisationStaffGroupPayload = {
  __typename?: 'CreateOrganisationStaffGroupPayload';
  errors: Array<ValidationError>;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
};

/** Autogenerated return type of CreatePattern */
export type CreatePatternPayload = {
  __typename?: 'CreatePatternPayload';
  errors: Array<ValidationError>;
  /** Created Rostering::WorkerPattern */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Autogenerated return type of Create */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  agencyWorker?: Maybe<AgencyWorker>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreatePaymentBatchWithShifts */
export type CreatePaymentBatchWithShiftsPayload = {
  __typename?: 'CreatePaymentBatchWithShiftsPayload';
  /** The batch that was created */
  batch?: Maybe<TempStaffBatch>;
  errors: Array<ValidationError>;
  /** If false see shiftsWithErrors to see which ones failed */
  result: Scalars['Boolean'];
};

/** Autogenerated return type of CreatePermissionSet */
export type CreatePermissionSetPayload = {
  __typename?: 'CreatePermissionSetPayload';
  errors: Array<ValidationError>;
  rosteringPermissionSet?: Maybe<Array<RosteringPermissionSet>>;
};

/** Attributes for creating a Rostering Rate Card */
export type CreateRateCardInput = {
  /** Basic annual salary */
  basicAnnualSalary: Scalars['Float'];
  /** Id for grade */
  gradeId: Scalars['Int'];
  /** Is this a Junior Doctor? Yes triggers additional unsocial hours */
  juniorDoctor?: InputMaybe<Scalars['Boolean']>;
  /** Name of rate card */
  name: Scalars['String'];
  /** Night shift end time in multiples of 30 minutes ("hh:mm") */
  nightShiftEndTime: Scalars['String'];
  /** Night shift hours rate applied only for weekdays (excluding NROC shifts) */
  nightShiftHourlyRate: Scalars['Float'];
  /** Night shift start time in multiples of 30 minutes ("hh:mm") */
  nightShiftStartTime: Scalars['String'];
  /** Nodal point between 1-5, corresponding to salary band */
  nodalPoint?: InputMaybe<Scalars['Int']>;
  /** On-Call annual rate (Flat rate in pounds added to yearly salary) */
  onCallAnnualRate: Scalars['Float'];
  /** Id for organisation staff group */
  organisationStaffGroupId: Scalars['Int'];
  /** % uplift on basic salary when on average 1 in 2 weekends worked */
  weekendPercentageRateMin1In2: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 3 weekends worked */
  weekendPercentageRateMin1In3: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 4 weekends worked */
  weekendPercentageRateMin1In4: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 5 weekends worked */
  weekendPercentageRateMin1In5: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 6 weekends worked */
  weekendPercentageRateMin1In6: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 7 weekends worked */
  weekendPercentageRateMin1In7: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 8 weekends worked */
  weekendPercentageRateMin1In8: Scalars['Float'];
};

/** Autogenerated return type of CreateRateCard */
export type CreateRateCardPayload = {
  __typename?: 'CreateRateCardPayload';
  errors: Array<ValidationError>;
  /** Created Rostering::RateCard */
  rosteringRateCard?: Maybe<RateCard>;
};

/** Autogenerated return type of CreateReason */
export type CreateReasonPayload = {
  __typename?: 'CreateReasonPayload';
  errors: Array<ValidationError>;
  reason?: Maybe<Reason>;
};

/** Autogenerated return type of CreateReceiptForRemoteSignOff */
export type CreateReceiptForRemoteSignOffPayload = {
  __typename?: 'CreateReceiptForRemoteSignOffPayload';
  errors: Array<ValidationError>;
  /** Paid per item Receipt */
  receipt?: Maybe<Receipt>;
};

/** Autogenerated return type of CreateReceiptWithPinSignOff */
export type CreateReceiptWithPinSignOffPayload = {
  __typename?: 'CreateReceiptWithPinSignOffPayload';
  errors: Array<ValidationError>;
  /** Paid per item Receipt */
  receipt?: Maybe<Receipt>;
};

/** Autogenerated return type of CreateReceiptWithSignatureSignOff */
export type CreateReceiptWithSignatureSignOffPayload = {
  __typename?: 'CreateReceiptWithSignatureSignOffPayload';
  errors: Array<ValidationError>;
  /** Paid per item Receipt */
  receipt?: Maybe<Receipt>;
};

/** Autogenerated return type of CreateReferee */
export type CreateRefereePayload = {
  __typename?: 'CreateRefereePayload';
  errors: Array<ValidationError>;
  referee?: Maybe<Referee>;
};

/** Autogenerated return type of CreateRegulationBody */
export type CreateRegulationBodyPayload = {
  __typename?: 'CreateRegulationBodyPayload';
  errors: Array<ValidationError>;
  regulationBody?: Maybe<RegulationBody>;
};

/** Autogenerated return type of CreateRequest */
export type CreateRequestPayload = {
  __typename?: 'CreateRequestPayload';
  errors: Array<ValidationError>;
  /** The shift swap request */
  rosteringShiftSwapRequest?: Maybe<Request>;
  success: Scalars['Boolean'];
};

/** Attributes for creating a permission set */
export type CreateRosteringPermissionSetInput = {
  deletePermission?: InputMaybe<Scalars['Boolean']>;
  hubUserId: Scalars['ID'];
  organisationId: Scalars['ID'];
  overridePermissions?: InputMaybe<Scalars['Boolean']>;
  permissionIds: Array<Scalars['Int']>;
  recordIds?: InputMaybe<Array<Scalars['Int']>>;
  subRecordIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of CreateRuleGroup */
export type CreateRuleGroupPayload = {
  __typename?: 'CreateRuleGroupPayload';
  errors: Array<ValidationError>;
  /** Created Rule Group */
  ruleGroup?: Maybe<RuleGroup>;
};

/** Autogenerated return type of CreateRuleGroupsRange */
export type CreateRuleGroupsRangePayload = {
  __typename?: 'CreateRuleGroupsRangePayload';
  errors: Array<ValidationError>;
  /** Created Rule Groups Range */
  ruleGroupsRange?: Maybe<RuleGroupsRange>;
};

/** Autogenerated return type of CreateRule */
export type CreateRulePayload = {
  __typename?: 'CreateRulePayload';
  errors: Array<ValidationError>;
  /** Created Rules */
  rule?: Maybe<AutoCascadeRule>;
};

/** Autogenerated return type of CreateRuleTriggers */
export type CreateRuleTriggersPayload = {
  __typename?: 'CreateRuleTriggersPayload';
  errors: Array<ValidationError>;
  /** Created Triggers */
  triggers?: Maybe<Array<Trigger>>;
};

/** Autogenerated return type of CreateShiftBlock */
export type CreateShiftBlockPayload = {
  __typename?: 'CreateShiftBlockPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of CreateShiftEventFromShiftTemplate */
export type CreateShiftEventFromShiftTemplatePayload = {
  __typename?: 'CreateShiftEventFromShiftTemplatePayload';
  errors: Array<ValidationError>;
  /** the newly created ShiftEvent */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of CreateShiftEvent */
export type CreateShiftEventPayload = {
  __typename?: 'CreateShiftEventPayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of CreateShiftEventWithSiteAndLocation */
export type CreateShiftEventWithSiteAndLocationPayload = {
  __typename?: 'CreateShiftEventWithSiteAndLocationPayload';
  errors: Array<ValidationError>;
  /** the newly created ShiftEvent */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of CreateSite */
export type CreateSitePayload = {
  __typename?: 'CreateSitePayload';
  errors: Array<ValidationError>;
  site?: Maybe<Site>;
};

/** Autogenerated return type of CreateSkill */
export type CreateSkillPayload = {
  __typename?: 'CreateSkillPayload';
  errors: Array<ValidationError>;
  skill?: Maybe<Skill>;
};

/** Autogenerated return type of CreateSpeciality */
export type CreateSpecialityPayload = {
  __typename?: 'CreateSpecialityPayload';
  errors: Array<ValidationError>;
  speciality?: Maybe<Speciality>;
};

/** Autogenerated return type of CreateStaffGroup */
export type CreateStaffGroupPayload = {
  __typename?: 'CreateStaffGroupPayload';
  errors: Array<ValidationError>;
  staffGroup?: Maybe<StaffGroup>;
};

/** Autogenerated return type of CreateSuperAdmin */
export type CreateSuperAdminPayload = {
  __typename?: 'CreateSuperAdminPayload';
  errors: Array<ValidationError>;
  superAdmin?: Maybe<HubUser>;
};

/** Autogenerated return type of CreateTempStaffShift */
export type CreateTempStaffShiftPayload = {
  __typename?: 'CreateTempStaffShiftPayload';
  errors: Array<ValidationError>;
  /** Result of the Shift::CreateAndAssignWorker operation */
  result: Scalars['Boolean'];
  /** The ids of the shifts that were created */
  shiftIds: Array<Scalars['Int']>;
  /** The shift that was created */
  shifts?: Maybe<Array<Shift>>;
};

/**
 * Sign-off authorisers in an organisation that workers can notify to confirm timesheets.
 *
 *     Each can authorise timesheet for multiple departments
 */
export type CreateTimesheetAuthoriserInput = {
  /** Ids of departments authoriser can approve timesheet for */
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Name of departments authoriser can approve timesheet for */
  departments?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
};

/** Autogenerated return type of CreateVmsAgency */
export type CreateVmsAgencyPayload = {
  __typename?: 'CreateVmsAgencyPayload';
  /** agency response object */
  agency?: Maybe<Agency>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of CreateVmsAgencyUser */
export type CreateVmsAgencyUserPayload = {
  __typename?: 'CreateVmsAgencyUserPayload';
  /** AgencyUserType when user has been created. */
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

/** Attributes for creating a Rostering Worker */
export type CreateWorkerInput = {
  /** Address line 1 of the worker */
  addressLineOne?: InputMaybe<Scalars['String']>;
  /** Address line 2 of the worker */
  addressLineTwo?: InputMaybe<Scalars['String']>;
  /** City of the worker */
  city?: InputMaybe<Scalars['String']>;
  /** Contract type of the worker */
  contractType?: InputMaybe<Scalars['String']>;
  /** Contracted work amount of the worker in hours or PAs */
  contractedWorkAmount: Scalars['Float'];
  /** true: hours, false: PAs */
  contractedWorkIsHours: Scalars['Boolean'];
  /** Cost centre code of the worker */
  costCentre?: InputMaybe<Scalars['String']>;
  /** Date of birth of the worker */
  dateOfBirth: Scalars['String'];
  /** Edi Gender value */
  ediGenderValue?: InputMaybe<Scalars['String']>;
  /** Email of the worker */
  email: Scalars['String'];
  /** First name of the worker */
  firstName: Scalars['String'];
  /** Does the worker have a fixed term contract? */
  fixedTerm: Scalars['Boolean'];
  /** Fixed term end date of the worker */
  fixedTermEndDate?: InputMaybe<Scalars['String']>;
  /** Grade name of the worker */
  gradeName?: InputMaybe<Scalars['String']>;
  /** Last name of the worker */
  lastName: Scalars['String'];
  /** Is the worker less than full time? */
  lessThanFullTime: Scalars['Boolean'];
  /** Other email of the worker */
  otherEmail?: InputMaybe<Scalars['String']>;
  /** Out of hours value of the worker */
  outOfHours?: InputMaybe<Scalars['Float']>;
  /** Phone number of the worker */
  phoneNumber: Scalars['String'];
  /** Postcode of the worker */
  postcode: Scalars['String'];
  /** Registration body number of the worker */
  regBodyNumber?: InputMaybe<Scalars['String']>;
  /** Registration body expiration date of the worker */
  regBodyNumberExpirationDate?: InputMaybe<Scalars['String']>;
  /** Array of speciality names of the worker */
  specialityName?: InputMaybe<Scalars['String']>;
  /** Staff group name of the worker */
  staffGroupName: Scalars['String'];
  /** ESR number of the worker */
  substantiveEsrNumber: Scalars['String'];
  /** ESR number valid from date */
  validFrom: Scalars['String'];
  /** ESR number valid to date */
  validTo?: InputMaybe<Scalars['String']>;
  /** Whole Time Equivalent value if the worker is Less Than Full Time */
  wholeTimeEquivalent?: InputMaybe<Scalars['Float']>;
  /** Working Arrangement of the worker */
  workingArrangement?: InputMaybe<Scalars['String']>;
};

/** Rostering Shift Types, and Sites/Locations of Activities, for the grid headers on Day View */
export type DayViewDataStructure = {
  __typename?: 'DayViewDataStructure';
  /**
   * Rostering Shift Types for which there is demand
   *
   *             on the specified Rota and Date
   */
  rosteringShiftTypes: Array<RosteringShiftType>;
  /**
   * Sites and Locations of Activities for which there is demand
   *
   *             on the specified Rota and Date
   */
  sitesOfActivities: Array<SiteOfActivities>;
};

/** Autogenerated return type of DeactivateEsrNumber */
export type DeactivateEsrNumberPayload = {
  __typename?: 'DeactivateEsrNumberPayload';
  errors: Array<ValidationError>;
  esrNumber?: Maybe<EsrNumber>;
};

/** Autogenerated return type of DeactivateWorker */
export type DeactivateWorkerPayload = {
  __typename?: 'DeactivateWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteAgencyRegistration */
export type DeleteAgencyRegistrationPayload = {
  __typename?: 'DeleteAgencyRegistrationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteCostCentre */
export type DeleteCostCentrePayload = {
  __typename?: 'DeleteCostCentrePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteDepartment */
export type DeleteDepartmentPayload = {
  __typename?: 'DeleteDepartmentPayload';
  /** Department object */
  department?: Maybe<Department>;
  errors: Array<ValidationError>;
  /** success status */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteDivision */
export type DeleteDivisionPayload = {
  __typename?: 'DeleteDivisionPayload';
  /** division errors */
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of DeleteDrafts */
export type DeleteDraftsPayload = {
  __typename?: 'DeleteDraftsPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteEsrNumber */
export type DeleteEsrNumberPayload = {
  __typename?: 'DeleteEsrNumberPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteEventTemplate */
export type DeleteEventTemplatePayload = {
  __typename?: 'DeleteEventTemplatePayload';
  errors: Array<ValidationError>;
  /** The Deleted Rostering::WorkerPatternEventTemplate */
  rosteringWorkerPatternEventTemplate?: Maybe<WorkerPatternEventTemplate>;
};

/** Autogenerated return type of DeleteExternalGrade */
export type DeleteExternalGradePayload = {
  __typename?: 'DeleteExternalGradePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteHubUser */
export type DeleteHubUserPayload = {
  __typename?: 'DeleteHubUserPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteItem */
export type DeleteItemPayload = {
  __typename?: 'DeleteItemPayload';
  errors: Array<ValidationError>;
  /** Resolver result */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteLeaveEntitlement */
export type DeleteLeaveEntitlementPayload = {
  __typename?: 'DeleteLeaveEntitlementPayload';
  errors: Array<ValidationError>;
  rosteringLeaveEntitlement?: Maybe<LeaveEntitlement>;
};

/** Autogenerated return type of DeleteLeaveGroup */
export type DeleteLeaveGroupPayload = {
  __typename?: 'DeleteLeaveGroupPayload';
  errors: Array<ValidationError>;
  rosteringLeaveGroup?: Maybe<LeaveGroup>;
};

/** Autogenerated return type of DeleteLeaveReason */
export type DeleteLeaveReasonPayload = {
  __typename?: 'DeleteLeaveReasonPayload';
  errors: Array<ValidationError>;
  rosteringLeaveReason?: Maybe<LeaveReason>;
};

/** Autogenerated return type of DeleteLevelGradeRates */
export type DeleteLevelGradeRatesPayload = {
  __typename?: 'DeleteLevelGradeRatesPayload';
  errors: Array<ValidationError>;
  /** Id of deleted level grade rates */
  id: Scalars['Int'];
};

/** Autogenerated return type of DeleteLocation */
export type DeleteLocationPayload = {
  __typename?: 'DeleteLocationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteLockConfig */
export type DeleteLockConfigPayload = {
  __typename?: 'DeleteLockConfigPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteMultipleShifts */
export type DeleteMultipleShiftsPayload = {
  __typename?: 'DeleteMultipleShiftsPayload';
  errors: Array<ValidationError>;
  /** Deleted shift events */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of DeleteOrgStaffGroupsSkill */
export type DeleteOrgStaffGroupsSkillPayload = {
  __typename?: 'DeleteOrgStaffGroupsSkillPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteOrganisationAgency */
export type DeleteOrganisationAgencyPayload = {
  __typename?: 'DeleteOrganisationAgencyPayload';
  errors: Array<ValidationError>;
};

/** Autogenerated return type of DeleteOrganisationComplianceDocument */
export type DeleteOrganisationComplianceDocumentPayload = {
  __typename?: 'DeleteOrganisationComplianceDocumentPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of Delete */
export type DeletePayload = {
  __typename?: 'DeletePayload';
  errors: Array<ValidationError>;
  /** The deleted Rostering::WorkerPattern */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Autogenerated return type of DeleteProfilePicture */
export type DeleteProfilePicturePayload = {
  __typename?: 'DeleteProfilePicturePayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of DeleteRateCard */
export type DeleteRateCardPayload = {
  __typename?: 'DeleteRateCardPayload';
  errors: Array<ValidationError>;
  /** The deleted Rostering Rate Card */
  rosteringRateCard?: Maybe<RateCard>;
};

/** Autogenerated return type of DeleteReason */
export type DeleteReasonPayload = {
  __typename?: 'DeleteReasonPayload';
  errors: Array<ValidationError>;
  /** Returns true if the reason was deleted */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteReferee */
export type DeleteRefereePayload = {
  __typename?: 'DeleteRefereePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteRule */
export type DeleteRulePayload = {
  __typename?: 'DeleteRulePayload';
  errors: Array<ValidationError>;
  /** Whether the Rule was destroyed */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteRuleTriggers */
export type DeleteRuleTriggersPayload = {
  __typename?: 'DeleteRuleTriggersPayload';
  errors: Array<ValidationError>;
  /** Success */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteSite */
export type DeleteSitePayload = {
  __typename?: 'DeleteSitePayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteSkill */
export type DeleteSkillPayload = {
  __typename?: 'DeleteSkillPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteSpeciality */
export type DeleteSpecialityPayload = {
  __typename?: 'DeleteSpecialityPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteUploadedFile */
export type DeleteUploadedFilePayload = {
  __typename?: 'DeleteUploadedFilePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteVmsAgency */
export type DeleteVmsAgencyPayload = {
  __typename?: 'DeleteVmsAgencyPayload';
  errors: Array<ValidationError>;
  /** Whether the agency was deleted successfully */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteVmsAgencyUser */
export type DeleteVmsAgencyUserPayload = {
  __typename?: 'DeleteVmsAgencyUserPayload';
  /** AgencyUserType when user has been deleted. */
  agencyUser?: Maybe<AgencyUser>;
  /** Errors when deleting the agency user. */
  errors: Array<Scalars['String']>;
};

/** Autogenerated return type of DeleteWorker */
export type DeleteWorkerPayload = {
  __typename?: 'DeleteWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteWorkerUpload */
export type DeleteWorkerUploadPayload = {
  __typename?: 'DeleteWorkerUploadPayload';
  documentType?: Maybe<DocumentType>;
  errors: Array<ValidationError>;
};

export type Department = {
  __typename?: 'Department';
  associatedCostCentres: Array<CostCentre>;
  colour?: Maybe<Scalars['String']>;
  costCentre?: Maybe<CostCentre>;
  covidRating?: Maybe<CovidRatingEnum>;
  createdAt?: Maybe<Scalars['DateTime']>;
  departmentsPreference: DepartmentsPreference;
  division?: Maybe<Division>;
  email?: Maybe<Scalars['String']>;
  externalName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  organisation: Organisation;
  pamNotificationSettings: PamNotificationsSettings;
  paymentApproverEmail?: Maybe<Scalars['String']>;
  pinSignoffEnabled?: Maybe<Scalars['Boolean']>;
  positionManagementCode?: Maybe<PositionManagementCodeEnum>;
  primarySpeciality?: Maybe<Speciality>;
  rateAuthorisers?: Maybe<Array<RateAuthoriser>>;
  shifts: Array<Shift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination: Pagination;
  /**
   * @deprecated Departments may have multiple sites, use `sites` instead.
   *  Or 'shift.site'
   */
  site: Site;
  sites: Array<Site>;
  specialities: Array<Speciality>;
  timesheetAuthorisers: Array<TimesheetAuthoriser>;
  trashedAt?: Maybe<Scalars['DateTime']>;
};


export type DepartmentShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Department. */
export type DepartmentConnection = {
  __typename?: 'DepartmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DepartmentEdge>>>;
  /** A list of nodes. */
  nodes: Array<Department>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Department>;
};

export type DepartmentsPreference = {
  __typename?: 'DepartmentsPreference';
  allowAppSignOff?: Maybe<Scalars['Boolean']>;
  allowEarlyAppSignOff?: Maybe<Scalars['Boolean']>;
  autoApprove?: Maybe<Scalars['Boolean']>;
  autoReleaseToSecondaryBank?: Maybe<Scalars['Boolean']>;
  bookHigher?: Maybe<Scalars['Boolean']>;
  bookLower?: Maybe<Scalars['Boolean']>;
  cutOffAgency?: Maybe<Scalars['Int']>;
  cutOffAlert?: Maybe<Scalars['Int']>;
  emailOnBooking?: Maybe<Scalars['Boolean']>;
  emailOnCancellation?: Maybe<Scalars['Boolean']>;
  emailOnSignOff?: Maybe<Scalars['Boolean']>;
  excludeFromRpa?: Maybe<Scalars['Boolean']>;
  higherPayToGrade?: Maybe<Scalars['Boolean']>;
  instantBookingForCollabBanks?: Maybe<Scalars['Boolean']>;
  isEnhanced?: Maybe<Scalars['Boolean']>;
  lowerGradeSelfBook?: Maybe<Scalars['Boolean']>;
  lowerPayToGrade?: Maybe<Scalars['Boolean']>;
  shiftReleaseOption?: Maybe<ShiftReleaseOptionEnum>;
  shiftReleaseOverride?: Maybe<Scalars['Boolean']>;
  signatureForShiftsSignOff?: Maybe<Scalars['Boolean']>;
  timedReleaseDays?: Maybe<Scalars['Int']>;
  urgentShiftNotifications?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DestroyAgencyRegistration */
export type DestroyAgencyRegistrationPayload = {
  __typename?: 'DestroyAgencyRegistrationPayload';
  errors: Array<ValidationError>;
  /** Success status of the deletion */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DestroyDocumentation */
export type DestroyDocumentationPayload = {
  __typename?: 'DestroyDocumentationPayload';
  errors: Array<ValidationError>;
  /** The success of the mutation */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DestroyRuleGroup */
export type DestroyRuleGroupPayload = {
  __typename?: 'DestroyRuleGroupPayload';
  errors: Array<ValidationError>;
  /** Whether the Rule Group was successfully destroyed. */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DestroyRuleGroupsRange */
export type DestroyRuleGroupsRangePayload = {
  __typename?: 'DestroyRuleGroupsRangePayload';
  errors: Array<ValidationError>;
  /** Was the Rule Groups Range destroyed? */
  success: Scalars['Boolean'];
};

export type DidNotAttend = {
  __typename?: 'DidNotAttend';
  /** The comment */
  comment?: Maybe<Scalars['String']>;
  /** The created at */
  createdAt: Scalars['ISO8601DateTime'];
  /** The id */
  id: Scalars['ID'];
  /** The shift */
  shift: Shift;
  /** The worker who did not attend */
  worker: Worker;
};

/** Reference data for EDI questions on disability */
export type Disability = {
  __typename?: 'Disability';
  /** ID of database record */
  id: Scalars['Int'];
  /** Determines display order */
  order: Scalars['Int'];
  /** Text value for display */
  value: Scalars['String'];
};

/** Represents Division */
export type Division = {
  __typename?: 'Division';
  /** Database ID of the division */
  id: Scalars['Int'];
  /** Division name */
  name?: Maybe<Scalars['String']>;
  /** organisation ID of the division */
  organisationId: Scalars['Int'];
};

export type Document = {
  __typename?: 'Document';
  documentNumber?: Maybe<Scalars['String']>;
  evidences: Array<Evidence>;
  id: Scalars['Int'];
  /** @deprecated Documents may have multiple evidence attachments, use `Document.evidence` instead. */
  imageUrl?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  niNumber?: Maybe<Scalars['String']>;
  obtainedDate?: Maybe<Scalars['Date']>;
  renewalDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  allowedFormats?: Maybe<Scalars['String']>;
  archivedWorkerUploads?: Maybe<Array<WorkerUpload>>;
  documentNumberVisibility?: Maybe<Scalars['Int']>;
  documentTitleVisibility?: Maybe<Scalars['Int']>;
  expirationDateVisibility?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  issueDateVisibility?: Maybe<Scalars['Int']>;
  issuerVisibility?: Maybe<Scalars['Int']>;
  mid?: Maybe<Scalars['String']>;
  multipleUploadsAllowed?: Maybe<Scalars['Boolean']>;
  qualificationNameVisibility?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  workerUploads?: Maybe<Array<WorkerUpload>>;
};

/** Agency Registration Documentation Type */
export type Documentation = {
  __typename?: 'Documentation';
  agencyRegistrationId: Scalars['Int'];
  categoryType: Scalars['String'];
  content?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  customName?: Maybe<Scalars['String']>;
  /** URLs for the attached files */
  fileUrls?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

/** Documentation Type */
export type DocumentationInputs = {
  /** The type of documentation */
  categoryType: Scalars['String'];
  /** JSON content for documentation */
  content?: InputMaybe<Scalars['JSON']>;
  /** Custom name if applicable */
  customName?: InputMaybe<Scalars['String']>;
  /** Files IO */
  files?: InputMaybe<Array<Scalars['Upload']>>;
};

/** Documentations Type */
export type Documentations = {
  __typename?: 'Documentations';
  agencyRegistrationId: Scalars['Int'];
  documentations: Array<Documentation>;
};

export type DraftShiftUpdateInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  breaks?: InputMaybe<Scalars['Int']>;
  costCentreCode?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['Int'];
  draftShiftId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['Int'];
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of Duplicate */
export type DuplicatePayload = {
  __typename?: 'DuplicatePayload';
  errors: Array<ValidationError>;
  /** The new Worker Pattern */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Input type to create a bank shift from roster */
export type EditBankRosterShiftInput = {
  activityId?: InputMaybe<Scalars['Int']>;
  adminNote?: InputMaybe<Scalars['String']>;
  bankShiftId: Scalars['Int'];
  breaks?: InputMaybe<Scalars['Int']>;
  costCentre?: InputMaybe<Scalars['String']>;
  isServicePlansUpdate?: InputMaybe<Scalars['Boolean']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  proposedEndTime?: InputMaybe<Scalars['String']>;
  proposedStartTime?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  reasonForTimeChange?: InputMaybe<Scalars['String']>;
  rosteringShiftId?: InputMaybe<Scalars['Int']>;
  shiftType?: InputMaybe<ShiftKindEnum>;
  siteId?: InputMaybe<Scalars['Int']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerNote?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of EditLeaveGroup */
export type EditLeaveGroupPayload = {
  __typename?: 'EditLeaveGroupPayload';
  errors: Array<ValidationError>;
  rosteringLeaveGroup?: Maybe<LeaveGroup>;
};

/** Autogenerated return type of EditLeaveReason */
export type EditLeaveReasonPayload = {
  __typename?: 'EditLeaveReasonPayload';
  errors: Array<ValidationError>;
  rosteringLeaveReason?: Maybe<LeaveReason>;
};

/** Autogenerated return type of EditLeaveRequestFromSuperHub */
export type EditLeaveRequestFromSuperHubPayload = {
  __typename?: 'EditLeaveRequestFromSuperHubPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequestType?: Maybe<LeaveRequest>;
};

/** Autogenerated return type of EditShift */
export type EditShiftPayload = {
  __typename?: 'EditShiftPayload';
  errors: Array<ValidationError>;
  /** Success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of EmptyRosterCreate */
export type EmptyRosterCreatePayload = {
  __typename?: 'EmptyRosterCreatePayload';
  errors: Array<ValidationError>;
  /** The created Roster */
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of EnqueueAddDayTemplateToRota */
export type EnqueueAddDayTemplateToRotaPayload = {
  __typename?: 'EnqueueAddDayTemplateToRotaPayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of EnqueueRosterCreate */
export type EnqueueRosterCreatePayload = {
  __typename?: 'EnqueueRosterCreatePayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Error = {
  __typename?: 'Error';
  attribute?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of EscalateRateWithAdvancedFlow */
export type EscalateRateWithAdvancedFlowPayload = {
  __typename?: 'EscalateRateWithAdvancedFlowPayload';
  errors: Array<ValidationError>;
  /** Indicates if the escalation was successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EscalateRateWithBasicFlow */
export type EscalateRateWithBasicFlowPayload = {
  __typename?: 'EscalateRateWithBasicFlowPayload';
  errors: Array<ValidationError>;
  /** Indicates if the escalation was successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of EscalateRateWithLevelGradeRates */
export type EscalateRateWithLevelGradeRatesPayload = {
  __typename?: 'EscalateRateWithLevelGradeRatesPayload';
  errors: Array<ValidationError>;
  /** Indicates if the escalation was successful */
  success?: Maybe<Scalars['Boolean']>;
};

/** Escalated rate */
export type EscalatedRate = {
  /** The escalated value */
  escalatedValue: Scalars['Float'];
  /** The rate name */
  name: Scalars['String'];
};

/** Escalated rate element */
export type EscalatedRateElement = {
  __typename?: 'EscalatedRateElement';
  name: Scalars['String'];
  newTotalRate: Scalars['Float'];
  oldTotalRate: Scalars['Float'];
};

/** Enum of receipt status */
export enum EscalatedRateOriginEnum {
  /** Bank rate card */
  BankRateCard = 'BANK_RATE_CARD',
  /** Grade rate */
  GradeRate = 'GRADE_RATE'
}

export type EsrNumber = {
  __typename?: 'EsrNumber';
  authoriserName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  esrNumber?: Maybe<Scalars['String']>;
  hospitalId: Scalars['Int'];
  id: Scalars['ID'];
  internalOrExternal?: Maybe<Scalars['String']>;
  lessThanFullTime?: Maybe<Scalars['Boolean']>;
  monthly: Scalars['Boolean'];
  organisation: Organisation;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

/** Ethnicity reference data for EDI questions */
export type Ethnicity = {
  __typename?: 'Ethnicity';
  /** ID of database record */
  id: Scalars['Int'];
  /** Used to determine display order */
  order: Scalars['Int'];
  /** Text value for display */
  value: Scalars['String'];
};

/** Sections grouping Ethnicity reference data */
export type EthnicitySection = {
  __typename?: 'EthnicitySection';
  /** List of ethnicity values */
  ethnicities: Array<Ethnicity>;
  /** Section ID */
  id: Scalars['Int'];
  /** Section order, determines display order */
  order: Scalars['Int'];
  /** Section title to be displayed */
  value: Scalars['String'];
};

/** Worker shifts, leave events and days off within the week */
export type EventsWithinWeek = {
  __typename?: 'EventsWithinWeek';
  /** leave events generated from leave requests (approved, pending cancellation) */
  leaveEvents: Array<LeaveEvent>;
  /** Rostering events of type shift and rest */
  shiftsAndRest: Array<RosteringEvent>;
  /** Week */
  week: Scalars['Date'];
};

/** The connection type for EventsWithinWeek. */
export type EventsWithinWeekConnection = {
  __typename?: 'EventsWithinWeekConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventsWithinWeekEdge>>>;
  /** A list of nodes. */
  nodes: Array<EventsWithinWeek>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EventsWithinWeekEdge = {
  __typename?: 'EventsWithinWeekEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EventsWithinWeek>;
};

export type Evidence = {
  __typename?: 'Evidence';
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
};

/** Notification Setting object for readonly permissions */
export type ExceptionReportCategoriesNotificationSetting = {
  __typename?: 'ExceptionReportCategoriesNotificationSetting';
  /** Send notifications in all cases */
  notifyAll: Scalars['Boolean'];
  /** Send notifications when compliance breaches */
  notifyComplianceBreach: Scalars['Boolean'];
  /** Send notifications when patient safety impacted */
  notifyPatientSafetyImpacted: Scalars['Boolean'];
};

/** Notification Setting object to pass in as an argument to ExceptionReportPermissions mutations */
export type ExceptionReportCategoriesNotificationSettingInput = {
  /** Send notifications in all cases */
  notifyAll?: InputMaybe<Scalars['Boolean']>;
  /** Send notifications when compliance breaches */
  notifyComplianceBreach?: InputMaybe<Scalars['Boolean']>;
  /** Send notifications when patient safety impacted */
  notifyPatientSafetyImpacted?: InputMaybe<Scalars['Boolean']>;
};

/** Describes given compensation in exception report */
export enum ExceptionReportGivenCompensationEnum {
  /** No compensation given */
  NoCompensation = 'no_compensation',
  /** Pay given as compensation */
  Pay = 'pay',
  /** TOIL given as compensation */
  TimeOffInLieu = 'time_off_in_lieu'
}

/** Describes status of exception report */
export enum ExceptionReportNoteTypeEnum {
  /** Dispute note attached to the Exception Report by submitting worker */
  DisputeNote = 'dispute_note',
  /** Exception report has been approved in initial review */
  Tier1Approve = 'tier1_approve',
  /** Exception report has been rejected in initial review */
  Tier1Reject = 'tier1_reject',
  /** Exception report has been approved after dispute */
  Tier2Approve = 'tier2_approve',
  /** Exception report has been rejected after dispute */
  Tier2Reject = 'tier2_reject'
}

/** Exception Report Permission object */
export type ExceptionReportPermission = {
  __typename?: 'ExceptionReportPermission';
  /** Supervise all workers in the organisation */
  allWorkers?: Maybe<Scalars['Boolean']>;
  /** User exception report approve permissions */
  approveCategories: Array<ExceptionReportSupervisorCategory>;
  /** Defines current user permission to manage exception report approvers */
  canManageExceptionReportApprovers: Scalars['Boolean'];
  /** User exception report read-only permissions */
  readonlyCategories: Array<ExceptionReportSupervisorCategory>;
  /** List of workers the permissions apply to. Empty if all_workers is true */
  workers: Array<OrganisationWorker>;
};

/** Describes requested compensation in exception report */
export enum ExceptionReportRequestedCompensationEnum {
  /** No preference for the compensation */
  NoPreference = 'no_preference',
  /** Prefered pay as compensation */
  Pay = 'pay',
  /** Prefered TOIL as compensation */
  TimeOffInLieu = 'time_off_in_lieu'
}

/** Describes status of exception report */
export enum ExceptionReportStatusEnum {
  /** Exception report has been approved */
  Approved = 'approved',
  /** Exception report has been created but not submitted yet */
  Draft = 'draft',
  /** Exception report has been submitted and is waiting for review */
  Pending = 'pending',
  /** Exception report has been disputed */
  PendingDispute = 'pending_dispute',
  /** Exception report has been rejected */
  Rejected = 'rejected',
  /** Exception report has been rejected after dispute */
  RejectedDispute = 'rejected_dispute'
}

/** Exception Report Permission object */
export type ExceptionReportSupervisorCategory = {
  __typename?: 'ExceptionReportSupervisorCategory';
  /** Determines if the user supervises all workers */
  allWorkers?: Maybe<Scalars['Boolean']>;
  /** Exception Report Category */
  exceptionReportCategory?: Maybe<RosteringExceptionReportReasonCategory>;
  /** Id of the workers supervisor exception report category */
  id?: Maybe<Scalars['ID']>;
  /** Exception Report notification settings */
  notificationSetting?: Maybe<ExceptionReportCategoriesNotificationSetting>;
  /** Determines if the user has readonly permission for a category */
  readonly?: Maybe<Scalars['Boolean']>;
  /** Exception Report Category id */
  rosteringExceptionReportReasonCategoryId?: Maybe<Scalars['Int']>;
  /** Supervisor for the category */
  supervisor?: Maybe<HubUser>;
  /** Determines if the user is a tier one supervisor */
  tier1?: Maybe<Scalars['Boolean']>;
  /** Determines if the user is a tier two supervisor */
  tier2?: Maybe<Scalars['Boolean']>;
};

/** Permission object to pass in as an argument to RosteringExceptionReportPermissions CRUD mutations */
export type ExceptionReportSupervisorCategoryInput = {
  /** Exception Report notification settings */
  notificationSetting?: InputMaybe<ExceptionReportCategoriesNotificationSettingInput>;
  /** Exception Report Category id */
  rosteringExceptionReportReasonCategoryId: Scalars['Int'];
  /** Determines if the user can approve or reject pending exception reports */
  tier1: Scalars['Boolean'];
  /** Determines if the user can approve or reject pending disputed exception reports */
  tier2: Scalars['Boolean'];
};

/** Autogenerated return type of ExportDocumentationsUploads */
export type ExportDocumentationsUploadsPayload = {
  __typename?: 'ExportDocumentationsUploadsPayload';
  errors: Array<ValidationError>;
  /** The success of the mutation */
  success: Scalars['Boolean'];
  /** Base64 encoded zip file of the attachments */
  zipString?: Maybe<Scalars['String']>;
};

export type ExportHubUserColumnsInput = {
  accountTypes?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
  dateRegistered?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  organisation?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ExportHubUsers */
export type ExportHubUsersPayload = {
  __typename?: 'ExportHubUsersPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ExportOption = {
  __typename?: 'ExportOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ExportSection = {
  __typename?: 'ExportSection';
  options: Array<ExportOption>;
  title: Scalars['String'];
};

/** Autogenerated return type of ExportShiftAndBlockCompliance */
export type ExportShiftAndBlockCompliancePayload = {
  __typename?: 'ExportShiftAndBlockCompliancePayload';
  errors: Array<ValidationError>;
  /** The success of the mutation */
  success: Scalars['Boolean'];
  /** The zip file string */
  zipString?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ExportShifts */
export type ExportShiftsPayload = {
  __typename?: 'ExportShiftsPayload';
  errors: Array<ValidationError>;
  /** Indicates if the request was successful */
  success: Scalars['Boolean'];
};

export type ExportWorkerColumnsInput = {
  activatedForBank?: InputMaybe<Scalars['Boolean']>;
  activatedForRostering?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
  activeBankOrganisation?: InputMaybe<Scalars['Boolean']>;
  collabBankOrganisation?: InputMaybe<Scalars['Boolean']>;
  dateRegistered?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['Boolean']>;
  grade?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['Boolean']>;
  staffGroup?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ExportWorkers */
export type ExportWorkersPayload = {
  __typename?: 'ExportWorkersPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  accountType?: Maybe<ExternalAccountTypeEnum>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shiftBasicRead?: Maybe<Scalars['Boolean']>;
  staffGroups?: Maybe<Array<StaffGroup>>;
  vmsWebhooks?: Maybe<Array<VmsWebhook>>;
  webhookToken?: Maybe<Scalars['String']>;
};

/** The connection type for ExternalAccount. */
export type ExternalAccountConnection = {
  __typename?: 'ExternalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExternalAccountEdge>>>;
  /** A list of nodes. */
  nodes: Array<ExternalAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExternalAccountEdge = {
  __typename?: 'ExternalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ExternalAccount>;
};

/** External Account type */
export enum ExternalAccountTypeEnum {
  /** Basic External Account */
  BasicExternalAccount = 'BASIC_EXTERNAL_ACCOUNT',
  /** Nhs Api Account */
  NhsApi = 'NHS_API',
  /** Read Only Account */
  ReadOnly = 'READ_ONLY',
  /** RPA Account */
  Rpa = 'RPA',
  /** VMS Account */
  VmsAccount = 'VMS_ACCOUNT',
  /** Wagestream Account */
  Wagestream = 'WAGESTREAM'
}

/** Autogenerated return type of ExternalApplication */
export type ExternalApplicationPayload = {
  __typename?: 'ExternalApplicationPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

/** External cancel reason and its mapping to Patchwork cancel reason in an organisation */
export type ExternalCancelReason = {
  __typename?: 'ExternalCancelReason';
  /** Id of external cancel reason held in other system */
  externalId: Scalars['String'];
  /** Id of the external cancel reason in Patchwork */
  id: Scalars['ID'];
  /** Organisation external cancel reason is attached to */
  organisation: Organisation;
  /** Cancel reason this mapping is attached to */
  reasonForCancellation: ReasonForCancellation;
};

export type ExternalGrade = {
  __typename?: 'ExternalGrade';
  /** Is this the external grade used for shifts created in Patchwork? */
  default: Scalars['Boolean'];
  externalName: Scalars['String'];
  externalType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Represents external organisation list of fields */
export type ExternalOrganisation = {
  __typename?: 'ExternalOrganisation';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  identityUrl?: Maybe<Scalars['String']>;
  organisation: Array<Organisation>;
  password?: Maybe<Scalars['String']>;
  trustCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** External request reason and its mapping to Patchwork request reason in an organisation */
export type ExternalRequestReason = {
  __typename?: 'ExternalRequestReason';
  /** Id of the external request reason held in other system */
  externalId: Scalars['String'];
  /** Id of the external request reason in Patchwork */
  id: Scalars['ID'];
  /** Organisation external request reason is attached to */
  organisation: Organisation;
  /** Request reason the mapping is attached to */
  reasonForRequest: ReasonForRequest;
};

/** Autogenerated return type of ExternalResetPassword */
export type ExternalResetPasswordPayload = {
  __typename?: 'ExternalResetPasswordPayload';
  errors: Array<ValidationError>;
  /** reset password success status */
  success: Scalars['Boolean'];
};

/** External skills that are mapped to Patchwork Master Skill */
export type ExternalSkill = {
  __typename?: 'ExternalSkill';
  /** Name of external skill */
  externalSkillName: Scalars['String'];
  /**
   * Type of mapping, options are Default, Standard and
   *           Ignored. Default are used to map from hub to bankstaff
   */
  externalType: Scalars['String'];
  /** ID */
  id: Scalars['Int'];
  /** Skill that is mapped to */
  skill: Skill;
};

export type ExternalUnit = {
  __typename?: 'ExternalUnit';
  costCentre?: Maybe<Scalars['String']>;
  department: Department;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  site: Site;
};

export type Field = {
  __typename?: 'Field';
  /** Display name */
  displayName?: Maybe<Scalars['String']>;
  /** Field name */
  fieldName?: Maybe<Scalars['String']>;
};

/** DE Contract Version fields */
export type Fields = {
  __typename?: 'Fields';
  /** List of Common fields */
  commonFields: Array<Field>;
  /** List of DE LTD fields */
  deLtdFields?: Maybe<Array<Field>>;
  /** List of DE Sole Trader fields */
  deSoleTraderFields?: Maybe<Array<Field>>;
};

/** Autogenerated return type of FileUploadReminder */
export type FileUploadReminderPayload = {
  __typename?: 'FileUploadReminderPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** The time type used with filters */
export enum FilterDateEnum {
  /** Selected specific dates */
  Specificdates = 'SPECIFICDATES',
  /** A date range of all dates between the start and end date (inclusive) */
  Timeframe = 'TIMEFRAME'
}

export type FilterGrade = {
  __typename?: 'FilterGrade';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  staffGroup: StaffGroup;
  title?: Maybe<Scalars['String']>;
};

/** The connection type for FilterGrade. */
export type FilterGradeConnection = {
  __typename?: 'FilterGradeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FilterGradeEdge>>>;
  /** A list of nodes. */
  nodes: Array<FilterGrade>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FilterGradeEdge = {
  __typename?: 'FilterGradeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FilterGrade>;
};

/** Filter option for receipts */
export type FilterOption = {
  __typename?: 'FilterOption';
  /** Label for filter option */
  label: Scalars['String'];
  /** Value for filter option */
  value: Scalars['Int'];
};

/** The time type used with filters */
export enum FilterTimeEnum {
  /** Daytime hours */
  Day = 'DAY',
  /** Night shift hours */
  Night = 'NIGHT'
}

/** Graphql filters response type */
export type Filters = {
  __typename?: 'Filters';
  /** the date type selection - can be a timeframe or specific dates */
  dateType?: Maybe<FilterDateEnum>;
  /** the distance in miles of the shift */
  distanceInMiles?: Maybe<Scalars['Int']>;
  /** the time shifts will end by (inclusive) */
  endsBy?: Maybe<Scalars['String']>;
  /** the grade ids for filtering */
  filterGradeIds?: Maybe<Scalars['JSON']>;
  /** the id of the filter */
  id: Scalars['ID'];
  /** the latitude of where the shift is located */
  latitude?: Maybe<Scalars['Float']>;
  /** the longitude of where the shift is located */
  longitude?: Maybe<Scalars['Float']>;
  /** the postcode the user provides to find shinfts within a distance of this postcode */
  postcode?: Maybe<Scalars['String']>;
  /** the selected dates for shifts */
  selectedDates?: Maybe<Scalars['JSON']>;
  /** the type of shift - will be sessional or hourly */
  shiftType?: Maybe<ShiftTypeEnum>;
  /** the ids of sites for filtering */
  siteIds?: Maybe<Scalars['JSON']>;
  /** the ids of specialties for filtering */
  specialtyIds?: Maybe<Scalars['JSON']>;
  /** the time shifts start after (inclusive) */
  startsAfter?: Maybe<Scalars['String']>;
  timeType?: Maybe<FilterTimeEnum>;
  /** Worker the filters belongs to */
  worker: Worker;
};

/** Choose if you want to include or exclude reviews from the payment shifts */
export enum FlaggedPaymentsOptionsEnum {
  /** Exclude reviews */
  Exclude = 'exclude',
  /** Include reviews */
  Include = 'include',
  /** Only reviews */
  Only = 'only'
}

/** Autogenerated return type of ForgetDeviceToken */
export type ForgetDeviceTokenPayload = {
  __typename?: 'ForgetDeviceTokenPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Reference data for EDI questions on gender */
export type Gender = {
  __typename?: 'Gender';
  /** ID of database record */
  id: Scalars['Int'];
  /** Determines display order */
  order: Scalars['Int'];
  /** Text value for display */
  value: Scalars['String'];
};

/** User gender */
export enum GenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSet = 'NOT_SET',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

/** Autogenerated return type of GeneratePayBreakdown */
export type GeneratePayBreakdownPayload = {
  __typename?: 'GeneratePayBreakdownPayload';
  /** Annual Pay Breakdown for the WorkerPattern */
  annualPayBreakdown?: Maybe<AnnualPayBreakdown>;
  errors: Array<ValidationError>;
};

export type Grade = {
  __typename?: 'Grade';
  agencyCap?: Maybe<Scalars['Float']>;
  agencySubjectiveCode?: Maybe<Scalars['String']>;
  autoApplyUnsocialNightRates?: Maybe<Scalars['Boolean']>;
  bankHolidayPayElement?: Maybe<Scalars['String']>;
  bankSubjectiveCode?: Maybe<Scalars['String']>;
  basicRate?: Maybe<Scalars['Float']>;
  basicRateUnsocial?: Maybe<Scalars['Float']>;
  breakRepeatMinutes?: Maybe<Scalars['Int']>;
  callOutRate: Scalars['Float'];
  colour?: Maybe<Scalars['String']>;
  coreWeekendsAndBankHolidays?: Maybe<Scalars['Boolean']>;
  defaultBreakMinutes?: Maybe<Scalars['Int']>;
  defaultExternalGrade?: Maybe<ExternalGrade>;
  enhancedRate?: Maybe<Scalars['Float']>;
  enhancedRateUnsocial?: Maybe<Scalars['Float']>;
  escalationLevelGradeRates: Array<LevelGradeRates>;
  externalGrades: Array<ExternalGrade>;
  filterGrade?: Maybe<FilterGrade>;
  id: Scalars['ID'];
  nightShiftEndTime?: Maybe<Scalars['String']>;
  nightShiftStartTime?: Maybe<Scalars['String']>;
  onCallRate: Scalars['Float'];
  organisation: Organisation;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  ranking?: Maybe<Scalars['Int']>;
  rateNotApplicable?: Maybe<Scalars['Boolean']>;
  rosteringLeaveGroup?: Maybe<LeaveGroup>;
  saturdayPayElement?: Maybe<Scalars['String']>;
  staffGroup: StaffGroup;
  staffGroupId: Scalars['Int'];
  sundayPayElement?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  useDefaultBreaks?: Maybe<Scalars['Boolean']>;
  usesFiftyPercentRule?: Maybe<Scalars['Boolean']>;
  weekdayDayPayElement?: Maybe<Scalars['String']>;
  weekdayNightPayElement?: Maybe<Scalars['String']>;
};

/** The connection type for Grade. */
export type GradeConnection = {
  __typename?: 'GradeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GradeEdge>>>;
  /** A list of nodes. */
  nodes: Array<Grade>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type GradeEdge = {
  __typename?: 'GradeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Grade>;
};

export type GradeLeaveGroup = {
  __typename?: 'GradeLeaveGroup';
  grade: LeaveGrade;
  id: Scalars['ID'];
  leaveGroup: LeaveGroup;
  staffGroup: LeaveStaffGroup;
};

export type HubUser = {
  __typename?: 'HubUser';
  accountType?: Maybe<Scalars['String']>;
  accountTypes?: Maybe<Array<Scalars['String']>>;
  /** Checks if user is .active or .confirmed? - for legacy HubUsers */
  active: Scalars['Boolean'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  authorisationLevels?: Maybe<Array<AuthorisationLevel>>;
  authoriserDepartments?: Maybe<Array<Department>>;
  avatar?: Maybe<Scalars['String']>;
  bankPermissions?: Maybe<BankPermission>;
  canAccessEsrTransfer: Scalars['Boolean'];
  canAccessSuperhub: Scalars['Boolean'];
  canCancelShift?: Maybe<Scalars['Boolean']>;
  canControlLocks: Scalars['Boolean'];
  canCreateRoster: Scalars['Boolean'];
  canManageAdjustments: Scalars['Boolean'];
  canManageAgency?: Maybe<Scalars['Boolean']>;
  canManageAutoCascade: Scalars['Boolean'];
  canManageBank?: Maybe<Scalars['Boolean']>;
  canManageDeContracts?: Maybe<Scalars['Boolean']>;
  canManageExceptionReportApprovers: Scalars['Boolean'];
  canManageExpenses?: Maybe<Scalars['Boolean']>;
  canManagePayRules?: Maybe<Scalars['Boolean']>;
  canManageProposals?: Maybe<Scalars['Boolean']>;
  canManageRoster?: Maybe<Scalars['Boolean']>;
  canOnlyViewProposals?: Maybe<Scalars['Boolean']>;
  canOverrideBreakDuration?: Maybe<Scalars['Boolean']>;
  canShortlistProposals?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  clinicalSupervisor: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  dbsNumber?: Maybe<Scalars['String']>;
  departments: Array<Department>;
  /** Whether the user has consented to EDI data collection */
  ediConsent?: Maybe<Scalars['Boolean']>;
  /** Edi country of qualification */
  ediCountryOfQualification?: Maybe<CountryOfQualification>;
  /** The disability for EDI data */
  ediDisability?: Maybe<Disability>;
  /** The ethnicity for EDI data */
  ediEthnicity?: Maybe<Ethnicity>;
  /** The gender for EDI data */
  ediGender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  escalationLevels?: Maybe<Array<Level>>;
  filterGrade?: Maybe<FilterGrade>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<GenderEnum>;
  hubUserTodos: HubUserTodos;
  id: Scalars['ID'];
  intercomKey?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  multipleOrganisationRegistrations: Scalars['Boolean'];
  niNumber?: Maybe<Scalars['String']>;
  organisation: Organisation;
  otherEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** Has the user provided EDI data */
  providedEdiData: Scalars['Boolean'];
  regBodyNumber?: Maybe<Scalars['String']>;
  regBodyNumberExpirationDate?: Maybe<Scalars['Date']>;
  rosterPermissions?: Maybe<RosterPermission>;
  rosteringOrganisationRegistrations?: Maybe<Array<RosteringOrganisationRegistration>>;
  rosteringOrganisations?: Maybe<Array<Organisation>>;
  rosteringPermissionSets?: Maybe<Array<RosteringPermissionSet>>;
  rosteringWorkerProfile?: Maybe<RosteringWorkerProfile>;
  rosteringWorkerProfileId?: Maybe<Scalars['Int']>;
  specialities: Array<Speciality>;
  staffGroup?: Maybe<StaffGroup>;
  supervisedWorkers?: Maybe<Array<RosteringOrganisationRegistration>>;
  unreadNotifications: Scalars['Int'];
  useKeycloakAuth: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
};

/** The connection type for HubUser. */
export type HubUserConnection = {
  __typename?: 'HubUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HubUserEdge>>>;
  /** A list of nodes. */
  nodes: Array<HubUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type HubUserEdge = {
  __typename?: 'HubUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<HubUser>;
};

/** Graphql Hub User receipts todos response type */
export type HubUserReceiptsTodos = {
  __typename?: 'HubUserReceiptsTodos';
  /** Number of receipts for approval */
  pendingApproval: Scalars['Int'];
  /** Number of receipts for sign off */
  signOffRequested: Scalars['Int'];
};

/** Autogenerated return type of HubUserRejectSignOff */
export type HubUserRejectSignOffPayload = {
  __typename?: 'HubUserRejectSignOffPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of HubUserSignOff */
export type HubUserSignOffPayload = {
  __typename?: 'HubUserSignOffPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of HubUserSignOffRequestedMultiple */
export type HubUserSignOffRequestedMultiplePayload = {
  __typename?: 'HubUserSignOffRequestedMultiplePayload';
  errors: Array<ValidationError>;
  /** Boolean for whether successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of HubUserSignOffWithTimesAmendedMultiple */
export type HubUserSignOffWithTimesAmendedMultiplePayload = {
  __typename?: 'HubUserSignOffWithTimesAmendedMultiplePayload';
  errors: Array<ValidationError>;
  /** Boolean for whether successful */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of HubUserSignOffWithTimesAmended */
export type HubUserSignOffWithTimesAmendedPayload = {
  __typename?: 'HubUserSignOffWithTimesAmendedPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Graphql Hub User todos response type */
export type HubUserTodos = {
  __typename?: 'HubUserTodos';
  /** Number of block proposals */
  blockProposalsCount: Scalars['Int'];
  /** Number of bookings */
  bookingsCount: Scalars['Int'];
  /** Number of payments */
  paymentsCount: Scalars['Int'];
  /** Number of receipts per status */
  receiptsCount: HubUserReceiptsTodos;
  /** Number of shift proposals */
  shiftProposalsCount: Scalars['Int'];
  /** Number of shifts with requested escalations */
  shiftWithEscalationRequestsCount: Scalars['Int'];
  /** Number of activation requests */
  staffActivationRequestsCount: Scalars['Int'];
  /** Number of external applications */
  staffExternalApplicationsCount: Scalars['Int'];
  /** Number of timesheets */
  timesheetsCount: Scalars['Int'];
};

/** Autogenerated return type of ImportWorkers */
export type ImportWorkersPayload = {
  __typename?: 'ImportWorkersPayload';
  /**
   * JSON response data
   * @deprecated No data will be available until the background job completes. An update email will be sent to the current_user on completion.
   */
  data?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  /** Indicates whether the job was successfully enqueued */
  success: Scalars['Boolean'];
};

/** Shift object to pass in as an argument in worker multiple sign off mutation */
export type InputMultipleAmendedShift = {
  /** Should accept Worker report boolean */
  acceptWorkerReport?: InputMaybe<Scalars['Boolean']>;
  /** Who approved sign-off */
  approvedBy?: InputMaybe<Scalars['String']>;
  /** Date of sign-off approval */
  approvedDate?: InputMaybe<Scalars['String']>;
  /** Why breaks were approved when enforcement was switched on */
  breaksExceptionNotes?: InputMaybe<Scalars['String']>;
  /** Notes with sign-off */
  confirmationNotes?: InputMaybe<Scalars['String']>;
  /** Actual length of break */
  confirmedBreaks: Scalars['Int'];
  /** Actual shift end time */
  confirmedEndTime: Scalars['String'];
  /** Actual shift start time */
  confirmedStartTime: Scalars['String'];
  /** ID of shift being signed-off */
  shiftId: Scalars['Int'];
  /** Sign-off authoriser ID */
  signOffAuthoriserId: Scalars['Int'];
};

/** Multi sign-off request params for on-call shifts */
export type InputMultipleOnCallShift = {
  /** End time for amended shift the timeframe is being submitted for */
  endTime?: InputMaybe<Scalars['String']>;
  /** ID of the shift the timeframe is being submitted for */
  id: Scalars['Int'];
  /** Notes for reported timeframes */
  notes?: InputMaybe<Scalars['String']>;
  /** Start time for amended shift the timeframe is being submitted for */
  startTime?: InputMaybe<Scalars['String']>;
  /** List of datetimes for each call-out */
  timeframes: Array<InputOnCallShiftTimeframe>;
};

/** Shift object to pass in as an argument in worker multiple sign off mutation */
export type InputMultipleShift = {
  /** Number of minutes of breaks */
  breaks: Scalars['Int'];
  /** End time of the shift the timesheet is being submitted for */
  endTime: Scalars['String'];
  /** ID of the shift the timesheet is being submitted for */
  id: Scalars['Int'];
  /** Any notes for explaining different times of shifts or breaks */
  reportedNotes?: InputMaybe<Scalars['String']>;
  /** Start time of the shift the timesheet is being submitted for */
  startTime: Scalars['String'];
};

/** Timeframe object to pass for on-call shifts */
export type InputOnCallShiftTimeframe = {
  /** Number of minutes of breaks */
  breaks: Scalars['Int'];
  /** End datetime of the shift timeframe is being submitted for */
  endTime: Scalars['String'];
  /** Start datetime of the shift timeframe is being submitted for */
  startTime: Scalars['String'];
};

/** Pay Element object to pass in as an argument in propose/assign shift mutation */
export type InputPayElement = {
  agencyMargin?: InputMaybe<Scalars['Float']>;
  baseRate?: InputMaybe<Scalars['Float']>;
  benefit?: InputMaybe<Scalars['Float']>;
  breakHours?: InputMaybe<Scalars['Float']>;
  comparatorRate?: InputMaybe<Scalars['Float']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  totalRate?: InputMaybe<Scalars['Float']>;
  workerBaseRate?: InputMaybe<Scalars['Float']>;
  workerRate?: InputMaybe<Scalars['Float']>;
};

/** Rate change for an escalation to check authorisation levels against */
export type InputRateChanges = {
  /** The escalated rate */
  newRate: Scalars['Float'];
  /** The rate before the change */
  oldRate: Scalars['Float'];
};

/** Shift object to pass in as an argument in worker sign off mutation */
export type InputShift = {
  breaks: Scalars['Int'];
  endTime: Scalars['String'];
  id: Scalars['Int'];
  startTime: Scalars['String'];
};

/** Integration Shift */
export type IntegrationShift = {
  __typename?: 'IntegrationShift';
  rosteringShiftId?: Maybe<Scalars['Int']>;
  rotaName?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InternalApplication */
export type InternalApplicationPayload = {
  __typename?: 'InternalApplicationPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

export type InvitationToken = {
  __typename?: 'InvitationToken';
  /** Agency registration ID for which token is generated */
  agencyRegistrationId: Scalars['Int'];
  /** Email of user token was sent to */
  email: Scalars['String'];
  /** Returns user account confirmation status */
  userStatus: Scalars['String'];
};

/** Autogenerated return type of Invite */
export type InvitePayload = {
  __typename?: 'InvitePayload';
  account?: Maybe<Account>;
  errors: Array<ValidationError>;
};

/** IR35 Status - Unspecified, Inside or Outside */
export enum Ir35Enum {
  /** IR35 status is inside */
  Inside = 'INSIDE',
  /** IR35 status is outside */
  Outside = 'OUTSIDE',
  /** IR35 status is unspecified */
  Unspecified = 'UNSPECIFIED'
}

/** PaidPerItem Item */
export type Item = {
  __typename?: 'Item';
  createdAt: Scalars['DateTime'];
  department: Department;
  id: Scalars['Int'];
  itemGradeCosts: Array<ItemGradeCost>;
  name: Scalars['String'];
  organisation: Organisation;
  organisationStaffGroup: OrganisationStaffGroup;
  ppiAuditEvents: Array<PpiAuditEvent>;
};

/** PPI Receipt Item calculation */
export type ItemCalculation = {
  __typename?: 'ItemCalculation';
  itemCost: Scalars['Float'];
  totalCost: Scalars['Float'];
  wtdCost: Scalars['Float'];
};

/** Item ID and Name for use in dropdown filters */
export type ItemFilterOption = {
  __typename?: 'ItemFilterOption';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** ItemsGradeCost */
export type ItemGradeCost = {
  __typename?: 'ItemGradeCost';
  cost: Scalars['Float'];
  grade?: Maybe<Grade>;
  id: Scalars['Int'];
  item?: Maybe<Item>;
};

/** Input arguments to create item grade costs */
export type ItemGradeCostInput = {
  /** Set it when you want to remove item grade cost */
  _destroy?: InputMaybe<Scalars['Boolean']>;
  /** Cost of item */
  cost: Scalars['Float'];
  /** ID of related Grade */
  gradeId: Scalars['Int'];
  /** Item Grade Cost ID */
  id?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of JoinCollaborativeBank */
export type JoinCollaborativeBankPayload = {
  __typename?: 'JoinCollaborativeBankPayload';
  errors: Array<ValidationError>;
  workerCollaborativeBank?: Maybe<WorkerCollaborativeBank>;
};

export type LeaveAbsenceCategory = {
  __typename?: 'LeaveAbsenceCategory';
  createdAt: Scalars['DateTime'];
  esrCode: Scalars['String'];
  esrDescription: Scalars['String'];
  esrType: Scalars['String'];
  id: Scalars['ID'];
  trashedAt?: Maybe<Scalars['DateTime']>;
};

export type LeaveAbsenceType = {
  __typename?: 'LeaveAbsenceType';
  createdAt: Scalars['DateTime'];
  esrType: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
};

export type LeaveEntitlement = {
  __typename?: 'LeaveEntitlement';
  allocatedAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  /** The number of hours in a day for the users leave requests */
  hoursInDay?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  measurementType?: Maybe<Scalars['String']>;
  /**
   * @deprecated Please use remainingBookableLeave instead, which\
   *           takes both Pending and Approved Leave into account
   */
  remainderValue?: Maybe<Scalars['Float']>;
  /** Entitlement value remaining once Approved and Pending Leave has been deducted */
  remainingBookableLeave: Scalars['Float'];
  rosteringLeaveType?: Maybe<LeaveType>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  startDate: Scalars['DateTime'];
  total: Scalars['Float'];
  /** Total Approved Leave taken against the Entitlement */
  totalApprovedLeave: Scalars['Float'];
  /** Total Pending Leave requested against the Entitlement */
  totalPendingLeave: Scalars['Float'];
  trashedAt?: Maybe<Scalars['DateTime']>;
};

export type LeaveEpisode = {
  __typename?: 'LeaveEpisode';
  createdAt: Scalars['DateTime'];
  durationInDays?: Maybe<Scalars['Float']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  rosteringLeaveReason: LeaveReason;
  rosteringLeaveRequests?: Maybe<Array<LeaveRequest>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  trashedAt?: Maybe<Scalars['DateTime']>;
};

/** Leave Event based on worker's leave request */
export type LeaveEvent = {
  __typename?: 'LeaveEvent';
  /** DateTime of Event end */
  endsAt: Scalars['DateTime'];
  /** Type of the Leave Event */
  eventType: RosteringEventTypeEnum;
  /** ID of Leave Event - only present for shifts marked as leave */
  id?: Maybe<Scalars['ID']>;
  /** Associated Leave Request */
  leaveRequest: LeaveRequest;
  /** Shift details - only present for shifts marked as leave */
  scheduleable?: Maybe<RosteringShift>;
  /** DateTime of Event start */
  startsAt: Scalars['DateTime'];
};

/** Grades ID, title */
export type LeaveGrade = {
  __typename?: 'LeaveGrade';
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type LeaveGroup = {
  __typename?: 'LeaveGroup';
  clinicalWorkPercentage?: Maybe<Scalars['Float']>;
  hoursInDay?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  leaveUnitType: Scalars['String'];
  name: Scalars['String'];
  nonClinicalWorkPercentage?: Maybe<Scalars['Float']>;
  organisationId: Scalars['ID'];
  rosteringGradeLeaveGroups?: Maybe<Array<GradeLeaveGroup>>;
  rosteringLeavePaCategories?: Maybe<Array<PaCategory>>;
};

/** The connection type for LeaveGroup. */
export type LeaveGroupConnection = {
  __typename?: 'LeaveGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeaveGroupEdge>>>;
  /** A list of nodes. */
  nodes: Array<LeaveGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LeaveGroupEdge = {
  __typename?: 'LeaveGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LeaveGroup>;
};

export type LeaveReason = {
  __typename?: 'LeaveReason';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organisationId?: Maybe<Scalars['ID']>;
  rosteringLeaveAbsenceCategory: LeaveAbsenceCategory;
  rosteringLeaveAbsenceType?: Maybe<LeaveAbsenceType>;
  trashedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for LeaveReason. */
export type LeaveReasonConnection = {
  __typename?: 'LeaveReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeaveReasonEdge>>>;
  /** A list of nodes. */
  nodes: Array<LeaveReason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LeaveReasonEdge = {
  __typename?: 'LeaveReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LeaveReason>;
};

export type LeaveRequest = {
  __typename?: 'LeaveRequest';
  /** Each date of the Request with am/pm/day description, for displaying Leave Calendar in Rota Hub */
  calendarDays?: Maybe<Array<CalendarDay>>;
  /** Determines if the Leave Request can be cancelled */
  cancellableRequest: Scalars['Boolean'];
  concurrentLeave?: Maybe<Array<LeaveRequest>>;
  createdAt: Scalars['DateTime'];
  deductionInMinutes?: Maybe<Scalars['Int']>;
  /** Duration of the Leave Request in days, rounded up to the next 0.25 */
  durationInDays?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** The number of hours in a day for the users leave requests */
  hoursInDay?: Maybe<Scalars['Float']>;
  /** Actual hours lost based on shift events which were unassigned after leave approved */
  hoursLost: Scalars['Float'];
  id: Scalars['ID'];
  initiatedBy?: Maybe<Scalars['String']>;
  leaveFlowType?: Maybe<Scalars['String']>;
  measurementType?: Maybe<Scalars['String']>;
  /**
   * Details of Shifts on published Rosters which the Worker was due to work\
   *           at the time of requesting Leave
   */
  missedShifts?: Maybe<Array<RosteringEvent>>;
  reasonForCancellation?: Maybe<Scalars['String']>;
  reasonForLeave?: Maybe<Scalars['String']>;
  /** Shifts which were reassigned back to the Worker following Leave cancellation */
  reassignedShifts?: Maybe<Array<RosteringEvent>>;
  /**
   * Time when the Leave
   * Request was requested or when a cancellation was requested
   */
  requestedAt?: Maybe<Scalars['DateTime']>;
  rosteringLeaveEntitlement?: Maybe<LeaveEntitlement>;
  rosteringLeaveEpisode?: Maybe<LeaveEpisode>;
  rosteringLeaveReason?: Maybe<LeaveReason>;
  rosteringLeaveRequestApprovals?: Maybe<Array<LeaveRequestApproval>>;
  rosteringLeaveType?: Maybe<LeaveType>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  /** Secondary sickness reason */
  secondaryLeaveReason?: Maybe<Scalars['String']>;
  shiftIds?: Maybe<Array<Scalars['Int']>>;
  /** Shifts from published Rosters which the Worker was due to work, and whether reassignable */
  shiftsForReassignment?: Maybe<Array<ShiftForReassignment>>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  trashedAt?: Maybe<Scalars['DateTime']>;
  workRelatedSickness?: Maybe<Scalars['Boolean']>;
};

export type LeaveRequestApproval = {
  __typename?: 'LeaveRequestApproval';
  approver?: Maybe<User>;
  id: Scalars['ID'];
  reasonForCancellationDecision?: Maybe<Scalars['String']>;
  reasonForDecision?: Maybe<Scalars['String']>;
  rosteringDepartmentList?: Maybe<RosteringDepartmentList>;
  rosteringLeaveRequest?: Maybe<LeaveRequest>;
  rosteringRota?: Maybe<RosteringRota>;
  status: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for LeaveRequestApproval. */
export type LeaveRequestApprovalConnection = {
  __typename?: 'LeaveRequestApprovalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeaveRequestApprovalEdge>>>;
  /** A list of nodes. */
  nodes: Array<LeaveRequestApproval>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LeaveRequestApprovalEdge = {
  __typename?: 'LeaveRequestApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LeaveRequestApproval>;
};

export type LeaveRequestCalculation = {
  __typename?: 'LeaveRequestCalculation';
  /**
   * @deprecated Please use remainingLeave field instead - renamed to avoid confusion\
   *            as Leave is not always taken in days
   */
  daysLeaveRemaining?: Maybe<Scalars['Float']>;
  deductionInMinutes?: Maybe<Scalars['Int']>;
  /** Errors from the calculation operation */
  errors?: Maybe<Array<ValidationError>>;
  /** @deprecated Deprecated in favour of LeaveRequestMissedShiftsResolver */
  missedShifts?: Maybe<Array<RosteringEvent>>;
  /** Total Leave remaining in the Entitlement, when Approved and Pending leave is deducted */
  remainingLeave?: Maybe<Scalars['Float']>;
  shiftAmountTaken?: Maybe<Scalars['Float']>;
};

/** The connection type for LeaveRequest. */
export type LeaveRequestConnection = {
  __typename?: 'LeaveRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LeaveRequestEdge>>>;
  /** A list of nodes. */
  nodes: Array<LeaveRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LeaveRequestEdge = {
  __typename?: 'LeaveRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LeaveRequest>;
};

/** Staff Group ID, title */
export type LeaveStaffGroup = {
  __typename?: 'LeaveStaffGroup';
  grades?: Maybe<Array<LeaveGrade>>;
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type LeaveType = {
  __typename?: 'LeaveType';
  id: Scalars['ID'];
  leaveType: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['ID'];
};

export type Level = {
  __typename?: 'Level';
  /** Level id */
  id: Scalars['Int'];
  /** Level name */
  name: Scalars['String'];
  /** Timeframe in days */
  timeframe: Scalars['Int'];
};

export type LevelGradeRates = {
  __typename?: 'LevelGradeRates';
  /** Core rate */
  core: Scalars['Float'];
  /** Id */
  id: Scalars['Int'];
  /** Escalation level */
  level: Level;
  /** Unsocial rate */
  unsocial: Scalars['Float'];
};

export type LevelGradeRatesList = {
  __typename?: 'LevelGradeRatesList';
  /** List of escalation levels */
  levelGradeRates?: Maybe<Array<LevelGradeRates>>;
};

export type Levels = {
  __typename?: 'Levels';
  /** List of escalation levels */
  levels?: Maybe<Array<Level>>;
};

export type LibraryItem = {
  __typename?: 'LibraryItem';
  createdAt?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of LinkHubUserToWorkerAccount */
export type LinkHubUserToWorkerAccountPayload = {
  __typename?: 'LinkHubUserToWorkerAccountPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of LinkWorkerToHubUserAccount */
export type LinkWorkerToHubUserAccountPayload = {
  __typename?: 'LinkWorkerToHubUserAccountPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of LoadWorkerPatterns */
export type LoadWorkerPatternsPayload = {
  __typename?: 'LoadWorkerPatternsPayload';
  errors: Array<ValidationError>;
  /** ID of Background Job */
  jobId?: Maybe<Scalars['String']>;
  /** Status of Background Job */
  success: Scalars['Boolean'];
};

export type Location = {
  __typename?: 'Location';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  site?: Maybe<Site>;
  updatedAt: Scalars['DateTime'];
};

/** The connection type for Location. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  /** A list of nodes. */
  nodes: Array<Location>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Location>;
};

/** Autogenerated return type of LockDay */
export type LockDayPayload = {
  __typename?: 'LockDayPayload';
  errors: Array<ValidationError>;
  /** Roster */
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of Lock */
export type LockPayload = {
  __typename?: 'LockPayload';
  errors: Array<ValidationError>;
  /** Rostering Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of MarkAsComplete */
export type MarkAsCompletePayload = {
  __typename?: 'MarkAsCompletePayload';
  errors: Array<ValidationError>;
  /** The Rostering::WorkerPatter marked as complete */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Autogenerated return type of MarkAsDidNotAttend */
export type MarkAsDidNotAttendPayload = {
  __typename?: 'MarkAsDidNotAttendPayload';
  errors: Array<ValidationError>;
  /** Result of the operation */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of MarkAsPaid */
export type MarkAsPaidPayload = {
  __typename?: 'MarkAsPaidPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of MarkSentToAgency */
export type MarkSentToAgencyPayload = {
  __typename?: 'MarkSentToAgencyPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of MarkShiftAsLeave */
export type MarkShiftAsLeavePayload = {
  __typename?: 'MarkShiftAsLeavePayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
  rosteringLeaveEvent?: Maybe<LeaveEvent>;
};

export type MetaActivity = {
  __typename?: 'MetaActivity';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** The connection type for MetaActivity. */
export type MetaActivityConnection = {
  __typename?: 'MetaActivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MetaActivityEdge>>>;
  /** A list of nodes. */
  nodes: Array<MetaActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MetaActivityEdge = {
  __typename?: 'MetaActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MetaActivity>;
};

export enum ModSecurityClearanceEnum {
  /** Baseline Personnel Security Standard (BPSS) */
  BaselinePersonnelSecurityStandard = 'BASELINE_PERSONNEL_SECURITY_STANDARD',
  /** Counter Terrorism Check (CTC) */
  CounterTerrorismCheck = 'COUNTER_TERRORISM_CHECK',
  /** Developed Vetting (DV) */
  DevelopedVetting = 'DEVELOPED_VETTING',
  /** Enhanced Developed Vetting (eDV) */
  EnhancedDevelopedVetting = 'ENHANCED_DEVELOPED_VETTING',
  /** Enhanced Security Check (eSC) */
  EnhancedSecurityCheck = 'ENHANCED_SECURITY_CHECK',
  /** Security Check (SC) */
  SecurityCheck = 'SECURITY_CHECK'
}

/** Autogenerated return type of MoveShiftsToFlaggedPayments */
export type MoveShiftsToFlaggedPaymentsPayload = {
  __typename?: 'MoveShiftsToFlaggedPaymentsPayload';
  errors: Array<ValidationError>;
  /** If false see shiftsWithErrors to see which ones failed, if true all were successful */
  result: Scalars['Boolean'];
  /** The ids of the shifts that failed to be moved to flagged payments */
  shiftsWithErrors: Array<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Verifies the invitation token and activates the Account */
  accountAcceptInvite?: Maybe<AcceptInvitePayload>;
  /** Registers a new account */
  accountCreate?: Maybe<CreateBrokenPayload>;
  /** Creates an invitation for a pre-existing account */
  accountInvite?: Maybe<InvitePayload>;
  /** A super admin can activate a worker */
  activateWorker?: Maybe<ActivateWorkerPayload>;
  /** Add ESR number for a bank worker */
  addEsrNumber?: Maybe<AddEsrNumberPayload>;
  /** A super admin can add skill in organisation staff group */
  addSkillToOrgStaffGroup?: Maybe<AddSkillToOrgStaffGroupPayload>;
  /** Add WorkerUpload by worker */
  addWorkerUpload?: Maybe<AddWorkerUploadPayload>;
  /** Add WorkerUpload by HubUser */
  addWorkerUploadFromHub?: Maybe<AddWorkerUploadFromHubPayload>;
  /** For a VMS to book a shift */
  agencyBook?: Maybe<AgencyBookPayload>;
  /** For a VMS to cancel a shift booking */
  agencyCancelBooking?: Maybe<AgencyCancelBookingPayload>;
  /** For Hub User to create Agency Users */
  agencyUserCreate?: Maybe<CreateAgencyUserPayload>;
  /** Login for agency users */
  agencyUserLogin?: Maybe<NonAuthorisedUser>;
  /** For Hub User to create Agency Users Permissions */
  agencyUserPermissionCreate: Scalars['Boolean'];
  /** For Hub User to delete Agency Users Permissions */
  agencyUserPermissionDestroy: Scalars['Boolean'];
  /** For Hub User to update Agency Users Permissions */
  agencyUserPermissionUpdate: Scalars['Boolean'];
  /** Refresh token for agency users */
  agencyUserRefreshToken?: Maybe<NonAuthorisedAgencyUser>;
  /** For Hub User to update Agency Users */
  agencyUserUpdate?: Maybe<UpdateAgencyUserPayload>;
  /** Create new worker documentations */
  agencyWorkerDocsCreateDocumentation?: Maybe<CreateDocumentationsPayload>;
  /** Archive worker documentation */
  agencyWorkerDocsDestroyDocumentation?: Maybe<DestroyDocumentationPayload>;
  /** Export all worker documentations uploads */
  agencyWorkerDocsExportDocumentationsUploads?: Maybe<ExportDocumentationsUploadsPayload>;
  /** Export all worker documentations uploads for a shift or block */
  agencyWorkerDocsExportShiftAndBlockCompliance?: Maybe<ExportShiftAndBlockCompliancePayload>;
  /** Update worker documentation */
  agencyWorkerDocsUpdateDocumentation?: Maybe<UpdateDocumentationPayload>;
  /** For Hub User to accept AgencyWorkerproposal */
  agencyWorkerProposalAccept?: Maybe<AgencyWorkerProposalAcceptPayload>;
  /** For Hub User to decline AgencyWorkerproposal */
  agencyWorkerProposalDecline?: Maybe<AgencyWorkerProposalDeclinePayload>;
  /** Worker creating relationship with a collaborative bank */
  applyToOrganisationAndCollaborativeBank?: Maybe<ApplyToOrganisationAndCollaborativeBankPayload>;
  /** Approve rate change */
  approveRateChange?: Maybe<ApproveRateChangePayload>;
  /** For hub user to approve an application to a shift */
  approveShiftApplication?: Maybe<ApproveShiftApplicationPayload>;
  /** A hub user can archive an agency */
  archiveAgency?: Maybe<ArchiveAgencyPayload>;
  /** A super admin can archive a grade */
  archiveGrade?: Maybe<ArchiveGradePayload>;
  /** For hub user to assign a worker to a shift */
  assignWorker?: Maybe<AssignWorkerPayload>;
  autoCasacdeDeleteRuleTriggers?: Maybe<DeleteRuleTriggersPayload>;
  autoCascadeCreateAutoSendToAgencyTrigger?: Maybe<CreateAutoSendToAgencyTriggerPayload>;
  autoCascadeCreateRule?: Maybe<CreateRulePayload>;
  autoCascadeCreateRuleGroup?: Maybe<CreateRuleGroupPayload>;
  autoCascadeCreateRuleGroupsRange?: Maybe<CreateRuleGroupsRangePayload>;
  autoCascadeCreateRuleTriggers?: Maybe<CreateRuleTriggersPayload>;
  autoCascadeDestroyRule?: Maybe<DeleteRulePayload>;
  autoCascadeDestroyRuleGroup?: Maybe<DestroyRuleGroupPayload>;
  autoCascadeDestroyRuleGroupsRange?: Maybe<DestroyRuleGroupsRangePayload>;
  /** Update Auto Send To Agency */
  autoCascadeUpdateAutoSendToAgency?: Maybe<UpdateAutoSendToAgencyPayload>;
  autoCascadeUpdateRule?: Maybe<UpdateRulePayload>;
  autoCascadeUpdateRuleGroup?: Maybe<UpdateRuleGroupPayload>;
  autoCascadeUpdateRuleGroupsRange?: Maybe<UpdateRuleGroupsRangePayload>;
  /** A hub user can upload a bank rate card */
  bankUploadRateCards?: Maybe<UploadRatesPayload>;
  /** For worker to book or apply to a shift */
  bookOrApply?: Maybe<BookOrApplyPayload>;
  /** For hub user to bulk send shifts to an agency tier */
  bulkAssignAgencyWorker?: Maybe<BulkAssignAgencyWorkerPayload>;
  /** For hub user to assign a worker to multiple shifts */
  bulkAssignWorker?: Maybe<BulkAssignWorkerPayload>;
  /** For hub user to bulk send shifts to an agency tier */
  bulkCancelAgencyWorker?: Maybe<BulkCancelAgencyWorkerPayload>;
  /** For hub user to cancel a worker bookings to multiple shifts */
  bulkCancelWorker?: Maybe<BulkCancelWorkerBookingPayload>;
  /** Creates one or more Shifts on a block */
  bulkCreateOnBlock?: Maybe<BulkCreateOnBlockPayload>;
  /** Bulk locks and unlocks shifts to shift */
  bulkLockToAgency?: Maybe<BulkLockToAgencyPayload>;
  /** For hub user to bulk recall shifts from secondary bank */
  bulkRecallFromSecondaryBank?: Maybe<BulkRecallFromSecondaryBankPayload>;
  /** For hub user to bulk send shifts to an agency tier */
  bulkSendToAgencyTier?: Maybe<BulkSendToAgencyTierPayload>;
  /** For hub user to bulk send shifts to secondary bank */
  bulkSendToSecondaryBank?: Maybe<BulkSendToSecondaryBankPayload>;
  /** For hub user to bulk sign off shfts */
  bulkSignOff?: Maybe<BulkSignOffPayload>;
  /** For hub user to bulk undo sign off shfts */
  bulkUndoSignOff?: Maybe<BulkUndoSignOffPayload>;
  /** For hub user to bulk update shifts agency tier */
  bulkUpdateAgencyTier?: Maybe<BulkUpdateAgencyTierPayload>;
  /** Bulk updates given list of shifts */
  bulkUpdateShifts?: Maybe<BulkUpdateShiftsPayload>;
  /** A superadmin can upload a CSV file with departments */
  bulkUploadDepartments?: Maybe<BulkUploadDepartmentsPayload>;
  /** For a hub user to cancel a worker from a shift */
  cancelWorker?: Maybe<CancelWorkerPayload>;
  /** A hub user with access to multiple organisations can switch between them. */
  changeOrganisation?: Maybe<ChangeOrganisationPayload>;
  /** change external application status */
  changeStatus?: Maybe<ChangeStatusPayload>;
  /** Executes Compliance Checks when editing shift times */
  checkComplianceForEditedShift?: Maybe<CheckComplianceForEditedShiftPayload>;
  /** Executes Compliance Checks in advance of a shift swap */
  checkComplianceForShiftSwap?: Maybe<CheckComplianceForShiftSwapPayload>;
  complianceDocumentsCreateOrganisationComplianceDocument?: Maybe<CreateOrganisationComplianceDocumentPayload>;
  complianceDocumentsDeleteOrganisationComplianceDocument?: Maybe<DeleteOrganisationComplianceDocumentPayload>;
  complianceDocumentsUpdateOrganisationComplianceDocument?: Maybe<UpdateOrganisationComplianceDocumentPayload>;
  /** A hub user can create an agency */
  createAgency?: Maybe<CreateAgencyPayload>;
  /** A hub user can create a collaborative bank */
  createCollaborativeBank?: Maybe<CreateCollaborativeBankPayload>;
  /** A hub user can create a collaborative bank staff group */
  createCollaborativeBankStaffGroup?: Maybe<CreateCollaborativeBankStaffGroupPayload>;
  /** A hub user can create a cost centre */
  createCostCentre?: Maybe<CreateCostCentrePayload>;
  /** A super admin can create a department */
  createDepartment?: Maybe<CreateDepartmentPayload>;
  /** A hub user can create a division */
  createDivision?: Maybe<CreateDivisionPayload>;
  /** Allows Patchwork reasons for cancellation to map to external cancellation reasons */
  createExternalCancelReason?: Maybe<CreateExternalCancelReasonPayload>;
  /** Allows Patchwork grades map to external grades */
  createExternalGrade?: Maybe<CreateExternalGradePayload>;
  /** Creating external organisations */
  createExternalOrganisation?: Maybe<CreateExternalOrganisationPayload>;
  /** Allows Patchwork reasons for request map to external request reasons */
  createExternalRequestReason?: Maybe<CreateExternalRequestReasonPayload>;
  /** Allows Patchwork departments and sites to map to external units */
  createExternalUnit?: Maybe<CreateExternalUnitPayload>;
  /** A super admin can create a filter grade */
  createFilterGrade?: Maybe<CreateFilterGradePayload>;
  /** A worker using the app can store filters - created the first time they save their search filters */
  createFilters?: Maybe<CreateFiltersPayload>;
  /** A super admin can create a grade */
  createGrade?: Maybe<CreateGradePayload>;
  /** A hub user can create a location */
  createLocation?: Maybe<CreateLocationPayload>;
  /** A super admin can create an organisation */
  createOrganisation?: Maybe<CreateOrganisationPayload>;
  /** Creating an organisation agency */
  createOrganisationAgency?: Maybe<CreateOrganisationAgencyPayload>;
  /** Allows a hub user to create an organisation registration note */
  createOrganisationRegistrationNote?: Maybe<CreateOrganisationRegistrationNotePayload>;
  /** A super admin create an organisation staff group */
  createOrganisationStaffGroup?: Maybe<CreateOrganisationStaffGroupPayload>;
  /** This mutation starts the passporting workflow where a user requests digital passport */
  createPassport?: Maybe<RelationshipCreationPayload>;
  /** Creates a payment batch with the given shifts */
  createPaymentBatchWithShifts?: Maybe<CreatePaymentBatchWithShiftsPayload>;
  /** A hub user can create a reason for request or for cancellation */
  createReason?: Maybe<CreateReasonPayload>;
  /** User creating a referee */
  createReferee?: Maybe<CreateRefereePayload>;
  /** A hub user can create a regulation body */
  createRegulationBody?: Maybe<CreateRegulationBodyPayload>;
  /** A hub user can create a site */
  createSite?: Maybe<CreateSitePayload>;
  /** A super admin can create a skill */
  createSkill?: Maybe<CreateSkillPayload>;
  /** A hub user can create a speciality */
  createSpeciality?: Maybe<CreateSpecialityPayload>;
  /** Create a Staff Group */
  createStaffGroup?: Maybe<CreateStaffGroupPayload>;
  /** A super admin can create a super admin */
  createSuperAdmin?: Maybe<CreateSuperAdminPayload>;
  /** Creates a shift */
  createTempStaffShift?: Maybe<CreateTempStaffShiftPayload>;
  /** Allow super admins to bulk upload timesheet authorisers on new implementations */
  createTimesheetAuthorisers?: Maybe<BulkCreateAuthorisersPayload>;
  /** A super admin can create an agency with many assigned organisations */
  createVmsAgency?: Maybe<CreateVmsAgencyPayload>;
  /** For SuperHub User to create Agency Users */
  createVmsAgencyUser?: Maybe<CreateVmsAgencyUserPayload>;
  deContractsArchiveContract?: Maybe<ArchiveContractPayload>;
  deContractsCreateContract?: Maybe<CreateContractPayload>;
  deContractsPublishContract?: Maybe<PublishContractPayload>;
  deContractsResendVersionAssignment?: Maybe<ResendOrUpdateLatestContractVersionPayload>;
  deContractsSaveVersion?: Maybe<SaveVersionPayload>;
  deContractsSignVersionAssignment?: Maybe<SignVersionAssignmentPayload>;
  deContractsUpdateContract?: Maybe<UpdateContractPayload>;
  /** Deactivates ESR Number */
  deactivateEsrNumber?: Maybe<DeactivateEsrNumberPayload>;
  /** A super admin can deactivate a worker */
  deactivateWorker?: Maybe<DeactivateWorkerPayload>;
  /** A hub user can delete a cost centre */
  deleteCostCentre?: Maybe<DeleteCostCentrePayload>;
  /** A super admin can delete a department */
  deleteDepartment?: Maybe<DeleteDepartmentPayload>;
  /** A hub user can deletes a division */
  deleteDivision?: Maybe<DeleteDivisionPayload>;
  /** Delete ESR Number */
  deleteEsrNumber?: Maybe<DeleteEsrNumberPayload>;
  /** Allows Patchwork grades map to external grades */
  deleteExternalGrade?: Maybe<DeleteExternalGradePayload>;
  /** A hub user can delete a location */
  deleteLocation?: Maybe<DeleteLocationPayload>;
  /** A super admin can delete a skill from organisation staff group */
  deleteOrgStaffGroupsSkill?: Maybe<DeleteOrgStaffGroupsSkillPayload>;
  /** Deleting an organisation agency */
  deleteOrganisationAgency?: Maybe<DeleteOrganisationAgencyPayload>;
  /** Delete Worker Profile Picture */
  deleteProfilePicture?: Maybe<DeleteProfilePicturePayload>;
  /** Delete a reason */
  deleteReason?: Maybe<DeleteReasonPayload>;
  /** User deleteing a referee */
  deleteReferee?: Maybe<DeleteRefereePayload>;
  /** A hub user can delete a site */
  deleteSite?: Maybe<DeleteSitePayload>;
  /** A super admin can delete a skill */
  deleteSkill?: Maybe<DeleteSkillPayload>;
  /** A hub user can delete a speciality */
  deleteSpeciality?: Maybe<DeleteSpecialityPayload>;
  /** A super admin can delete an agency */
  deleteVmsAgency?: Maybe<DeleteVmsAgencyPayload>;
  /** For SuperHub to delete Agency Users */
  deleteVmsAgencyUser?: Maybe<DeleteVmsAgencyUserPayload>;
  /** A super admin can delete a worker */
  deleteWorker?: Maybe<DeleteWorkerPayload>;
  /** To delete WorkerUpload by worker */
  deleteWorkerUpload?: Maybe<DeleteWorkerUploadPayload>;
  /** A hub user can destroy a collaborative bank staff group */
  destroyCollaborativeBankStaffGroup: Scalars['Boolean'];
  didNotAttendCreate?: Maybe<MarkAsDidNotAttendPayload>;
  /** Escalate rate with advanced flow */
  escalateRateWithAdvancedFlow?: Maybe<EscalateRateWithAdvancedFlowPayload>;
  /** Escalate rate with basic flow */
  escalateRateWithBasicFlow?: Maybe<EscalateRateWithBasicFlowPayload>;
  /** Escalate rate with level grade rates */
  escalateRateWithLevelGradeRates?: Maybe<EscalateRateWithLevelGradeRatesPayload>;
  /** Create a level grade rate */
  escalationCreateLevelGradeRates?: Maybe<CreateLevelGradeRatesPayload>;
  /** Delete a level grade rate */
  escalationDeleteLevelGradeRates?: Maybe<DeleteLevelGradeRatesPayload>;
  /** Update a level grade rate */
  escalationUpdateLevelGradeRates?: Maybe<UpdateLevelGradeRatesPayload>;
  /** Exports hub user accounts to CSV */
  exportHubUsers?: Maybe<ExportHubUsersPayload>;
  /** Request shifts to be exported to users email */
  exportShifts?: Maybe<ExportShiftsPayload>;
  /** Exports workers to CSV */
  exportWorkers?: Maybe<ExportWorkersPayload>;
  /** Worker applying to a trust where they do not currently work */
  externalApplication?: Maybe<ExternalApplicationPayload>;
  /** Creating external accounts */
  externalCreate?: Maybe<CreateExternalAccountPayload>;
  /** Login for external accounts */
  externalLogin?: Maybe<NonAuthorisedUser>;
  /**
   * Use the refreshToken to get a NonAuthorisedUser object that
   *         can be used to return an updated set of tokens.
   */
  externalRefreshToken?: Maybe<NonAuthorisedUser>;
  /** Resets the password for an external account */
  externalResetPassword?: Maybe<ExternalResetPasswordPayload>;
  /** Send password reset instructions to external account email */
  externalSendResetPasswordInstructions?: Maybe<Scalars['Boolean']>;
  /** Updating external account */
  externalUpdateAccount?: Maybe<UpdateExternalAccountPayload>;
  /** Updating vms external account */
  externalUpdateVmsAccount?: Maybe<UpdateVmsAccountPayload>;
  /** Updating vms webhooks */
  externalUpdateVmsWebhooks?: Maybe<UpdateVmsWebhooksPayload>;
  /** Trigger the file upload reminder email */
  fileUploadReminderEmail?: Maybe<FileUploadReminderPayload>;
  /** Remove device token from user.devices */
  forgetDeviceToken?: Maybe<ForgetDeviceTokenPayload>;
  /** Allows HubUser to set up their login credentials */
  hubUserActivateAccount?: Maybe<ActivateAccountPayload>;
  /** Allows a hub user with permissions to activate or deactivate an account */
  hubUserChangeStatusOfAccount?: Maybe<ChangeStatusOfHubUserAccountPayload>;
  /** Allows HubUser to set confirm his email */
  hubUserConfirmEmail?: Maybe<ConfirmEmailPayload>;
  /** Create Hub User */
  hubUserCreate?: Maybe<CreateHubUserPayload>;
  /** Deletes a Hub User */
  hubUserDelete?: Maybe<DeleteHubUserPayload>;
  /** Login for hub users */
  hubUserLogin?: Maybe<NonAuthorisedHubUser>;
  /** For a hub user to reject sign off request */
  hubUserRejectSignOff?: Maybe<HubUserRejectSignOffPayload>;
  hubUserResetPassword?: Maybe<ResetPasswordPayload>;
  /** Send account confirmation instructions to hub users email */
  hubUserSendConfirmationInstructions?: Maybe<SendConfirmationInstructionsPayload>;
  /** Send password reset instructions to hub users email */
  hubUserSendResetPasswordInstructions?: Maybe<SendResetPasswordInstructionsPayload>;
  /** For a hub user to sign off shift without amended times */
  hubUserSignOff?: Maybe<HubUserSignOffPayload>;
  /** For a worker to request remote sign off */
  hubUserSignOffRequestedMultiple?: Maybe<HubUserSignOffRequestedMultiplePayload>;
  /** For a hub user to sign off shift with amended times */
  hubUserSignOffWithTimesAmended?: Maybe<HubUserSignOffWithTimesAmendedPayload>;
  /** For a hub user to sign off shift with amended times */
  hubUserSignOffWithTimesAmendedMultiple?: Maybe<HubUserSignOffWithTimesAmendedMultiplePayload>;
  /** Update Hub User */
  hubUserUpdate?: Maybe<UpdateHubUserPayload>;
  /** Create an audit log in Rostering from Bank */
  integrationPatchworkCreateAuditLog?: Maybe<CreateAuditLogPayload>;
  /** Update a rostering shift that was sent to bank */
  integrationPatchworkEditBankShift?: Maybe<UpdateBankShiftPayload>;
  /** Edit a shift in Rostering from Bank */
  integrationPatchworkEditShift?: Maybe<EditShiftPayload>;
  /** Recall a rostering shift from bank */
  integrationPatchworkRecallFromBank?: Maybe<RecallFromBankPayload>;
  /** Send multiple shift events to bank */
  integrationPatchworkSendMultipleShiftsToBank?: Maybe<SendMultipleShiftsToBankPayload>;
  /** Send a rostering shift to bank */
  integrationPatchworkSendToBank?: Maybe<SendToBankPayload>;
  /** Worker applying to a trust where they currently work */
  internalApplication?: Maybe<InternalApplicationPayload>;
  /** Worker creating relationship with a collaborative bank */
  joinCollaborativeBank?: Maybe<JoinCollaborativeBankPayload>;
  /** Link a Hub User Account to a Worker */
  linkHubUserToWorkerAccount?: Maybe<LinkHubUserToWorkerAccountPayload>;
  /** A super admin can link an existing hub user to his worker's account */
  linkWorkerToHubUserAccount?: Maybe<LinkWorkerToHubUserAccountPayload>;
  /** Login for users */
  login?: Maybe<NonAuthorisedUser>;
  /** Create Meta Activity */
  metaActivityCreate?: Maybe<CreateMetaActivityPayload>;
  /** Update Meta Activity */
  metaActivityUpdate?: Maybe<UpdateMetaActivityPayload>;
  /** Moves the given shifts to flagged payments */
  moveShiftsToFlaggedPayments?: Maybe<MoveShiftsToFlaggedPaymentsPayload>;
  /** Move external application to internal */
  moveToInternal?: Maybe<Scalars['Boolean']>;
  /** A super admin can add lock config for organisation */
  organisationLockConfigCreate?: Maybe<CreateLockConfigPayload>;
  /** A super admin can delete organisation lock config */
  organisationLockConfigDelete?: Maybe<DeleteLockConfigPayload>;
  paidPerItemCreateBatch?: Maybe<CreateBatchPayload>;
  paidPerItemCreateItem?: Maybe<CreateItemPayload>;
  paidPerItemCreateReceiptForRemoteSignOff?: Maybe<CreateReceiptForRemoteSignOffPayload>;
  paidPerItemCreateReceiptWithPinSignOff?: Maybe<CreateReceiptWithPinSignOffPayload>;
  paidPerItemCreateReceiptWithSignatureSignOff?: Maybe<CreateReceiptWithSignatureSignOffPayload>;
  /** Delete PPI items */
  paidPerItemDeleteItem?: Maybe<DeleteItemPayload>;
  paidPerItemReceiptsBulkApproveForPayment?: Maybe<BulkApproveForPaymentPayload>;
  paidPerItemReceiptsBulkFlag?: Maybe<BulkFlagPayload>;
  paidPerItemReceiptsBulkSignOffReceipts?: Maybe<BulkSignOffReceiptsPayload>;
  paidPerItemReceiptsBulkUnapproveForPayment?: Maybe<BulkUnapproveForPaymentPayload>;
  paidPerItemReceiptsBulkUnflag?: Maybe<BulkUnflagPayload>;
  paidPerItemReceiptsReject?: Maybe<RejectPayload>;
  paidPerItemReceiptsUnreject?: Maybe<UnrejectPayload>;
  paidPerItemSignOffReceipt?: Maybe<SignOffReceiptPayload>;
  paidPerItemUpdateItem?: Maybe<UpdateItemPayload>;
  paidPerItemUpdateReceipt?: Maybe<UpdateReceiptPayload>;
  /** A hub user can upload a vms rate card */
  pamRateCardsUpload?: Maybe<UploadPayload>;
  /** Loads in a workers passport information */
  passportInformation?: Maybe<PassportInformationPayload>;
  payrollAdjustmentsCreateAgencyAdjustment?: Maybe<CreateAgencyAdjustmentPayload>;
  /** A hub user can reactivate an agency */
  reactivateAgency?: Maybe<ReactivateAgencyPayload>;
  /** Marks notifications as read */
  readNotifications?: Maybe<ReadNotificationsPayload>;
  /**
   * Use the refreshToken to get a User object that
   *         can be used to return an updated set of tokens.
   */
  refreshToken?: Maybe<NonAuthorisedUser>;
  /** Reject internal or external application */
  rejectApplication?: Maybe<Scalars['Boolean']>;
  /** Reject rate change */
  rejectRateChange?: Maybe<RejectRateChangePayload>;
  /** For hub user to reject an application to a shift */
  rejectShiftApplication?: Maybe<RejectShiftApplicationPayload>;
  /** Removes the given shifts from flagged payments */
  removeShiftsFromFlaggedPayments?: Maybe<RemoveShiftsFromFlaggedPaymentsPayload>;
  /** A super admin can delete a super admin */
  removeSuperAdmin?: Maybe<RemoveSuperAdminPayload>;
  /** Save device token reported by app */
  reportDeviceToken?: Maybe<ReportDeviceTokenPayload>;
  /**
   * @deprecated This endpoint does not return error messages. New endpoint (worker_reset_password) will replace it '
   *
   */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** A super admin can restore a department */
  restoreDepartment?: Maybe<RestoreDepartmentPayload>;
  /** A super admin can restore a grade */
  restoreGrade?: Maybe<RestoreGradePayload>;
  /** To add a rostering day template to a rota */
  rosteringAddDayTemplateToRota?: Maybe<RosteringAddDayTemplateToRotaPayload>;
  /** Assign a worker to a schedule */
  rosteringAddWorkerToSchedule?: Maybe<RosteringAddWorkerToSchedulePayload>;
  /** A hub user can update a worker's availabilities on rosters */
  rosteringAvailabilitiesOnRostersUpdate?: Maybe<RosteringAvailabilitiesOnRostersUpdatePayload>;
  /** Calculate pay for substantive worker between given dates, on a roster */
  rosteringCalculateWorkerPayForRotationPeriod?: Maybe<CalculateWorkerPayForRotationPeriodPayload>;
  /** Creates a new permission for a set of users */
  rosteringCreatePermissionSet?: Maybe<CreatePermissionSetPayload>;
  /** To create a rostering day pattern */
  rosteringDayPatternCreate?: Maybe<RosteringDayPatternCreatePayload>;
  /** To delete a rostering day pattern */
  rosteringDayPatternDelete?: Maybe<RosteringDayPatternDeletePayload>;
  /** To create a rostering day pattern shift set */
  rosteringDayPatternShiftSetCreate?: Maybe<RosteringDayPatternShiftSetCreatePayload>;
  /** To delete a rostering day pattern shift set */
  rosteringDayPatternShiftSetDelete?: Maybe<RosteringDayPatternShiftSetDeletePayload>;
  /** To update a rostering day pattern shift set name */
  rosteringDayPatternShiftSetUpdate?: Maybe<RosteringDayPatternShiftSetUpdatePayload>;
  /** To create a rostering day template */
  rosteringDayTemplateCreate?: Maybe<RosteringDayTemplateCreatePayload>;
  /** To delete a day template */
  rosteringDayTemplateDelete?: Maybe<RosteringDayTemplateDeletePayload>;
  /** To update a rostering day template name */
  rosteringDayTemplateUpdate?: Maybe<RosteringDayTemplateUpdatePayload>;
  /** To update a rostering day templates activity order */
  rosteringDayTemplatesActivityUpdate?: Maybe<RosteringDayTemplatesActivityUpdatePayload>;
  /** A hub user can create a demand template */
  rosteringDemandTemplateCreate?: Maybe<RosteringDemandTemplateCreatePayload>;
  /** A hub user can update an demand template */
  rosteringDemandTemplateUpdate?: Maybe<RosteringDemandTemplateUpdatePayload>;
  /** A hub user can create a demand template activity */
  rosteringDemandTemplatesActivityCreate?: Maybe<RosteringDemandTemplatesActivityCreatePayload>;
  /** To delete a rostering demand template activity */
  rosteringDemandTemplatesActivityDelete?: Maybe<RosteringDemandTemplatesActivityDeletePayload>;
  /** A hub user can update a demand templates activity */
  rosteringDemandTemplatesActivityUpdate?: Maybe<RosteringDemandTemplatesActivityUpdatePayload>;
  /** To add workers to department list */
  rosteringDepartmentListAddWorkers?: Maybe<RosteringDepartmentListAddWorkersPayload>;
  /** To create a department list */
  rosteringDepartmentListCreate?: Maybe<RosteringDepartmentListCreatePayload>;
  /** To delete a department list */
  rosteringDepartmentListDelete?: Maybe<RosteringDepartmentListDeletePayload>;
  /** To update a department list */
  rosteringDepartmentListUpdate?: Maybe<RosteringDepartmentListUpdatePayload>;
  /** Enqueue job to add a rostering day template to a rota */
  rosteringEnqueueAddDayTemplateToRota?: Maybe<EnqueueAddDayTemplateToRotaPayload>;
  /** Create a roster using a background job */
  rosteringEnqueueRosterCreate?: Maybe<EnqueueRosterCreatePayload>;
  /** Add ESR number for a rostering worker */
  rosteringEsrCreate?: Maybe<RosteringEsrCreatePayload>;
  /** Deactivates ESR Number */
  rosteringEsrNumberDeactivate?: Maybe<RosteringEsrNumberDeactivatePayload>;
  /** Deletes ESR Number */
  rosteringEsrNumberDelete?: Maybe<RosteringEsrNumberDeletePayload>;
  /** Updates ESR Number Expiration Date */
  rosteringEsrNumberUpdateExpiryDate?: Maybe<RosteringEsrNumberUpdateExpiryDatePayload>;
  /** Assigning (syncing) a bank worker to a shift event */
  rosteringEventAssignBankWorkerToShift?: Maybe<AssignBankWorkerToShiftPayload>;
  /** Assigning a worker to multiple shift events */
  rosteringEventAssignWorkerToMultipleShifts?: Maybe<AssignWorkerToMultipleShiftsPayload>;
  /** Assigning a worker to a shift event */
  rosteringEventAssignWorkerToShift?: Maybe<AssignWorkerToShiftPayload>;
  /** Bulk creating events of type shift from shift templates from service plans */
  rosteringEventBulkCreateFromServicePlans?: Maybe<BulkCreateFromServicePlansPayload>;
  /** Bulk creating events of type shift from shift templates */
  rosteringEventBulkCreateFromShiftTemplates?: Maybe<BulkCreateFromShiftTemplatesPayload>;
  /** Moves shift from one schedule to another */
  rosteringEventChangeSchedule?: Maybe<ChangeSchedulePayload>;
  /** Creating a new event of type shift from a rota shift set */
  rosteringEventCreateShiftEvent?: Maybe<CreateShiftEventPayload>;
  /** Creating a new event of type shift from a shift template */
  rosteringEventCreateShiftEventFromShiftTemplate?: Maybe<CreateShiftEventFromShiftTemplatePayload>;
  /** Creating a new RosteringShiftEvent in a specified Location and Site, for use on Day View */
  rosteringEventCreateShiftEventWithSiteAndLocation?: Maybe<CreateShiftEventWithSiteAndLocationPayload>;
  /** Deleting multiple shifts */
  rosteringEventDeleteMultipleShifts?: Maybe<DeleteMultipleShiftsPayload>;
  /** Lock/Unlock event */
  rosteringEventLock?: Maybe<LockPayload>;
  /** Marking a shift as leave on a roster */
  rosteringEventMarkShiftAsLeave?: Maybe<MarkShiftAsLeavePayload>;
  /** Unassigning a worker from a shift event */
  rosteringEventUnassignWorkerFromShift?: Maybe<UnassignWorkerFromShiftPayload>;
  /** Unassigning workers from shift events */
  rosteringEventUnassignWorkersFromShifts?: Maybe<UnassignWorkersFromShiftsPayload>;
  /** Changing an Activity on multiple shift events */
  rosteringEventUpdateActivityOnMultipleShifts?: Maybe<UpdateActivityOnMultipleShiftsPayload>;
  /** To approve an exception report */
  rosteringExceptionReportApprove?: Maybe<RosteringExceptionReportApprovePayload>;
  /** To create a rostering exception report category */
  rosteringExceptionReportCategoryCreate?: Maybe<RosteringExceptionReportCategoryCreatePayload>;
  /** To delete a rostering exception report category */
  rosteringExceptionReportCategoryDelete?: Maybe<RosteringExceptionReportCategoryDeletePayload>;
  /** To update a rostering exception report category */
  rosteringExceptionReportCategoryUpdate?: Maybe<RosteringExceptionReportCategoryUpdatePayload>;
  /** To create a rostering exception report */
  rosteringExceptionReportCreate?: Maybe<RosteringExceptionReportCreatePayload>;
  /** To delete an exception report */
  rosteringExceptionReportDelete?: Maybe<RosteringExceptionReportDeletePayload>;
  /** To dispute an exception report */
  rosteringExceptionReportDispute?: Maybe<RosteringExceptionReportDisputePayload>;
  /** Sets users Exception Report permissions */
  rosteringExceptionReportPermissionsSet?: Maybe<RosteringExceptionReportPermissionsSetPayload>;
  /** To reject an exception report */
  rosteringExceptionReportReject?: Maybe<RosteringExceptionReportRejectPayload>;
  /** To update an exception report */
  rosteringExceptionReportUpdate?: Maybe<RosteringExceptionReportUpdatePayload>;
  /** A super admin can import workers sourced from an Excel workbook data */
  rosteringImportWorkers?: Maybe<ImportWorkersPayload>;
  /** Creating a new leave entitlement */
  rosteringLeaveEntitlementCreate?: Maybe<CreateLeaveEntitlementPayload>;
  /** Delete a leave entitlement */
  rosteringLeaveEntitlementDelete?: Maybe<DeleteLeaveEntitlementPayload>;
  /** Update a leave entitlement */
  rosteringLeaveEntitlementUpdate?: Maybe<UpdateLeaveEntitlementPayload>;
  /** Creating a new leave group */
  rosteringLeaveGroupCreate?: Maybe<CreateLeaveGroupPayload>;
  /** Delete a leave group */
  rosteringLeaveGroupDelete?: Maybe<DeleteLeaveGroupPayload>;
  /** Editing a leave group */
  rosteringLeaveGroupEdit?: Maybe<EditLeaveGroupPayload>;
  /** Creating a new leave reason */
  rosteringLeaveReasonCreate?: Maybe<CreateLeaveReasonPayload>;
  /** Deleting a leave reason */
  rosteringLeaveReasonDelete?: Maybe<DeleteLeaveReasonPayload>;
  /** Editing a leave reason */
  rosteringLeaveReasonEdit?: Maybe<EditLeaveReasonPayload>;
  /** Action a request to cancel a Leave Request */
  rosteringLeaveRequestActionCancellationRequest?: Maybe<ActionCancellationRequestPayload>;
  /** Accept a Leave Request Approval */
  rosteringLeaveRequestApprovalAccept?: Maybe<AcceptLeaveRequestApprovalPayload>;
  /** Reject a Leave Request Approval */
  rosteringLeaveRequestApprovalReject?: Maybe<RejectLeaveRequestApprovalPayload>;
  /** Archives (deletes) a leave request */
  rosteringLeaveRequestArchive?: Maybe<ArchiveLeaveRequestPayload>;
  /** Cancel a Leave Request */
  rosteringLeaveRequestCancel?: Maybe<CancelLeaveRequestPayload>;
  /** Creates a new leave request */
  rosteringLeaveRequestCreate?: Maybe<CreateLeaveRequestPayload>;
  /** Creates a new leave request from Super Hub */
  rosteringLeaveRequestCreateFromSuperHub?: Maybe<CreateLeaveRequestFromSuperHubPayload>;
  /** Edits a new leave request from Super Hub */
  rosteringLeaveRequestEditFromSuperHub?: Maybe<EditLeaveRequestFromSuperHubPayload>;
  /** Updates default leave flow for organisation registration */
  rosteringLeaveUpdateRosteringOrgRegLeaveFlow?: Maybe<UpdateRosteringOrgRegLeaveFlowPayload>;
  /** To delete an org registration dept list */
  rosteringOrgRegDeptListDelete?: Maybe<RosteringOrgRegDeptListDeletePayload>;
  /** To multi delete org registration dept lists */
  rosteringOrgRegDeptListMultiDelete?: Maybe<RosteringOrgRegDeptListMultiDeletePayload>;
  /** To create a Rostering Organisation Activity */
  rosteringOrganisationActivityCreate?: Maybe<RosteringOrganisationActivityCreatePayload>;
  /** To delete a Rostering Organisation Activity */
  rosteringOrganisationActivityDelete?: Maybe<RosteringOrganisationActivityDeletePayload>;
  /** To update a Rostering Organisation Activity */
  rosteringOrganisationActivityUpdate?: Maybe<RosteringOrganisationActivityUpdatePayload>;
  /** A hub user can create a worker's rostering org registration */
  rosteringOrganisationRegistrationCreate?: Maybe<RosteringOrganisationRegistrationCreatePayload>;
  /** A hub user can create a rostering org registration for an existing bank worker */
  rosteringOrganisationRegistrationCreateForBankWorker?: Maybe<RosteringOrganisationRegistrationCreateForBankWorkerPayload>;
  /** Allows a hub user to create rostering org reg note */
  rosteringOrganisationRegistrationNoteCreate?: Maybe<RosteringOrganisationRegistrationNoteCreatePayload>;
  /** Switch enable rostering */
  rosteringOrganisationRegistrationSwitch?: Maybe<RosteringOrganisationRegistrationSwitchPayload>;
  /** A hub user can update a worker's rostering org registration */
  rosteringOrganisationRegistrationUpdate?: Maybe<RosteringOrganisationRegistrationUpdatePayload>;
  /** To archive a rostering pay rule */
  rosteringPayRuleArchive?: Maybe<RosteringPayRuleArchivePayload>;
  /** To create a rostering pay rule */
  rosteringPayRuleCreate?: Maybe<RosteringPayRuleCreatePayload>;
  /** To update a rostering pay rule */
  rosteringPayRuleUpdate?: Maybe<RosteringPayRuleUpdatePayload>;
  /** Create a new rostering rate card */
  rosteringRateCardCreate?: Maybe<CreateRateCardPayload>;
  /** Delete a Rostering Rate Card */
  rosteringRateCardDelete?: Maybe<DeleteRateCardPayload>;
  /** Update a rostering rate card */
  rosteringRateCardUpdate?: Maybe<UpdateRateCardPayload>;
  /** Remove a worker from a schedule */
  rosteringRemoveWorkerFromSchedule?: Maybe<RosteringRemoveWorkerFromSchedulePayload>;
  /** Add a Rostering Organisation Activity to a Roster */
  rosteringRosterAddActivity?: Maybe<AddActivityPayload>;
  /** Adding an empty schedule to a roster */
  rosteringRosterAddSchedule?: Maybe<RosterAddSchedulePayload>;
  /** Create a roster */
  rosteringRosterCreate?: Maybe<RosterCreatePayload>;
  /** Create an empty roster */
  rosteringRosterCreateEmpty?: Maybe<EmptyRosterCreatePayload>;
  /** Allows deletion of draft and expired rosters */
  rosteringRosterDelete?: Maybe<RosterDeletePayload>;
  /** deleting an event on a roster */
  rosteringRosterDeleteEvent?: Maybe<RosteringRosterDeleteEventPayload>;
  /** Lock/Unlock day */
  rosteringRosterLockDay?: Maybe<LockDayPayload>;
  /** publishing a roster */
  rosteringRosterPublish?: Maybe<RosteringRosterPublishPayload>;
  /** unpublishing a roster reverting it to draft */
  rosteringRosterRevertToDraft?: Maybe<RosteringRosterRevertToDraftPayload>;
  /** Store row orders for Roster Views for the current user */
  rosteringRosterStoreRowOrderForUser?: Maybe<StoreRowOrderForUserPayload>;
  /** Update the department list associated with a roster */
  rosteringRosterUpdate?: Maybe<RosterUpdatePayload>;
  /** Updating an event */
  rosteringRosterUpdateEvent?: Maybe<RosteringRosterUpdateEventPayload>;
  /** To create a rostering rota */
  rosteringRotaCreate?: Maybe<RosteringRotaCreatePayload>;
  /** To delete a Rostering Rota */
  rosteringRotaDelete?: Maybe<RosteringRotaDeletePayload>;
  /**
   * Loads one or more RosteringWorkerPattern into a Rota with defined stagger period\
   * and slots required for each
   */
  rosteringRotaLoadWorkerPatterns?: Maybe<LoadWorkerPatternsPayload>;
  /** To create a rota shift set */
  rosteringRotaShiftSetCreate?: Maybe<RosteringRotaShiftSetCreatePayload>;
  /** To delete a rostering rota shift set */
  rosteringRotaShiftSetDelete?: Maybe<RosteringRotaShiftSetDeletePayload>;
  /** To update a rostering rota shift set */
  rosteringRotaShiftSetUpdate?: Maybe<RosteringRotaShiftSetUpdatePayload>;
  /** To update the status of a rostering rota */
  rosteringRotaUpdate?: Maybe<RosteringRotaUpdatePayload>;
  /** Adds an event to a roster schedule */
  rosteringScheduleAddEvent?: Maybe<AddEventPayload>;
  /** Mutation to add one or more Schedules to a Roster, with optional Worker Pattern association */
  rosteringScheduleAddToPatternView?: Maybe<AddToPatternViewPayload>;
  /** Deletes a schedule from an unpublished or published roster */
  rosteringScheduleDelete?: Maybe<ScheduleDeletePayload>;
  /** Marks a roster schedule as assignable */
  rosteringScheduleSetAssignable?: Maybe<RosteringScheduleSetAssignablePayload>;
  /** Marks a roster schedule as unassignable */
  rosteringScheduleSetUnassignable?: Maybe<RosteringScheduleSetUnassignablePayload>;
  /** Endpoint for updating the end date of a Schedule on a Roster */
  rosteringScheduleUpdateEndDate?: Maybe<UpdateScheduleEndDatePayload>;
  /** To create a Rostering Shift Set */
  rosteringShiftSetCreate?: Maybe<RosteringShiftSetCreatePayload>;
  /** To delete a Rostering Shift Set */
  rosteringShiftSetDelete?: Maybe<RosteringShiftSetDeletePayload>;
  /** To update a Rostering Shift Set */
  rosteringShiftSetUpdate?: Maybe<RosteringShiftSetUpdatePayload>;
  /** To create a Rostering Shift Template */
  rosteringShiftTemplateCreate?: Maybe<RosteringShiftTemplateCreatePayload>;
  /** To delete a Rostering Shift Template */
  rosteringShiftTemplateDelete?: Maybe<RosteringShiftTemplateDeletePayload>;
  /** To restore a Rostering Shift Template */
  rosteringShiftTemplateRestore?: Maybe<RosteringShiftTemplateRestorePayload>;
  /** To update a Rostering Shift Template */
  rosteringShiftTemplateUpdate?: Maybe<RosteringShiftTemplateUpdatePayload>;
  /** Hub user can approve a swap request */
  rosteringSwapsAcceptRequest?: Maybe<AcceptRequestPayload>;
  /** Worker can create a swap request */
  rosteringSwapsCreateRequest?: Maybe<CreateRequestPayload>;
  /** Hub user can reject a swap request */
  rosteringSwapsRejectRequest?: Maybe<RejectRequestPayload>;
  /** Worker can accept a swap request */
  rosteringSwapsWorkerAcceptRequest?: Maybe<WorkerAcceptRequestPayload>;
  /** Worker can reject a swap request */
  rosteringSwapsWorkerRejectRequest?: Maybe<WorkerRejectRequestPayload>;
  /** To mark or unmark TOIL as taken */
  rosteringTimeOffInLieuUpdate?: Maybe<RosteringTimeOffInLieuUpdatePayload>;
  /** Create, Update, or Delete permission sets for a rostering department list */
  rosteringUpdateDepartmentListPermissionSets?: Maybe<UpdateDepartmentListPermissionSetsPayload>;
  /** Create, Update or Delete permission sets for a rostering rota */
  rosteringUpdateRotaPermissionSets?: Maybe<UpdateRotaPermissionSetsPayload>;
  /** A super admin can upload an Excel workbook to configure an Organisation */
  rosteringUploadConfigurationWorkbook?: Maybe<UploadConfigurationWorkbookPayload>;
  /** Creates a work preference for a less than full time worker */
  rosteringWorkPreferenceCreate?: Maybe<RosteringWorkPreferenceCreatePayload>;
  /** Allows deletion of workers preference */
  rosteringWorkPreferenceDelete?: Maybe<RosteringWorkPreferenceDeletePayload>;
  /** Updates a work preference for a less than full time worker */
  rosteringWorkPreferenceUpdate?: Maybe<RosteringWorkPreferenceUpdatePayload>;
  /** Endpoint to add a week to a RosteringWorkerPattern */
  rosteringWorkerPatternAddWeek?: Maybe<AddWeekPayload>;
  /** Create a new draft worker pattern for rota building using Rostering::WorkerPattern */
  rosteringWorkerPatternCreate?: Maybe<CreatePatternPayload>;
  /** Delete a Draft Rostering::WorkerPattern */
  rosteringWorkerPatternDelete?: Maybe<DeletePayload>;
  /** Endpoint to duplicate RosteringWorkerPattern and associated EventTemplates */
  rosteringWorkerPatternDuplicate?: Maybe<DuplicatePayload>;
  /** Create multiple event templates in Worker Pattern */
  rosteringWorkerPatternEventTemplateBulkCreate?: Maybe<BulkCreatePayload>;
  /** Update Activity on multiple Event Templates in Worker Pattern */
  rosteringWorkerPatternEventTemplateBulkUpdate?: Maybe<BulkUpdatePayload>;
  /** Delete worker pattern event template in Worker Pattern */
  rosteringWorkerPatternEventTemplateDelete?: Maybe<DeleteEventTemplatePayload>;
  /** Endpoint to generate an Annual Pay Breakdown for a Worker Pattern, using figures from a Rate Card */
  rosteringWorkerPatternGeneratePayBreakdown?: Maybe<GeneratePayBreakdownPayload>;
  /** Mark Rostering::WorkerPattern as complete */
  rosteringWorkerPatternMarkAsComplete?: Maybe<MarkAsCompletePayload>;
  /** Update the end date of a worker pattern on roster */
  rosteringWorkerPatternOnRosterUpdateEndDate?: Maybe<UpdateEndDatePayload>;
  /** Endpoint to remove a week from a WorkerPattern */
  rosteringWorkerPatternRemoveWeek?: Maybe<RemoveWeekPayload>;
  /** Mark Rostering::WorkerPattern as draft */
  rosteringWorkerPatternRevertToDraft?: Maybe<RevertToDraftPayload>;
  /** Updates Rostering Worker Grade */
  rosteringWorkerUpdateGrade?: Maybe<RosteringWorkerUpdateGradePayload>;
  /** Set the value for a sail setting. */
  sailSet?: Maybe<SailSetPayload>;
  /** For worker to make enquiry about the shift */
  sendEnquiry?: Maybe<SendEnquiryPayload>;
  /** Send password reset instructions to users email */
  sendResetPasswordInstructions?: Maybe<Scalars['Boolean']>;
  /** For hub user to send a shift to an agency tier */
  sendToAgencyTier?: Maybe<SendToAgencyTierPayload>;
  /** Allows Patchwork grades map to external grades and set the default for a grade */
  setDefaultExternalGrade?: Maybe<SetDefaultExternalGradePayload>;
  /** Add Shifts to a Block */
  shiftBlockAddShifts?: Maybe<AddShiftsPayload>;
  /** Approve a Block Application for a Worker's Organisation Registration */
  shiftBlockApproveApplication?: Maybe<ApproveApplicationPayload>;
  /** Assign an organisation registration to a block */
  shiftBlockAssignOrganisationRegistration?: Maybe<AssignOrganisationRegistrationPayload>;
  /** Cancel shifts in bulk */
  shiftBlockBulkCancelWorker?: Maybe<BulkCancelWorkerPayload>;
  /** Cancel a Worker's Block Application */
  shiftBlockCancelWorkerApplication?: Maybe<CancelWorkerApplicationPayload>;
  /** Create a Block (optionally with Shifts) */
  shiftBlockCreate?: Maybe<CreateShiftBlockPayload>;
  /** Create a Block Application for a Worker's Organisation Registration */
  shiftBlockCreateOrganisationRegistrationApplication?: Maybe<CreateOrganisationRegistrationApplicationPayload>;
  /** Delete a Block */
  shiftBlockDelete?: Maybe<ShiftBlockDeletePayload>;
  /** Send Block to Agency */
  shiftBlockMarkSentToAgency?: Maybe<MarkSentToAgencyPayload>;
  /** Publish a Block and all of its draft Shifts */
  shiftBlockPublish?: Maybe<ShiftBlockPublishPayload>;
  /** Recall Block from Agency */
  shiftBlockRecallFromAgency?: Maybe<RecallFromAgencyPayload>;
  /** Reject a Block Application for a Worker's Organisation Registration */
  shiftBlockRejectApplication?: Maybe<RejectApplicationPayload>;
  /** Remove Shifts from a Block */
  shiftBlockRemoveShifts?: Maybe<RemoveShiftsPayload>;
  /** Send Block to Agency Tier */
  shiftBlockSendBlockToAgencyTier?: Maybe<SendBlockToAgencyTierPayload>;
  /** Update a Shift Block */
  shiftBlockUpdate?: Maybe<UpdateShiftBlockPayload>;
  /** Update Agency Tier for Block */
  shiftBlockUpdateAgencyTierOnBlock?: Maybe<UpdateAgencyTierOnBlockPayload>;
  /** Withdraw a Block Application for a Worker's Organisation Registration */
  shiftBlockWithdrawWorkerApplication?: Maybe<WithdrawWorkerApplicationPayload>;
  /** Withdraw a Worker from a Block */
  shiftBlockWithdrawWorkerShiftBlock?: Maybe<WithdrawWorkerShiftBlockPayload>;
  /** Approve shifts in bulk */
  shiftBulkApprove?: Maybe<BulkApprovePayload>;
  /** Delete shifts in bulk */
  shiftBulkDelete?: Maybe<BulkDeletePayload>;
  /** Recalculate rates for shifts in bulk */
  shiftBulkRecalculateRates?: Maybe<BulkRecalculateRatesPayload>;
  /** For hub user to send a shifts to an agency */
  shiftBulkRecallFromAgency?: Maybe<BulkRecallFromAgencyPayload>;
  /** Remove rate escalations for shifts in bulk */
  shiftBulkRemoveRateEscalation?: Maybe<BulkRemoveRateEscalationPayload>;
  /** For hub user to bulk restore shfts */
  shiftBulkRestore?: Maybe<BulkRestorePayload>;
  /** For hub user to send a shifts to an agency */
  shiftBulkSendToAgency?: Maybe<BulkSendToAgencyPayload>;
  /** Undo shift payment approval in bulk */
  shiftBulkUndoPaymentApproval?: Maybe<BulkUndoPaymentApprovalPayload>;
  /** For hub user to bulk undo RPA lock */
  shiftBulkUndoRpaLock?: Maybe<BulkUndoRpaLockPayload>;
  /** Delete draft shifts */
  shiftDeleteDrafts?: Maybe<DeleteDraftsPayload>;
  /** Mark shift as paid */
  shiftMarkAsPaid?: Maybe<MarkAsPaidPayload>;
  shiftRollbackFromPayroll?: Maybe<RollbackFromPayrollPayload>;
  /**
   * Sign up for users
   * @deprecated This endpoint assumes the Account is a bank worker. Use the account_create endpoint with rostering_enabled and/or bank_enabled to create Accounts'
   *
   */
  signUp?: Maybe<SignupPayload>;
  /** A hub user can suspend an agency user */
  suspendAgencyUser: Scalars['Boolean'];
  suspendWorker?: Maybe<SuspendWorkerPayload>;
  /** Sync an External Shift with Patchwork */
  syncExternalShift?: Maybe<ShiftPayload>;
  /** Triggers the email to create PIN number */
  timesheetAuthoriserRequestPin?: Maybe<RequestPinPayload>;
  /** Triggers the email to create PIN number using email address as argument */
  timesheetAuthoriserRequestPinWithEmail?: Maybe<RequestPinWithEmailPayload>;
  /** Triggers the email to create PIN number */
  timesheetAuthoriserResetPin?: Maybe<ResetPinPayload>;
  /** Allows SuperHub Users to toggle the is_super_user field for Agency Users. */
  toggleSuperAgencyUser?: Maybe<ToggleSuperAgencyUserPayload>;
  /**
   * Returns the current User object for the JWT token in the request header.
   *         Can be used to get updated tokens.
   */
  tokenLogin?: Maybe<NonAuthorisedUser>;
  undoSuspendWorker?: Maybe<UndoSuspendWorkerPayload>;
  /** A hub user can unsuspend an agency user */
  unsuspendAgencyUser: Scalars['Boolean'];
  /** A hub user can update an agency */
  updateAgency?: Maybe<UpdateAgencyPayload>;
  /** For hub user to update an agency tier shift sent to */
  updateAgencyTier?: Maybe<UpdateAgencyTierPayload>;
  /** For a VMS to update agency worked times */
  updateAgencyWorkedTimes?: Maybe<UpdateAgencyWorkedTimesPayload>;
  /** A hub user can update a collaborative bank */
  updateCollaborativeBank?: Maybe<UpdateCollaborativeBankPayload>;
  /** A hub user can update a collaborative bank staff group */
  updateCollaborativeBankStaffGroup?: Maybe<UpdateCollaborativeBankStaffGroupPayload>;
  /** An admin can update a department's contact details */
  updateContactsDepartment?: Maybe<UpdateContactsDepartmentPayload>;
  /** A hub user can update a cost centre */
  updateCostCentre?: Maybe<UpdateCostCentrePayload>;
  /** Update Worker DBS number */
  updateDbsNumber?: Maybe<UpdateDbsNumberPayload>;
  /** A super admin can update a department */
  updateDepartment?: Maybe<UpdateDepartmentPayload>;
  /** A hub user can updates a division */
  updateDivision?: Maybe<UpdateDivisionPayload>;
  /** Updates a draft shift */
  updateDraftShift?: Maybe<UpdateDraftShiftPayload>;
  /** Update worker employment information */
  updateEmploymentInfo?: Maybe<UpdateEmploymentInfoPayload>;
  /** Updates ESR Number Expiry date */
  updateEsrNumberExpirationDate?: Maybe<UpdateEsrNumberExpirationDatePayload>;
  /** Allows Patchwork grades map to external grades */
  updateExternalGrade?: Maybe<UpdateExternalGradePayload>;
  /** Updating external organisations */
  updateExternalOrganisation?: Maybe<UpdateExternalOrganisationPayload>;
  /** A super admin can update a filter grade */
  updateFilterGrade?: Maybe<UpdateFilterGradePayload>;
  /** A worker using the app can update saved filters */
  updateFilters?: Maybe<UpdateFiltersPayload>;
  /** A super admin can update a grade */
  updateGrade?: Maybe<UpdateGradePayload>;
  /** A hub user can update a location */
  updateLocation?: Maybe<UpdateLocationPayload>;
  /** A super admin can update an organisation */
  updateOrganisation?: Maybe<UpdateOrganisationPayload>;
  /** Updating an organisation agency */
  updateOrganisationAgency?: Maybe<UpdateOrganisationAgencyPayload>;
  /** A super admin update an organisation staff group */
  updateOrganisationStaffGroup?: Maybe<UpdateOrganisationStaffGroupPayload>;
  /** Update AgencyWorkerProposal or AgencyWorkerBlockProposal shortlisting status */
  updateProposalShortlistingStatus?: Maybe<UpdateProposalShortlistingStatusPayload>;
  /** A hub user can update a reason for request or for cancellation */
  updateReason?: Maybe<UpdateReasonPayload>;
  /** User updating a referee */
  updateReferee?: Maybe<UpdateRefereePayload>;
  /** Update Worker Registered Body Number (e.g. GMC number) */
  updateRegBodyNumber?: Maybe<UpdateRegBodyNumberPayload>;
  /** A hub user can update a regulation body */
  updateRegulationBody?: Maybe<UpdateRegulationBodyPayload>;
  /** A hub user can update a site */
  updateSite?: Maybe<UpdateSitePayload>;
  /** A super admin can upate a skill */
  updateSkill?: Maybe<UpdateSkillPayload>;
  /** A hub user can update a speciality */
  updateSpeciality?: Maybe<UpdateSpecialityPayload>;
  /** Update a Staff Group */
  updateStaffGroup?: Maybe<UpdateStaffGroupPayload>;
  /** A super admin can update a super admin */
  updateSuperAdmin?: Maybe<UpdateSuperAdminPayload>;
  /** Updates a shift */
  updateTempStaffShift?: Maybe<UpdateTempStaffShiftPayload>;
  /** A super admin can update an agency with many assigned organisations */
  updateVmsAgency?: Maybe<UpdateVmsAgencyPayload>;
  /** For SuperHub to update Agency Users */
  updateVmsAgencyUser?: Maybe<UpdateVmsAgencyUserPayload>;
  /** Update worker booking preferences, i.e. instant book departments and dept preferred workers */
  updateWorkerBookingPreferences?: Maybe<UpdateBookingPreferencesPayload>;
  /** Updating workers collaborative bank information */
  updateWorkerCollaborativeBank?: Maybe<UpdateWorkerCollaborativeBankPayload>;
  /** Updating worker's grade */
  updateWorkerGrade?: Maybe<WorkerUpdateGradePayload>;
  /** A hub user can upload an CSV file with agencies and organisations they are linked to */
  uploadAgenciesCsv?: Maybe<UploadAgenciesCsvPayload>;
  /** A hub user can upload a custom rate card */
  uploadCustomRateCard?: Maybe<UploadCollabBankRatesPayload>;
  /** Upload Worker Profile Picture */
  uploadProfilePicture?: Maybe<UploadProfilePicturePayload>;
  /** Check if an email is already linked to a user account */
  userCheckEmailIsLinkedToAccount?: Maybe<CheckEmailIsLinkedToAccountPayload>;
  /** Create a user essential information */
  userCreateEssentialInfo?: Maybe<CreateEssentialInfoPayload>;
  /** Updates a user's essential information */
  userUpdateEssentialInfo?: Maybe<UpdateEssentialInfoPayload>;
  /** Executes Compliance Checks when assigning a worker to a shift */
  validatePayloadForAssignWorker?: Maybe<ValidatePayloadForAssignWorkerPayload>;
  /** Executes Compliance Checks for a given Roster Worker in the context of given rosterand writes breaches to the database */
  validateWorkerShifts?: Maybe<ValidateWorkerShiftsPayload>;
  /** Send email invitation to agency registration */
  vmsAgencyInvitation?: Maybe<AgencyInvitationPayload>;
  /** allowing agencies to remove agency worker from agency */
  vmsAgencyRegistrationDeleteAgencyRegistration?: Maybe<DeleteAgencyRegistrationPayload>;
  /** Delete Compliance Pack */
  vmsAgencyRegistrationDeleteUploadedFile?: Maybe<DeleteUploadedFilePayload>;
  /** To hard delete an agency registration by agency_user */
  vmsAgencyRegistrationDestroyAgencyRegistration?: Maybe<DestroyAgencyRegistrationPayload>;
  /** allowing agencies to recover deleted agency worker from agency */
  vmsAgencyRegistrationRecoverAgencyRegistration?: Maybe<RecoverAgencyRegistrationPayload>;
  /** allowing agencies to to register agency worker */
  vmsAgencyRegistrationRegisterAgencyWorker?: Maybe<RegisterAgencyWorkerPayload>;
  /** Update agency worker */
  vmsAgencyRegistrationUpdateAgencyWorker?: Maybe<UpdateAgencyWorkerPayload>;
  /** Allowing agencies to upload compliance pack for agency registration */
  vmsAgencyRegistrationUploadCompliancePack?: Maybe<UploadCompliancePackPayload>;
  /** Allowing agencies to upload CV for agency registration */
  vmsAgencyRegistrationUploadCv?: Maybe<UploadCvPayload>;
  /** Create an Agency User */
  vmsAgencyUserCreate?: Maybe<VmsAgencyUserCreatePayload>;
  /** For Agency User to create Agency Users Permissions */
  vmsAgencyUserPermissionCreate: Scalars['Boolean'];
  /** For Agency User to delete Agency Users Permissions */
  vmsAgencyUserPermissionDestroy: Scalars['Boolean'];
  /** For Agency User to update Agency Users Permissions */
  vmsAgencyUserPermissionUpdate: Scalars['Boolean'];
  /** For Agency User to suspend Agency Users */
  vmsAgencyUserSuspend: Scalars['Boolean'];
  /** An agency user with permissions can grant all permissions to another user for a specific agency. */
  vmsAgencyUserSwitchAgency?: Maybe<SwitchAgencyPayload>;
  /** An agency user with permissions can unsuspend an agency user */
  vmsAgencyUserUnsuspend: Scalars['Boolean'];
  /** For Agency User to update Agency Users */
  vmsAgencyUserUpdate?: Maybe<VmsAgencyUserUpdatePayload>;
  /** To create an agency worker by agency_user */
  vmsAgencyWorkerCreate?: Maybe<CreatePayload>;
  /** For AgencyUser to propose AgencyWorker to the shift */
  vmsAgencyWorkerProposalCreate?: Maybe<AgencyWorkerProposalCreatePayload>;
  /** For AgencyUser to delete AgencyWorkerProposal */
  vmsAgencyWorkerProposalDelete?: Maybe<AgencyWorkerProposalDeletePayload>;
  /** To update an agency worker by agency_user */
  vmsAgencyWorkerUpdate?: Maybe<UpdatePayload>;
  /** To assign agency_registration to the shift by agency_user */
  vmsShiftAssignAgencyRegistration?: Maybe<ShiftAssignAgencyRegistrationPayload>;
  /** To assing agency_worker to the shift by agency_user */
  vmsShiftAssignAgencyWorker?: Maybe<ShiftAssignAgencyWorkerPayload>;
  /** To assing agency_worker proposed ealier by AgencyWorkerProposal */
  vmsShiftAssignProposedAgencyWorker?: Maybe<ShiftAssignProposedAgencyWorkerPayload>;
  /** For AgencyUser to cancel AgencyWorker booking */
  vmsShiftBlockCancelAgencyBooking?: Maybe<CancelAgencyBookingPayload>;
  /** For HubUser to accept proposed AgencyWorker to a shift block */
  vmsShiftBlockProposalAccept?: Maybe<ProposalAcceptPayload>;
  /** For AgencyUser to propose AgencyWorker to a shift block */
  vmsShiftBlockProposalCreate?: Maybe<ProposalCreatePayload>;
  /** For HubUser to reject a shift block proposal */
  vmsShiftBlockProposalReject?: Maybe<ProposalRejectPayload>;
  /** For agency to revoke a shift block proposal */
  vmsShiftBlockProposalRevoke?: Maybe<ProposalRevokePayload>;
  /** An agency user can cancel an agency booking */
  vmsShiftCancelAgencyWorker?: Maybe<ShiftCancelAgencyWorkerPayload>;
  /** Signup mutation for agency workers */
  vmsSignup?: Maybe<SignupWithTokenPayload>;
  /** Confirm agency registration */
  vmsWorkerConfirmRegistration?: Maybe<ConfirmRegistrationPayload>;
  /** For a worker to withdraw an application */
  withdrawApplication?: Maybe<WithdrawApplicationPayload>;
  /** Approve rate change */
  withdrawRateChange?: Maybe<WithdrawRateChangePayload>;
  /** For a worker to withdraw a booking */
  withdrawWorker?: Maybe<WithdrawWorkerPayload>;
  /** Allows worker to update their profile information in new onboarding flow */
  workerOnboardingUpdateProfile?: Maybe<UpdateWorkerProfilePayload>;
  /** Request a one-time code to reset a worker password */
  workerPasswordResetRequestOneTimeCode?: Maybe<RequestOneTimeCodePayload>;
  /** Reset worker password with one time code */
  workerPasswordResetWithOneTimeCode?: Maybe<WithOneTimeCodePayload>;
  /** Create a new worker account */
  workerRegistrationCreateAccount?: Maybe<CreateAccountPayload>;
  /** Send new one time code for worker registration. */
  workerRegistrationResendOneTimeCode?: Maybe<ResendOneTimeCodePayload>;
  /** Validate one time code for worker registration */
  workerRegistrationValidateOneTimeCode?: Maybe<ValidateOneTimeCodePayload>;
  /** Resets the password for a worker account */
  workerResetPassword?: Maybe<WorkerResetPasswordPayload>;
  /** For a worker to sign off shifts with signature */
  workerSignOff?: Maybe<WorkerSignOffPayload>;
  /** For a worker to sign off a shift */
  workerSignOffCreate?: Maybe<WorkerSignOffCreatePayload>;
  /** For a worker to request remote sign off */
  workerSignOffRequested?: Maybe<WorkerSignOffRequestedPayload>;
  /** For a worker to request remote sign off */
  workerSignOffRequestedMultiple?: Maybe<WorkerSignOffRequestedMultiplePayload>;
  /** For a worker to request remote sign off for on call shift */
  workerSignOffRequestedMultipleOnCall?: Maybe<WorkerSignOffRequestedMultipleOnCallPayload>;
  /** For a worker to sign off shifts with PIN */
  workerSignOffWithPin?: Maybe<WorkerSignOffWithPinPayload>;
  /** Update Worker National Insurance number and/or date or birth */
  workerUpdateNiAndOrDob?: Maybe<UpdateNiAndOrDobPayload>;
  /** Update Worker Notification Settings. To stop receiving notifications, set: documentExpiryNotifications: false, urgentShiftNotifications: false, vacancyNotifications: NONE */
  workerUpdateNotificationSettings?: Maybe<UpdateNotificationSettingsPayload>;
  /** Update User Password */
  workerUpdatePassword?: Maybe<User>;
  /** Update Worker Profile Information */
  workerUpdateProfile?: Maybe<UpdateProfilePayload>;
  /** Update Worker Specialities */
  workerUpdateSkills?: Maybe<UpdateSkillsPayload>;
  /** Update Worker Specialities */
  workerUpdateSpecialities?: Maybe<UpdateSpecialitiesPayload>;
};


export type MutationAccountAcceptInviteArgs = {
  invitationToken: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAccountCreateArgs = {
  accountAttributes: AccountAttributes;
};


export type MutationAccountInviteArgs = {
  accountId: Scalars['Int'];
};


export type MutationActivateWorkerArgs = {
  id: Scalars['Int'];
};


export type MutationAddEsrNumberArgs = {
  authoriserName: Scalars['String'];
  esrNumber: Scalars['String'];
  internalOrExternal: Scalars['String'];
  lessThanFullTime: Scalars['Boolean'];
  monthly: Scalars['Boolean'];
  organisationId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
  validFrom: Scalars['DateTime'];
  validTo?: InputMaybe<Scalars['DateTime']>;
};


export type MutationAddSkillToOrgStaffGroupArgs = {
  organisationStaffGroupId: Scalars['Int'];
  skillId: Scalars['Int'];
};


export type MutationAddWorkerUploadArgs = {
  documentNumber?: InputMaybe<Scalars['String']>;
  documentTitle?: InputMaybe<Scalars['String']>;
  documentTypeId: Scalars['Int'];
  expirationDate?: InputMaybe<Scalars['String']>;
  file: Scalars['String'];
  fileName: Scalars['String'];
  issueDate?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  qualificationName?: InputMaybe<Scalars['String']>;
};


export type MutationAddWorkerUploadFromHubArgs = {
  documentNumber?: InputMaybe<Scalars['String']>;
  documentTitle?: InputMaybe<Scalars['String']>;
  documentTypeId: Scalars['Int'];
  expirationDate?: InputMaybe<Scalars['String']>;
  file: Scalars['String'];
  fileName: Scalars['String'];
  issueDate?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  qualificationName?: InputMaybe<Scalars['String']>;
  workerId: Scalars['Int'];
};


export type MutationAgencyBookArgs = {
  agencyMargin: Scalars['Float'];
  agencyName: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  directEngagement?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  poNumber?: InputMaybe<Scalars['String']>;
  rate: Scalars['Float'];
  receiptNumber?: InputMaybe<Scalars['String']>;
  regBodyNumber: Scalars['String'];
  requisitionNumber?: InputMaybe<Scalars['String']>;
  shiftId: Scalars['Int'];
};


export type MutationAgencyCancelBookingArgs = {
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationAgencyUserCreateArgs = {
  agencyId: Scalars['Int'];
  canCreateAgencyWorkers: Scalars['Boolean'];
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationAgencyUserLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAgencyUserPermissionCreateArgs = {
  agencyId: Scalars['Int'];
  agencyUserId: Scalars['Int'];
  canAssignWorker: Scalars['Boolean'];
  canProposeWorker: Scalars['Boolean'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationAgencyUserPermissionDestroyArgs = {
  id: Scalars['Int'];
};


export type MutationAgencyUserPermissionUpdateArgs = {
  agencyId: Scalars['Int'];
  canAssignWorker?: InputMaybe<Scalars['Boolean']>;
  canProposeWorker: Scalars['Boolean'];
  id: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationAgencyUserRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationAgencyUserUpdateArgs = {
  agencyId: Scalars['Int'];
  canCreateAgencyWorkers: Scalars['Boolean'];
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationAgencyWorkerDocsCreateDocumentationArgs = {
  agencyRegistrationId: Scalars['Int'];
  documentations: Array<DocumentationInputs>;
};


export type MutationAgencyWorkerDocsDestroyDocumentationArgs = {
  id: Scalars['Int'];
};


export type MutationAgencyWorkerDocsExportDocumentationsUploadsArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationAgencyWorkerDocsExportShiftAndBlockComplianceArgs = {
  agencyRegistrationId: Scalars['Int'];
  objectId: Scalars['Int'];
  objectType: Scalars['String'];
};


export type MutationAgencyWorkerDocsUpdateDocumentationArgs = {
  content: Scalars['JSON'];
  id: Scalars['Int'];
};


export type MutationAgencyWorkerProposalAcceptArgs = {
  deContractVersionId?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
};


export type MutationAgencyWorkerProposalDeclineArgs = {
  id: Scalars['ID'];
  reasonForRejection?: InputMaybe<Scalars['String']>;
};


export type MutationApplyToOrganisationAndCollaborativeBankArgs = {
  answer?: InputMaybe<Scalars['String']>;
  collaborativeBankId: Scalars['Int'];
  instantBooking?: InputMaybe<Scalars['Boolean']>;
  organisationId: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationApproveRateChangeArgs = {
  rateChangeId: Scalars['Int'];
};


export type MutationApproveShiftApplicationArgs = {
  shiftId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationArchiveAgencyArgs = {
  id: Scalars['Int'];
};


export type MutationArchiveGradeArgs = {
  id: Scalars['Int'];
};


export type MutationAssignWorkerArgs = {
  shiftId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationAutoCasacdeDeleteRuleTriggersArgs = {
  triggerableId: Scalars['Int'];
  triggerableType: Scalars['String'];
};


export type MutationAutoCascadeCreateAutoSendToAgencyTriggerArgs = {
  triggerableId: Scalars['Int'];
  triggerableType: Scalars['String'];
};


export type MutationAutoCascadeCreateRuleArgs = {
  autoCascadeRuleGroupsRangeId: Scalars['Int'];
  fromTier?: InputMaybe<Scalars['Int']>;
  frontendTimeUnit?: InputMaybe<Scalars['String']>;
  minutesAfterLastTrigger?: InputMaybe<Scalars['Int']>;
  toTier: Scalars['Int'];
};


export type MutationAutoCascadeCreateRuleGroupArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationAutoCascadeCreateRuleGroupsRangeArgs = {
  autoCascadeRuleGroupId: Scalars['Int'];
  daysBeforeShiftStart: Scalars['Int'];
};


export type MutationAutoCascadeCreateRuleTriggersArgs = {
  triggerableId: Scalars['Int'];
  triggerableType: Scalars['String'];
};


export type MutationAutoCascadeDestroyRuleArgs = {
  id: Scalars['Int'];
};


export type MutationAutoCascadeDestroyRuleGroupArgs = {
  id: Scalars['Int'];
};


export type MutationAutoCascadeDestroyRuleGroupsRangeArgs = {
  id: Scalars['Int'];
};


export type MutationAutoCascadeUpdateAutoSendToAgencyArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  daysBeforeSendingToAgency: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationAutoCascadeUpdateRuleArgs = {
  fromTier?: InputMaybe<Scalars['Int']>;
  frontendTimeUnit?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  minutesAfterLastTrigger?: InputMaybe<Scalars['Int']>;
  toTier: Scalars['Int'];
};


export type MutationAutoCascadeUpdateRuleGroupArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
};


export type MutationAutoCascadeUpdateRuleGroupsRangeArgs = {
  daysBeforeShiftStart: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationBankUploadRateCardsArgs = {
  organisationId: Scalars['Int'];
  source: Scalars['Upload'];
  staffGroupId: Scalars['Int'];
};


export type MutationBookOrApplyArgs = {
  shiftId: Scalars['Int'];
};


export type MutationBulkAssignAgencyWorkerArgs = {
  agencyId: Scalars['Int'];
  agencyLocked?: InputMaybe<Scalars['Boolean']>;
  agencyWorkerId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  coreAgencyMargin?: InputMaybe<Scalars['Float']>;
  coreLocumRate?: InputMaybe<Scalars['Float']>;
  coreNiContribution?: InputMaybe<Scalars['Float']>;
  coreTotalRate: Scalars['Float'];
  directEngagement?: InputMaybe<Scalars['Boolean']>;
  onFramework?: InputMaybe<Scalars['Boolean']>;
  poNumber?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  requisitionNumber?: InputMaybe<Scalars['String']>;
  shiftIds: Array<Scalars['Int']>;
  unsocialAgencyMargin?: InputMaybe<Scalars['Float']>;
  unsocialLocumRate?: InputMaybe<Scalars['Float']>;
  unsocialNiContribution?: InputMaybe<Scalars['Float']>;
  unsocialTotalRate?: InputMaybe<Scalars['Float']>;
};


export type MutationBulkAssignWorkerArgs = {
  shiftIds: Array<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type MutationBulkCancelAgencyWorkerArgs = {
  comments?: InputMaybe<Scalars['String']>;
  reasonForCancellationId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkCancelWorkerArgs = {
  comments?: InputMaybe<Scalars['String']>;
  reasonForCancellationId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkCreateOnBlockArgs = {
  isHub3?: InputMaybe<Scalars['Boolean']>;
  shiftsAttributes: ShiftCreateInput;
};


export type MutationBulkLockToAgencyArgs = {
  agencyLocked: Scalars['Boolean'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkRecallFromSecondaryBankArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkSendToAgencyTierArgs = {
  agencyTierId?: InputMaybe<Scalars['Int']>;
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkSendToSecondaryBankArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkSignOffArgs = {
  forTimesheet?: InputMaybe<Scalars['Boolean']>;
  shiftIds: Array<Scalars['Int']>;
  signOffAuthoriserId: Scalars['Int'];
};


export type MutationBulkUndoSignOffArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkUpdateAgencyTierArgs = {
  agencyTierId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkUpdateShiftsArgs = {
  shiftBlockAttributes: ShiftUpdateInput;
  shiftBlockId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationBulkUploadDepartmentsArgs = {
  file: Scalars['Upload'];
};


export type MutationCancelWorkerArgs = {
  comments?: InputMaybe<Scalars['String']>;
  reasonForCancellationId: Scalars['Int'];
  shiftId: Scalars['Int'];
};


export type MutationChangeOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type MutationChangeStatusArgs = {
  finish: Scalars['String'];
  organisationId: Scalars['Int'];
  start: Scalars['String'];
  workerId: Scalars['Int'];
};


export type MutationCheckComplianceForEditedShiftArgs = {
  newEndsAt: Scalars['DateTime'];
  newStartsAt: Scalars['DateTime'];
  rosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationCheckComplianceForShiftSwapArgs = {
  originalRosteringEventId: Scalars['Int'];
  proposedRosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationComplianceDocumentsCreateOrganisationComplianceDocumentArgs = {
  complianceDocumentTypeId: Scalars['Int'];
  organisationId: Scalars['Int'];
  renewalPeriod?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationComplianceDocumentsDeleteOrganisationComplianceDocumentArgs = {
  organisationComplianceDocumentId: Scalars['Int'];
};


export type MutationComplianceDocumentsUpdateOrganisationComplianceDocumentArgs = {
  organisationComplianceDocumentId: Scalars['Int'];
  renewalPeriod?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationCreateAgencyArgs = {
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  notificationEmail?: InputMaybe<Scalars['String']>;
  shortTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateCollaborativeBankArgs = {
  allowsExternalApplicants?: InputMaybe<Scalars['Boolean']>;
  collaborativeBankStaffGroups?: InputMaybe<Array<CreateCollaborativeBankStaffGroupInput>>;
  contractText?: InputMaybe<Scalars['String']>;
  leadEmployerOrganisationId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  welcomeEmailBody?: InputMaybe<Scalars['String']>;
  welcomeEmailSubject?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCollaborativeBankStaffGroupArgs = {
  collaborativeBankId: Scalars['Int'];
  instantBooking?: InputMaybe<Scalars['Boolean']>;
  instantBookingByDefault?: InputMaybe<Scalars['Boolean']>;
  staffGroupId: Scalars['Int'];
};


export type MutationCreateCostCentreArgs = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDepartmentArgs = {
  allowAppSignOff: Scalars['Boolean'];
  allowEarlyAppSignOff: Scalars['Boolean'];
  associatedCostCentreIds?: InputMaybe<Array<Scalars['Int']>>;
  autoApprove: Scalars['Boolean'];
  autoReleaseToSecondaryBank: Scalars['Boolean'];
  canWorkerBookHigherShiftGrade: Scalars['Boolean'];
  canWorkerBookLowerShiftGrade: Scalars['Boolean'];
  costCentreId: Scalars['Int'];
  covidRating?: InputMaybe<Scalars['Int']>;
  cutOffAgency: Scalars['Int'];
  cutOffAlert: Scalars['Int'];
  deptEmail: Scalars['String'];
  deptNumber: Scalars['String'];
  divisionId?: InputMaybe<Scalars['Int']>;
  emailOnBooking: Scalars['Boolean'];
  emailOnCancellation: Scalars['Boolean'];
  emailOnSignOff: Scalars['Boolean'];
  enhanced: Scalars['Boolean'];
  excludeFromRpa: Scalars['Boolean'];
  externalName?: InputMaybe<Scalars['String']>;
  instantBookingForCollabBanks?: InputMaybe<Scalars['Boolean']>;
  lowerGradeSelfBook: Scalars['Boolean'];
  name: Scalars['String'];
  payAtWorkerGradeIfWorkerIsHigherThanShift: Scalars['Boolean'];
  payAtWorkerGradeIfWorkerIsLowerThanShift: Scalars['Boolean'];
  paymentApproverEmail?: InputMaybe<Scalars['String']>;
  positionManagementCode?: InputMaybe<PositionManagementCodeEnum>;
  primarySpecialityId?: InputMaybe<Scalars['Int']>;
  shiftReleaseOption?: InputMaybe<Scalars['Int']>;
  shiftReleaseOverride?: InputMaybe<Scalars['Boolean']>;
  signatureForShiftsSignOff?: InputMaybe<Scalars['Boolean']>;
  siteIds: Array<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  timedReleaseDays?: InputMaybe<Scalars['Int']>;
  urgentShiftNotifications?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateDivisionArgs = {
  name: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationCreateExternalCancelReasonArgs = {
  externalId: Scalars['String'];
  organisationId: Scalars['Int'];
  reasonForCancellationId: Scalars['Int'];
};


export type MutationCreateExternalGradeArgs = {
  externalName: Scalars['String'];
  externalType?: InputMaybe<Scalars['String']>;
  gradeId: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationCreateExternalOrganisationArgs = {
  identityUrl: Scalars['String'];
  password: Scalars['String'];
  trustCode: Scalars['String'];
  url: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreateExternalRequestReasonArgs = {
  externalId: Scalars['String'];
  organisationId: Scalars['Int'];
  reasonForRequestId: Scalars['Int'];
};


export type MutationCreateExternalUnitArgs = {
  costCentre?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['Int'];
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationId: Scalars['Int'];
  siteId: Scalars['Int'];
};


export type MutationCreateFilterGradeArgs = {
  staffGroupId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationCreateFiltersArgs = {
  dateType?: InputMaybe<FilterDateEnum>;
  distanceInMiles?: InputMaybe<Scalars['Int']>;
  endsBy?: InputMaybe<Scalars['String']>;
  filterGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postcode?: InputMaybe<Scalars['String']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialtyIds?: InputMaybe<Array<Scalars['Int']>>;
  startsAfter?: InputMaybe<Scalars['String']>;
  timeType?: InputMaybe<FilterTimeEnum>;
};


export type MutationCreateGradeArgs = {
  grade: CreateGradeInput;
};


export type MutationCreateLocationArgs = {
  name: Scalars['String'];
};


export type MutationCreateOrganisationArgs = {
  description?: InputMaybe<Scalars['String']>;
  fraudDeclarationStatement?: InputMaybe<Scalars['String']>;
  hasPatchworkBank?: InputMaybe<Scalars['Boolean']>;
  isCovidBank?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['Upload']>;
  multipleRateAuthorisers?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organisationType?: InputMaybe<Scalars['Int']>;
  shiftBlockTimesheets?: InputMaybe<Scalars['Boolean']>;
  showDuplicatePaymentRows?: InputMaybe<Scalars['Boolean']>;
  usesCollabBankPpi?: InputMaybe<Scalars['Boolean']>;
  usesCovidRating?: InputMaybe<Scalars['Boolean']>;
  usesFraudDeclarationStatement?: InputMaybe<Scalars['Boolean']>;
  usesNewHub?: InputMaybe<Scalars['Boolean']>;
  usesPpi?: InputMaybe<Scalars['Boolean']>;
  usesRateEscalationInsights?: InputMaybe<Scalars['Boolean']>;
  usesSessional?: InputMaybe<Scalars['Boolean']>;
  usesShiftBlocks?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOrganisationAgencyArgs = {
  agencyId: Scalars['Int'];
  agencyMarginLocked?: InputMaybe<Scalars['Boolean']>;
  agencyTierIds: Array<Scalars['Int']>;
  canAssignWorker?: InputMaybe<Scalars['Boolean']>;
  canEditChargeRate?: InputMaybe<Scalars['Boolean']>;
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  canProposeWorker?: InputMaybe<Scalars['Boolean']>;
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationCreateOrganisationRegistrationNoteArgs = {
  note: Scalars['String'];
  organisationRegistrationId: Scalars['Int'];
};


export type MutationCreateOrganisationStaffGroupArgs = {
  agencyEmail?: InputMaybe<Scalars['String']>;
  askQuestion?: InputMaybe<Scalars['Boolean']>;
  autoAddItemWtd?: InputMaybe<Scalars['Int']>;
  autoAddWtd?: InputMaybe<Scalars['Int']>;
  blockLateCancellationsHours?: InputMaybe<Scalars['Int']>;
  calculatePensions?: InputMaybe<Scalars['Boolean']>;
  defaultIr35?: InputMaybe<Ir35Enum>;
  ditDeanery?: InputMaybe<Scalars['String']>;
  ditElementAllowanceType?: InputMaybe<Scalars['String']>;
  emailLateCancellationsHours?: InputMaybe<Scalars['Int']>;
  escalationNotificationEmail?: InputMaybe<Scalars['String']>;
  excludedDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
  excludedGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  mandateDeBookings?: InputMaybe<Scalars['Boolean']>;
  paymentApprovalEnabled?: InputMaybe<Scalars['Boolean']>;
  pushNewVacanciesHours?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  rateNotApplicable?: InputMaybe<Scalars['Boolean']>;
  rateUrl?: InputMaybe<Scalars['String']>;
  rechargeCostCentre?: InputMaybe<Scalars['String']>;
  safeWorkingEnabled?: InputMaybe<Scalars['Boolean']>;
  safeWorkingIntervalHours?: InputMaybe<Scalars['Float']>;
  safeWorkingMaxHours?: InputMaybe<Scalars['Float']>;
  shiftReleaseOption?: InputMaybe<Scalars['Int']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  staffBankAgreementForm?: InputMaybe<Scalars['String']>;
  staffGroupId: Scalars['Int'];
  timedReleaseDays?: InputMaybe<Scalars['Int']>;
  tracLink?: InputMaybe<Scalars['String']>;
  tsoEmail?: InputMaybe<Scalars['String']>;
  tsoName?: InputMaybe<Scalars['String']>;
  tsoPhone?: InputMaybe<Scalars['String']>;
  usesSkills?: InputMaybe<Scalars['Boolean']>;
  vms?: InputMaybe<Scalars['Boolean']>;
  wtdPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationCreatePassportArgs = {
  organisationRegistrationId: Scalars['Int'];
};


export type MutationCreatePaymentBatchWithShiftsArgs = {
  organisationStaffGroupId: Scalars['Int'];
  paymentCategory: PaymentCategoryEnum;
  payrollCycle: TempStaffPaymentCycleEnum;
  shiftIds: Array<Scalars['Int']>;
};


export type MutationCreateReasonArgs = {
  reason: Scalars['String'];
  type: ReasonTypeEnum;
};


export type MutationCreateRefereeArgs = {
  contactNumber?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  organisation: Scalars['String'];
  position: Scalars['String'];
};


export type MutationCreateRegulationBodyArgs = {
  name: Scalars['String'];
  nameAbbr?: InputMaybe<Scalars['String']>;
  registrationNumberMaxLength?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateSiteArgs = {
  latitude?: InputMaybe<Scalars['Float']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  longitude?: InputMaybe<Scalars['Float']>;
  mapsUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateSkillArgs = {
  name: Scalars['String'];
};


export type MutationCreateSpecialityArgs = {
  title: Scalars['String'];
};


export type MutationCreateStaffGroupArgs = {
  regulationBodyId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  title: Scalars['String'];
};


export type MutationCreateSuperAdminArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationCreateTempStaffShiftArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Int']>;
  costCentre?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['Int'];
  endDates?: InputMaybe<Array<Scalars['Date']>>;
  gradeId: Scalars['Int'];
  ir35?: InputMaybe<Ir35Enum>;
  limitToPreferred?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  positionManagementCode?: InputMaybe<Scalars['String']>;
  proposedEndTime: Scalars['String'];
  proposedStartTime: Scalars['String'];
  rate?: InputMaybe<Scalars['Float']>;
  rateAuthoriserId?: InputMaybe<Scalars['Int']>;
  rateCore?: InputMaybe<Scalars['Float']>;
  rateUnsocial?: InputMaybe<Scalars['Float']>;
  reasonForRequestId: Scalars['Int'];
  selfBooking?: InputMaybe<SelfBookingEnum>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteId: Scalars['Int'];
  skillIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startDates: Array<Scalars['Date']>;
  workerId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateTimesheetAuthorisersArgs = {
  createTimesheetAuthoriserAttributes: Array<CreateTimesheetAuthoriserInput>;
};


export type MutationCreateVmsAgencyArgs = {
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  notificationEmail?: InputMaybe<Scalars['String']>;
  organisations: Array<OrganisationInput>;
  shortTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateVmsAgencyUserArgs = {
  agencyId: Scalars['Int'];
  canCreateAgencyWorkers: Scalars['Boolean'];
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationDeContractsArchiveContractArgs = {
  deContractId: Scalars['Int'];
};


export type MutationDeContractsCreateContractArgs = {
  contractType: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationStaffGroupIds: Array<Scalars['Int']>;
};


export type MutationDeContractsPublishContractArgs = {
  deContractId: Scalars['Int'];
};


export type MutationDeContractsResendVersionAssignmentArgs = {
  blockId?: InputMaybe<Scalars['Int']>;
  shiftId?: InputMaybe<Scalars['Int']>;
};


export type MutationDeContractsSaveVersionArgs = {
  content: Scalars['String'];
  deContractId: Scalars['Int'];
};


export type MutationDeContractsSignVersionAssignmentArgs = {
  id: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationDeContractsUpdateContractArgs = {
  contractType: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  organisationStaffGroupIds: Array<Scalars['Int']>;
  status: Scalars['Int'];
};


export type MutationDeactivateEsrNumberArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateWorkerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCostCentreArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDepartmentArgs = {
  departmentId: Scalars['Int'];
};


export type MutationDeleteDivisionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEsrNumberArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalGradeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOrgStaffGroupsSkillArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteOrganisationAgencyArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReasonArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteRefereeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSiteArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSkillArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSpecialityArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVmsAgencyArgs = {
  agencyId: Scalars['ID'];
};


export type MutationDeleteVmsAgencyUserArgs = {
  agencyUserId: Scalars['Int'];
};


export type MutationDeleteWorkerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteWorkerUploadArgs = {
  id: Scalars['Int'];
};


export type MutationDestroyCollaborativeBankStaffGroupArgs = {
  id: Scalars['Int'];
};


export type MutationDidNotAttendCreateArgs = {
  comment?: InputMaybe<Scalars['String']>;
  deleteMode: Scalars['Boolean'];
  shiftId: Scalars['Int'];
};


export type MutationEscalateRateWithAdvancedFlowArgs = {
  escalatedRates: Array<EscalatedRate>;
  method?: AdvancedEscalationMethodEnum;
  rateAuthoriserIds?: InputMaybe<Array<Scalars['Int']>>;
  rateOrigin?: EscalatedRateOriginEnum;
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationEscalateRateWithBasicFlowArgs = {
  coreRate: Scalars['Float'];
  rateAuthoriserId: Scalars['Int'];
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
  unsocialRate: Scalars['Float'];
};


export type MutationEscalateRateWithLevelGradeRatesArgs = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  levelGradeRateId: Scalars['Int'];
  reasonForEscalationId: Scalars['Int'];
  shiftId: Scalars['Int'];
};


export type MutationEscalationCreateLevelGradeRatesArgs = {
  core: Scalars['Float'];
  gradeId: Scalars['Int'];
  levelId: Scalars['Int'];
  unsocial: Scalars['Float'];
};


export type MutationEscalationDeleteLevelGradeRatesArgs = {
  id: Scalars['Int'];
};


export type MutationEscalationUpdateLevelGradeRatesArgs = {
  core: Scalars['Float'];
  id: Scalars['Int'];
  unsocial: Scalars['Float'];
};


export type MutationExportHubUsersArgs = {
  accountTypes?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  exportHubUserColumns: ExportHubUserColumnsInput;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  superAdmins?: InputMaybe<Scalars['Boolean']>;
};


export type MutationExportShiftsArgs = {
  agencyWorkerLocumIds?: InputMaybe<Array<Scalars['Int']>>;
  agencyWorkerRegistrationIds?: InputMaybe<Array<Scalars['Int']>>;
  applications?: InputMaybe<ShiftApplicationsEnum>;
  bankWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
  batchId?: InputMaybe<Scalars['Int']>;
  columns: Array<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  divisionIds?: InputMaybe<Array<Scalars['Int']>>;
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  limitToScope?: InputMaybe<ShiftScope>;
  origin?: InputMaybe<ShiftOriginEnum>;
  rate?: InputMaybe<ShiftRateEscalationEnum>;
  releasedTo?: InputMaybe<ShiftReleasedToEnum>;
  requesterIds?: InputMaybe<Array<Scalars['Int']>>;
  shiftBlockIds?: InputMaybe<Array<Scalars['ID']>>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  statuses?: InputMaybe<Array<ShiftStatus>>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  types?: InputMaybe<Array<ShiftTypeEnum>>;
};


export type MutationExportWorkersArgs = {
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  exportWorkerColumns: ExportWorkerColumnsInput;
  fromStartDate?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeRosteringWorkers?: InputMaybe<Scalars['Boolean']>;
  internalOrExternal?: InputMaybe<Scalars['String']>;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  primaryOrSecondary?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['String']>;
  suspended?: InputMaybe<Scalars['String']>;
  toEndDate?: InputMaybe<Scalars['String']>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationExternalApplicationArgs = {
  answer?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationExternalCreateArgs = {
  accountType: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['Int'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  shiftBasicRead?: InputMaybe<Scalars['Boolean']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationExternalLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationExternalRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationExternalResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationExternalSendResetPasswordInstructionsArgs = {
  email: Scalars['String'];
};


export type MutationExternalUpdateAccountArgs = {
  accountType: ExternalAccountTypeEnum;
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  shiftBasicRead: Scalars['Boolean'];
  staffGroupIds: Array<Scalars['Int']>;
};


export type MutationExternalUpdateVmsAccountArgs = {
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  shiftBasicRead: Scalars['Boolean'];
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationExternalUpdateVmsWebhooksArgs = {
  bookingCancelledUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  shiftAvailableUrl?: InputMaybe<Scalars['String']>;
  shiftCancelledUrl?: InputMaybe<Scalars['String']>;
  shiftUpdatedUrl?: InputMaybe<Scalars['String']>;
};


export type MutationForgetDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationHubUserActivateAccountArgs = {
  invitationToken: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationHubUserChangeStatusOfAccountArgs = {
  hubUserId: Scalars['Int'];
  status: Scalars['String'];
};


export type MutationHubUserConfirmEmailArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationHubUserCreateArgs = {
  createHubUserAttributes: CreateHubUserInput;
};


export type MutationHubUserDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationHubUserLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationHubUserRejectSignOffArgs = {
  reasonForRejection: Scalars['String'];
  shiftId: Scalars['Int'];
  signOffRequestId: Scalars['Int'];
};


export type MutationHubUserResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationHubUserSendConfirmationInstructionsArgs = {
  email: Scalars['String'];
};


export type MutationHubUserSendResetPasswordInstructionsArgs = {
  email: Scalars['String'];
};


export type MutationHubUserSignOffArgs = {
  breaks: Scalars['Int'];
  breaksExceptionNotes?: InputMaybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  shiftId: Scalars['Int'];
  signOffAuthoriserId: Scalars['Int'];
  timeframes?: InputMaybe<Array<InputOnCallShiftTimeframe>>;
};


export type MutationHubUserSignOffRequestedMultipleArgs = {
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationHubUserSignOffWithTimesAmendedArgs = {
  acceptWorkerReport?: InputMaybe<Scalars['Boolean']>;
  approvedBy?: InputMaybe<Scalars['String']>;
  approvedDate?: InputMaybe<Scalars['String']>;
  breaksExceptionNotes?: InputMaybe<Scalars['String']>;
  confirmationNotes?: InputMaybe<Scalars['String']>;
  confirmedBreaks: Scalars['Int'];
  confirmedEndTime: Scalars['DateTime'];
  confirmedStartTime: Scalars['DateTime'];
  shiftId: Scalars['Int'];
  signOffAuthoriserId: Scalars['Int'];
  timeframes?: InputMaybe<Array<InputOnCallShiftTimeframe>>;
};


export type MutationHubUserSignOffWithTimesAmendedMultipleArgs = {
  shifts: Array<InputMultipleAmendedShift>;
};


export type MutationHubUserUpdateArgs = {
  updateHubUserAttributes: UpdateHubUserInput;
};


export type MutationIntegrationPatchworkCreateAuditLogArgs = {
  bankShiftId: Scalars['Int'];
  changesData?: InputMaybe<Scalars['JSON']>;
  eventName: Scalars['String'];
};


export type MutationIntegrationPatchworkEditBankShiftArgs = {
  updateBankShiftParams: EditBankRosterShiftInput;
};


export type MutationIntegrationPatchworkEditShiftArgs = {
  adminNote: Scalars['String'];
  bankShiftId: Scalars['Int'];
  note: Scalars['String'];
};


export type MutationIntegrationPatchworkRecallFromBankArgs = {
  recallFromBankParams: RecallFromBankShiftInput;
};


export type MutationIntegrationPatchworkSendMultipleShiftsToBankArgs = {
  bulkAddSiteParams?: InputMaybe<Array<BulkAddSiteInput>>;
  bulkOverrideParams?: InputMaybe<BulkOverrideInput>;
  sendToBankParams?: InputMaybe<Array<BankRosterShiftInput>>;
};


export type MutationIntegrationPatchworkSendToBankArgs = {
  sendToBankParams: BankRosterShiftInput;
};


export type MutationInternalApplicationArgs = {
  answer?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  organisationId: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationJoinCollaborativeBankArgs = {
  collaborativeBankId: Scalars['Int'];
  primaryOrganisationId: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationLinkHubUserToWorkerAccountArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  hubUserId: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  workerEmail: Scalars['String'];
};


export type MutationLinkWorkerToHubUserAccountArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  hubUserEmail: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  workerId: Scalars['Int'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMetaActivityCreateArgs = {
  createMetaActivityAttributes: CreateMetaActivityInput;
};


export type MutationMetaActivityUpdateArgs = {
  updateMetaActivityAttributes: UpdateMetaActivityInput;
};


export type MutationMoveShiftsToFlaggedPaymentsArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationMoveToInternalArgs = {
  organisationId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationOrganisationLockConfigCreateArgs = {
  lockDay: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationOrganisationLockConfigDeleteArgs = {
  organisationId: Scalars['Int'];
};


export type MutationPaidPerItemCreateBatchArgs = {
  inCollaborativeBank?: InputMaybe<Scalars['Boolean']>;
  monthly: Scalars['Boolean'];
  organisationStaffGroupId: Scalars['Int'];
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemCreateItemArgs = {
  departmentId: Scalars['Int'];
  itemGradeCostsAttributes: Array<ItemGradeCostInput>;
  name: Scalars['String'];
  organisationId: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationPaidPerItemCreateReceiptForRemoteSignOffArgs = {
  dateCompleted: Scalars['Date'];
  departmentId: Scalars['Int'];
  gradeId: Scalars['Int'];
  organisationId: Scalars['Int'];
  organisationRegistrationId?: InputMaybe<Scalars['Int']>;
  receiptItemsAttributes: Array<ReceiptItemInput>;
  siteId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationPaidPerItemCreateReceiptWithPinSignOffArgs = {
  dateCompleted: Scalars['Date'];
  departmentId: Scalars['Int'];
  gradeId: Scalars['Int'];
  organisationId: Scalars['Int'];
  organisationRegistrationId?: InputMaybe<Scalars['Int']>;
  pin: Scalars['String'];
  receiptItemsAttributes: Array<ReceiptItemInput>;
  signOffNotes?: InputMaybe<Scalars['String']>;
  siteId: Scalars['Int'];
  timesheetAuthoriserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationPaidPerItemCreateReceiptWithSignatureSignOffArgs = {
  dateCompleted: Scalars['Date'];
  departmentId: Scalars['Int'];
  gradeId: Scalars['Int'];
  organisationId: Scalars['Int'];
  organisationRegistrationId?: InputMaybe<Scalars['Int']>;
  receiptItemsAttributes: Array<ReceiptItemInput>;
  signOffNotes?: InputMaybe<Scalars['String']>;
  signature: Scalars['String'];
  siteId: Scalars['Int'];
  timesheetAuthoriserId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationPaidPerItemDeleteItemArgs = {
  id: Scalars['Int'];
};


export type MutationPaidPerItemReceiptsBulkApproveForPaymentArgs = {
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemReceiptsBulkFlagArgs = {
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemReceiptsBulkSignOffReceiptsArgs = {
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemReceiptsBulkUnapproveForPaymentArgs = {
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemReceiptsBulkUnflagArgs = {
  receiptIds: Array<Scalars['Int']>;
};


export type MutationPaidPerItemReceiptsRejectArgs = {
  receiptId: Scalars['Int'];
  rejectionNotes: Scalars['String'];
};


export type MutationPaidPerItemReceiptsUnrejectArgs = {
  receiptId: Scalars['Int'];
};


export type MutationPaidPerItemSignOffReceiptArgs = {
  receiptId: Scalars['Int'];
};


export type MutationPaidPerItemUpdateItemArgs = {
  departmentId: Scalars['Int'];
  id: Scalars['Int'];
  itemGradeCostsAttributes: Array<ItemGradeCostInput>;
  organisationId: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationPaidPerItemUpdateReceiptArgs = {
  id: Scalars['Int'];
  receiptItemsAttributes: Array<ReceiptItemInput>;
};


export type MutationPamRateCardsUploadArgs = {
  organisationId: Scalars['Int'];
  source: Scalars['Upload'];
  staffGroupId: Scalars['Int'];
};


export type MutationPassportInformationArgs = {
  organisationId: Scalars['Int'];
  passportId: Scalars['Int'];
};


export type MutationPayrollAdjustmentsCreateAgencyAdjustmentArgs = {
  adjustmentReason: Scalars['String'];
  agencyRates: Array<AgencyRateInput>;
  breakMinutes?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['String'];
  onCallTimeframes?: InputMaybe<Array<OnCallTimeframe>>;
  shiftId: Scalars['Int'];
  startTime: Scalars['String'];
};


export type MutationReactivateAgencyArgs = {
  id: Scalars['Int'];
};


export type MutationReadNotificationsArgs = {
  notificationIds: Array<Scalars['Int']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRejectApplicationArgs = {
  organisationId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationRejectRateChangeArgs = {
  rateChangeId: Scalars['Int'];
  reasonForRejection: Scalars['String'];
};


export type MutationRejectShiftApplicationArgs = {
  shiftId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationRemoveShiftsFromFlaggedPaymentsArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationRemoveSuperAdminArgs = {
  id: Scalars['Int'];
};


export type MutationReportDeviceTokenArgs = {
  deviceApp?: InputMaybe<Scalars['String']>;
  deviceName: Scalars['String'];
  deviceToken: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationRestoreDepartmentArgs = {
  departmentId: Scalars['Int'];
};


export type MutationRestoreGradeArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringAddDayTemplateToRotaArgs = {
  dayTemplateId: Scalars['Int'];
  rotaId: Scalars['Int'];
  startFromDay: Scalars['Int'];
};


export type MutationRosteringAddWorkerToScheduleArgs = {
  orgRegId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};


export type MutationRosteringAvailabilitiesOnRostersUpdateArgs = {
  availabilitiesOnRostersParams: Array<AvailabilityOnRosterInput>;
  currentRosterUuid?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringCalculateWorkerPayForRotationPeriodArgs = {
  payPeriodEndDate: Scalars['Date'];
  payPeriodStartDate: Scalars['Date'];
  rateCardId: Scalars['ID'];
  rosterUniqueIdentifier: Scalars['String'];
  rosteringOrganisationRegistrationId: Scalars['ID'];
};


export type MutationRosteringCreatePermissionSetArgs = {
  permissionSetParams: Array<CreateRosteringPermissionSetInput>;
};


export type MutationRosteringDayPatternCreateArgs = {
  dayOrder?: InputMaybe<Scalars['Int']>;
  rosteringDayTemplateId: Scalars['Int'];
};


export type MutationRosteringDayPatternDeleteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringDayPatternShiftSetCreateArgs = {
  order?: InputMaybe<Scalars['Int']>;
  rosteringDayPatternId: Scalars['Int'];
  rosteringShiftSetId: Scalars['Int'];
};


export type MutationRosteringDayPatternShiftSetDeleteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringDayPatternShiftSetUpdateArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idealQuantity?: InputMaybe<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  minimumQuantity?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringDayTemplateCreateArgs = {
  name: Scalars['String'];
  rosteringDemandTemplateId: Scalars['Int'];
};


export type MutationRosteringDayTemplateDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringDayTemplateUpdateArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationRosteringDayTemplatesActivityUpdateArgs = {
  id: Scalars['Int'];
  order: Scalars['Int'];
};


export type MutationRosteringDemandTemplateCreateArgs = {
  departmentId: Scalars['Int'];
  name: Scalars['String'];
  rosteringDemandTemplateId?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringDemandTemplateUpdateArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationRosteringDemandTemplatesActivityCreateArgs = {
  rosteringDemandTemplateId: Scalars['Int'];
  rosteringOrganisationActivityId: Scalars['Int'];
};


export type MutationRosteringDemandTemplatesActivityDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringDemandTemplatesActivityUpdateArgs = {
  id: Scalars['Int'];
  rosteringDemandTemplateId: Scalars['Int'];
  rosteringOrganisationActivityId: Scalars['Int'];
};


export type MutationRosteringDepartmentListAddWorkersArgs = {
  deptListId: Scalars['Int'];
  orgRegIds: Array<Scalars['Int']>;
};


export type MutationRosteringDepartmentListCreateArgs = {
  departmentId: Scalars['Int'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
};


export type MutationRosteringDepartmentListDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRosteringDepartmentListUpdateArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationRosteringEnqueueAddDayTemplateToRotaArgs = {
  dayTemplateId: Scalars['Int'];
  rotaId: Scalars['Int'];
  startFromDay: Scalars['Int'];
};


export type MutationRosteringEnqueueRosterCreateArgs = {
  departmentListId: Scalars['Int'];
  rotaId: Scalars['Int'];
};


export type MutationRosteringEsrCreateArgs = {
  rosteringOrganisationRegistrationId: Scalars['Int'];
  substantiveEsrNumber: Scalars['String'];
  validFrom: Scalars['String'];
  validTo?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringEsrNumberDeactivateArgs = {
  id: Scalars['ID'];
};


export type MutationRosteringEsrNumberDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringEsrNumberUpdateExpiryDateArgs = {
  id: Scalars['ID'];
  validTo: Scalars['DateTime'];
};


export type MutationRosteringEventAssignBankWorkerToShiftArgs = {
  eventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationRosteringEventAssignWorkerToMultipleShiftsArgs = {
  eventIds: Array<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationRosteringEventAssignWorkerToShiftArgs = {
  eventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationRosteringEventBulkCreateFromServicePlansArgs = {
  rosterBulkCreateShiftEvents: Array<RosterBulkCreateShiftEventInput>;
  rosterUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringEventBulkCreateFromShiftTemplatesArgs = {
  rosterBulkCreateShiftEvents: Array<RosterBulkCreateShiftEventInput>;
  rosterUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringEventChangeScheduleArgs = {
  eventId: Scalars['Int'];
  scheduleId: Scalars['Int'];
};


export type MutationRosteringEventCreateShiftEventArgs = {
  date: Scalars['Date'];
  rosterId: Scalars['Int'];
  rotaShiftSetId: Scalars['Int'];
};


export type MutationRosteringEventCreateShiftEventFromShiftTemplateArgs = {
  activityId?: InputMaybe<Scalars['Int']>;
  date: Scalars['Date'];
  locationId?: InputMaybe<Scalars['Int']>;
  rosterUniqueIdentifier: Scalars['String'];
  shiftTemplateId: Scalars['Int'];
  siteId?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringEventCreateShiftEventWithSiteAndLocationArgs = {
  date: Scalars['Date'];
  locationId?: InputMaybe<Scalars['Int']>;
  rotaShiftSetId: Scalars['Int'];
  siteId?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringEventDeleteMultipleShiftsArgs = {
  eventIds: Array<Scalars['Int']>;
};


export type MutationRosteringEventLockArgs = {
  eventId: Scalars['Int'];
  lock: Scalars['Boolean'];
};


export type MutationRosteringEventMarkShiftAsLeaveArgs = {
  eventId: Scalars['Int'];
  eventTypeEnum?: InputMaybe<RosteringEventTypeEnum>;
  reasonForLeave?: InputMaybe<Scalars['String']>;
  rosteringLeaveEntitlementId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveEpisodeId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveReasonId?: InputMaybe<Scalars['Int']>;
  secondaryLeaveReason?: InputMaybe<Scalars['String']>;
  workRelatedSickness?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRosteringEventUnassignWorkerFromShiftArgs = {
  eventId: Scalars['Int'];
};


export type MutationRosteringEventUnassignWorkersFromShiftsArgs = {
  eventIds: Array<Scalars['Int']>;
};


export type MutationRosteringEventUpdateActivityOnMultipleShiftsArgs = {
  eventIds: Array<Scalars['Int']>;
  organisationActivityId?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringExceptionReportApproveArgs = {
  givenCompensation: ExceptionReportGivenCompensationEnum;
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  timeOffInLieuValue?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringExceptionReportCategoryCreateArgs = {
  allowCompensation: Scalars['Boolean'];
  exceptionReportReasonNames: Array<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationRosteringExceptionReportCategoryDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringExceptionReportCategoryUpdateArgs = {
  allowCompensation?: InputMaybe<Scalars['Boolean']>;
  exceptionReportReasonNames: Array<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationRosteringExceptionReportCreateArgs = {
  breakActualTime?: InputMaybe<Scalars['String']>;
  breakScheduledTime?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  differenceInWorkedHours?: InputMaybe<NoYesEnum>;
  location?: InputMaybe<Scalars['String']>;
  mostSeniorClinician?: InputMaybe<Scalars['String']>;
  mostSeniorClinicianId?: InputMaybe<Scalars['Int']>;
  patientSafetyImpacted?: InputMaybe<NoYesEnum>;
  requestedCompensation?: InputMaybe<ExceptionReportRequestedCompensationEnum>;
  rosteringEventId?: InputMaybe<Scalars['Int']>;
  rosteringExceptionReportReasonIds?: InputMaybe<Array<Scalars['Int']>>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
  shiftActualEndTime?: InputMaybe<Scalars['String']>;
  shiftActualStartTime?: InputMaybe<Scalars['String']>;
  shiftDate?: InputMaybe<Scalars['Date']>;
  shiftScheduledEndTime?: InputMaybe<Scalars['String']>;
  shiftScheduledStartTime?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringExceptionReportDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringExceptionReportDisputeArgs = {
  id: Scalars['Int'];
  note: Scalars['String'];
};


export type MutationRosteringExceptionReportPermissionsSetArgs = {
  allWorkers?: InputMaybe<Scalars['Boolean']>;
  approveCategories?: InputMaybe<Array<ExceptionReportSupervisorCategoryInput>>;
  readonlyCategories?: InputMaybe<Array<ExceptionReportSupervisorCategoryInput>>;
  supervisorId: Scalars['Int'];
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringExceptionReportRejectArgs = {
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringExceptionReportUpdateArgs = {
  breakActualTime?: InputMaybe<Scalars['String']>;
  breakScheduledTime?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  differenceInWorkedHours?: InputMaybe<NoYesEnum>;
  id: Scalars['Int'];
  location?: InputMaybe<Scalars['String']>;
  mostSeniorClinician?: InputMaybe<Scalars['String']>;
  patientSafetyImpacted?: InputMaybe<NoYesEnum>;
  requestedCompensation?: InputMaybe<ExceptionReportRequestedCompensationEnum>;
  rosteringEventId?: InputMaybe<Scalars['Int']>;
  rosteringExceptionReportReasonIds?: InputMaybe<Array<Scalars['Int']>>;
  shiftActualEndTime?: InputMaybe<Scalars['String']>;
  shiftActualStartTime?: InputMaybe<Scalars['String']>;
  shiftDate?: InputMaybe<Scalars['Date']>;
  shiftScheduledEndTime?: InputMaybe<Scalars['String']>;
  shiftScheduledStartTime?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringImportWorkersArgs = {
  workerInputs: Array<CreateWorkerInput>;
};


export type MutationRosteringLeaveEntitlementCreateArgs = {
  accountId: Scalars['Int'];
  endDate: Scalars['Date'];
  name?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
  rosteringLeaveType?: InputMaybe<Scalars['String']>;
  rosteringLeaveTypeId?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  total: Scalars['Float'];
};


export type MutationRosteringLeaveEntitlementDeleteArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringLeaveEntitlementUpdateArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
  total?: InputMaybe<Scalars['Float']>;
};


export type MutationRosteringLeaveGroupCreateArgs = {
  clinicalWorkPercentage?: InputMaybe<Scalars['Float']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  hoursInDay?: InputMaybe<Scalars['Float']>;
  leaveUnitType: Scalars['String'];
  name: Scalars['String'];
  nonClinicalWorkPercentage?: InputMaybe<Scalars['Float']>;
  organisationId: Scalars['Int'];
  paCategories?: InputMaybe<Array<CreateLeavePaCategoryInput>>;
};


export type MutationRosteringLeaveGroupDeleteArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringLeaveGroupEditArgs = {
  clinicalWorkPercentage?: InputMaybe<Scalars['Float']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  hoursInDay?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  leaveUnitType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nonClinicalWorkPercentage?: InputMaybe<Scalars['Float']>;
  organisationId: Scalars['Int'];
  paCategories?: InputMaybe<Array<UpdateLeavePaCategoryInput>>;
};


export type MutationRosteringLeaveReasonCreateArgs = {
  description?: InputMaybe<Scalars['String']>;
  leaveAbsenceCategoryId: Scalars['Int'];
  leaveAbsenceTypeId: Scalars['Int'];
  name: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringLeaveReasonDeleteArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringLeaveReasonEditArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  leaveAbsenceCategoryId: Scalars['Int'];
  leaveAbsenceTypeId: Scalars['Int'];
  name: Scalars['String'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringLeaveRequestActionCancellationRequestArgs = {
  approveCancellation: Scalars['Boolean'];
  leaveRequestApprovalId: Scalars['Int'];
  reasonForCancellationDecision?: InputMaybe<Scalars['String']>;
  reassignShifts?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRosteringLeaveRequestApprovalAcceptArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  reasonForDecision?: InputMaybe<Scalars['String']>;
  rosteringLeaveRequestId: Scalars['Int'];
};


export type MutationRosteringLeaveRequestApprovalRejectArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  reasonForDecision?: InputMaybe<Scalars['String']>;
  rosteringLeaveRequestId: Scalars['Int'];
};


export type MutationRosteringLeaveRequestArchiveArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringLeaveRequestCancelArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  reasonForCancellation?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringLeaveRequestCreateArgs = {
  accountId: Scalars['Int'];
  endDatetime: Scalars['DateTime'];
  organisationId: Scalars['Int'];
  reasonForLeave?: InputMaybe<Scalars['String']>;
  rosteringLeaveEntitlementId: Scalars['Int'];
  rosteringLeaveReasonId?: InputMaybe<Scalars['Int']>;
  startDatetime: Scalars['DateTime'];
};


export type MutationRosteringLeaveRequestCreateFromSuperHubArgs = {
  accountId: Scalars['Int'];
  endDatetime: Scalars['DateTime'];
  organisationId: Scalars['Int'];
  reasonForLeave?: InputMaybe<Scalars['String']>;
  rosteringLeaveEntitlementId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveEpisodeId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveReasonId: Scalars['Int'];
  secondaryLeaveReason?: InputMaybe<Scalars['String']>;
  startDatetime: Scalars['DateTime'];
  workRelatedSickness?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRosteringLeaveRequestEditFromSuperHubArgs = {
  accountId: Scalars['Int'];
  endDatetime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  reasonForLeave?: InputMaybe<Scalars['String']>;
  rosteringLeaveEpisodeId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveReasonId: Scalars['Int'];
  secondaryLeaveReason?: InputMaybe<Scalars['String']>;
  startDatetime?: InputMaybe<Scalars['DateTime']>;
  workRelatedSickness?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRosteringLeaveUpdateRosteringOrgRegLeaveFlowArgs = {
  defaultLeaveFlow: Scalars['Boolean'];
  organisationId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationRosteringOrgRegDeptListDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRosteringOrgRegDeptListMultiDeleteArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationRosteringOrganisationActivityCreateArgs = {
  departmentIds: Array<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  metaActivityId: Scalars['Int'];
  name: Scalars['String'];
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringOrganisationActivityDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringOrganisationActivityUpdateArgs = {
  departmentIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  metaActivityId: Scalars['Int'];
  name: Scalars['String'];
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringOrganisationRegistrationCreateArgs = {
  contract?: InputMaybe<Scalars['Upload']>;
  contractType?: InputMaybe<Scalars['String']>;
  contractedWorkAmount: Scalars['Float'];
  contractedWorkIsHours: Scalars['Boolean'];
  costCentre?: InputMaybe<Scalars['String']>;
  enableRostering: Scalars['Boolean'];
  fixedTerm: Scalars['Boolean'];
  fixedTermEndDate?: InputMaybe<Scalars['String']>;
  gradeId: Scalars['Int'];
  lessThanFullTime: Scalars['Boolean'];
  outOfHours?: InputMaybe<Scalars['Float']>;
  rosteringWorkerProfileId?: InputMaybe<Scalars['Int']>;
  sendActivationLink: Scalars['Boolean'];
  substantiveEsrNumber: Scalars['String'];
  validFrom: Scalars['String'];
  validTo?: InputMaybe<Scalars['String']>;
  wholeTimeEquivalent?: InputMaybe<Scalars['Float']>;
  workedAtOrgInThePastTwelveMonths: Scalars['Boolean'];
  workingArrangement?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringOrganisationRegistrationCreateForBankWorkerArgs = {
  contract?: InputMaybe<Scalars['Upload']>;
  contractType?: InputMaybe<Scalars['String']>;
  contractedWorkAmount: Scalars['Float'];
  contractedWorkIsHours: Scalars['Boolean'];
  costCentre?: InputMaybe<Scalars['String']>;
  fixedTerm: Scalars['Boolean'];
  fixedTermEndDate?: InputMaybe<Scalars['String']>;
  gradeId: Scalars['Int'];
  lessThanFullTime: Scalars['Boolean'];
  outOfHours?: InputMaybe<Scalars['Float']>;
  sendActivationLink: Scalars['Boolean'];
  wholeTimeEquivalent?: InputMaybe<Scalars['Float']>;
  workerId: Scalars['Int'];
  workingArrangement?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringOrganisationRegistrationNoteCreateArgs = {
  note: Scalars['String'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationRosteringOrganisationRegistrationSwitchArgs = {
  enableRostering: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationRosteringOrganisationRegistrationUpdateArgs = {
  contract?: InputMaybe<Scalars['Upload']>;
  contractType?: InputMaybe<Scalars['String']>;
  contractedWorkAmount: Scalars['Float'];
  contractedWorkIsHours: Scalars['Boolean'];
  costCentre?: InputMaybe<Scalars['String']>;
  fixedTerm: Scalars['Boolean'];
  fixedTermEndDate?: InputMaybe<Scalars['String']>;
  gradeId: Scalars['Int'];
  id: Scalars['Int'];
  lessThanFullTime: Scalars['Boolean'];
  outOfHours?: InputMaybe<Scalars['Float']>;
  sendActivationLink: Scalars['Boolean'];
  wholeTimeEquivalent?: InputMaybe<Scalars['Float']>;
  workingArrangement?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringPayRuleArchiveArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringPayRuleCreateArgs = {
  rosteringPayRuleAttributes: RosteringPayRuleCreateInput;
};


export type MutationRosteringPayRuleUpdateArgs = {
  rosteringPayRuleAttributes: RosteringPayRuleUpdateInput;
};


export type MutationRosteringRateCardCreateArgs = {
  rateCardAttributes: CreateRateCardInput;
};


export type MutationRosteringRateCardDeleteArgs = {
  rosteringRateCardId: Scalars['Int'];
};


export type MutationRosteringRateCardUpdateArgs = {
  rateCardAttributes: UpdateRateCardInput;
};


export type MutationRosteringRemoveWorkerFromScheduleArgs = {
  scheduleId: Scalars['Int'];
};


export type MutationRosteringRosterAddActivityArgs = {
  activityId: Scalars['Int'];
  rosterUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringRosterAddScheduleArgs = {
  addScheduleAttributes: RosterAddScheduleInput;
};


export type MutationRosteringRosterCreateArgs = {
  departmentListId: Scalars['Int'];
  rotaId: Scalars['Int'];
};


export type MutationRosteringRosterCreateEmptyArgs = {
  departmentId: Scalars['Int'];
  departmentListId: Scalars['Int'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
};


export type MutationRosteringRosterDeleteArgs = {
  rosterId: Scalars['Int'];
};


export type MutationRosteringRosterDeleteEventArgs = {
  id: Scalars['Int'];
  onlyVacantShifts?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRosteringRosterLockDayArgs = {
  day: Scalars['Date'];
  lock: Scalars['Boolean'];
  rosterUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringRosterPublishArgs = {
  rosterId: Scalars['Int'];
};


export type MutationRosteringRosterRevertToDraftArgs = {
  rosterId: Scalars['Int'];
};


export type MutationRosteringRosterStoreRowOrderForUserArgs = {
  activityViewOrder?: InputMaybe<Scalars['String']>;
  rosterUniqueIdentifier: Scalars['String'];
  workerPatternViewOrder?: InputMaybe<Scalars['String']>;
  workerViewOrder?: InputMaybe<Scalars['String']>;
};


export type MutationRosteringRosterUpdateArgs = {
  departmentListId: Scalars['Int'];
  rosterId: Scalars['Int'];
};


export type MutationRosteringRosterUpdateEventArgs = {
  updateEventAttributes: RosteringUpdateEventInput;
};


export type MutationRosteringRotaCreateArgs = {
  departmentId: Scalars['Int'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  rotaFlowType?: InputMaybe<RotaFlowTypeEnum>;
  startDate: Scalars['Date'];
};


export type MutationRosteringRotaDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringRotaLoadWorkerPatternsArgs = {
  rosteringRotaId: Scalars['Int'];
  workerPatternInputs: Array<RotaWorkerPatternInput>;
};


export type MutationRosteringRotaShiftSetCreateArgs = {
  rosteringRotaDayId: Scalars['Int'];
  rosteringShiftSetId: Scalars['Int'];
};


export type MutationRosteringRotaShiftSetDeleteArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringRotaShiftSetUpdateArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idealQuantity?: InputMaybe<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  minimumQuantity?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringRotaUpdateArgs = {
  id: Scalars['Int'];
  status: Scalars['String'];
};


export type MutationRosteringScheduleAddEventArgs = {
  addEventInput: ScheduleAddEventInput;
};


export type MutationRosteringScheduleAddToPatternViewArgs = {
  numberOfSchedules?: InputMaybe<Scalars['Int']>;
  rosterUniqueIdentifier: Scalars['String'];
  workerPatternOnRosterId?: InputMaybe<Scalars['ID']>;
};


export type MutationRosteringScheduleDeleteArgs = {
  scheduleId: Scalars['Int'];
};


export type MutationRosteringScheduleSetAssignableArgs = {
  scheduleId: Scalars['Int'];
};


export type MutationRosteringScheduleSetUnassignableArgs = {
  scheduleId: Scalars['Int'];
};


export type MutationRosteringScheduleUpdateEndDateArgs = {
  endDate: Scalars['Date'];
  scheduleId: Scalars['Int'];
};


export type MutationRosteringShiftSetCreateArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  idealQuantity: Scalars['Int'];
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  minimumQuantity: Scalars['Int'];
  rosteringDemandTemplatesActivityId: Scalars['Int'];
  rosteringShiftTemplateId: Scalars['Int'];
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  skillsIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringShiftSetDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringShiftSetUpdateArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idealQuantity: Scalars['Int'];
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  minimumQuantity: Scalars['Int'];
  rosteringDemandTemplatesActivityId: Scalars['Int'];
  rosteringShiftTemplateId: Scalars['Int'];
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  skillsIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationRosteringShiftTemplateCreateArgs = {
  endTime: Scalars['String'];
  gradeId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId: Scalars['Int'];
  predictedCoreDurationMinutes?: InputMaybe<Scalars['Int']>;
  predictedUnsocialDurationMinutes?: InputMaybe<Scalars['Int']>;
  rosteringShiftTypeId: Scalars['Int'];
  startTime: Scalars['String'];
};


export type MutationRosteringShiftTemplateDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringShiftTemplateRestoreArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringShiftTemplateUpdateArgs = {
  endTime: Scalars['String'];
  gradeId: Scalars['Int'];
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId: Scalars['Int'];
  predictedCoreDurationMinutes?: InputMaybe<Scalars['Int']>;
  predictedUnsocialDurationMinutes?: InputMaybe<Scalars['Int']>;
  rosteringShiftTypeId: Scalars['Int'];
  startTime: Scalars['String'];
};


export type MutationRosteringSwapsAcceptRequestArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringSwapsCreateRequestArgs = {
  eventToSwapId: Scalars['Int'];
  proposedEventId: Scalars['Int'];
  proposedWorkerId?: InputMaybe<Scalars['Int']>;
};


export type MutationRosteringSwapsRejectRequestArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type MutationRosteringSwapsWorkerAcceptRequestArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringSwapsWorkerRejectRequestArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringTimeOffInLieuUpdateArgs = {
  id: Scalars['Int'];
  taken: Scalars['Boolean'];
};


export type MutationRosteringUpdateDepartmentListPermissionSetsArgs = {
  permissionSetParams: Array<CreateRosteringPermissionSetInput>;
  rosteringDepartmentListId: Scalars['Int'];
};


export type MutationRosteringUpdateRotaPermissionSetsArgs = {
  permissionSetParams: Array<CreateRosteringPermissionSetInput>;
  rosteringRotaId: Scalars['Int'];
};


export type MutationRosteringUploadConfigurationWorkbookArgs = {
  file: Scalars['Upload'];
};


export type MutationRosteringWorkPreferenceCreateArgs = {
  endDate: Scalars['Date'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
  startDate: Scalars['Date'];
  weeklyPreference: RosteringWorkPreferenceWeekInput;
};


export type MutationRosteringWorkPreferenceDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationRosteringWorkPreferenceUpdateArgs = {
  endDate: Scalars['Date'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
  startDate: Scalars['Date'];
  weeklyPreference: RosteringWorkPreferenceWeekInput;
  workPreferenceId: Scalars['Int'];
};


export type MutationRosteringWorkerPatternAddWeekArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerPatternCreateArgs = {
  workerPatternAttributes: WorkerPatternInput;
};


export type MutationRosteringWorkerPatternDeleteArgs = {
  rosteringWorkerPatternId: Scalars['Int'];
};


export type MutationRosteringWorkerPatternDuplicateArgs = {
  fullTime?: InputMaybe<Scalars['Boolean']>;
  fullTimePatternId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  rosteringWorkerPatternId: Scalars['Int'];
  typicalAnnualLeaveEntitlement?: InputMaybe<Scalars['Float']>;
};


export type MutationRosteringWorkerPatternEventTemplateBulkCreateArgs = {
  createEventTemplateInputs?: InputMaybe<Array<CreateEventTemplateInput>>;
  days: Array<Scalars['Int']>;
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerPatternEventTemplateBulkUpdateArgs = {
  updateEventTemplateInputs: Array<UpdateEventTemplateInput>;
};


export type MutationRosteringWorkerPatternEventTemplateDeleteArgs = {
  rosteringWorkerPatternEventTemplateId: Scalars['Int'];
};


export type MutationRosteringWorkerPatternGeneratePayBreakdownArgs = {
  includeOnCallAllowance: Scalars['Boolean'];
  rateCardId: Scalars['ID'];
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerPatternMarkAsCompleteArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerPatternOnRosterUpdateEndDateArgs = {
  endDate: Scalars['Date'];
  workerPatternOnRosterId: Scalars['Int'];
};


export type MutationRosteringWorkerPatternRemoveWeekArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerPatternRevertToDraftArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type MutationRosteringWorkerUpdateGradeArgs = {
  gradeId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationSailSetArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MutationSendEnquiryArgs = {
  message: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationSendResetPasswordInstructionsArgs = {
  email: Scalars['String'];
};


export type MutationSendToAgencyTierArgs = {
  agencyTierId?: InputMaybe<Scalars['Int']>;
  shiftId: Scalars['Int'];
};


export type MutationSetDefaultExternalGradeArgs = {
  id: Scalars['ID'];
};


export type MutationShiftBlockAddShiftsArgs = {
  shiftBlockId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBlockApproveApplicationArgs = {
  shiftBlockApplicationId: Scalars['Int'];
};


export type MutationShiftBlockAssignOrganisationRegistrationArgs = {
  organisationRegistrationId: Scalars['Int'];
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockBulkCancelWorkerArgs = {
  bulkCancelWorkerAttributes: BulkCancelWorkerInput;
  shiftBlockId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBlockCancelWorkerApplicationArgs = {
  reasonForCancellationId?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockCreateArgs = {
  shiftBlockAttributes: ShiftBlockCreateInput;
};


export type MutationShiftBlockCreateOrganisationRegistrationApplicationArgs = {
  organisationRegistrationId: Scalars['Int'];
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockDeleteArgs = {
  reasonForDeletionId?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockMarkSentToAgencyArgs = {
  shiftBlockId: Scalars['Int'];
  tierId: Scalars['Int'];
};


export type MutationShiftBlockPublishArgs = {
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockRecallFromAgencyArgs = {
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockRejectApplicationArgs = {
  shiftBlockApplicationId: Scalars['Int'];
};


export type MutationShiftBlockRemoveShiftsArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  shiftBlockId: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBlockSendBlockToAgencyTierArgs = {
  agencyTierId: Scalars['Int'];
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockUpdateArgs = {
  shiftBlockAttributes: ShiftBlockUpdateInput;
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockUpdateAgencyTierOnBlockArgs = {
  agencyTierId: Scalars['Int'];
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBlockWithdrawWorkerApplicationArgs = {
  organisationRegistrationId: Scalars['Int'];
  reasonForCancellationId?: InputMaybe<Scalars['Int']>;
  shiftBlockApplicationId: Scalars['Int'];
};


export type MutationShiftBlockWithdrawWorkerShiftBlockArgs = {
  reasonForCancellationId?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};


export type MutationShiftBulkApproveArgs = {
  forTimesheet?: InputMaybe<Scalars['Boolean']>;
  paymentApproverNotes?: InputMaybe<Scalars['String']>;
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkDeleteArgs = {
  reasonForDeletionId?: InputMaybe<Scalars['Int']>;
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkRecalculateRatesArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkRecallFromAgencyArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkRemoveRateEscalationArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkRestoreArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkSendToAgencyArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkUndoPaymentApprovalArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftBulkUndoRpaLockArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftDeleteDraftsArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type MutationShiftMarkAsPaidArgs = {
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationShiftRollbackFromPayrollArgs = {
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  regBodyNumber?: InputMaybe<Scalars['String']>;
  staffGroup?: InputMaybe<StaffGroupEnum>;
  termsAccepted?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSuspendAgencyUserArgs = {
  id: Scalars['Int'];
};


export type MutationSuspendWorkerArgs = {
  suspendedAdminNote: Scalars['String'];
  suspendedWorkerNote: Scalars['String'];
  workerId: Scalars['Int'];
};


export type MutationSyncExternalShiftArgs = {
  actualBreakTime?: InputMaybe<Scalars['Int']>;
  actualEndTime?: InputMaybe<Scalars['DateTime']>;
  actualStartTime?: InputMaybe<Scalars['DateTime']>;
  adminNote?: InputMaybe<Scalars['String']>;
  agencyLocked?: InputMaybe<Scalars['Boolean']>;
  agencyTitle?: InputMaybe<Scalars['String']>;
  agencyWorkerLastNameFirstName?: InputMaybe<Scalars['String']>;
  bankWorkerLastNameFirstName?: InputMaybe<Scalars['String']>;
  cancellationComments?: InputMaybe<Scalars['String']>;
  costCentre?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  departmentName?: InputMaybe<Scalars['String']>;
  esrNumber?: InputMaybe<Scalars['String']>;
  externalGrade?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  externalUnit?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeTitle?: InputMaybe<Scalars['String']>;
  historic?: InputMaybe<Scalars['Boolean']>;
  hubId?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  paymentOverrideCostCentreCode?: InputMaybe<Scalars['String']>;
  proposedEndTime?: InputMaybe<Scalars['DateTime']>;
  proposedStartTime?: InputMaybe<Scalars['DateTime']>;
  reasonForCancellation?: InputMaybe<Scalars['String']>;
  reasonForRequest?: InputMaybe<Scalars['String']>;
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  sentToAgency?: InputMaybe<Scalars['Boolean']>;
  signedOff?: InputMaybe<Scalars['Boolean']>;
  siteName?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationTimesheetAuthoriserRequestPinArgs = {
  timesheetAuthoriserId: Scalars['Int'];
};


export type MutationTimesheetAuthoriserRequestPinWithEmailArgs = {
  email: Scalars['String'];
};


export type MutationTimesheetAuthoriserResetPinArgs = {
  pin: Scalars['String'];
  token: Scalars['String'];
};


export type MutationToggleSuperAgencyUserArgs = {
  agencyUserId: Scalars['ID'];
};


export type MutationUndoSuspendWorkerArgs = {
  workerId: Scalars['Int'];
};


export type MutationUnsuspendAgencyUserArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateAgencyArgs = {
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  notificationEmail?: InputMaybe<Scalars['String']>;
  shortTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationUpdateAgencyTierArgs = {
  agencyTierId?: InputMaybe<Scalars['Int']>;
  shiftId: Scalars['Int'];
};


export type MutationUpdateAgencyWorkedTimesArgs = {
  breaks?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  id: Scalars['Int'];
  startTime: Scalars['DateTime'];
};


export type MutationUpdateCollaborativeBankArgs = {
  allowsExternalApplicants?: InputMaybe<Scalars['Boolean']>;
  collaborativeBankStaffGroups?: InputMaybe<Array<UpdateCollaborativeBankStaffGroupInput>>;
  contractText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  leadEmployerOrganisationId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  welcomeEmailBody?: InputMaybe<Scalars['String']>;
  welcomeEmailSubject?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCollaborativeBankStaffGroupArgs = {
  collaborativeBankId: Scalars['Int'];
  id: Scalars['Int'];
  instantBooking?: InputMaybe<Scalars['Boolean']>;
  instantBookingByDefault?: InputMaybe<Scalars['Boolean']>;
  staffGroupId: Scalars['Int'];
};


export type MutationUpdateContactsDepartmentArgs = {
  covidRating?: InputMaybe<Scalars['String']>;
  deptEmail?: InputMaybe<Scalars['String']>;
  deptNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  paymentApproverEmail?: InputMaybe<Scalars['String']>;
  signatureForShiftsSignOff?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCostCentreArgs = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationUpdateDbsNumberArgs = {
  documentId?: InputMaybe<Scalars['Int']>;
  documentNumber?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDepartmentArgs = {
  allowAppSignOff: Scalars['Boolean'];
  allowEarlyAppSignOff: Scalars['Boolean'];
  associatedCostCentreIds?: InputMaybe<Array<Scalars['Int']>>;
  autoApprove: Scalars['Boolean'];
  autoReleaseToSecondaryBank?: InputMaybe<Scalars['Boolean']>;
  canWorkerBookHigherShiftGrade: Scalars['Boolean'];
  canWorkerBookLowerShiftGrade: Scalars['Boolean'];
  costCentreId: Scalars['Int'];
  covidRating?: InputMaybe<Scalars['Int']>;
  cutOffAgency: Scalars['Int'];
  cutOffAlert: Scalars['Int'];
  deptEmail: Scalars['String'];
  deptNumber: Scalars['String'];
  divisionId?: InputMaybe<Scalars['Int']>;
  emailOnBooking: Scalars['Boolean'];
  emailOnCancellation: Scalars['Boolean'];
  emailOnSignOff: Scalars['Boolean'];
  enhanced: Scalars['Boolean'];
  excludeFromRpa?: InputMaybe<Scalars['Boolean']>;
  externalName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  instantBookingForCollabBanks?: InputMaybe<Scalars['Boolean']>;
  lowerGradeSelfBook: Scalars['Boolean'];
  name: Scalars['String'];
  payAtWorkerGradeIfWorkerIsHigherThanShift: Scalars['Boolean'];
  payAtWorkerGradeIfWorkerIsLowerThanShift: Scalars['Boolean'];
  paymentApproverEmail?: InputMaybe<Scalars['String']>;
  positionManagementCode?: InputMaybe<PositionManagementCodeEnum>;
  primarySpecialityId?: InputMaybe<Scalars['Int']>;
  proposalNotifications?: InputMaybe<Scalars['Boolean']>;
  sendToAgencyNotifications?: InputMaybe<Scalars['Boolean']>;
  shiftCancellationNotifications?: InputMaybe<Scalars['Boolean']>;
  shiftReleaseOption?: InputMaybe<Scalars['Int']>;
  shiftReleaseOverride?: InputMaybe<Scalars['Boolean']>;
  signatureForShiftsSignOff?: InputMaybe<Scalars['Boolean']>;
  siteIds: Array<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  timedReleaseDays?: InputMaybe<Scalars['Int']>;
  urgentShiftNotifications?: InputMaybe<Scalars['Boolean']>;
  workerReplacedNotifications?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDivisionArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateDraftShiftArgs = {
  draftShiftAttributes: DraftShiftUpdateInput;
};


export type MutationUpdateEmploymentInfoArgs = {
  dbsNumber?: InputMaybe<Scalars['String']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  niNumber?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationUpdateEsrNumberExpirationDateArgs = {
  id: Scalars['ID'];
  validTo: Scalars['DateTime'];
};


export type MutationUpdateExternalGradeArgs = {
  externalName: Scalars['String'];
  externalType?: InputMaybe<Scalars['String']>;
  gradeId: Scalars['Int'];
  id: Scalars['ID'];
};


export type MutationUpdateExternalOrganisationArgs = {
  active: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationUpdateFilterGradeArgs = {
  id: Scalars['Int'];
  staffGroupId: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationUpdateFiltersArgs = {
  dateType?: InputMaybe<FilterDateEnum>;
  distanceInMiles?: InputMaybe<Scalars['Int']>;
  endsBy?: InputMaybe<Scalars['String']>;
  filterGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postcode?: InputMaybe<Scalars['String']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialtyIds?: InputMaybe<Array<Scalars['Int']>>;
  startsAfter?: InputMaybe<Scalars['String']>;
  timeType?: InputMaybe<FilterTimeEnum>;
};


export type MutationUpdateGradeArgs = {
  grade: UpdateGradeInput;
};


export type MutationUpdateLocationArgs = {
  locationId: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationUpdateOrganisationArgs = {
  description?: InputMaybe<Scalars['String']>;
  fraudDeclarationStatement?: InputMaybe<Scalars['String']>;
  hasPatchworkBank?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isCovidBank?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['Upload']>;
  mspId?: InputMaybe<Scalars['Int']>;
  mspType?: InputMaybe<Scalars['Int']>;
  multipleRateAuthorisers?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organisationType?: InputMaybe<Scalars['Int']>;
  shiftBlockTimesheets?: InputMaybe<Scalars['Boolean']>;
  showDuplicatePaymentRows?: InputMaybe<Scalars['Boolean']>;
  usesAgencySelfbill?: InputMaybe<Scalars['Boolean']>;
  usesCollabBankPpi?: InputMaybe<Scalars['Boolean']>;
  usesCovidRating?: InputMaybe<Scalars['Boolean']>;
  usesFraudDeclarationStatement?: InputMaybe<Scalars['Boolean']>;
  usesNewHub?: InputMaybe<Scalars['Boolean']>;
  usesPinSignOff?: InputMaybe<Scalars['Boolean']>;
  usesPpi?: InputMaybe<Scalars['Boolean']>;
  usesSessional?: InputMaybe<Scalars['Boolean']>;
  usesShiftBlocks?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateOrganisationAgencyArgs = {
  agencyMarginLocked?: InputMaybe<Scalars['Boolean']>;
  agencyTierIds: Array<Scalars['Int']>;
  canAssignWorker?: InputMaybe<Scalars['Boolean']>;
  canEditChargeRate?: InputMaybe<Scalars['Boolean']>;
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  canProposeWorker?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};


export type MutationUpdateOrganisationStaffGroupArgs = {
  agencyEmail?: InputMaybe<Scalars['String']>;
  askQuestion?: InputMaybe<Scalars['Boolean']>;
  autoAddItemWtd?: InputMaybe<Scalars['Int']>;
  autoAddWtd?: InputMaybe<Scalars['Int']>;
  blockLateCancellationsHours?: InputMaybe<Scalars['Int']>;
  calculatePensions?: InputMaybe<Scalars['Boolean']>;
  defaultIr35?: InputMaybe<Ir35Enum>;
  ditDeanery?: InputMaybe<Scalars['String']>;
  ditElementAllowanceType?: InputMaybe<Scalars['String']>;
  emailLateCancellationsHours?: InputMaybe<Scalars['Int']>;
  escalationNotificationEmail?: InputMaybe<Scalars['String']>;
  excludedDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
  excludedGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  mandateDeBookings?: InputMaybe<Scalars['Boolean']>;
  paymentApprovalEnabled?: InputMaybe<Scalars['Boolean']>;
  pushNewVacanciesHours?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  rateNotApplicable?: InputMaybe<Scalars['Boolean']>;
  rateUrl?: InputMaybe<Scalars['String']>;
  rechargeCostCentre?: InputMaybe<Scalars['String']>;
  safeWorkingEnabled?: InputMaybe<Scalars['Boolean']>;
  safeWorkingIntervalHours?: InputMaybe<Scalars['Float']>;
  safeWorkingMaxHours?: InputMaybe<Scalars['Float']>;
  shiftReleaseOption?: InputMaybe<Scalars['Int']>;
  staffBankAgreementForm?: InputMaybe<Scalars['String']>;
  timedReleaseDays?: InputMaybe<Scalars['Int']>;
  tracLink?: InputMaybe<Scalars['String']>;
  tsoEmail: Scalars['String'];
  tsoName: Scalars['String'];
  tsoPhone: Scalars['String'];
  usesSkills?: InputMaybe<Scalars['Boolean']>;
  vms?: InputMaybe<Scalars['Boolean']>;
  wtdPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateProposalShortlistingStatusArgs = {
  className: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateReasonArgs = {
  id: Scalars['Int'];
  reason: Scalars['String'];
};


export type MutationUpdateRefereeArgs = {
  contactNumber?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  organisation: Scalars['String'];
  position: Scalars['String'];
};


export type MutationUpdateRegBodyNumberArgs = {
  regBodyNumber: Scalars['String'];
};


export type MutationUpdateRegulationBodyArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  nameAbbr?: InputMaybe<Scalars['String']>;
  registrationNumberMaxLength?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateSiteArgs = {
  id: Scalars['Int'];
  latitude?: InputMaybe<Scalars['Float']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  longitude?: InputMaybe<Scalars['Float']>;
  mapsUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateSkillArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationUpdateSpecialityArgs = {
  id: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationUpdateStaffGroupArgs = {
  id: Scalars['Int'];
  regulationBodyId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  title: Scalars['String'];
};


export type MutationUpdateSuperAdminArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
};


export type MutationUpdateTempStaffShiftArgs = {
  adminNote?: InputMaybe<Scalars['String']>;
  agencyLocked?: InputMaybe<Scalars['Boolean']>;
  breaks?: InputMaybe<Scalars['Int']>;
  costCentre?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  endDay?: InputMaybe<Scalars['Date']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  ir35?: InputMaybe<Ir35Enum>;
  limitToPreferred?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  proposedEndTime?: InputMaybe<Scalars['String']>;
  proposedStartTime?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Float']>;
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  skillIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startDay?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateVmsAgencyArgs = {
  agencyId: Scalars['Int'];
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  notificationEmail?: InputMaybe<Scalars['String']>;
  organisations: Array<OrganisationInput>;
  shortTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationUpdateVmsAgencyUserArgs = {
  agencyId: Scalars['Int'];
  canCreateAgencyWorkers: Scalars['Boolean'];
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationUpdateWorkerBookingPreferencesArgs = {
  departmentIds: Array<Scalars['Int']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  preferredAtDepartmentIds: Array<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type MutationUpdateWorkerCollaborativeBankArgs = {
  active: Scalars['Boolean'];
  collaborativeBankId: Scalars['Int'];
  instantBooking?: InputMaybe<Scalars['Boolean']>;
  primaryOrganisationId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationUpdateWorkerGradeArgs = {
  gradeId: Scalars['Int'];
  organisationId?: InputMaybe<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type MutationUploadAgenciesCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadCustomRateCardArgs = {
  collaborativeBankStaffGroupId: Scalars['Int'];
  path: Scalars['Upload'];
};


export type MutationUploadProfilePictureArgs = {
  file: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationUserCheckEmailIsLinkedToAccountArgs = {
  context: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUserCreateEssentialInfoArgs = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  ediGenderId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  filterGradeId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  otherEmail?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  regBodyNumber?: InputMaybe<Scalars['String']>;
  regBodyNumberExpirationDate?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId: Scalars['Int'];
};


export type MutationUserUpdateEssentialInfoArgs = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  ediGenderId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  filterGradeId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastName: Scalars['String'];
  niNumber?: InputMaybe<Scalars['String']>;
  otherEmail?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  regBodyNumber?: InputMaybe<Scalars['String']>;
  regBodyNumberExpirationDate?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId: Scalars['Int'];
};


export type MutationValidatePayloadForAssignWorkerArgs = {
  rosterId?: InputMaybe<Scalars['Int']>;
  rosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationValidateWorkerShiftsArgs = {
  rosterId?: InputMaybe<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type MutationVmsAgencyInvitationArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationVmsAgencyRegistrationDeleteAgencyRegistrationArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationVmsAgencyRegistrationDeleteUploadedFileArgs = {
  agencyRegistrationId: Scalars['Int'];
  uploadType: AgencyRegistrationFileUploadEnum;
};


export type MutationVmsAgencyRegistrationDestroyAgencyRegistrationArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationVmsAgencyRegistrationRecoverAgencyRegistrationArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationVmsAgencyRegistrationRegisterAgencyWorkerArgs = {
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressTown?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyNumber?: InputMaybe<Scalars['String']>;
  companyVatNumber?: InputMaybe<Scalars['String']>;
  complianceExpirationDate?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  dbsNumber: Scalars['String'];
  email: Scalars['String'];
  employmentType: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['Int']>;
  gradeId: Scalars['Int'];
  isSoleTrader?: InputMaybe<Scalars['Boolean']>;
  isVatRegistered?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  niNumber: Scalars['String'];
  paymentType: Scalars['String'];
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  regBodyNumber: Scalars['String'];
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId: Scalars['Int'];
};


export type MutationVmsAgencyRegistrationUpdateAgencyWorkerArgs = {
  companyAddressLine1?: InputMaybe<Scalars['String']>;
  companyAddressLine2?: InputMaybe<Scalars['String']>;
  companyAddressPostcode?: InputMaybe<Scalars['String']>;
  companyAddressTown?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyNumber?: InputMaybe<Scalars['String']>;
  companyVatNumber?: InputMaybe<Scalars['String']>;
  complianceExpirationDate?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  dbsNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isSoleTrader?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  niNumber?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  regBodyNumber?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationVmsAgencyRegistrationUploadCompliancePackArgs = {
  agencyRegistrationId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationVmsAgencyRegistrationUploadCvArgs = {
  agencyRegistrationId: Scalars['Int'];
  file: Scalars['Upload'];
};


export type MutationVmsAgencyUserCreateArgs = {
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationVmsAgencyUserPermissionCreateArgs = {
  agencyUserId: Scalars['Int'];
  canAssignWorker: Scalars['Boolean'];
  canProposeWorker: Scalars['Boolean'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationVmsAgencyUserPermissionDestroyArgs = {
  id: Scalars['Int'];
};


export type MutationVmsAgencyUserPermissionUpdateArgs = {
  canAssignWorker?: InputMaybe<Scalars['Boolean']>;
  canProposeWorker: Scalars['Boolean'];
  id: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
};


export type MutationVmsAgencyUserSuspendArgs = {
  id: Scalars['Int'];
};


export type MutationVmsAgencyUserSwitchAgencyArgs = {
  agencyId: Scalars['Int'];
};


export type MutationVmsAgencyUserUnsuspendArgs = {
  id: Scalars['Int'];
};


export type MutationVmsAgencyUserUpdateArgs = {
  canManageUsers: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  organisations?: InputMaybe<Array<OrganisationInput>>;
  phoneNumber: Scalars['String'];
};


export type MutationVmsAgencyWorkerCreateArgs = {
  cv?: InputMaybe<Scalars['Upload']>;
  firstName: Scalars['String'];
  gradeId: Scalars['Int'];
  lastName: Scalars['String'];
  niNumber: Scalars['String'];
  regBodyNumber: Scalars['String'];
  staffGroupId: Scalars['Int'];
};


export type MutationVmsAgencyWorkerProposalCreateArgs = {
  agencyWorkerId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  coreAgencyMargin?: InputMaybe<Scalars['Float']>;
  coreLocumRate?: InputMaybe<Scalars['Float']>;
  coreNiContribution?: InputMaybe<Scalars['Float']>;
  coreTotalRate?: InputMaybe<Scalars['Float']>;
  directEngagement?: InputMaybe<Scalars['Boolean']>;
  poNumber?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  requisitionNumber?: InputMaybe<Scalars['String']>;
  shiftId: Scalars['Int'];
  shiftPayElements?: InputMaybe<Array<InputPayElement>>;
  splitRate?: InputMaybe<Scalars['Boolean']>;
  totalComparatorRate?: InputMaybe<Scalars['Float']>;
  totalMargin?: InputMaybe<Scalars['Float']>;
  unsocialAgencyMargin?: InputMaybe<Scalars['Float']>;
  unsocialLocumRate?: InputMaybe<Scalars['Float']>;
  unsocialNiContribution?: InputMaybe<Scalars['Float']>;
  unsocialTotalRate?: InputMaybe<Scalars['Float']>;
};


export type MutationVmsAgencyWorkerProposalDeleteArgs = {
  agencyWorkerProposalId: Scalars['Int'];
};


export type MutationVmsAgencyWorkerUpdateArgs = {
  cv?: InputMaybe<Scalars['Upload']>;
  firstName: Scalars['String'];
  gradeId: Scalars['Int'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  niNumber: Scalars['String'];
  regBodyNumber: Scalars['String'];
  staffGroupId: Scalars['Int'];
};


export type MutationVmsShiftAssignAgencyRegistrationArgs = {
  agencyRegistrationId: Scalars['Int'];
  shiftId: Scalars['Int'];
  shiftPayElements?: InputMaybe<Array<InputPayElement>>;
  totalComparatorRate?: InputMaybe<Scalars['Float']>;
  totalMargin?: InputMaybe<Scalars['Float']>;
};


export type MutationVmsShiftAssignAgencyWorkerArgs = {
  agencyWorkerId: Scalars['Int'];
  comment: Scalars['String'];
  coreAgencyMargin: Scalars['Float'];
  coreLocumRate: Scalars['Float'];
  coreNiContribution: Scalars['Float'];
  coreTotalRate: Scalars['Float'];
  directEngagement: Scalars['Boolean'];
  poNumber: Scalars['String'];
  receiptNumber: Scalars['String'];
  requisitionNumber: Scalars['String'];
  shiftId: Scalars['Int'];
  splitRate: Scalars['Boolean'];
  unsocialAgencyMargin?: InputMaybe<Scalars['Float']>;
  unsocialLocumRate?: InputMaybe<Scalars['Float']>;
  unsocialNiContribution?: InputMaybe<Scalars['Float']>;
  unsocialTotalRate?: InputMaybe<Scalars['Float']>;
};


export type MutationVmsShiftAssignProposedAgencyWorkerArgs = {
  agencyWorkerProposalId: Scalars['Int'];
};


export type MutationVmsShiftBlockCancelAgencyBookingArgs = {
  cancelAgencyBookingAttributes: CancelAgencyBookingAttributes;
};


export type MutationVmsShiftBlockProposalAcceptArgs = {
  agencyWorkerBlockProposalId: Scalars['Int'];
  deContractVersionId?: InputMaybe<Scalars['Int']>;
};


export type MutationVmsShiftBlockProposalCreateArgs = {
  agencyWorkerProposalAttributes: ProposalCreate;
};


export type MutationVmsShiftBlockProposalRejectArgs = {
  agencyWorkerBlockProposalId: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationVmsShiftBlockProposalRevokeArgs = {
  agencyWorkerBlockProposalId: Scalars['Int'];
};


export type MutationVmsShiftCancelAgencyWorkerArgs = {
  reason: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationVmsSignupArgs = {
  niNumber: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationVmsWorkerConfirmRegistrationArgs = {
  agencyRegistrationId: Scalars['Int'];
};


export type MutationWithdrawApplicationArgs = {
  shiftId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type MutationWithdrawRateChangeArgs = {
  rateChangeId: Scalars['Int'];
};


export type MutationWithdrawWorkerArgs = {
  reasonForCancellationId: Scalars['Int'];
  shiftId: Scalars['Int'];
};


export type MutationWorkerOnboardingUpdateProfileArgs = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  dbsNumber?: InputMaybe<Scalars['String']>;
  ediConsent?: InputMaybe<Scalars['Boolean']>;
  ediCountryOfQualificationId?: InputMaybe<Scalars['Int']>;
  ediDisabilityId?: InputMaybe<Scalars['Int']>;
  ediEthnicityId?: InputMaybe<Scalars['Int']>;
  ediGenderId?: InputMaybe<Scalars['Int']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  niNumber?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  regBodyNumber?: InputMaybe<Scalars['String']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type MutationWorkerPasswordResetRequestOneTimeCodeArgs = {
  email: Scalars['String'];
};


export type MutationWorkerPasswordResetWithOneTimeCodeArgs = {
  email: Scalars['String'];
  oneTimeCode: Scalars['String'];
  password: Scalars['String'];
};


export type MutationWorkerRegistrationCreateAccountArgs = {
  email: Scalars['String'];
  keycloakActive?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  termsAccepted: Scalars['Boolean'];
};


export type MutationWorkerRegistrationResendOneTimeCodeArgs = {
  email: Scalars['String'];
};


export type MutationWorkerRegistrationValidateOneTimeCodeArgs = {
  email: Scalars['String'];
  oneTimeCode: Scalars['String'];
};


export type MutationWorkerResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationWorkerSignOffArgs = {
  shifts: Array<InputShift>;
  signOffAuthoriserId: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationWorkerSignOffCreateArgs = {
  content: Scalars['String'];
  emails: Array<Scalars['String']>;
  user: Scalars['String'];
};


export type MutationWorkerSignOffRequestedArgs = {
  reportedBreaks: Scalars['Int'];
  reportedEndTime: Scalars['String'];
  reportedNotes?: InputMaybe<Scalars['String']>;
  reportedStartTime: Scalars['String'];
  shiftId: Scalars['Int'];
};


export type MutationWorkerSignOffRequestedMultipleArgs = {
  shifts: Array<InputMultipleShift>;
};


export type MutationWorkerSignOffRequestedMultipleOnCallArgs = {
  shifts: Array<InputMultipleOnCallShift>;
};


export type MutationWorkerSignOffWithPinArgs = {
  pin: Scalars['String'];
  shifts: Array<InputShift>;
  signOffAuthoriserId: Scalars['Int'];
};


export type MutationWorkerUpdateNiAndOrDobArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  niNumber?: InputMaybe<Scalars['String']>;
};


export type MutationWorkerUpdateNotificationSettingsArgs = {
  documentExpiryNotifications: Scalars['Boolean'];
  urgentNotifications?: InputMaybe<Scalars['Int']>;
  urgentShiftNotifications?: InputMaybe<Scalars['Boolean']>;
  vacancyNotifications: VacantNotificationEnum;
};


export type MutationWorkerUpdatePasswordArgs = {
  password?: InputMaybe<Scalars['String']>;
  passwordConfirmation?: InputMaybe<Scalars['String']>;
};


export type MutationWorkerUpdateProfileArgs = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  ediConsent?: InputMaybe<Scalars['Boolean']>;
  ediCountryOfQualificationId?: InputMaybe<Scalars['Int']>;
  ediDisabilityId?: InputMaybe<Scalars['Int']>;
  ediEthnicityId?: InputMaybe<Scalars['Int']>;
  ediGenderId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  firstName: Scalars['String'];
  gender?: InputMaybe<GenderEnum>;
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  regBodyNumber?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId: Scalars['Int'];
};


export type MutationWorkerUpdateSkillsArgs = {
  organisationId?: InputMaybe<Scalars['Int']>;
  skillIds: Array<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type MutationWorkerUpdateSpecialitiesArgs = {
  specialityIds: Array<Scalars['Int']>;
};

/** Night shift information: rate, start time, end time */
export type NightShiftInformation = {
  __typename?: 'NightShiftInformation';
  /** night shift end time */
  endTime: Scalars['String'];
  /** percentage uplift on basic hourly rate for unsocial hours worked */
  rate: Scalars['Float'];
  /** night shift start time */
  startTime: Scalars['String'];
};

/** Describes a simple no/yes enum */
export enum NoYesEnum {
  No = 'no',
  Yes = 'yes'
}

export type NonAuthorisedAgencyUser = {
  __typename?: 'NonAuthorisedAgencyUser';
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type NonAuthorisedHubUser = {
  __typename?: 'NonAuthorisedHubUser';
  hubUser?: Maybe<HubUser>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type NonAuthorisedUser = {
  __typename?: 'NonAuthorisedUser';
  agencyUser?: Maybe<AgencyUser>;
  refreshToken?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Annual on-call allowance according to Nodal Point */
export type OnCallAllowanceByNodalPoint = {
  __typename?: 'OnCallAllowanceByNodalPoint';
  /** Annual on-call allowance for a Doctor on Nodal Point 1 */
  nodalPoint1: Scalars['Float'];
  /** Annual on-call allowance for a Doctor on Nodal Point 2 */
  nodalPoint2: Scalars['Float'];
  /** Annual on-call allowance for a Doctor on Nodal Point 3 */
  nodalPoint3: Scalars['Float'];
  /** Annual on-call allowance for a Doctor on Nodal Point 4 */
  nodalPoint4: Scalars['Float'];
  /** Annual on-call allowance for a Doctor on Nodal Point 5 */
  nodalPoint5: Scalars['Float'];
};

export type OnCallShiftRate = {
  __typename?: 'OnCallShiftRate';
  basicRate: Scalars['Float'];
  benefit: Scalars['Float'];
  breakMinutes: Scalars['Float'];
  hours: Scalars['Float'];
  paymentType: Scalars['String'];
  totalPay: Scalars['Float'];
  totalRate: Scalars['Float'];
  units: Scalars['Float'];
};

/** On call rates preview */
export type OnCallShiftRatePreview = {
  __typename?: 'OnCallShiftRatePreview';
  /** Error messages that have been raised */
  errors: Array<Scalars['String']>;
  /** List of payment objects */
  preview?: Maybe<Array<OnCallShiftRate>>;
  /** ID of shift used to generate preview */
  shiftId: Scalars['Int'];
  /** Indicates when calculation is successful */
  success: Scalars['Boolean'];
};

/** Timeframe object to get for on-call shifts */
export type OnCallShiftTimeframe = {
  __typename?: 'OnCallShiftTimeframe';
  /** Number of minutes of breaks */
  reportedBreaks: Scalars['Int'];
  /** End datetime of the shift timeframe was submitted for */
  reportedEndTime: Scalars['String'];
  /** Start datetime of the shift timeframe was submitted for */
  reportedStartTime: Scalars['String'];
};

export type OnCallTimeframe = {
  /** The ID of the adjustment */
  id?: InputMaybe<Scalars['ID']>;
  /** Breaks length */
  reportedBreaks: Scalars['Int'];
  /** End date of the timeslot */
  reportedEndTime: Scalars['String'];
  /** Start date of the timeslot */
  reportedStartTime: Scalars['String'];
};

/** OrderBy Input used by frontend to define order of records */
export type OrderByInput = {
  /** Direction of ordering, ascending or descending */
  direction: OrderDirectionEnum;
  /** Field that we want to order by */
  field: Scalars['String'];
};

/** Order direction - Ascending or Descending */
export enum OrderDirectionEnum {
  /** Order direction is ascending */
  Asc = 'ASC',
  /** Order direction is descending */
  Desc = 'DESC'
}

export type OrgStaffGroupSkill = {
  __typename?: 'OrgStaffGroupSkill';
  id: Scalars['Int'];
  organisationStaffGroup: OrganisationStaffGroup;
  skill: Skill;
};

export type Organisation = {
  __typename?: 'Organisation';
  /** Unique abbreviation for the organisation */
  abbreviation?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  agencies: Array<Agency>;
  /** @deprecated Moved to organisation staff group */
  agencyEmail?: Maybe<Scalars['String']>;
  allowsExternalApplicants: Scalars['Boolean'];
  /** @deprecated Moved to organisation staff group */
  askQuestion: Scalars['Boolean'];
  authorisationLevels?: Maybe<Array<AuthorisationLevel>>;
  autoApproveApplication: Scalars['Boolean'];
  canBePrimaryTrustInCollaborativeBank: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  collaborativeBank?: Maybe<CollaborativeBank>;
  costCentres: Array<CostCentre>;
  county?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  departments: Array<Department>;
  /** @deprecated Use departments directly */
  departmentsCount: Scalars['Int'];
  /** Pagination Object for departments, must be called after departments */
  departmentsPagination?: Maybe<Pagination>;
  description?: Maybe<Scalars['String']>;
  divisions?: Maybe<Array<Division>>;
  /** Allows users to upload rate card */
  enabledRateCardUpload: Scalars['Boolean'];
  escalationLevels?: Maybe<Array<Level>>;
  externalUnits: Array<ExternalUnit>;
  fraudDeclarationStatement?: Maybe<Scalars['String']>;
  grades?: Maybe<Array<Grade>>;
  /** @deprecated Use grades directly */
  gradesCount: Scalars['Int'];
  hideDeLtdAgencyWorkers: Scalars['Boolean'];
  id: Scalars['Int'];
  leadEmployer: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  /** ID of the agency which is MSP */
  mspId?: Maybe<Scalars['Int']>;
  mspUserType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onlySam: Scalars['Boolean'];
  organisationComplianceDocuments?: Maybe<Array<OrganisationComplianceDocument>>;
  organisationPreferences?: Maybe<OrganisationPreferences>;
  organisationStaffGroups: Array<OrganisationStaffGroup>;
  organisationType?: Maybe<OrganisationType>;
  patchworkBank: Scalars['Boolean'];
  /** @deprecated returns number of days remaining */
  pinGracePeriodRemaining?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
  /** @deprecated Moved to organisation staff group */
  question?: Maybe<Scalars['String']>;
  requiresAgencyCompliance: Scalars['Boolean'];
  rosteringExceptionReportReasonCategories?: Maybe<Array<RosteringExceptionReportReasonCategory>>;
  rpa: Scalars['Boolean'];
  shifts: Array<Shift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination?: Maybe<Pagination>;
  sites?: Maybe<Array<Site>>;
  /** @deprecated Moved to organisation staff group */
  staffBankAgreementForm?: Maybe<Scalars['String']>;
  /** @deprecated Moved to organisation staff group */
  tsoEmail?: Maybe<Scalars['String']>;
  /** @deprecated Moved to organisation staff group */
  tsoName?: Maybe<Scalars['String']>;
  /** @deprecated Moved to organisation staff group */
  tsoPhone?: Maybe<Scalars['String']>;
  usesCollabBankPpi: Scalars['Boolean'];
  usesDeSoleTrader: Scalars['Boolean'];
  usesExpenses: Scalars['Boolean'];
  usesNewHub: Scalars['Boolean'];
  usesPpi: Scalars['Boolean'];
  usesRateEscalationInsights: Scalars['Boolean'];
  usesSkills: Scalars['Boolean'];
  usesTwoStepPaymentApproval: Scalars['Boolean'];
  vmsStaffGroups: Scalars['Boolean'];
};


export type OrganisationAgenciesArgs = {
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type OrganisationDepartmentsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type OrganisationShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Graphql Organisation Agency model response */
export type OrganisationAgency = {
  __typename?: 'OrganisationAgency';
  /** Associated Agency */
  agency?: Maybe<Agency>;
  /** Toggle for margin being locked or not */
  agencyMarginLocked: Scalars['Boolean'];
  /** Associated Agency Tier */
  agencyTiers?: Maybe<Array<AgencyTier>>;
  /** Permission to assign worker */
  canAssignWorker: Scalars['Boolean'];
  /** Permission to edit charge rates */
  canEditChargeRate: Scalars['Boolean'];
  /** Permission to propose workers */
  canProposeWorker: Scalars['Boolean'];
  /** ID of model */
  id: Scalars['ID'];
  /** Associated Organisation */
  organisation?: Maybe<Organisation>;
  /** Organisation ID */
  organisationId: Scalars['Int'];
  /** Associated OrganisationStaffGroup model */
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
};

export type OrganisationComplianceDocument = {
  __typename?: 'OrganisationComplianceDocument';
  complianceDocumentType: ComplianceDocumentType;
  id: Scalars['Int'];
  organisation: Organisation;
  organisationComplianceDocumentsStaffGroups?: Maybe<Array<OrganisationComplianceDocumentStaffGroup>>;
  renewalPeriod?: Maybe<Scalars['Int']>;
};

export type OrganisationComplianceDocumentStaffGroup = {
  __typename?: 'OrganisationComplianceDocumentStaffGroup';
  organisationComplianceDocumentId: Scalars['Int'];
  staffGroup: StaffGroup;
  staffGroupId: Scalars['Int'];
};

/** Input type for use as part of complex form */
export type OrganisationInput = {
  /** ID of organisation */
  id: Scalars['ID'];
  /** Organisation staff group input from user */
  organisationStaffGroups?: InputMaybe<Array<OrganisationStaffGroupInput>>;
  /** Ability to switch org permission */
  toggleOrgPermission?: InputMaybe<Scalars['Boolean']>;
};

/** OrganisationLockConfig */
export type OrganisationLockConfig = {
  __typename?: 'OrganisationLockConfig';
  /** ID of the organisation lock config */
  id: Scalars['Int'];
  /** The day of the month when organisation resources are locked */
  lockDay: Scalars['Int'];
  /** Organisation ID */
  organisationId: Scalars['Int'];
};

export type OrganisationPreferences = {
  __typename?: 'OrganisationPreferences';
  hasPatchworkBank: Scalars['Boolean'];
  hideDeLtdAgencyWorkers: Scalars['Boolean'];
  hideInstantBooking: Scalars['Boolean'];
  hub3BlocksUrlsDisabled: Scalars['Boolean'];
  hub3PaymentsPageUrlsDisabled: Scalars['Boolean'];
  hub3ShiftListUrlsEnabled: Scalars['Boolean'];
  hub3ShiftPageEnabled: Scalars['Boolean'];
  hub3ShiftUrlsEnabled: Scalars['Boolean'];
  id: Scalars['Int'];
  isCovidBank: Scalars['Boolean'];
  multipleRateAuthorisers: Scalars['Boolean'];
  requiresAgencyCompliance: Scalars['Boolean'];
  shiftBlockTimesheets?: Maybe<Scalars['Boolean']>;
  usesAdvancedRateEscalationFlow: Scalars['Boolean'];
  usesAutoCascade: Scalars['Boolean'];
  usesBankRateEscalation: Scalars['Boolean'];
  usesCollabBankPpi: Scalars['Boolean'];
  usesComplianceDocuments: Scalars['Boolean'];
  usesCovidRating?: Maybe<Scalars['Boolean']>;
  usesDeContracts: Scalars['Boolean'];
  usesDeSoleTrader: Scalars['Boolean'];
  usesExpenses: Scalars['Boolean'];
  usesFraudDeclarationStatement: Scalars['Boolean'];
  usesMspShortlisting: Scalars['Boolean'];
  usesOnCallShifts: Scalars['Boolean'];
  usesPinSignOff: Scalars['Boolean'];
  usesPpi: Scalars['Boolean'];
  usesRateEscalationInsights: Scalars['Boolean'];
  usesRequestRate: Scalars['Boolean'];
  usesSessional: Scalars['Boolean'];
  usesSetEscalationLevels: Scalars['Boolean'];
  usesShiftBlocks?: Maybe<Scalars['Boolean']>;
  usesSmartRateEscalationGuidance: Scalars['Boolean'];
};

export type OrganisationRegistration = {
  __typename?: 'OrganisationRegistration';
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  esrNumber?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['Int'];
  onboardingAnswer?: Maybe<Scalars['String']>;
  organisation: Organisation;
  /** Legacy method that returns the last organisation registration note */
  organisationRegistrationNote?: Maybe<OrganisationRegistrationNote>;
  organisationRegistrationNotes?: Maybe<Array<OrganisationRegistrationNote>>;
  requiresActivation: Scalars['Boolean'];
  skills: Array<Skill>;
  suspendedAdminNote?: Maybe<Scalars['String']>;
  suspendedOn?: Maybe<Scalars['DateTime']>;
  suspendedWorkerNote?: Maybe<Scalars['String']>;
  user: User;
};

export type OrganisationRegistrationNote = {
  __typename?: 'OrganisationRegistrationNote';
  createdAt?: Maybe<Scalars['DateTime']>;
  deleted: Scalars['Boolean'];
  hubUser?: Maybe<HubUser>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Graphql response type for Organisation staff group model */
export type OrganisationStaffGroup = {
  __typename?: 'OrganisationStaffGroup';
  /** Agency email */
  agencyEmail?: Maybe<Scalars['String']>;
  /** Agency tiers list */
  agencyTiers?: Maybe<Array<AgencyTier>>;
  /** Permission to ask a question */
  askQuestion: Scalars['Boolean'];
  /** Automatically add working time directive to items */
  autoAddItemWtd?: Maybe<Scalars['Int']>;
  /** Automatically add working time directive to shifts */
  autoAddWtd?: Maybe<Scalars['Int']>;
  /** Late cancellation hour */
  blockLateCancellationsHours?: Maybe<Scalars['Int']>;
  /** Calculate pensions */
  calculatePensions: Scalars['Boolean'];
  /** Timestamp when model was created */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Default agency tier */
  defaultAgencyTier?: Maybe<AgencyTier>;
  /** Default IR35 status */
  defaultIr35?: Maybe<Ir35Enum>;
  /** Deanery abbreviation */
  ditDeanery?: Maybe<Scalars['String']>;
  /** Element allowance */
  ditElementAllowanceType?: Maybe<Scalars['String']>;
  /** Numuber of late cancellation hours */
  emailLateCancellationsHours?: Maybe<Scalars['Int']>;
  /** Enforces a minimum break duration */
  enforcesBreakDuration: Scalars['Boolean'];
  /** Email to send escalations to */
  escalationNotificationEmail?: Maybe<Scalars['String']>;
  /** Grades associated with organisation staff group */
  grades?: Maybe<Array<Grade>>;
  /** Helper that sums up the number associated grades */
  gradesCount?: Maybe<Scalars['Int']>;
  /** ID of model */
  id: Scalars['ID'];
  /** If should ignore working time directive percentage */
  ignoreItemWtd?: Maybe<Scalars['Boolean']>;
  /** Mandating DE agency bookings */
  mandateDeBookings?: Maybe<Scalars['Boolean']>;
  /** Associated organisation */
  organisation?: Maybe<Organisation>;
  /** Toggle on/off payment approval */
  paymentApprovalEnabled: Scalars['Boolean'];
  pushNewVacanciesHours?: Maybe<Scalars['Int']>;
  /** Question to be filed for organisation staff group */
  question?: Maybe<Scalars['String']>;
  /** Toggle for whether rate is applicable */
  rateNotApplicable?: Maybe<Scalars['Boolean']>;
  /** Url for the rate */
  rateUrl?: Maybe<Scalars['String']>;
  /** Cost centre number and name for NHS */
  rechargeCostCentre?: Maybe<Scalars['String']>;
  /** Toggle to switch safe working on/off */
  safeWorkingEnabled: Scalars['Boolean'];
  /** Time between last shift to resume work */
  safeWorkingIntervalHours?: Maybe<Scalars['Int']>;
  /** Maximum safe working hours */
  safeWorkingMaxHours?: Maybe<Scalars['Int']>;
  /** Shift release option */
  shiftReleaseOption?: Maybe<ShiftReleaseOptionEnum>;
  /** Associated skills list */
  skills?: Maybe<Array<Skill>>;
  /** Staff bank agreement form */
  staffBankAgreementForm?: Maybe<Scalars['String']>;
  /** Associated staff group */
  staffGroup?: Maybe<StaffGroup>;
  /** Timed release days */
  timedReleaseDays?: Maybe<Scalars['Int']>;
  /** Staff group title */
  title?: Maybe<Scalars['String']>;
  tracLink?: Maybe<Scalars['String']>;
  /** Temporary staffing office email address */
  tsoEmail?: Maybe<Scalars['String']>;
  /** Temporary staffing office name */
  tsoName?: Maybe<Scalars['String']>;
  /** Temporary staffing ofice phone */
  tsoPhone?: Maybe<Scalars['String']>;
  usesSkills?: Maybe<Scalars['Boolean']>;
  /** Uses associated skills switch */
  vms?: Maybe<Scalars['Boolean']>;
  /** Working time directive percentage */
  wtdPercentage?: Maybe<Scalars['Float']>;
};

/** The connection type for OrganisationStaffGroup. */
export type OrganisationStaffGroupConnection = {
  __typename?: 'OrganisationStaffGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganisationStaffGroupEdge>>>;
  /** A list of nodes. */
  nodes: Array<OrganisationStaffGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganisationStaffGroupEdge = {
  __typename?: 'OrganisationStaffGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganisationStaffGroup>;
};

/** Organisation staff group input for use in complex frontend form. */
export type OrganisationStaffGroupInput = {
  /** Agency margin locked? */
  agencyMarginLocked?: InputMaybe<Scalars['Boolean']>;
  /** AgencyTier ID */
  agencyTierIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Can propose Agency Worker? */
  canProposeWorker?: InputMaybe<Scalars['Boolean']>;
  /** OrganisationAgency ID */
  organisationAgencyId?: InputMaybe<Scalars['Int']>;
  /** OrganisationStaffGroup ID */
  organisationStaffGroupId: Scalars['Int'];
};

/** Represents the Organisation type */
export type OrganisationType = {
  __typename?: 'OrganisationType';
  /** Database ID of the Organisation type */
  id: Scalars['Int'];
  /** Organisation type name */
  name?: Maybe<Scalars['String']>;
  /** Index order for displaying purposes */
  order?: Maybe<Scalars['Int']>;
};

export type OrganisationWorker = {
  __typename?: 'OrganisationWorker';
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  agencyRegistrations?: Maybe<Array<AgencyRegistration>>;
  als: Array<Document>;
  appliedShifts: Array<WorkerShift>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bls: Array<Document>;
  bookedShifts: Array<WorkerShift>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['String']>;
  dateRegistered?: Maybe<Scalars['DateTime']>;
  dbs: Array<Document>;
  dbsNumber?: Maybe<Scalars['String']>;
  departments: Array<Department>;
  /** Whether the user has consented to EDI data collection */
  ediConsent?: Maybe<Scalars['Boolean']>;
  /** Edi country of qualification */
  ediCountryOfQualification?: Maybe<CountryOfQualification>;
  /** The disability for EDI data */
  ediDisability?: Maybe<Disability>;
  /** The ethnicity for EDI data */
  ediEthnicity?: Maybe<Ethnicity>;
  /** The gender for EDI data */
  ediGender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  esrNumber?: Maybe<Scalars['String']>;
  esrNumberModels?: Maybe<Array<EsrNumber>>;
  filterGrade?: Maybe<FilterGrade>;
  fireSafety: Array<Document>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<GenderEnum>;
  grade?: Maybe<Grade>;
  grades: Array<Grade>;
  gradesForOrganisation?: Maybe<Array<Grade>>;
  id: Scalars['ID'];
  informationGovernance: Array<Document>;
  instantBookDepartments?: Maybe<Array<Department>>;
  intercomKey?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  latestEsrNumberModel?: Maybe<EsrNumber>;
  latestRosteringEsrNumberModel?: Maybe<RosteringEsrNumber>;
  libraryItems: Array<LibraryItem>;
  manualHandling: Array<Document>;
  niNumber?: Maybe<Scalars['String']>;
  numberOfShiftsWorked?: Maybe<Scalars['Int']>;
  numberOfSuspendedOrgRegistrations?: Maybe<Scalars['Int']>;
  occupationalHealth: Array<Document>;
  onboardingProfileComplete: Scalars['Boolean'];
  onlySecondaryBank?: Maybe<Scalars['Boolean']>;
  orderedAuditUserEvents?: Maybe<Array<AuditUserEvent>>;
  organisation?: Maybe<Organisation>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
  organisationRegistrationNotes?: Maybe<Array<OrganisationRegistrationNote>>;
  organisationRegistrations: Array<OrganisationRegistration>;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  organisations?: Maybe<Array<Organisation>>;
  other: Array<Document>;
  otherEmail?: Maybe<Scalars['String']>;
  passportInvitations: Array<PassportInvitation>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  preferences?: Maybe<UserPreferences>;
  preferredAtDepartments?: Maybe<Array<Department>>;
  preferredName?: Maybe<Scalars['String']>;
  previousEsrs?: Maybe<Array<EsrNumber>>;
  previousRosteringEsrs?: Maybe<Array<RosteringEsrNumber>>;
  primaryOrSecondary?: Maybe<Scalars['String']>;
  primaryQualifications: Array<Document>;
  profileComplete: Scalars['Boolean'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  proofOfAddress: Array<Document>;
  /** Has the user provided EDI data */
  providedEdiData: Scalars['Boolean'];
  referees: Array<Referee>;
  regBodyNumber?: Maybe<Scalars['String']>;
  regBodyNumberExpirationDate?: Maybe<Scalars['Date']>;
  rightToWork: Array<Document>;
  rosteringGrade?: Maybe<Grade>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  rosteringOrganisationRegistrations?: Maybe<Array<RosteringOrganisationRegistration>>;
  rosteringOrganisations?: Maybe<Array<Organisation>>;
  rosteringWorkerProfile?: Maybe<RosteringWorkerProfile>;
  rosteringWorkerProfileId?: Maybe<Scalars['Int']>;
  safeGuarding: Array<Document>;
  shifts: Array<WorkerShift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination: Pagination;
  skills?: Maybe<Array<Skill>>;
  specialities: Array<Speciality>;
  staffGroup?: Maybe<StaffGroup>;
  status?: Maybe<Scalars['String']>;
  suspended: Scalars['Boolean'];
  suspendedOn?: Maybe<Scalars['DateTime']>;
  suspendedWorkerNote?: Maybe<Scalars['String']>;
  upcomingEsr?: Maybe<EsrNumber>;
  upcomingRosteringEsr?: Maybe<RosteringEsrNumber>;
  useKeycloakAuth: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
  visaType?: Maybe<VisaType>;
  workExperiences: Array<WorkExperience>;
  workerCollaborativeBank?: Maybe<WorkerCollaborativeBank>;
  workerCollaborativeBanks: Array<WorkerCollaborativeBank>;
  workerUploads?: Maybe<Array<WorkerUpload>>;
  workersSupervisors?: Maybe<Array<RosteringWorkersSupervisor>>;
};


export type OrganisationWorkerBookedShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** The connection type for OrganisationWorker. */
export type OrganisationWorkerConnectionPagination = {
  __typename?: 'OrganisationWorkerConnectionPagination';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganisationWorkerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrganisationWorker>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganisationWorkerEdge = {
  __typename?: 'OrganisationWorkerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrganisationWorker>;
};

/** For a specific worker, the limits that are relevant for this shift */
export type OrganisationWorkerShiftLimits = {
  __typename?: 'OrganisationWorkerShiftLimits';
  visaAllowed: Scalars['Boolean'];
  workerId: Scalars['ID'];
};

export type PaCategory = {
  __typename?: 'PaCategory';
  createdAt: Scalars['DateTime'];
  endTime: Scalars['String'];
  hoursPerPa: Scalars['Float'];
  id: Scalars['Int'];
  leaveGroup: LeaveGroup;
  name: Scalars['String'];
  startTime: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Paginated list of Batches */
export type PaginatedAgencySelfBillBatchesList = {
  __typename?: 'PaginatedAgencySelfBillBatchesList';
  /** List of Shift Batches */
  list?: Maybe<Array<AgencySelfBillBatch>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Batches */
export type PaginatedBatchesList = {
  __typename?: 'PaginatedBatchesList';
  /** List of PaidPerItem Batches */
  list?: Maybe<Array<Batch>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of DE Contracts */
export type PaginatedContractsList = {
  __typename?: 'PaginatedContractsList';
  /** List of DE Contracts */
  list?: Maybe<Array<Contract>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Items */
export type PaginatedItemsList = {
  __typename?: 'PaginatedItemsList';
  /** List of PaidPerItem Items */
  list?: Maybe<Array<Item>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Payment Receipts */
export type PaginatedPaymentReceiptsList = {
  __typename?: 'PaginatedPaymentReceiptsList';
  /** Count of PPI Payment Receipts */
  count?: Maybe<PaymentReceiptsCount>;
  /** Filter options for payment receipts */
  filterOptions?: Maybe<PaymentReceiptsFilterOptions>;
  /** List of PPI Payment Receipts */
  list?: Maybe<Array<Receipt>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Receipts */
export type PaginatedReceiptsList = {
  __typename?: 'PaginatedReceiptsList';
  /** Filter options */
  filterOptions?: Maybe<ReceiptsFiltersOptions>;
  /** List of PPI Receipts */
  list?: Maybe<Array<Receipt>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Auto Cascade Rule Groups */
export type PaginatedRuleGroupsList = {
  __typename?: 'PaginatedRuleGroupsList';
  /** List of Auto Cascade Rule Groups */
  list?: Maybe<Array<RuleGroup>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Auto Cascade Rule Groups Ranges */
export type PaginatedRuleGroupsRangesList = {
  __typename?: 'PaginatedRuleGroupsRangesList';
  /** List of Auto Cascade Rule Groups Ranges */
  list?: Maybe<Array<RuleGroupsRange>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Auto Cascade Rule Groups */
export type PaginatedRulesList = {
  __typename?: 'PaginatedRulesList';
  /** List of Auto Cascade Rules */
  list?: Maybe<Array<AutoCascadeRule>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Batches */
export type PaginatedTempStaffBatchesList = {
  __typename?: 'PaginatedTempStaffBatchesList';
  /** List of Shift Batches */
  list?: Maybe<Array<TempStaffBatch>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of Auto Cascade Triggers */
export type PaginatedTriggersList = {
  __typename?: 'PaginatedTriggersList';
  /** List of Auto Cascade Triggers */
  list?: Maybe<Array<Trigger>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

/** Paginated list of DE Contract Versions */
export type PaginatedVersionsList = {
  __typename?: 'PaginatedVersionsList';
  /** List of DE Contract Versions */
  list?: Maybe<Array<Version>>;
  /** Pagination information */
  pagination?: Maybe<Pagination>;
};

export type Pagination = {
  __typename?: 'Pagination';
  count?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  items?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  prev?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

/** Department's agency email notification settings */
export type PamNotificationsSettings = {
  __typename?: 'PamNotificationsSettings';
  /** email after create proposal action */
  proposalNotifications: Scalars['Boolean'];
  /** email after sent to agency action */
  sendToAgencyNotifications: Scalars['Boolean'];
  /** email after cancelling agency booking */
  shiftCancellationNotifications: Scalars['Boolean'];
  /** email after agency worker gets replaced with bank one */
  workerReplacedNotifications: Scalars['Boolean'];
};

/** List of Pam Rate Card Uploads */
export type PamRateCardUpload = {
  __typename?: 'PamRateCardUpload';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Hub user who created this record */
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation>;
  rateCardFilePath?: Maybe<Scalars['String']>;
  staffGroup?: Maybe<StaffGroup>;
  status?: Maybe<Scalars['String']>;
  uploadErrors?: Maybe<Scalars['String']>;
};

/** The connection type for PamRateCardUpload. */
export type PamRateCardUploadConnection = {
  __typename?: 'PamRateCardUploadConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PamRateCardUploadEdge>>>;
  /** A list of nodes. */
  nodes: Array<PamRateCardUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PamRateCardUploadEdge = {
  __typename?: 'PamRateCardUploadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PamRateCardUpload>;
};

/** Autogenerated return type of PassportInformation */
export type PassportInformationPayload = {
  __typename?: 'PassportInformationPayload';
  errors: Array<ValidationError>;
  passportRequestId: Scalars['String'];
};

export type PassportInformationRequest = {
  __typename?: 'PassportInformationRequest';
  id: Scalars['Int'];
  verified: Scalars['Boolean'];
};

export type PassportInvitation = {
  __typename?: 'PassportInvitation';
  id: Scalars['Int'];
  inviteUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  threadId: Scalars['String'];
};

/** Password validation result */
export type PasswordValidation = {
  __typename?: 'PasswordValidation';
  errors: Array<ValidationError>;
  valid: Scalars['Boolean'];
};

/** Pay details for worker, pro-rata for rotation period */
export type PayBreakdownForWorker = {
  __typename?: 'PayBreakdownForWorker';
  /** Average hours worked above 40 per week */
  averageAdditionalHours: Scalars['Float'];
  /** Average hours worked in unsocial hours */
  averageUnsocialHours: Scalars['Float'];
  /** Gross annual salary excluding allowances */
  basicAnnualSalary: Scalars['Float'];
  /** Annual flat rate for on-call duties */
  onCallAllowance?: Maybe<Scalars['Float']>;
  /** Extra pay for hours above 40 per week */
  payForAdditionalHours: Scalars['Float'];
  /** Extra pay for unsocial hours worked */
  payForUnsocialHours: Scalars['Float'];
  /** Total gross salary including allowances */
  totalSalary: Scalars['Float'];
  /** Allowance for working weekend duties */
  weekendAllowance: Scalars['Float'];
  /** Weekend banding as a percentage */
  weekendBanding: Scalars['Float'];
  /** Frequency weekends worked 1 in X this field is the X */
  weekendFrequency: Scalars['Int'];
};

/** Title, publication date and effective_from date for Pay Document */
export type PayDocumentInformation = {
  __typename?: 'PayDocumentInformation';
  /** Date on which Pay Doc took effect */
  effectiveFrom: Scalars['String'];
  /** Date on which Pay Doc was published */
  publicationDate: Scalars['String'];
  /** Title of Pay Document */
  title: Scalars['String'];
  /** Url to the Pay Document */
  url: Scalars['String'];
};

export type PayElement = {
  __typename?: 'PayElement';
  agencyMargin?: Maybe<Scalars['Float']>;
  baseRate: Scalars['Float'];
  benefit: Scalars['Float'];
  comparatorRate?: Maybe<Scalars['Float']>;
  hourlyRate: Scalars['Float'];
  hours: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  rateType: PayElementRateTypeEnum;
  tier?: Maybe<Scalars['String']>;
  totalRate: Scalars['Float'];
  workerBaseRate?: Maybe<Scalars['Float']>;
  workerRate?: Maybe<Scalars['Float']>;
};

/** Pay element rate type */
export enum PayElementRateTypeEnum {
  /** Element is call out rate for on call bank shift */
  BankCallOutGradeRate = 'BANK_CALL_OUT_GRADE_RATE',
  /** Element comes from bank grade rate */
  BankGradeRate = 'BANK_GRADE_RATE',
  /** Element is on call rate for for on call bank shift */
  BankOnCallGradeRate = 'BANK_ON_CALL_GRADE_RATE',
  /** Element comes from bank rate card */
  BankRateCard = 'BANK_RATE_CARD',
  /** Element is for sessional bank shift */
  BankSessional = 'BANK_SESSIONAL',
  /** Element comes from collaborative bank rate card */
  CollaborativeBankRateCard = 'COLLABORATIVE_BANK_RATE_CARD',
  /** Element comes from PAM rate card */
  PamRateCard = 'PAM_RATE_CARD',
  /** Element is for PAM agency shift */
  SimpleAgencyHourly = 'SIMPLE_AGENCY_HOURLY',
  /** Element is for sessional PAM agency shift */
  SimpleAgencySessional = 'SIMPLE_AGENCY_SESSIONAL',
  /** Unknown type */
  Unknown = 'UNKNOWN'
}

/** The dynamically generated payments information for the shift */
export type Payment = {
  __typename?: 'Payment';
  agencyMargin?: Maybe<Scalars['Float']>;
  basicRate: Scalars['Float'];
  benefit: Scalars['Float'];
  costCentre: Scalars['String'];
  departmentName: Scalars['String'];
  endTime: Scalars['DateTime'];
  esrNumber?: Maybe<Scalars['String']>;
  esrNumberIsValid: Scalars['Boolean'];
  hours: Scalars['Float'];
  internalOrExternal: Scalars['String'];
  lessThanFullTime: Scalars['Boolean'];
  monthly: Scalars['Boolean'];
  part: Scalars['Int'];
  paymentType: Scalars['String'];
  payrollElement?: Maybe<Scalars['String']>;
  pensionable?: Maybe<Scalars['Boolean']>;
  shiftGradeTitle: Scalars['String'];
  shiftId: Scalars['Int'];
  startTime: Scalars['DateTime'];
  status: Scalars['String'];
  submittedForPaymentOn?: Maybe<Scalars['DateTime']>;
  systemRate?: Maybe<Scalars['Float']>;
  timesheetAuthoriserEmail: Scalars['String'];
  totalPay?: Maybe<Scalars['Float']>;
  totalRate?: Maybe<Scalars['Float']>;
  units: Scalars['Float'];
  workerGradeTitle?: Maybe<Scalars['String']>;
  workerName?: Maybe<Scalars['String']>;
};

/** Choose if you want to include or exclude reviews from the payment shifts */
export enum PaymentCategoryEnum {
  /** Agency Shifts */
  Agency = 'agency',
  /** Primary Bank Shifts */
  Bank = 'bank',
  /** Secondary Bank / Collaborative Bank Shifts */
  CollabBank = 'collab_bank'
}

/** Enum of payment cycle type */
export enum PaymentCycleEnum {
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Weekly */
  Weekly = 'WEEKLY'
}

/** PPI Payment Receipt status count */
export type PaymentReceiptsCount = {
  __typename?: 'PaymentReceiptsCount';
  /** Count of flagged PPI Payments */
  flagged: Scalars['Int'];
  /** Count of outstanding PPI Payments */
  outstanding: Scalars['Int'];
};

/** Filter options for payment receipts */
export type PaymentReceiptsFilterOptions = {
  __typename?: 'PaymentReceiptsFilterOptions';
  /** departments */
  departments: Array<FilterOption>;
  /** grades */
  grades: Array<FilterOption>;
  /** sites */
  sites: Array<FilterOption>;
  /** workers */
  workers: Array<FilterOption>;
};

/** Shift Position Management Code settings for staff group */
export enum PositionManagementCodeEnum {
  /** No position management code is used */
  NotUsed = 'NOT_USED',
  /** Position management code is optiona for shifts */
  Optional = 'OPTIONAL',
  /** Position management code is required at shift creation */
  Required = 'REQUIRED'
}

/** Audit events for PPI objects */
export type PpiAuditEvent = {
  __typename?: 'PpiAuditEvent';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['JSON']>;
  eventType: Scalars['String'];
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userDetails?: Maybe<Scalars['JSON']>;
};

/** National salary and allowance figures for Junior Doctors */
export type PrefillValues = {
  __typename?: 'PrefillValues';
  /** basic annual salaries according to nodal point */
  basicAnnualSalaryByNodalPoint: BasicAnnualSalaryByNodalPoint;
  /** night shift information: rate, start time, end time */
  nightShiftInformation: NightShiftInformation;
  /** on call allowance according to nodal point */
  onCallAllowanceByNodalPoint: OnCallAllowanceByNodalPoint;
  /** title, publication date and effective date of Pay Document from which figures are taken */
  payDocumentInformation: PayDocumentInformation;
  /** percentage uplift of basic annual salary for rostered weekends */
  weekendPercentageRates: WeekendPercentageRates;
};

export type PreviewBatch = {
  __typename?: 'PreviewBatch';
  /** The URL to download the batch CSV from */
  batchCsvUrl?: Maybe<Scalars['String']>;
  /** The receipts included in the preview batch */
  receipts?: Maybe<Array<Receipt>>;
};

/** Autogenerated return type of PreviewSubscription */
export type PreviewSubscriptionPayload = {
  __typename?: 'PreviewSubscriptionPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Preview Itemised Batch */
  previewBatch?: Maybe<PreviewBatch>;
};

/** List of products an account can have access to */
export enum ProductTypeEnum {
  Bank = 'BANK',
  Rostering = 'ROSTERING'
}

/** Autogenerated return type of ProposalAccept */
export type ProposalAcceptPayload = {
  __typename?: 'ProposalAcceptPayload';
  errors: Array<ValidationError>;
  /** Returns ShiftBlock */
  shiftBlock: ShiftBlock;
  /** Returns true if proposal is accepted with no errors */
  success: Scalars['Boolean'];
};

export type ProposalCreate = {
  agencyWorkerId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  coreAgencyMargin?: InputMaybe<Scalars['Float']>;
  coreLocumRate?: InputMaybe<Scalars['Float']>;
  coreNiContribution?: InputMaybe<Scalars['Float']>;
  coreTotalRate?: InputMaybe<Scalars['Float']>;
  directEngagement?: InputMaybe<Scalars['Boolean']>;
  poNumber?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  requisitionNumber?: InputMaybe<Scalars['String']>;
  shiftBlockId: Scalars['Int'];
  shiftBlockPayElements?: InputMaybe<Array<InputPayElement>>;
  splitRate?: InputMaybe<Scalars['Boolean']>;
  totalComparatorRate?: InputMaybe<Scalars['Float']>;
  totalMargin?: InputMaybe<Scalars['Float']>;
  unsocialAgencyMargin?: InputMaybe<Scalars['Float']>;
  unsocialLocumRate?: InputMaybe<Scalars['Float']>;
  unsocialNiContribution?: InputMaybe<Scalars['Float']>;
  unsocialTotalRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of ProposalCreate */
export type ProposalCreatePayload = {
  __typename?: 'ProposalCreatePayload';
  /** Returns proposal if proposal created with no errors */
  agencyWorkerBlockProposal?: Maybe<AgencyWorkerBlockProposal>;
  errors: Array<ValidationError>;
  /** Returns true if proposal created with no errors */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ProposalReject */
export type ProposalRejectPayload = {
  __typename?: 'ProposalRejectPayload';
  errors: Array<ValidationError>;
  /** Returns ShiftBlock */
  shiftBlock?: Maybe<ShiftBlock>;
  /** Returns true if proposal rejected with no errors */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ProposalRevoke */
export type ProposalRevokePayload = {
  __typename?: 'ProposalRevokePayload';
  errors: Array<ValidationError>;
  /** Returns true if proposal revoked with no errors */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of PublishContract */
export type PublishContractPayload = {
  __typename?: 'PublishContractPayload';
  errors: Array<ValidationError>;
  /** Result of the operation */
  result?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  /** Agency query returns paginated list of agencies */
  agencies: AgenciesPaginatedList;
  /** Agency Shifts booked by VMS account. Maximum of a 100 items per page. */
  agencyBookedShifts: Array<Shift>;
  /** Agency registrations */
  agencyRegistrations: AgencyRegistrationsPaginatedList;
  /** Agency Self-Bill Batch resolver */
  agencySelfBillBatch?: Maybe<AgencySelfBillBatch>;
  /** List of Agency Self-Bill Batches */
  agencySelfBillBatches?: Maybe<PaginatedAgencySelfBillBatchesList>;
  /** Agency Shifts query. Maximum of a 100 items per page. */
  agencyShifts: Array<AgencyShift>;
  /** Agency User resolver */
  agencyUser?: Maybe<AgencyUser>;
  /** Returns AgencyUserPermissions for an AgencyUser, scoped to the current HubUser's Organisation */
  agencyUserPermissions: Array<AgencyUserPermission>;
  /** Agency query returns paginated list of agency users */
  agencyUsers: AgencyUsersPaginatedList;
  /** List of Agency Worker Doc Documentations */
  agencyWorkerDocsBookingSnapshot: Array<StructuredDocumentations>;
  /** List of Agency Worker Doc Documentations */
  agencyWorkerDocsDocumentations: Array<StructuredDocumentations>;
  /** Paginated list of AgencyWorkers */
  agencyWorkers: AgencyWorkersPaginatedList;
  /** Returns a list of Signed Agreements URLs */
  allSignedAgreements: AllSignedAgreement;
  /** List of Auto Cascade Rule Groups */
  autoCascadeRuleGroups: PaginatedRuleGroupsList;
  /** List of Auto Cascade Rule Groups Ranges */
  autoCascadeRuleGroupsRange: PaginatedRuleGroupsRangesList;
  /** List of Auto Cascade Rules */
  autoCascadeRules: PaginatedRulesList;
  /** List of Auto Cascade Triggers */
  autoCascadeTriggers: PaginatedTriggersList;
  /** Returns all the temporary staffing payments shifts for an organisation */
  bankPaymentsShifts: ShiftConnection;
  /** Paginated query returning the BankRateCard uploads */
  bankRateCardsUploads: BankUploadConnection;
  /** Beamer Configuration Details */
  beamerConfig: Config;
  /** Shifts query for Calender Page */
  calenderShifts: Array<CalenderShift>;
  canHubUserSelfAuthorise: Scalars['Boolean'];
  /** Paginated query returning the BankRateCard uploads */
  collabBankRateCardUploads: CollabBankUploadConnection;
  /** Returns worked shifts for all organisations within a collaborative bank */
  collaborativeBankWorkedShifts: Array<Shift>;
  collaborativeBanks: Array<CollaborativeBank>;
  /**
   * Returns a list of cached breaches for a given worker \
   *                                 that were cached against a given event ID and edited event times, \
   *                                 along with an indication as to whether the user can override the breaches or not
   */
  complianceCachedBreachesForEditedShift: CachedWorkerBreaches;
  /**
   * Returns a list of cached breaches for a given worker that were cached against a given event ID, \
   *                                 along with an indication as to whether the user can override the breaches or not
   */
  complianceCachedBreachesForWorker: CachedWorkerBreaches;
  /**
   * Returns a list of cached breaches for a given worker that were cached against given event IDs, \
   *                                 along with an indication as to whether the user can override the breaches or not
   */
  complianceCachedBreachesForWorkerEvents: CachedWorkerBreaches;
  /** Compliance document types */
  complianceDocumentTypes: Array<ComplianceDocumentType>;
  connectionTypeCollaborativeBanks: CollaborativeBankConnection;
  /** Departments query with object total count, page info, edges and nodes information */
  connectionTypeDepartments: DepartmentConnection;
  connectionTypeFilterGrades: FilterGradeConnection;
  connectionTypeGrades: GradeConnection;
  /** Hub Users query with object total count, page info, edges and nodes information */
  connectionTypeHubUsers?: Maybe<HubUserConnection>;
  /** Meta activities query with object total count, page info, edges and nodes information */
  connectionTypeMetaActivities?: Maybe<MetaActivityConnection>;
  /** Organisation Staff Group query with object total count, page info, edges and nodes information */
  connectionTypeOrganisationStaffGroups: OrganisationStaffGroupConnection;
  /** Regulation Body query with object total count, page info, edges and nodes information */
  connectionTypeRegulationBodies?: Maybe<RegulationBodyConnection>;
  /** Sites query with object total count, page info, edges and nodes information */
  connectionTypeSites: SiteConnection;
  /** Skills query with object total count, page info, edges and nodes information */
  connectionTypeSkills: SkillConnection;
  /** Specialities query with object total count, page info, edges and nodes information */
  connectionTypeSpecialities: SpecialityConnection;
  /** Returns a list of staff groups */
  connectionTypeStaffGroups: StaffGroupConnection;
  /** Organisation Workers query with object total count, page info, edges and nodes information */
  connectionTypeWorkers: OrganisationWorkerConnectionPagination;
  /** Cost centres query returns paginated list of cost centres */
  costCentres?: Maybe<CostCentreConnection>;
  /** Returns the current account */
  currentAccount: Account;
  currentAgencyUser?: Maybe<AgencyUser>;
  /** The ExternalAccount providing the auth token */
  currentExternalAccount?: Maybe<ExternalAccount>;
  currentHubUser?: Maybe<HubUser>;
  currentOrganisation?: Maybe<Organisation>;
  currentWorker?: Maybe<Worker>;
  /** DE Contract resolver */
  deContractsContract: Contract;
  /** List of DE Contracts */
  deContractsContracts: PaginatedContractsList;
  /** DE Contract Version resolver */
  deContractsVersion: Version;
  /** DE Contract Version Assignment resolver */
  deContractsVersionAssignment: VersionAssignment;
  /** DE Contract Version content resolver */
  deContractsVersionContent: Scalars['String'];
  /** DE Contract Version fields resolver */
  deContractsVersionFields: Fields;
  /** List of DE Contract Versions */
  deContractsVersions: PaginatedVersionsList;
  department: Department;
  departments: Array<Department>;
  /** Returns a list of divisions */
  divisions: Array<Division>;
  documentTypes: Array<DocumentType>;
  /** Returns reference data for EDI questions asked during onboarding */
  ediReferenceData: ReferenceData;
  /** Escalation levels per organisation */
  escalationLevels?: Maybe<Levels>;
  /** Escalation levels grade rates for shift */
  escalationLevelsGradeRatesForShift?: Maybe<LevelGradeRatesList>;
  /** The Shift Data Enrichment Resolver */
  expensesShiftDataEnrichment: ShiftEnrichedData;
  /** External account query returns paginated list of external accounts */
  externalAccounts: ExternalAccountConnection;
  externalUnit?: Maybe<ExternalUnit>;
  /** External Unit query returns a list of external units */
  externalUnits?: Maybe<Array<ExternalUnit>>;
  filterGrades: Array<FilterGrade>;
  /** Returns users saved filters */
  filters?: Maybe<Filters>;
  /** Endpoint for hub users to retrieve workers */
  filtersWorkers: Array<OrganisationWorker>;
  /** For a Hub User to view the details of a Grade */
  grade: Grade;
  grades: Array<Grade>;
  hubUser: HubUser;
  /** Get a list of organisations available to the current user. */
  hubUserOrganisations: Array<Organisation>;
  /**
   * Can be used to return all bank admins and bank managers.
   * Allowed account types: bank_admin, bank_manager, roster
   *
   */
  hubUsers?: Maybe<Array<HubUser>>;
  /** Retrieve a bank shift */
  integrationBankShift?: Maybe<RosterBankShift>;
  locations: LocationConnection;
  /** Query Meta Activities */
  metaActivities?: Maybe<Array<MetaActivity>>;
  orgStaffGroupSkills: Array<OrgStaffGroupSkill>;
  organisation: Organisation;
  /** Organisation agency query returns a list of organisation agencies */
  organisationAgencies?: Maybe<Array<OrganisationAgency>>;
  /** Organisation compliance documents */
  organisationComplianceDocuments: Array<OrganisationComplianceDocument>;
  /** returns the grades of the current Hub Users organisation */
  organisationGrades?: Maybe<Array<Grade>>;
  organisationStaffGroup: OrganisationStaffGroup;
  organisationStaffGroups: Array<OrganisationStaffGroup>;
  /** Returns a list of organisation types */
  organisationTypes: Array<OrganisationType>;
  organisationWorker: OrganisationWorker;
  /** Organisations query returns a list of organisations that supports the worker's staff group by default */
  organisations: Array<Organisation>;
  /** Paid Per Item Batch resolver */
  paidPerItemBatch: Batch;
  /** List of PPI Batches */
  paidPerItemBatches: PaginatedBatchesList;
  /** List of departments with PPI items for an organisation */
  paidPerItemDepartments: Array<Department>;
  /** Paid Per Item Item resolver */
  paidPerItemItem: Item;
  /** Item calculation */
  paidPerItemItemCalculation: Array<ItemCalculation>;
  /** Paginated list of items for hub users organisation */
  paidPerItemItems: PaginatedItemsList;
  /** List of organisations and collaborative bank organisations that the worker is a member of */
  paidPerItemOrganisations: Array<Organisation>;
  /** Returns paginated list of PPI payment receipts */
  paidPerItemPaymentReceipts: PaginatedPaymentReceiptsList;
  /** Paid Per Item Receipt resolver */
  paidPerItemReceipt: Receipt;
  /** Returns paginated list of PPI receipts */
  paidPerItemReceipts: PaginatedReceiptsList;
  /** Returns options for filtering receipts */
  paidPerItemReceiptsFilters: ReceiptsFilters;
  /** Returns list of grades matching workers grade at an organisation */
  paidPerItemWorkerGradesForOrganisation: Array<Grade>;
  /** Returns non paginated list of items in department */
  paidPerItemWorkerItems: Array<Item>;
  /** Returns paginated list of PPI receipts for a worker */
  paidPerItemWorkerReceipts: PaginatedReceiptsList;
  /** Returns the count of worker receipts by status */
  paidPerItemWorkerReceiptsCount: Array<ReceiptStatusCount>;
  /** Rate Card Uploads query with object total count, page info etc. */
  pamRateCardsUploads: PamRateCardUploadConnection;
  /** Allow to query the status of passport information request */
  passportInformationRequests: PassportInformationRequest;
  rateAuthorisers: Array<RateAuthoriser>;
  rateAuthorisersBulk: Array<RateAuthoriser>;
  rateAuthorisersNewShift: Array<RateAuthoriser>;
  rateCardRatesForGrade: Array<Maybe<PayElement>>;
  rateCardTemplate: Scalars['String'];
  /** Returns a list of reasons for cancellation, deletion and request */
  reasons?: Maybe<ReasonConnection>;
  reasonsForCancellation: Array<ReasonForCancellation>;
  /** Find all reasons for deletion for an organisation */
  reasonsForDeletion: Array<ReasonForDeletion>;
  /** Find all reasons for escalation for an organisation */
  reasonsForEscalation: Array<ReasonForEscalation>;
  /** Returns a list of regions */
  regions: Array<Region>;
  /** Regulation Body query returns a list of reg bodies */
  regulationBodies?: Maybe<Array<RegulationBody>>;
  /** Returns an ordered list of rostering events that are conflicting */
  rosterConflictingEvents?: Maybe<RosteringEventConnection>;
  /** Returns workers with shifts that overlap with given shift */
  rosterWorkersInShift?: Maybe<Array<RosteringShift>>;
  /**
   * Returns a list of Organisation Activities used in a given Roster \
   *       with nested Site and Location data for rendering the Activity x Date view
   */
  rosteringActivityByDateDataStructure: Array<ActivityWithNestedAssociations>;
  /** Returns a list of Audit Logs for the PayRule */
  rosteringAuditLogsForPayRule: Array<AuditRosteringEvent>;
  /** Returns a list of Audit Logs for the Schedule */
  rosteringAuditLogsForSchedule: Array<AuditRosteringEvent>;
  /** Returns a list of available organisation registrations for a given date range */
  rosteringAvailableOrganisationRegistrationsForDateRange?: Maybe<AvailableRosteringOrganisationRegistration>;
  /** Returns a list of available organisation registrations on a given date */
  rosteringAvailableOrganisationRegistrationsForShift?: Maybe<RosteringOrganisationRegistrationConnection>;
  /** Returns a list of available organisation registrations on multiple dates */
  rosteringAvailableOrganisationRegistrationsOnMultipleDates?: Maybe<RosteringOrganisationRegistrationConnection>;
  /** Returns a list of compliance breaches for given worker base on organisation registration */
  rosteringComplianceBreaches: BreachConnection;
  /** Returns clashing rostering shift events given a shift event */
  rosteringConflictingEvents?: Maybe<ConflictingEvents>;
  /** Returns clashing rostering events given a shift event and edited start and end times */
  rosteringConflictingEventsForEditedShift?: Maybe<ConflictingEvents>;
  /** Returns a day pattern shift set given id */
  rosteringDayPatternShiftSet?: Maybe<RosteringDayPatternShiftSet>;
  /** Returns a day template given a unique identifier */
  rosteringDayTemplate?: Maybe<RosteringDayTemplate>;
  /** day templates query returns paginated list of day templates */
  rosteringDayTemplates: RosteringDayTemplateConnection;
  /**
   * Returns Sites/Locations/ShiftTypes for which there is demand on the specified Rota and date,
   *
   *         to form the grid headers in Day View
   */
  rosteringDayViewDataStructure: DayViewDataStructure;
  /** Returns rota shift sets for specified Rota, date, shift type, site and location */
  rosteringDayViewRotaShiftSets: RosteringRotaShiftSetConnection;
  /** Returns a demand template given a unique identifier */
  rosteringDemandTemplate?: Maybe<RosteringDemandTemplate>;
  /** demand templates query returns paginated list of demand templates */
  rosteringDemandTemplates?: Maybe<RosteringDemandTemplateConnection>;
  /** demand templates activities query returns paginated list of demand templates activities */
  rosteringDemandTemplatesActivities: Array<RosteringDemandTemplatesActivity>;
  /** Returns a dept list given a unique identifier */
  rosteringDepartmentList?: Maybe<RosteringDepartmentList>;
  /** DepartmentLists query returns paginated list of department lists */
  rosteringDepartmentLists?: Maybe<RosteringDepartmentListConnection>;
  /** Returns rostering event when given event id */
  rosteringEvent?: Maybe<RosteringEvent>;
  /** Returns list of rostering events */
  rosteringEvents?: Maybe<RosteringEventConnection>;
  /** Returns list of rostering events by id */
  rosteringEventsById?: Maybe<Array<RosteringEvent>>;
  /** Rostering exception report by id */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
  /** Rostering exception report permissions by id */
  rosteringExceptionReportPermissions: ExceptionReportPermission;
  /** Returns all exception report reason categories */
  rosteringExceptionReportReasonCategories: Array<RosteringExceptionReportReasonCategory>;
  /** Rostering exception reports */
  rosteringExceptionReports?: Maybe<Array<RosteringExceptionReport>>;
  /** Returns a list of the unique grades in the rota */
  rosteringGradesInRota?: Maybe<GradeConnection>;
  /** Returns a list of grades associated with shifts in a given roster */
  rosteringGradesOfShiftsInRoster?: Maybe<GradeConnection>;
  /** Returns leave absence categories */
  rosteringLeaveAbsenceCategories?: Maybe<Array<LeaveAbsenceCategory>>;
  /** Returns leave absence types */
  rosteringLeaveAbsenceTypes?: Maybe<Array<LeaveAbsenceType>>;
  /** Returns leave entitlements for a worker */
  rosteringLeaveEntitlements?: Maybe<Array<LeaveEntitlement>>;
  /** Returns a day template given a unique identifier */
  rosteringLeaveGroups?: Maybe<LeaveGroupConnection>;
  /** Returns requested LeaveGroup */
  rosteringLeaveLeaveGroup?: Maybe<LeaveGroup>;
  /** Returns leave reasons */
  rosteringLeaveReasons?: Maybe<LeaveReasonConnection>;
  /** Returns a single leave request */
  rosteringLeaveRequest?: Maybe<LeaveRequest>;
  /** Returns leave requests based on the status of their request approvals */
  rosteringLeaveRequestApprovals?: Maybe<LeaveRequestApprovalConnection>;
  /** Returns a list of Audit Logs for the Leave Request */
  rosteringLeaveRequestAuditLogs: Array<AuditRosteringEvent>;
  /** Returns leave request calculation */
  rosteringLeaveRequestCalculation?: Maybe<LeaveRequestCalculation>;
  /** LeaveRequestCalendar query returns workers and their leave requests for a single month */
  rosteringLeaveRequestCalendar: RosteringOrganisationRegistrationConnection;
  /** Returns shifts that would be missed if the leave request were approved */
  rosteringLeaveRequestMissedShifts?: Maybe<Array<RosteringEvent>>;
  /** Returns leave requests for a worker */
  rosteringLeaveRequests?: Maybe<Array<LeaveRequest>>;
  /** Returns leave requests for a worker always ordered by start_date descending */
  rosteringLeaveRequestsForWorker?: Maybe<LeaveRequestConnection>;
  /** Returns a list of the staff groups with grades for an organisation */
  rosteringLeaveStaffGroupsGrades: Array<LeaveStaffGroup>;
  /** Returns organisational rostering leave types */
  rosteringLeaveTypes?: Maybe<Array<LeaveType>>;
  /** RosteringNotifications query returns list of Worker's notifications */
  rosteringNotifications: RosteringNotificationsConnection;
  /** OrgRegDeptList query returns org reg dept lists by id */
  rosteringOrgRegDeptList?: Maybe<RosteringOrgRegDeptList>;
  /** OrgRegistrationDeptLists query returns list of org registration dept lists */
  rosteringOrgRegDeptLists: RosteringOrgRegDeptListConnection;
  /** Rostering Organisation Activities. Maximum of a 100 items per page. */
  rosteringOrganisationActivities: RosteringOrganisationActivityConnection;
  /** Returns a list of organisation activities to be used in the activities filter on a roster */
  rosteringOrganisationActivitiesForRosterFilter?: Maybe<RosteringOrganisationActivityConnection>;
  /** Returns a list of activities that belong to the roster department but are not part of the roster */
  rosteringOrganisationActivitiesNotInRoster?: Maybe<RosteringOrganisationActivityConnection>;
  /** Returns a single Organisation Activity */
  rosteringOrganisationActivity: RosteringOrganisationActivity;
  /** OrganisationRegistration query return organisation registration for given worker_id */
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  /** OrganisationRegistration query return organisation registrations */
  rosteringOrganisationRegistrations: RosteringOrganisationRegistrationConnection;
  /**
   * OrganisationRegistration query returns organisation
   *                   registrations to add to a department list,
   *                   filtering out the pre-existing ones
   */
  rosteringOrganisationRegistrationsForDeptList: RosteringOrganisationRegistrationConnection;
  /** Returns a list of available org regs for a schedule */
  rosteringOrganisationRegistrationsForSchedule?: Maybe<RosteringOrganisationRegistrationConnection>;
  /** Returns shift events that overlap with a given event */
  rosteringOverlappingEvents?: Maybe<Array<RosteringEvent>>;
  /** Return a Rostering Pay Rule by ID */
  rosteringPayRule: RosteringPayRule;
  /** Return all Rostering Pay Rules */
  rosteringPayRules: RosteringPayRuleConnection;
  /** Returns a count of available shifts for self-rostering */
  rosteringPeriodShiftCount?: Maybe<Scalars['Int']>;
  /** Returns a list of users based on a specific permission */
  rosteringPermissionUsers?: Maybe<Array<User>>;
  /** Returns a list of permissions filtered by a category */
  rosteringPermissions?: Maybe<Array<RosteringPermission>>;
  /** Returns the rostering permitted departments of the current user */
  rosteringPermittedDepartments: Array<Department>;
  /** Returns a RosteringRateCard given id */
  rosteringRateCard?: Maybe<RateCard>;
  /** Returns national salary and allowance figures for Junior Doctors */
  rosteringRateCardPrefillValues: PrefillValues;
  /** Returns a list of RosteringRateCards */
  rosteringRateCards: RateCardConnection;
  /** Returns a roster given a unique identifier */
  rosteringRoster?: Maybe<RosteringRoster>;
  /** Returns all schedules on the roster */
  rosteringRosterSchedules?: Maybe<Array<RosteringSchedule>>;
  /** Returns a list of rosters */
  rosteringRosters?: Maybe<RosteringRosterConnection>;
  /** Returns a rota given a unique identifier */
  rosteringRota?: Maybe<RosteringRota>;
  /** A query to list all Rota Days associated with a given Rota, with optional Date filters */
  rosteringRotaDays?: Maybe<RosteringRotaDayConnection>;
  /** Returns a single RosteringRotaShiftSet */
  rosteringRotaShiftSet?: Maybe<RosteringRotaShiftSet>;
  /** Returns rota shift sets given a rota unique identifier */
  rosteringRotaShiftSets?: Maybe<RosteringRotaShiftSetConnection>;
  /** Returns a single RosteringRotaSlot */
  rosteringRotaSlot?: Maybe<RotaSlot>;
  /** Returns rota statuses given a unique identifier */
  rosteringRotaStatuses?: Maybe<Array<Scalars['String']>>;
  /** Rotas query returns paginated list of rotas */
  rosteringRotas: RosteringRotaConnection;
  /** Returns a list of available org regs for a reassigning a shift (workers within the roster not on a shift on that day) */
  rosteringSchedulesForReassigningShift?: Maybe<RosteringScheduleConnection>;
  /** Returns all schedules on the roster grouped by worker pattern */
  rosteringSchedulesGroupedByPattern?: Maybe<WorkerViewDataStructure>;
  /** Return a shift, given a specific shift ID */
  rosteringShift?: Maybe<RosteringShift>;
  /** Returns a shift set given id */
  rosteringShiftSet?: Maybe<RosteringShiftSet>;
  /** Rostering shift templates. Maximum of a 100 items per page. */
  rosteringShiftTemplates: RosteringShiftTemplateConnection;
  /** Rostering shift types */
  rosteringShiftTypes: Array<RosteringShiftType>;
  /** Returns a list of all the Rostering::ShiftTypes used for the Rostering::Shifts within Rostering::Roster */
  rosteringShiftTypesInRoster: Array<RosteringShiftType>;
  /** Returns list of rostering events that qualify for a shift swap */
  rosteringSwaps?: Maybe<RosteringEventConnection>;
  /** Returns a single swap request */
  rosteringSwapsRequest?: Maybe<Request>;
  /** Returns a list of swap requests */
  rosteringSwapsRequests: Array<Request>;
  /**
   * Returns rostering organisation registrations in the roster's dept list
   *         who have leave / shifts / day off in the given dates, grouped by grade
   */
  rosteringTeamRota: Array<TeamRota>;
  /** Returns worker events in the roster from a given date */
  rosteringWorkerEventsGroupedByWeek?: Maybe<EventsWithinWeekConnection>;
  /** Returns the most recent rostering shift for a worker */
  rosteringWorkerMostRecentShift?: Maybe<RosteringEvent>;
  /** Returns a single RosteringWorkerPattern */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
  /** Returns a list of RosteringWorkerPatterns */
  rosteringWorkerPatterns: WorkerPatternConnection;
  /** Returns rosters with active department list which the worker has worked on */
  rosteringWorkerRosters?: Maybe<RosteringRosterConnection>;
  /** Returns clashes given worker shifts */
  rosteringWorkerShiftClashesForMultipleShifts?: Maybe<Array<ShiftClashes>>;
  /** Returns a list of swap requests which can be accepted/rejected by worker */
  rosteringWorkerSwapRequests: Array<Request>;
  /** Returns list of times off in lieu */
  rosteringWorkerTimesOffInLieu?: Maybe<Array<RosteringTimeOffInLieu>>;
  /** Returns a list of rostering organisation registrations working a specific date, activity and roster */
  rosteringWorkersInActivity: RosteringOrganisationRegistrationConnection;
  /** Returns all shifts from other rosters for the workers who are assigned to current roster */
  rosteringWorkersShiftsFromOtherRosters: Array<WorkerShiftsFromOtherRosters>;
  /** Returns the value for a given setting. */
  sailGet?: Maybe<Scalars['JSON']>;
  /** Switches between the positive or negative setting based on the throttle. */
  sailSwitcher?: Maybe<Scalars['JSON']>;
  /** Returns a list of self rostering shifts */
  selfRosteringShifts?: Maybe<RosteringEventConnection>;
  shift: Shift;
  /** Return a list of one or more Applications for a Block */
  shiftBlockApplications: ShiftBlockApplicationConnection;
  /** Return a list of timesheets for a given block id */
  shiftBlockTimesheets: Array<ShiftBlockTimesheet>;
  /** Return a list of workers for blocks */
  shiftBlockWorkers: Array<ShiftBlockOrganisationRegistration>;
  /** Return a list of one or more Blocks */
  shiftBlocks: ShiftBlockConnection;
  /** Return a list of one or more Blocks */
  shiftBlocksList: ShiftBlockPaginatedList;
  /** Get shift export options */
  shiftExportOptions: Array<ExportSection>;
  /** Shifts query. If organisation_ids is not set, the workers approved organisations are used. argument sign_off_requested_shifts_only could be empty or one of 'all', 'without_amendments' or 'with_amendments' */
  shifts: Array<Shift>;
  /** New Shifts resolver, contains a paginated List for use on FE */
  shiftsList: ShiftsPaginatedList;
  /** Returns status of sidekiq job */
  sidekiqJobStatus: Sidekiq;
  site: Site;
  /** Site query returns a list of sites */
  sites: Array<Site>;
  skills: Array<Skill>;
  /** Skills query returns a list of skills for the org staff group */
  skillsForOrganisationStaffGroup: Array<Skill>;
  /** Skills query returns a list of skills */
  skillsForWorker: Array<Skill>;
  /** Match shift skills with worker skills */
  skillsMatch: SkillsMatch;
  /** Speciality query returns a list of specialities */
  specialities: Array<Speciality>;
  /** Returns a list of staff groups */
  staffGroups: Array<StaffGroup>;
  /** Super admin can fetch all hub users through this endpoint */
  superAdminHubUsers?: Maybe<HubUserConnection>;
  /** returns the lock config for the given org */
  superAdminOrgLockConfig?: Maybe<OrganisationLockConfig>;
  superAdminVmsAgencies: AgencyConnection;
  /** SuperHub VMS Agencies resolver */
  superAdminVmsAgency?: Maybe<Agency>;
  /** Returns agency user permissions for an Agency User ID */
  superAdminVmsAgencyUserPermissions: Array<SuperAdminAgencyUserPermission>;
  /** Agency Users query with object total count, page info, edges and nodes information */
  superAdminVmsAgencyUsers: AgencyUserConnection;
  superAdminWorkers?: Maybe<WorkerConnection>;
  /** Super admin can fetch all super admins through this endpoint */
  superAdmins?: Maybe<HubUserConnection>;
  /** Returns all the events in rosters the current account works in */
  teamRoster?: Maybe<Array<RosteringEvent>>;
  /** Returns all the events in rosters the current account works in */
  teamRosterPaginated?: Maybe<RosteringEventConnection>;
  /** Temp Staff Batch resolver */
  tempStaffBatch?: Maybe<TempStaffBatch>;
  /** List of Temp Staff Payment Batches */
  tempStaffBatches?: Maybe<PaginatedTempStaffBatchesList>;
  /** Returns a temporary staffing payments batch */
  tempStaffPaymentBatch?: Maybe<TempStaffBatch>;
  /** Returns payment batches for an organisation */
  tempStaffPaymentBatches?: Maybe<PaginatedTempStaffBatchesList>;
  /** Returns all the temporary staffing payments shifts for an organisation */
  tempStaffPaymentsShifts: ShiftConnection;
  /** Validate password against password rules */
  validatePassword: PasswordValidation;
  /** Calculate whether a shift or list of shifts would take a worker over their maximum hours */
  visaLimitsMaxHoursCheck: VisaLimitsShiftsAllowed;
  /** Calculate whether a new shift would take a worker over their maximum hours */
  visaLimitsMaxHoursNewShiftCheck: VisaLimitsShiftsAllowed;
  /** Validate invitation token and fetch registration status */
  vmsAgencyInvitation: InvitationToken;
  /** Agency registrations */
  vmsAgencyRegistrations: AgencyRegistrationConnection;
  /** Agency User Agencies resolver */
  vmsAgencyUserAgencies?: Maybe<Array<AgencyUserAgency>>;
  /** Returns list of Agency User Permissions for an Agency User (on VMS) */
  vmsAgencyUserPermissions: AgencyUserPermissionConnection;
  /** Agency query returns paginated list of agency users */
  vmsAgencyUsers: AgencyUsersPaginatedList;
  /** List of pay elements for worker proposal for specified shift or shift block */
  vmsAgencyWorkerProposalRates?: Maybe<Array<AgencyWorkerProposalRates>>;
  /** List of agency worker proposals for specified shift */
  vmsAgencyWorkerProposals?: Maybe<Array<AgencyWorkerProposal>>;
  /** Paginated list of workers for the agency (agency defined by current AgencyUser) */
  vmsAgencyWorkers?: Maybe<AgencyWorkerConnection>;
  /** Get shift query for VMS user */
  vmsShift: Shift;
  /** Shifts query for VMS user */
  vmsShifts?: Maybe<ShiftConnection>;
  /** Fetch agency registration as worker */
  vmsWorkerRegistration: AgencyRegistrationConnection;
  /** Shifts query. If organisation_ids is not set, the workers approved organisations are used. */
  workedShifts: Array<Shift>;
  worker: Worker;
  /** For a worker to preview on call shift rates */
  workerOnCallRatesPreview: OnCallShiftRatePreview;
  workerOrganisationShifts: Array<WorkerOrganisationShifts>;
  workerPrimaryOrganisationBasicInfo?: Maybe<WorkerPrimaryOrganisationBasicInfo>;
  /** Worker Shift query. Returns a worker shift by id */
  workerShift: WorkerShift;
  workers: Array<OrganisationWorker>;
  /** Workers Shifts query. Returns available, applied and booked shifts for a worker */
  workersShifts: Array<WorkerShift>;
  /** Workers Shifts query. Returns available, applied and booked shifts for a worker */
  workersShiftsQuick: Array<WorkerShift>;
  /** Returns summary of shift statuses broken down by date */
  workersShiftsSummary: Array<WorkerDailySummary>;
};


export type QueryAgenciesArgs = {
  agencyTierIds?: InputMaybe<Array<Scalars['Int']>>;
  agencyTitle?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAgencyBookedShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryAgencyRegistrationsArgs = {
  agencyWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAgencySelfBillBatchArgs = {
  agencyId?: InputMaybe<Scalars['Int']>;
  batchId: Scalars['Int'];
};


export type QueryAgencySelfBillBatchesArgs = {
  agencyId: Scalars['Int'];
  batchId?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  organisationIds: Array<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAgencyShiftsArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  shiftBlockId?: InputMaybe<Scalars['ID']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};


export type QueryAgencyUserArgs = {
  agencyUserId: Scalars['Int'];
};


export type QueryAgencyUserPermissionsArgs = {
  agencyUserId: Scalars['Int'];
};


export type QueryAgencyUsersArgs = {
  email?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryAgencyWorkerDocsBookingSnapshotArgs = {
  agencyRegistrationId: Scalars['Int'];
  bookableId: Scalars['Int'];
  bookableType: Scalars['String'];
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAgencyWorkerDocsDocumentationsArgs = {
  agencyRegistrationId: Scalars['Int'];
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAgencyWorkersArgs = {
  agencyId?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAutoCascadeRuleGroupsArgs = {
  departmentName?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  ruleGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryAutoCascadeRuleGroupsRangeArgs = {
  autoCascadeRuleGroupId: Scalars['Int'];
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAutoCascadeRulesArgs = {
  autoCascadeRuleGroupsRangeId: Scalars['Int'];
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAutoCascadeTriggersArgs = {
  failed?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  triggerableId?: InputMaybe<Scalars['Int']>;
  triggerableType?: InputMaybe<Scalars['String']>;
};


export type QueryBankPaymentsShiftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  flaggedPayments?: InputMaybe<FlaggedPaymentsOptionsEnum>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  group?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  paymentCategory?: InputMaybe<PaymentCategoryEnum>;
  paymentCycle: TempStaffPaymentCycleEnum;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  workerId?: InputMaybe<Scalars['Int']>;
};


export type QueryBankRateCardsUploadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationId: Scalars['Int'];
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryCalenderShiftsArgs = {
  fromStartTime?: InputMaybe<Scalars['Date']>;
  limitToScope?: InputMaybe<ShiftScope>;
  toStartTime?: InputMaybe<Scalars['Date']>;
};


export type QueryCanHubUserSelfAuthoriseArgs = {
  newCoreRate?: InputMaybe<Scalars['Float']>;
  newUnsocialRate?: InputMaybe<Scalars['Float']>;
  rateChanges?: InputMaybe<Array<InputRateChanges>>;
  shiftId: Scalars['Int'];
};


export type QueryCollabBankRateCardUploadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  collaborativeBankStaffGroupId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCollaborativeBankWorkedShiftsArgs = {
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCollaborativeBanksArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryComplianceCachedBreachesForEditedShiftArgs = {
  newEndsAt: Scalars['DateTime'];
  newStartsAt: Scalars['DateTime'];
  rosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryComplianceCachedBreachesForWorkerArgs = {
  rosterId?: InputMaybe<Scalars['Int']>;
  rosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryComplianceCachedBreachesForWorkerEventsArgs = {
  rosterId?: InputMaybe<Scalars['Int']>;
  rosteringEventIds: Array<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryConnectionTypeCollaborativeBanksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryConnectionTypeFilterGradesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeGradesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroup?: InputMaybe<StaffGroupEnum>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  withOnCallRates?: InputMaybe<Scalars['Boolean']>;
};


export type QueryConnectionTypeHubUsersArgs = {
  accountTypes?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hubUserIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  superAdmins?: InputMaybe<Scalars['Boolean']>;
};


export type QueryConnectionTypeMetaActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  queryMetaActivityAttributes?: InputMaybe<QueryMetaActivityInput>;
};


export type QueryConnectionTypeOrganisationStaffGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryConnectionTypeRegulationBodiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameAbbr?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeSkillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeSpecialitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeStaffGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  extended?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryConnectionTypeWorkersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromStartDate?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeRosteringWorkers?: InputMaybe<Scalars['Boolean']>;
  internalOrExternal?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primaryOrSecondary?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['String']>;
  suspended?: InputMaybe<Scalars['String']>;
  toEndDate?: InputMaybe<Scalars['String']>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryCostCentresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDeContractsContractArgs = {
  deContractId: Scalars['Int'];
};


export type QueryDeContractsContractsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  contractIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
};


export type QueryDeContractsVersionArgs = {
  deContractVersionId: Scalars['Int'];
  workerId?: InputMaybe<Scalars['Int']>;
};


export type QueryDeContractsVersionAssignmentArgs = {
  id: Scalars['Int'];
};


export type QueryDeContractsVersionContentArgs = {
  deContractVersionId?: InputMaybe<Scalars['Int']>;
  versionAssignmentId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type QueryDeContractsVersionFieldsArgs = {
  deContractType: Scalars['String'];
};


export type QueryDeContractsVersionsArgs = {
  deContractId: Scalars['Int'];
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDepartmentArgs = {
  id: Scalars['Int'];
};


export type QueryDepartmentsArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryDivisionsArgs = {
  organisationId: Scalars['Int'];
};


export type QueryEscalationLevelsGradeRatesForShiftArgs = {
  shiftId: Scalars['Int'];
};


export type QueryExpensesShiftDataEnrichmentArgs = {
  approverId: Scalars['Int'];
  shiftId: Scalars['Int'];
};


export type QueryExternalAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryExternalUnitArgs = {
  id: Scalars['Int'];
};


export type QueryExternalUnitsArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryFilterGradesArgs = {
  staffGroupId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryFiltersWorkersArgs = {
  forPpi?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGradeArgs = {
  id: Scalars['Int'];
};


export type QueryGradesArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroup?: InputMaybe<StaffGroupEnum>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  withOnCallRates?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHubUserArgs = {
  id: Scalars['Int'];
};


export type QueryHubUsersArgs = {
  accountTypes?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  hubUserIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  superAdmins?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIntegrationBankShiftArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  withoutSites?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMetaActivitiesArgs = {
  queryMetaActivityAttributes?: InputMaybe<QueryMetaActivityInput>;
};


export type QueryOrgStaffGroupSkillsArgs = {
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationArgs = {
  id: Scalars['Int'];
};


export type QueryOrganisationAgenciesArgs = {
  agencyId?: InputMaybe<Scalars['Int']>;
  agencyPortal?: InputMaybe<Scalars['Boolean']>;
  agencyTierId?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationComplianceDocumentsArgs = {
  organisationId: Scalars['Int'];
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationGradesArgs = {
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  rosteringDepartmentListId?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationStaffGroupArgs = {
  organisationId: Scalars['Int'];
  staffGroup: StaffGroupEnum;
};


export type QueryOrganisationStaffGroupsArgs = {
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryOrganisationWorkerArgs = {
  id: Scalars['Int'];
  organisationId?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganisationsArgs = {
  hasPatchworkBank?: InputMaybe<Array<Scalars['Boolean']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  inCollaborativeBank?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  staffGroup?: InputMaybe<StaffGroupEnum>;
};


export type QueryPaidPerItemBatchArgs = {
  batchId: Scalars['Int'];
};


export type QueryPaidPerItemBatchesArgs = {
  inCollaborativeBank?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  payrollCycle?: InputMaybe<PaymentCycleEnum>;
};


export type QueryPaidPerItemDepartmentsArgs = {
  gradeId?: InputMaybe<Scalars['Int']>;
  organisationId: Scalars['Int'];
};


export type QueryPaidPerItemItemArgs = {
  itemId: Scalars['Int'];
};


export type QueryPaidPerItemItemCalculationArgs = {
  gradeId: Scalars['Int'];
  itemId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type QueryPaidPerItemItemsArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryPaidPerItemOrganisationsArgs = {
  workerId: Scalars['Int'];
};


export type QueryPaidPerItemPaymentReceiptsArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  flaggedPayments?: InputMaybe<Scalars['Boolean']>;
  fromDate?: InputMaybe<Scalars['Date']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  inCollaborativeBank?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  paymentCycle: PaymentCycleEnum;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  toDate?: InputMaybe<Scalars['Date']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryPaidPerItemReceiptArgs = {
  receiptId: Scalars['Int'];
};


export type QueryPaidPerItemReceiptsArgs = {
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  fromDate?: InputMaybe<Scalars['Date']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  itemIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Array<ReceiptStatusEnum>>;
  toDate?: InputMaybe<Scalars['Date']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryPaidPerItemWorkerGradesForOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type QueryPaidPerItemWorkerItemsArgs = {
  departmentId: Scalars['Int'];
  gradeId?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryPaidPerItemWorkerReceiptsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  status: Array<ReceiptStatusEnum>;
};


export type QueryPamRateCardsUploadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationId: Scalars['Int'];
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryPassportInformationRequestsArgs = {
  passportRequestId: Scalars['Int'];
};


export type QueryRateAuthorisersArgs = {
  newCoreRate?: InputMaybe<Scalars['Float']>;
  newUnsocialRate?: InputMaybe<Scalars['Float']>;
  rateChanges?: InputMaybe<Array<InputRateChanges>>;
  shiftId: Scalars['Int'];
};


export type QueryRateAuthorisersBulkArgs = {
  shiftIds: Array<Scalars['Int']>;
};


export type QueryRateAuthorisersNewShiftArgs = {
  departmentId: Scalars['Int'];
};


export type QueryRateCardRatesForGradeArgs = {
  departmentId: Scalars['Int'];
  endTime: Scalars['DateTime'];
  gradeId: Scalars['Int'];
  startTime: Scalars['DateTime'];
};


export type QueryRateCardTemplateArgs = {
  rateCardType: RateCardTypeEnum;
};


export type QueryReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  reasonType?: InputMaybe<Scalars['String']>;
};


export type QueryReasonsForCancellationArgs = {
  organisationId: Scalars['Int'];
};


export type QueryReasonsForDeletionArgs = {
  organisationId: Scalars['Int'];
};


export type QueryReasonsForEscalationArgs = {
  organisationId: Scalars['Int'];
};


export type QueryRegulationBodiesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  nameAbbr?: InputMaybe<Scalars['String']>;
};


export type QueryRosterConflictingEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  eventTypeEnum?: InputMaybe<RosteringEventTypeEnum>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  metaActivityIds?: InputMaybe<Array<Scalars['Int']>>;
  organisationActivityIds?: InputMaybe<Array<Scalars['Int']>>;
  rosterId?: InputMaybe<Scalars['Int']>;
  startsAtFrom?: InputMaybe<Scalars['Date']>;
  startsAtUntil?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosterWorkersInShiftArgs = {
  shiftId: Scalars['Int'];
};


export type QueryRosteringActivityByDateDataStructureArgs = {
  rosterUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringAuditLogsForPayRuleArgs = {
  payRuleId: Scalars['Int'];
};


export type QueryRosteringAuditLogsForScheduleArgs = {
  scheduleId: Scalars['Int'];
};


export type QueryRosteringAvailableOrganisationRegistrationsForDateRangeArgs = {
  fromDate: Scalars['Date'];
  rosterId: Scalars['Int'];
  toDate: Scalars['Date'];
};


export type QueryRosteringAvailableOrganisationRegistrationsForShiftArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  rosterId: Scalars['Int'];
};


export type QueryRosteringAvailableOrganisationRegistrationsOnMultipleDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dates: Array<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['Int'];
};


export type QueryRosteringComplianceBreachesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationRegistrationId: Scalars['Int'];
  rosterId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringConflictingEventsArgs = {
  rosteringEventId: Scalars['Int'];
  rosteringOrganisationRegistrationId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringConflictingEventsForEditedShiftArgs = {
  newEndsAt: Scalars['DateTime'];
  newStartsAt: Scalars['DateTime'];
  rosteringEventId: Scalars['Int'];
};


export type QueryRosteringDayPatternShiftSetArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringDayTemplateArgs = {
  uniqueIdentifier: Scalars['String'];
};


export type QueryRosteringDayTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dayTemplateIds?: InputMaybe<Array<Scalars['Int']>>;
  demandTemplateId?: InputMaybe<Scalars['Int']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringDayViewDataStructureArgs = {
  date: Scalars['Date'];
  rotaUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringDayViewRotaShiftSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  rotaUniqueIdentifier: Scalars['String'];
  shiftTypeId: Scalars['Int'];
  siteId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringDemandTemplateArgs = {
  uniqueIdentifier: Scalars['String'];
};


export type QueryRosteringDemandTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  demandTemplateIds?: InputMaybe<Array<Scalars['Int']>>;
  demandTemplateName?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringDemandTemplatesActivitiesArgs = {
  demandTemplateId?: InputMaybe<Scalars['Int']>;
  demandTemplateUniqueIdentifier?: InputMaybe<Scalars['String']>;
  demandTemplatesActivityIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  organisationActivityId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringDepartmentListArgs = {
  uniqueIdentifier: Scalars['String'];
};


export type QueryRosteringDepartmentListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringEventArgs = {
  eventId: Scalars['Int'];
};


export type QueryRosteringEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['String']>;
  eventTypeEnum?: InputMaybe<RosteringEventTypeEnum>;
  eventTypes?: InputMaybe<Array<RosteringEventTypeEnum>>;
  excludeRosterBankShifts?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  metaActivityIds?: InputMaybe<Array<Scalars['Int']>>;
  onlyAvailableShifts?: InputMaybe<Scalars['Boolean']>;
  organisationActivityIds?: InputMaybe<Array<Scalars['Int']>>;
  rosterId?: InputMaybe<Scalars['Int']>;
  startsAtFrom?: InputMaybe<Scalars['Date']>;
  startsAtUntil?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringEventsByIdArgs = {
  eventIds: Array<Scalars['Int']>;
  rosterUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringExceptionReportArgs = {
  reportId: Scalars['Int'];
};


export type QueryRosteringExceptionReportPermissionsArgs = {
  supervisorId: Scalars['Int'];
};


export type QueryRosteringExceptionReportReasonCategoriesArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringExceptionReportsArgs = {
  asApprover?: InputMaybe<Scalars['Boolean']>;
  organisationId: Scalars['Int'];
};


export type QueryRosteringGradesInRotaArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rotaId: Scalars['Int'];
};


export type QueryRosteringGradesOfShiftsInRosterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['Int'];
};


export type QueryRosteringLeaveAbsenceCategoriesArgs = {
  esrType?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
};


export type QueryRosteringLeaveAbsenceTypesArgs = {
  esrType?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
};


export type QueryRosteringLeaveEntitlementsArgs = {
  entitlementLeaveTypeEnum?: InputMaybe<RosteringLeaveEntitlementLeaveTypeEnum>;
  expired?: InputMaybe<Scalars['Boolean']>;
  organisationId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryRosteringLeaveGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leaveUnitType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
};


export type QueryRosteringLeaveLeaveGroupArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
};


export type QueryRosteringLeaveReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leaveAbsenceCategory?: InputMaybe<Scalars['String']>;
  leaveAbsenceType?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringLeaveRequestArgs = {
  id: Scalars['Int'];
  organisationId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryRosteringLeaveRequestApprovalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalActionRequired?: InputMaybe<ApprovalActionRequiredEnum>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  leaveRequestStatuses?: InputMaybe<Array<RosteringLeaveRequestStatusEnum>>;
  leaveRequestTypes?: InputMaybe<Array<RosteringLeaveTypeEnum>>;
  organisationId: Scalars['Int'];
  sortBy?: InputMaybe<OrderByInput>;
  status?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<RosteringLeaveRequestStatusEnum>>;
  uniqueToLeaveRequest?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRosteringLeaveRequestAuditLogsArgs = {
  leaveRequestId: Scalars['Int'];
};


export type QueryRosteringLeaveRequestCalculationArgs = {
  accountId?: InputMaybe<Scalars['Int']>;
  endDatetime: Scalars['DateTime'];
  organisationId: Scalars['Int'];
  rosteringLeaveEntitlementId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
};


export type QueryRosteringLeaveRequestCalendarArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  calendarDate: Scalars['Date'];
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  rosterIds?: InputMaybe<Array<Scalars['Int']>>;
  withBookedLeave?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRosteringLeaveRequestMissedShiftsArgs = {
  endDatetime: Scalars['DateTime'];
  organisationId: Scalars['Int'];
  startDatetime: Scalars['DateTime'];
};


export type QueryRosteringLeaveRequestsArgs = {
  date?: InputMaybe<Scalars['Date']>;
  endDateFrom?: InputMaybe<Scalars['Date']>;
  leaveAbsenceCategory?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
  rosterId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveEntitlementId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveEpisodeId?: InputMaybe<Scalars['Int']>;
  rosteringLeaveTypeId?: InputMaybe<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
  startDateUntil?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<RosteringLeaveRequestStatusEnum>>;
};


export type QueryRosteringLeaveRequestsForWorkerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDateFrom?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosteringLeaveTypes?: InputMaybe<Array<RosteringLeaveTypeEnum>>;
  startDateUntil?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<RosteringLeaveRequestStatusEnum>>;
};


export type QueryRosteringLeaveStaffGroupsGradesArgs = {
  organisationId: Scalars['Int'];
};


export type QueryRosteringLeaveTypesArgs = {
  name?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['Int'];
};


export type QueryRosteringNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringOrgRegDeptListArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringOrgRegDeptListsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentListId?: InputMaybe<Scalars['Int']>;
  esr?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringOrganisationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  metaActivityId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringOrganisationActivitiesForRosterFilterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['Int'];
};


export type QueryRosteringOrganisationActivitiesNotInRosterArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosterUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringOrganisationActivityArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringOrganisationRegistrationArgs = {
  workerId: Scalars['Int'];
};


export type QueryRosteringOrganisationRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  departmentListId?: InputMaybe<Scalars['Int']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  esr?: InputMaybe<Scalars['String']>;
  esrOrFullName?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  onlyPermittedDepartments?: InputMaybe<Scalars['Boolean']>;
  orderByUser?: InputMaybe<OrderByInput>;
  organisationRegistrationIds?: InputMaybe<Array<Scalars['Int']>>;
  rosterIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryRosteringOrganisationRegistrationsForDeptListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentListId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringOrganisationRegistrationsForScheduleArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentListId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  scheduleId: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringOverlappingEventsArgs = {
  eventId: Scalars['Int'];
};


export type QueryRosteringPayRuleArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringPayRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryRosteringPeriodShiftCountArgs = {
  gradeId: Scalars['ID'];
  rosterId: Scalars['ID'];
  rosteringPeriodEndDate: Scalars['Date'];
  rosteringPeriodStartDate: Scalars['Date'];
};


export type QueryRosteringPermissionUsersArgs = {
  organisationId: Scalars['Int'];
  permissionName: Scalars['String'];
  recordId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringPermissionsArgs = {
  category: Scalars['String'];
  subCategory?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringPermittedDepartmentsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringRateCardArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringRateCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringRosterArgs = {
  uniqueIdentifier: Scalars['String'];
};


export type QueryRosteringRosterSchedulesArgs = {
  rosterUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringRostersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  notEnded?: InputMaybe<Scalars['Boolean']>;
  published?: InputMaybe<Scalars['Boolean']>;
  rosterName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uniqueIdentifiers?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRosteringRotaArgs = {
  uniqueIdentifier: Scalars['String'];
};


export type QueryRosteringRotaDaysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rotaId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringRotaShiftSetArgs = {
  rosteringRotaShiftSetId: Scalars['ID'];
};


export type QueryRosteringRotaShiftSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  organisationActivityId?: InputMaybe<Scalars['Int']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  uniqueIdentifier: Scalars['String'];
  unknownActivity?: InputMaybe<Scalars['Boolean']>;
};


export type QueryRosteringRotaSlotArgs = {
  id: Scalars['ID'];
};


export type QueryRosteringRotasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  includeReadyToRoster?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringSchedulesForReassigningShiftArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  shiftId: Scalars['Int'];
};


export type QueryRosteringSchedulesGroupedByPatternArgs = {
  includeWorkerPatternInformation?: InputMaybe<Scalars['Boolean']>;
  rosterUniqueIdentifier: Scalars['String'];
  startDate: Scalars['Date'];
};


export type QueryRosteringShiftArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringShiftSetArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringShiftTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  rosterUniqueIdentifier?: InputMaybe<Scalars['String']>;
};


export type QueryRosteringShiftTypesInRosterArgs = {
  rosterId: Scalars['Int'];
};


export type QueryRosteringSwapsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  eventIdForSwap: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringSwapsRequestArgs = {
  id: Scalars['Int'];
};


export type QueryRosteringSwapsRequestsArgs = {
  organisationId: Scalars['Int'];
};


export type QueryRosteringTeamRotaArgs = {
  endDate: Scalars['Date'];
  rosterId: Scalars['Int'];
  startDate: Scalars['Date'];
};


export type QueryRosteringWorkerEventsGroupedByWeekArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['Int'];
  rosteringOrganisationRegistrationId: Scalars['Int'];
  startDateFrom: Scalars['Date'];
};


export type QueryRosteringWorkerMostRecentShiftArgs = {
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryRosteringWorkerPatternArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


export type QueryRosteringWorkerPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  first?: InputMaybe<Scalars['Int']>;
  fullTime?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfWeeks?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<WorkerPatternStatusEnum>>;
};


export type QueryRosteringWorkerRostersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringWorkerShiftClashesForMultipleShiftsArgs = {
  eventIds: Array<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryRosteringWorkerTimesOffInLieuArgs = {
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


export type QueryRosteringWorkersInActivityArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  eventId: Scalars['Int'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRosteringWorkersShiftsFromOtherRostersArgs = {
  rosterUniqueIdentifier: Scalars['String'];
  startDate: Scalars['Date'];
};


export type QuerySailGetArgs = {
  name: Scalars['String'];
};


export type QuerySailSwitcherArgs = {
  negative: Scalars['String'];
  positive: Scalars['String'];
  throttledBy: Scalars['String'];
};


export type QuerySelfRosteringShiftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
  rosterId: Scalars['ID'];
  rosteringPeriodEndDate: Scalars['Date'];
  rosteringPeriodStartDate: Scalars['Date'];
};


export type QueryShiftArgs = {
  id: Scalars['Int'];
};


export type QueryShiftBlockApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};


export type QueryShiftBlockTimesheetsArgs = {
  afterDate?: InputMaybe<Scalars['Date']>;
  beforeDate?: InputMaybe<Scalars['Date']>;
  historic?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
};


export type QueryShiftBlockWorkersArgs = {
  workerName?: InputMaybe<Scalars['String']>;
};


export type QueryShiftBlocksArgs = {
  activeTimesheet?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  agencyRegistrationId?: InputMaybe<Scalars['Int']>;
  amendedTimesheet?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  dateType?: InputMaybe<FilterDateEnum>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  endDateFrom?: InputMaybe<Scalars['DateTime']>;
  endDateTo?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  orgStaffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  organisationRegistrationId?: InputMaybe<Scalars['Int']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftBlockIds?: InputMaybe<Array<Scalars['Int']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  startDateFrom?: InputMaybe<Scalars['DateTime']>;
  startDateTo?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<ShiftBlockStatusEnum>>;
};


export type QueryShiftBlocksListArgs = {
  activeTimesheet?: InputMaybe<Scalars['Boolean']>;
  agencyRegistrationId?: InputMaybe<Scalars['Int']>;
  amendedTimesheet?: InputMaybe<Scalars['Boolean']>;
  dateType?: InputMaybe<FilterDateEnum>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  endDateFrom?: InputMaybe<Scalars['DateTime']>;
  endDateTo?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  organisationRegistrationId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftBlockIds?: InputMaybe<Array<Scalars['Int']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  startDateFrom?: InputMaybe<Scalars['DateTime']>;
  startDateTo?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<ShiftBlockStatusEnum>>;
};


export type QueryShiftsArgs = {
  batchId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  limitToScope?: InputMaybe<ShiftScope>;
  organisationId?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  shiftBlockId?: InputMaybe<Scalars['ID']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  signOffRequestedShiftsOnly?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  types?: InputMaybe<Array<ShiftTypeEnum>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};


export type QueryShiftsListArgs = {
  agencyWorkerLocumIds?: InputMaybe<Array<Scalars['Int']>>;
  agencyWorkerRegistrationIds?: InputMaybe<Array<Scalars['Int']>>;
  applications?: InputMaybe<ShiftApplicationsEnum>;
  bankWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
  batchId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  divisionIds?: InputMaybe<Array<Scalars['Int']>>;
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  limitToScope?: InputMaybe<ShiftScope>;
  origin?: InputMaybe<ShiftOriginEnum>;
  page?: InputMaybe<Scalars['Int']>;
  rate?: InputMaybe<ShiftRateEscalationEnum>;
  releasedTo?: InputMaybe<ShiftReleasedToEnum>;
  requesterIds?: InputMaybe<Array<Scalars['Int']>>;
  shiftBlockIds?: InputMaybe<Array<Scalars['ID']>>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  statuses?: InputMaybe<Array<ShiftStatus>>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  types?: InputMaybe<Array<ShiftTypeEnum>>;
};


export type QuerySidekiqJobStatusArgs = {
  jobId: Scalars['String'];
};


export type QuerySiteArgs = {
  id: Scalars['Int'];
};


export type QuerySitesArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySkillsArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySkillsForOrganisationStaffGroupArgs = {
  organisationStaffGroupId: Scalars['Int'];
};


export type QuerySkillsForWorkerArgs = {
  organisationId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type QuerySkillsMatchArgs = {
  shiftId: Scalars['Int'];
  workerId: Scalars['Int'];
};


export type QuerySpecialitiesArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryStaffGroupsArgs = {
  extended?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QuerySuperAdminHubUsersArgs = {
  accountTypes?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySuperAdminOrgLockConfigArgs = {
  organisationId: Scalars['Int'];
};


export type QuerySuperAdminVmsAgenciesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySuperAdminVmsAgencyArgs = {
  agencyId?: InputMaybe<Scalars['Int']>;
};


export type QuerySuperAdminVmsAgencyUserPermissionsArgs = {
  agencyUserId: Scalars['Int'];
};


export type QuerySuperAdminVmsAgencyUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isSuperUser?: InputMaybe<Scalars['Boolean']>;
  isSuspended?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QuerySuperAdminWorkersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fromStartDate?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeRosteringWorkers?: InputMaybe<Scalars['Boolean']>;
  internalOrExternal?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationId?: InputMaybe<Scalars['Int']>;
  primaryOrSecondary?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['String']>;
  suspended?: InputMaybe<Scalars['String']>;
  toEndDate?: InputMaybe<Scalars['String']>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QuerySuperAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  items?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryTeamRosterArgs = {
  from?: InputMaybe<Scalars['String']>;
  onlyAssignedShifts?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryTeamRosterPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  onlyAssignedShifts?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryTempStaffBatchArgs = {
  batchId: Scalars['Int'];
};


export type QueryTempStaffBatchesArgs = {
  items: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
  page: Scalars['Int'];
  paymentCategory: PaymentCategoryEnum;
  payrollCycle: TempStaffPaymentCycleEnum;
  reference?: InputMaybe<Scalars['Int']>;
};


export type QueryTempStaffPaymentBatchArgs = {
  batchId: Scalars['Int'];
};


export type QueryTempStaffPaymentBatchesArgs = {
  items: Scalars['Int'];
  organisationStaffGroupId: Scalars['Int'];
  page: Scalars['Int'];
  paymentCategory: PaymentCategoryEnum;
  payrollCycle: TempStaffPaymentCycleEnum;
  reference?: InputMaybe<Scalars['Int']>;
};


export type QueryTempStaffPaymentsShiftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  flaggedPayments?: InputMaybe<FlaggedPaymentsOptionsEnum>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  group?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  paymentCategory?: InputMaybe<PaymentCategoryEnum>;
  paymentCycle: TempStaffPaymentCycleEnum;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
  workerId?: InputMaybe<Scalars['Int']>;
};


export type QueryValidatePasswordArgs = {
  password: Scalars['String'];
};


export type QueryVisaLimitsMaxHoursCheckArgs = {
  shiftIds: Array<Scalars['ID']>;
  workerId: Scalars['ID'];
};


export type QueryVisaLimitsMaxHoursNewShiftCheckArgs = {
  shifts: Array<ShiftHours>;
  workerId: Scalars['ID'];
};


export type QueryVmsAgencyInvitationArgs = {
  token: Scalars['String'];
};


export type QueryVmsAgencyRegistrationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  agencyRegistrationId?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  hideLtdWorkers?: InputMaybe<Scalars['Boolean']>;
  hideSoleTraders?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  trashed?: InputMaybe<Scalars['Boolean']>;
};


export type QueryVmsAgencyUserAgenciesArgs = {
  agencyUserId: Scalars['Int'];
};


export type QueryVmsAgencyUserPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  agencyUserId: Scalars['Int'];
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryVmsAgencyUsersArgs = {
  email?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryVmsAgencyWorkerProposalRatesArgs = {
  agencyRegistrationId: Scalars['Int'];
  revisedRates?: InputMaybe<Array<RevisedProposalRate>>;
  shiftBlockId?: InputMaybe<Scalars['Int']>;
  shiftId?: InputMaybe<Scalars['Int']>;
};


export type QueryVmsAgencyWorkerProposalsArgs = {
  shiftId: Scalars['Int'];
};


export type QueryVmsAgencyWorkersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
};


export type QueryVmsShiftArgs = {
  id: Scalars['Int'];
};


export type QueryVmsShiftsArgs = {
  after?: InputMaybe<Scalars['String']>;
  agencyRegistrationId?: InputMaybe<Scalars['Int']>;
  agencyWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
  before?: InputMaybe<Scalars['String']>;
  bookedByAgency?: InputMaybe<Scalars['Boolean']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  filterByShiftBlocks?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  last?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  rate?: InputMaybe<Scalars['String']>;
  shiftBlockUid?: InputMaybe<Scalars['String']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<ShiftStatus>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryVmsWorkerRegistrationArgs = {
  after?: InputMaybe<Scalars['String']>;
  agencyRegistrationId: Scalars['Int'];
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkedShiftsArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  fromStartTime?: InputMaybe<Scalars['DateTime']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  items?: InputMaybe<Scalars['Int']>;
  limitToScope?: InputMaybe<ShiftScope>;
  page?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  toStartTime?: InputMaybe<Scalars['DateTime']>;
};


export type QueryWorkerArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkerOnCallRatesPreviewArgs = {
  shift: InputMultipleOnCallShift;
};


export type QueryWorkerOrganisationShiftsArgs = {
  organisationIds: Array<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type QueryWorkerPrimaryOrganisationBasicInfoArgs = {
  workerId: Scalars['Int'];
};


export type QueryWorkerShiftArgs = {
  breaks?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  proposedEndTime?: InputMaybe<Scalars['String']>;
  proposedStartTime?: InputMaybe<Scalars['String']>;
};


export type QueryWorkersArgs = {
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<Scalars['String']>;
  fromStartDate?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeRosteringWorkers?: InputMaybe<Scalars['Boolean']>;
  internalOrExternal?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  primaryOrSecondary?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Scalars['String']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  staffGroupIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<Scalars['String']>;
  suspended?: InputMaybe<Scalars['String']>;
  toEndDate?: InputMaybe<Scalars['String']>;
  workerId?: InputMaybe<Scalars['Int']>;
  workerIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryWorkersShiftsArgs = {
  dateType?: InputMaybe<FilterDateEnum>;
  distanceInMiles?: InputMaybe<Scalars['Int']>;
  endsBy?: InputMaybe<Scalars['String']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  filterGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  fromStartTime?: InputMaybe<Scalars['Date']>;
  gradeIds?: InputMaybe<Array<Scalars['Int']>>;
  hideUnbookable?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['Float']>;
  limitToScope?: InputMaybe<ShiftScope>;
  longitude?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyInstantBook?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<ShiftOrderBy>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  startsAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ShiftStatus>;
  timeType?: InputMaybe<FilterTimeEnum>;
  toStartTime?: InputMaybe<Scalars['Date']>;
};


export type QueryWorkersShiftsQuickArgs = {
  filterGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  fromStartTime?: InputMaybe<Scalars['Date']>;
  items?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationIds?: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<ShiftStatus>;
  toStartTime?: InputMaybe<Scalars['Date']>;
};


export type QueryWorkersShiftsSummaryArgs = {
  dateType?: InputMaybe<FilterDateEnum>;
  distanceInMiles?: InputMaybe<Scalars['Int']>;
  endsBy?: InputMaybe<Scalars['String']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  filterGradeIds?: InputMaybe<Array<Scalars['Int']>>;
  fromStartTime?: InputMaybe<Scalars['Date']>;
  hideUnbookable?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  limitToScope?: InputMaybe<ShiftScope>;
  longitude?: InputMaybe<Scalars['Float']>;
  onlyInstantBook?: InputMaybe<Scalars['Boolean']>;
  selectedDates?: InputMaybe<Array<Scalars['String']>>;
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  specialityId?: InputMaybe<Scalars['Int']>;
  specialityIds?: InputMaybe<Array<Scalars['Int']>>;
  startsAfter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ShiftStatus>;
  timeType?: InputMaybe<FilterTimeEnum>;
  toStartTime?: InputMaybe<Scalars['Date']>;
};

export type QueryMetaActivityInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RateAuthoriser = {
  __typename?: 'RateAuthoriser';
  departments: Array<Department>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  outOfHours?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

/** A RosteringRateCard */
export type RateCard = {
  __typename?: 'RateCard';
  /** Basic annual salary */
  basicAnnualSalary: Scalars['Float'];
  /** RateCard Creation timestamp */
  createdAt: Scalars['DateTime'];
  /** associated Grade */
  grade: Grade;
  /** RosteringRateCard ID */
  id: Scalars['ID'];
  /** Is this rate card for a junior doctor */
  juniorDoctor: Scalars['Boolean'];
  /** Name of rate card */
  name: Scalars['String'];
  /** Night shift end time in multiples of 30 minutes */
  nightShiftEndTime: Scalars['String'];
  /** Night shift hours rate applied only for weekdays (excluding NROC shifts) */
  nightShiftHourlyRate: Scalars['Float'];
  /** Night shift start time in multiples of 30 minutes */
  nightShiftStartTime: Scalars['String'];
  /** Nodal point between 1-5, corresponding to salary band */
  nodalPoint?: Maybe<Scalars['Int']>;
  /** On-Call annual rate (Flat rate in pounds added to yearly salary) */
  onCallAnnualRate: Scalars['Float'];
  /** associated OrganisationStaffGroup */
  organisationStaffGroup: OrganisationStaffGroup;
  /** % uplift on basic salary when on average 1 in 2 weekends worked */
  weekendPercentageRateMin1In2: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 3 weekends worked */
  weekendPercentageRateMin1In3: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 4 weekends worked */
  weekendPercentageRateMin1In4: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 5 weekends worked */
  weekendPercentageRateMin1In5: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 6 weekends worked */
  weekendPercentageRateMin1In6: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 7 weekends worked */
  weekendPercentageRateMin1In7: Scalars['Float'];
  /** % uplift on basic salary when on average 1 in 8 weekends worked */
  weekendPercentageRateMin1In8: Scalars['Float'];
};

/** The connection type for RateCard. */
export type RateCardConnection = {
  __typename?: 'RateCardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RateCardEdge>>>;
  /** A list of nodes. */
  nodes: Array<RateCard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RateCardEdge = {
  __typename?: 'RateCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RateCard>;
};

/** Type of rate card */
export enum RateCardTypeEnum {
  /** Bank Rate Card */
  Bank = 'BANK',
  /** Collaborative Bank Rate Card */
  CollabBank = 'COLLAB_BANK',
  /** PAM Rate Card */
  Pam = 'PAM'
}

export type RateChange = {
  __typename?: 'RateChange';
  additionalDetails?: Maybe<Scalars['String']>;
  approved: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  escalatedRateElements: Array<EscalatedRateElement>;
  escalationLevel?: Maybe<Level>;
  escalationLevelId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  newCoreRate: Scalars['Float'];
  newTotalPay: Scalars['Float'];
  newUnsocialRate: Scalars['Float'];
  oldCoreRate: Scalars['Float'];
  oldTotalPay: Scalars['Float'];
  oldUnsocialRate: Scalars['Float'];
  reason: Scalars['String'];
  requester: HubUser;
};

/** Autogenerated return type of ReactivateAgency */
export type ReactivateAgencyPayload = {
  __typename?: 'ReactivateAgencyPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ReadNotifications */
export type ReadNotificationsPayload = {
  __typename?: 'ReadNotificationsPayload';
  errors: Array<ValidationError>;
  notifications?: Maybe<Array<RosteringNotifications>>;
};

export type Reason = {
  __typename?: 'Reason';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  organisation: Organisation;
  reason: Scalars['String'];
  type?: Maybe<ReasonTypeEnum>;
};

/** The connection type for Reason. */
export type ReasonConnection = {
  __typename?: 'ReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReasonEdge>>>;
  /** A list of nodes. */
  nodes: Array<Reason>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReasonEdge = {
  __typename?: 'ReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Reason>;
};

export type ReasonForCancellation = {
  __typename?: 'ReasonForCancellation';
  id: Scalars['Int'];
  reason: Scalars['String'];
};

/** Reason for deletion of shift or shift block */
export type ReasonForDeletion = {
  __typename?: 'ReasonForDeletion';
  /** ID of the reason */
  id: Scalars['Int'];
  /** Reason for deletion */
  reason: Scalars['String'];
};

/** Reason for escalation of a shift */
export type ReasonForEscalation = {
  __typename?: 'ReasonForEscalation';
  id: Scalars['Int'];
  reason: Scalars['String'];
};

export type ReasonForRequest = {
  __typename?: 'ReasonForRequest';
  id: Scalars['Int'];
  reason: Scalars['String'];
};

/** Reason inheritance subclasses class name */
export enum ReasonTypeEnum {
  Cancellation = 'cancellation',
  CancellationTrust = 'cancellation_trust',
  CancellationWorker = 'cancellation_worker',
  Deletion = 'deletion',
  Escalation = 'escalation',
  Request = 'request'
}

/** Autogenerated return type of RecallFromAgency */
export type RecallFromAgencyPayload = {
  __typename?: 'RecallFromAgencyPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of RecallFromBank */
export type RecallFromBankPayload = {
  __typename?: 'RecallFromBankPayload';
  errors: Array<ValidationError>;
  /** Rostering Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Input type to create a bank shift from roster */
export type RecallFromBankShiftInput = {
  /** Free text comments */
  comments: Scalars['String'];
  /** Reason for recall */
  reason: Scalars['String'];
  /** Roster event id */
  rosteringEventId: Scalars['Int'];
};

/** PaidPerItem Receipt */
export type Receipt = {
  __typename?: 'Receipt';
  authoriserName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateCompleted: Scalars['Date'];
  department: Department;
  esrNumber?: Maybe<Scalars['String']>;
  grade: Grade;
  id: Scalars['Int'];
  organisation: Organisation;
  ppiAuditEvents: Array<PpiAuditEvent>;
  receiptItems: Array<ReceiptItem>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  rejectedByHubUser?: Maybe<HubUser>;
  rejectionNotes?: Maybe<Scalars['String']>;
  site: Site;
  status: Scalars['String'];
  totalCost: Scalars['Float'];
  worker: Worker;
};

/** PaidPerItem ReceiptItem */
export type ReceiptItem = {
  __typename?: 'ReceiptItem';
  costCentre?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  id: Scalars['Int'];
  itemCost: Scalars['Float'];
  itemCostWithWtd: Scalars['Float'];
  itemGradeCost: ItemGradeCost;
  totalCost: Scalars['Float'];
  wtdCost: Scalars['Float'];
  wtdPercentage?: Maybe<Scalars['Float']>;
};

/** Input arguments to create receipt items */
export type ReceiptItemInput = {
  /** Cost centre associated with receipt items */
  costCentre?: InputMaybe<Scalars['String']>;
  /** Number of items completed */
  count: Scalars['Int'];
  /** Receipt Item ID */
  id?: InputMaybe<Scalars['Int']>;
  /** ID of item grade cost */
  itemGradeCostId?: InputMaybe<Scalars['Int']>;
};

/** PPI Receipt status count */
export type ReceiptStatusCount = {
  __typename?: 'ReceiptStatusCount';
  status: Scalars['String'];
  totalCount: Scalars['Int'];
};

/** Enum of receipt status */
export enum ReceiptStatusEnum {
  /** Approved for payment */
  ApprovedForPayment = 'APPROVED_FOR_PAYMENT',
  /** Paid */
  Paid = 'PAID',
  /** Pending approval */
  PendingApproval = 'PENDING_APPROVAL',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Review */
  Review = 'REVIEW',
  /** Signed off */
  SignedOff = 'SIGNED_OFF',
  /** Sign off requested */
  SignOffRequested = 'SIGN_OFF_REQUESTED'
}

/** Filters for PPI receipts */
export type ReceiptsFilters = {
  __typename?: 'ReceiptsFilters';
  itemFilterOptions: Array<ItemFilterOption>;
};

/** Filter options for receipts */
export type ReceiptsFiltersOptions = {
  __typename?: 'ReceiptsFiltersOptions';
  /** locations */
  locations: Array<FilterOption>;
  /** workers */
  workers: Array<FilterOption>;
};

/** Autogenerated return type of RecoverAgencyRegistration */
export type RecoverAgencyRegistrationPayload = {
  __typename?: 'RecoverAgencyRegistrationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type Referee = {
  __typename?: 'Referee';
  contactNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

/** Reference data for EDI questions */
export type ReferenceData = {
  __typename?: 'ReferenceData';
  /** List of options for country of qualification question */
  countryOfQualification?: Maybe<Array<CountryOfQualification>>;
  /** List of options for disability question */
  disability?: Maybe<Array<Disability>>;
  /** List of options for ethnicity question */
  ethnicitySection?: Maybe<Array<EthnicitySection>>;
  /** List of options for gender question */
  gender?: Maybe<Array<Gender>>;
};

/** Represents Region */
export type Region = {
  __typename?: 'Region';
  /** Database ID of the region */
  id: Scalars['Int'];
  /** Region name */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RegisterAgencyWorker */
export type RegisterAgencyWorkerPayload = {
  __typename?: 'RegisterAgencyWorkerPayload';
  agencyRegistrationId?: Maybe<Scalars['Int']>;
  errors: Array<ValidationError>;
  userId?: Maybe<Scalars['Int']>;
};

/** Regulation body ID, name and registration number max length */
export type RegulationBody = {
  __typename?: 'RegulationBody';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nameAbbr?: Maybe<Scalars['String']>;
  registrationNumberMaxLength?: Maybe<Scalars['Int']>;
};

/** The connection type for RegulationBody. */
export type RegulationBodyConnection = {
  __typename?: 'RegulationBodyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RegulationBodyEdge>>>;
  /** A list of nodes. */
  nodes: Array<RegulationBody>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RegulationBodyEdge = {
  __typename?: 'RegulationBodyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RegulationBody>;
};

/** Autogenerated return type of RejectApplication */
export type RejectApplicationPayload = {
  __typename?: 'RejectApplicationPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of RejectLeaveRequestApproval */
export type RejectLeaveRequestApprovalPayload = {
  __typename?: 'RejectLeaveRequestApprovalPayload';
  errors: Array<ValidationError>;
  rosteringLeaveRequestApproval?: Maybe<LeaveRequestApproval>;
};

/** Autogenerated return type of Reject */
export type RejectPayload = {
  __typename?: 'RejectPayload';
  errors: Array<ValidationError>;
  /** was the receipt rejected? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RejectRateChange */
export type RejectRateChangePayload = {
  __typename?: 'RejectRateChangePayload';
  errors: Array<ValidationError>;
  /** Success indicator */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RejectRequest */
export type RejectRequestPayload = {
  __typename?: 'RejectRequestPayload';
  errors: Array<ValidationError>;
  /** The shift swap request */
  rosteringShiftSwapRequest?: Maybe<Request>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RejectShiftApplication */
export type RejectShiftApplicationPayload = {
  __typename?: 'RejectShiftApplicationPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<Shift>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RelationshipCreation */
export type RelationshipCreationPayload = {
  __typename?: 'RelationshipCreationPayload';
  errors: Array<ValidationError>;
};

/** Autogenerated return type of RemoveShiftsFromFlaggedPayments */
export type RemoveShiftsFromFlaggedPaymentsPayload = {
  __typename?: 'RemoveShiftsFromFlaggedPaymentsPayload';
  errors: Array<ValidationError>;
  /** If false see shiftsWithErrors to see which ones failed, if true all were successful */
  result: Scalars['Boolean'];
  /** The ids of the shifts that failed to be removed from flagged payments */
  shiftsWithErrors: Array<Scalars['Int']>;
};

/** Autogenerated return type of RemoveShifts */
export type RemoveShiftsPayload = {
  __typename?: 'RemoveShiftsPayload';
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveSuperAdmin */
export type RemoveSuperAdminPayload = {
  __typename?: 'RemoveSuperAdminPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RemoveWeek */
export type RemoveWeekPayload = {
  __typename?: 'RemoveWeekPayload';
  errors: Array<ValidationError>;
  /** Updated WorkerPattern */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Autogenerated return type of ReportDeviceToken */
export type ReportDeviceTokenPayload = {
  __typename?: 'ReportDeviceTokenPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** A request to swap shifts */
export type Request = {
  __typename?: 'Request';
  createdAt: Scalars['DateTime'];
  eventToSwap: RosteringEvent;
  id: Scalars['ID'];
  proposedEvent: RosteringEvent;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated return type of RequestOneTimeCode */
export type RequestOneTimeCodePayload = {
  __typename?: 'RequestOneTimeCodePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RequestPin */
export type RequestPinPayload = {
  __typename?: 'RequestPinPayload';
  errors: Array<ValidationError>;
  /** Was PIN requested */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RequestPinWithEmail */
export type RequestPinWithEmailPayload = {
  __typename?: 'RequestPinWithEmailPayload';
  errors: Array<ValidationError>;
  /** Was PIN requested */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResendOneTimeCode */
export type ResendOneTimeCodePayload = {
  __typename?: 'ResendOneTimeCodePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResendOrUpdateLatestContractVersion */
export type ResendOrUpdateLatestContractVersionPayload = {
  __typename?: 'ResendOrUpdateLatestContractVersionPayload';
  errors: Array<ValidationError>;
  /** Was the version assignment re-sent? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResetPin */
export type ResetPinPayload = {
  __typename?: 'ResetPinPayload';
  errors: Array<ValidationError>;
  /** Was PIN requested */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RestoreDepartment */
export type RestoreDepartmentPayload = {
  __typename?: 'RestoreDepartmentPayload';
  /** Department object */
  department?: Maybe<Department>;
  errors: Array<ValidationError>;
  /** Success status */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RestoreGrade */
export type RestoreGradePayload = {
  __typename?: 'RestoreGradePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RevertToDraft */
export type RevertToDraftPayload = {
  __typename?: 'RevertToDraftPayload';
  errors: Array<ValidationError>;
  /** The Rostering::WorkerPatter marked as draft */
  rosteringWorkerPattern?: Maybe<WorkerPattern>;
};

/** Revised agency shift rates for validation */
export type RevisedProposalRate = {
  agencyMargin?: InputMaybe<Scalars['Float']>;
  comparatorRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  workerRate?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of RollbackFromPayroll */
export type RollbackFromPayrollPayload = {
  __typename?: 'RollbackFromPayrollPayload';
  errors: Array<ValidationError>;
  /** The success of the rollback */
  success: Scalars['Boolean'];
};

export type RosterAddScheduleInput = {
  rosterId: Scalars['Int'];
  rosteringOrganisationRegistrationId?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of RosterAddSchedule */
export type RosterAddSchedulePayload = {
  __typename?: 'RosterAddSchedulePayload';
  errors: Array<ValidationError>;
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

/** A Bank Shift created from Rostering */
export type RosterBankShift = {
  __typename?: 'RosterBankShift';
  adminNote?: Maybe<Scalars['String']>;
  breaks: Scalars['Int'];
  coreTotalRate?: Maybe<Scalars['String']>;
  costCentre: Scalars['String'];
  /** Timestamp of Shift creation */
  createdAt: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  /** Event ID */
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  rate?: Maybe<Scalars['Float']>;
  reasonForRequest?: Maybe<ReasonForRequest>;
  shiftType: ShiftKindEnum;
  siteId?: Maybe<Scalars['Int']>;
  skills?: Maybe<Array<Skill>>;
  startTime?: Maybe<Scalars['DateTime']>;
  totalPay?: Maybe<Scalars['String']>;
  unsocialTotalRate?: Maybe<Scalars['String']>;
  /** Timestamp of Shift Update */
  updatedAt: Scalars['DateTime'];
  worker?: Maybe<Worker>;
};

/** Bulk create shifts */
export type RosterBulkCreateShiftEventInput = {
  /** Date */
  date: Scalars['Date'];
  /** UUIDs of the activities for the new ShiftEvent */
  shiftActivitiesIds: Array<Scalars['String']>;
  /** ID of the ShiftTemplate from which to create a ShiftEvent */
  shiftTemplateId: Scalars['Int'];
};

/** Autogenerated return type of RosterCreate */
export type RosterCreatePayload = {
  __typename?: 'RosterCreatePayload';
  errors: Array<ValidationError>;
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of RosterDelete */
export type RosterDeletePayload = {
  __typename?: 'RosterDeletePayload';
  errors: Array<ValidationError>;
  /** The deleted Roster */
  rosteringRoster?: Maybe<RosteringRoster>;
};

export type RosterPermission = {
  __typename?: 'RosterPermission';
  canManageExceptionReportApprovers: Scalars['Boolean'];
  canOverrideComplianceRulesTier1: Scalars['Boolean'];
  canOverrideComplianceRulesTier2: Scalars['Boolean'];
  canOverrideComplianceRulesTier3: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Row Orders for Roster Views, for a given User */
export type RosterRowOrder = {
  __typename?: 'RosterRowOrder';
  /** order of Activities on Activity x Date view */
  activityViewOrder?: Maybe<Scalars['JSON']>;
  /** Rostering::RosterRowOrder ID */
  id: Scalars['ID'];
  /** associated Roster */
  roster: RosteringRoster;
  /** associated User */
  user: User;
  /** order of Worker Patterns and their Schedules on Worker Pattern view */
  workerPatternViewOrder?: Maybe<Scalars['JSON']>;
  /** order of Schedules on Worker x Date view */
  workerViewOrder?: Maybe<Scalars['JSON']>;
};

/** Roster status - published, unpublished */
export enum RosterStatusEnum {
  /** Roster is published */
  Published = 'published',
  /** Roster status is unpublished */
  Unpublished = 'unpublished'
}

/** Autogenerated return type of RosterUpdate */
export type RosterUpdatePayload = {
  __typename?: 'RosterUpdatePayload';
  errors: Array<ValidationError>;
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** The inputs required to load a Worker Pattern into a Roster */
export type RosterWorkerPatternInput = {
  /** When a pattern should end */
  endDate: Scalars['Date'];
  /** Number of schedules to create */
  numberOfSlots: Scalars['Int'];
  /** When a pattern should start */
  startDate: Scalars['Date'];
  /** The unique id of rostering worker pattern */
  workerPatternId: Scalars['Int'];
};

/** Autogenerated return type of RosteringAddDayTemplateToRota */
export type RosteringAddDayTemplateToRotaPayload = {
  __typename?: 'RosteringAddDayTemplateToRotaPayload';
  errors: Array<ValidationError>;
  rosteringRota?: Maybe<RosteringRota>;
};

/** Autogenerated return type of RosteringAddWorkerToSchedule */
export type RosteringAddWorkerToSchedulePayload = {
  __typename?: 'RosteringAddWorkerToSchedulePayload';
  errors: Array<ValidationError>;
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

/** Autogenerated return type of RosteringAvailabilitiesOnRostersUpdate */
export type RosteringAvailabilitiesOnRostersUpdatePayload = {
  __typename?: 'RosteringAvailabilitiesOnRostersUpdatePayload';
  /** list of changed availabilities */
  availabilitiesOnRosters?: Maybe<Array<AvailabilityOnRoster>>;
  /** new schedule which may be created if effective dates are updated from a roster */
  createdScheduleForCurrentRoster?: Maybe<RosteringSchedule>;
  errors: Array<ValidationError>;
  /** extended schedule if availability of worker is lengthened */
  extendedScheduleForCurrentRoster?: Maybe<RosteringSchedule>;
};

export type RosteringDayPattern = {
  __typename?: 'RosteringDayPattern';
  dayOrder: Scalars['Int'];
  id: Scalars['ID'];
  rosteringDayPatternShiftSets?: Maybe<Array<RosteringDayPatternShiftSet>>;
  rosteringDayTemplate?: Maybe<RosteringDayTemplate>;
  rosteringDayTemplatesActivities?: Maybe<Array<RosteringDayTemplatesActivity>>;
};

/** Autogenerated return type of RosteringDayPatternCreate */
export type RosteringDayPatternCreatePayload = {
  __typename?: 'RosteringDayPatternCreatePayload';
  errors: Array<ValidationError>;
  rosteringDayPattern?: Maybe<RosteringDayPattern>;
};

/** Autogenerated return type of RosteringDayPatternDelete */
export type RosteringDayPatternDeletePayload = {
  __typename?: 'RosteringDayPatternDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type RosteringDayPatternShiftSet = {
  __typename?: 'RosteringDayPatternShiftSet';
  adminNote?: Maybe<Scalars['String']>;
  grade: Grade;
  id: Scalars['ID'];
  idealQuantity?: Maybe<Scalars['Int']>;
  location?: Maybe<Location>;
  locations?: Maybe<Array<Location>>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  rosteringDayTemplatesActivity: RosteringDayTemplatesActivity;
  rosteringShiftTemplate: RosteringShiftTemplate;
  rosteringShiftType: RosteringShiftType;
  shiftEndTime?: Maybe<Scalars['DateTime']>;
  shiftStartTime?: Maybe<Scalars['DateTime']>;
  sites?: Maybe<Array<Site>>;
  skills?: Maybe<Array<Skill>>;
};

/** Autogenerated return type of RosteringDayPatternShiftSetCreate */
export type RosteringDayPatternShiftSetCreatePayload = {
  __typename?: 'RosteringDayPatternShiftSetCreatePayload';
  errors: Array<ValidationError>;
  rosteringDayPatternShiftSet?: Maybe<RosteringDayPatternShiftSet>;
};

/** Autogenerated return type of RosteringDayPatternShiftSetDelete */
export type RosteringDayPatternShiftSetDeletePayload = {
  __typename?: 'RosteringDayPatternShiftSetDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RosteringDayPatternShiftSetUpdate */
export type RosteringDayPatternShiftSetUpdatePayload = {
  __typename?: 'RosteringDayPatternShiftSetUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDayPatternShiftSet?: Maybe<RosteringDayPatternShiftSet>;
};

export type RosteringDayTemplate = {
  __typename?: 'RosteringDayTemplate';
  createdBy?: Maybe<HubUser>;
  dayPatternsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rosteringDayPatterns?: Maybe<Array<RosteringDayPattern>>;
  rosteringDayTemplatesActivities?: Maybe<Array<RosteringDayTemplatesActivity>>;
  rosteringDemandTemplate: RosteringDemandTemplate;
  uniqueIdentifier: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for RosteringDayTemplate. */
export type RosteringDayTemplateConnection = {
  __typename?: 'RosteringDayTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringDayTemplateEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringDayTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringDayTemplateCreate */
export type RosteringDayTemplateCreatePayload = {
  __typename?: 'RosteringDayTemplateCreatePayload';
  errors: Array<ValidationError>;
  rosteringDayTemplate?: Maybe<RosteringDayTemplate>;
};

/** Autogenerated return type of RosteringDayTemplateDelete */
export type RosteringDayTemplateDeletePayload = {
  __typename?: 'RosteringDayTemplateDeletePayload';
  errors: Array<ValidationError>;
  rosteringDayTemplate?: Maybe<RosteringDayTemplate>;
};

/** An edge in a connection. */
export type RosteringDayTemplateEdge = {
  __typename?: 'RosteringDayTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringDayTemplate>;
};

/** Autogenerated return type of RosteringDayTemplateUpdate */
export type RosteringDayTemplateUpdatePayload = {
  __typename?: 'RosteringDayTemplateUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDayTemplate?: Maybe<RosteringDayTemplate>;
};

export type RosteringDayTemplatesActivity = {
  __typename?: 'RosteringDayTemplatesActivity';
  id: Scalars['ID'];
  order: Scalars['Int'];
  rosteringDayPatternShiftSets?: Maybe<Array<RosteringDayPatternShiftSet>>;
  rosteringDayTemplate: RosteringDayTemplate;
  rosteringOrganisationActivity: RosteringOrganisationActivity;
};

/** Autogenerated return type of RosteringDayTemplatesActivityUpdate */
export type RosteringDayTemplatesActivityUpdatePayload = {
  __typename?: 'RosteringDayTemplatesActivityUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDayTemplatesActivity?: Maybe<RosteringDayTemplatesActivity>;
};

export type RosteringDemandTemplate = {
  __typename?: 'RosteringDemandTemplate';
  createdBy?: Maybe<HubUser>;
  department?: Maybe<Department>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rosteringDemandTemplatesActivities?: Maybe<Array<RosteringDemandTemplatesActivity>>;
  uniqueIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for RosteringDemandTemplate. */
export type RosteringDemandTemplateConnection = {
  __typename?: 'RosteringDemandTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringDemandTemplateEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringDemandTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringDemandTemplateCreate */
export type RosteringDemandTemplateCreatePayload = {
  __typename?: 'RosteringDemandTemplateCreatePayload';
  errors: Array<ValidationError>;
  rosteringDemandTemplate?: Maybe<RosteringDemandTemplate>;
};

/** An edge in a connection. */
export type RosteringDemandTemplateEdge = {
  __typename?: 'RosteringDemandTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringDemandTemplate>;
};

/** Autogenerated return type of RosteringDemandTemplateUpdate */
export type RosteringDemandTemplateUpdatePayload = {
  __typename?: 'RosteringDemandTemplateUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDemandTemplate?: Maybe<RosteringDemandTemplate>;
};

export type RosteringDemandTemplatesActivity = {
  __typename?: 'RosteringDemandTemplatesActivity';
  id: Scalars['ID'];
  rosteringDemandTemplate?: Maybe<RosteringDemandTemplate>;
  rosteringOrganisationActivity?: Maybe<RosteringOrganisationActivity>;
  rosteringShiftSets?: Maybe<Array<RosteringShiftSet>>;
};

/** Autogenerated return type of RosteringDemandTemplatesActivityCreate */
export type RosteringDemandTemplatesActivityCreatePayload = {
  __typename?: 'RosteringDemandTemplatesActivityCreatePayload';
  errors: Array<ValidationError>;
  rosteringDemandTemplatesActivity?: Maybe<RosteringDemandTemplatesActivity>;
};

/** Autogenerated return type of RosteringDemandTemplatesActivityDelete */
export type RosteringDemandTemplatesActivityDeletePayload = {
  __typename?: 'RosteringDemandTemplatesActivityDeletePayload';
  errors: Array<ValidationError>;
  rosteringDemandTemplatesActivity?: Maybe<RosteringDemandTemplatesActivity>;
};

/** Autogenerated return type of RosteringDemandTemplatesActivityUpdate */
export type RosteringDemandTemplatesActivityUpdatePayload = {
  __typename?: 'RosteringDemandTemplatesActivityUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDemandTemplatesActivity?: Maybe<RosteringDemandTemplatesActivity>;
};

export type RosteringDepartmentInfo = {
  __typename?: 'RosteringDepartmentInfo';
  date?: Maybe<Scalars['Date']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['Int']>;
};

export type RosteringDepartmentList = {
  __typename?: 'RosteringDepartmentList';
  createdAt: Scalars['DateTime'];
  department: Department;
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  permissions?: Maybe<Array<RosteringPermissionSet>>;
  startDate: Scalars['Date'];
  uniqueIdentifier?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated return type of RosteringDepartmentListAddWorkers */
export type RosteringDepartmentListAddWorkersPayload = {
  __typename?: 'RosteringDepartmentListAddWorkersPayload';
  errors: Array<ValidationError>;
  rosteringDepartmentList?: Maybe<RosteringDepartmentList>;
};

/** The connection type for RosteringDepartmentList. */
export type RosteringDepartmentListConnection = {
  __typename?: 'RosteringDepartmentListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringDepartmentListEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringDepartmentList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringDepartmentListCreate */
export type RosteringDepartmentListCreatePayload = {
  __typename?: 'RosteringDepartmentListCreatePayload';
  errors: Array<ValidationError>;
  rosteringDepartmentList?: Maybe<RosteringDepartmentList>;
};

/** Autogenerated return type of RosteringDepartmentListDelete */
export type RosteringDepartmentListDeletePayload = {
  __typename?: 'RosteringDepartmentListDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type RosteringDepartmentListEdge = {
  __typename?: 'RosteringDepartmentListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringDepartmentList>;
};

/** Autogenerated return type of RosteringDepartmentListUpdate */
export type RosteringDepartmentListUpdatePayload = {
  __typename?: 'RosteringDepartmentListUpdatePayload';
  errors: Array<ValidationError>;
  rosteringDepartmentList?: Maybe<RosteringDepartmentList>;
};

/** Autogenerated return type of RosteringEsrCreate */
export type RosteringEsrCreatePayload = {
  __typename?: 'RosteringEsrCreatePayload';
  errors: Array<ValidationError>;
  rosteringEsrNumber?: Maybe<RosteringEsrNumber>;
};

export type RosteringEsrNumber = {
  __typename?: 'RosteringEsrNumber';
  authoriser: HubUser;
  authoriserName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  rosteringOrganisationRegistration: RosteringOrganisationRegistration;
  substantiveEsrNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated return type of RosteringEsrNumberDeactivate */
export type RosteringEsrNumberDeactivatePayload = {
  __typename?: 'RosteringEsrNumberDeactivatePayload';
  errors: Array<ValidationError>;
  rosteringEsrNumber?: Maybe<RosteringEsrNumber>;
};

/** Autogenerated return type of RosteringEsrNumberDelete */
export type RosteringEsrNumberDeletePayload = {
  __typename?: 'RosteringEsrNumberDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of RosteringEsrNumberUpdateExpiryDate */
export type RosteringEsrNumberUpdateExpiryDatePayload = {
  __typename?: 'RosteringEsrNumberUpdateExpiryDatePayload';
  errors: Array<ValidationError>;
  rosteringEsrNumber?: Maybe<RosteringEsrNumber>;
};

/** A Rostering Event */
export type RosteringEvent = {
  __typename?: 'RosteringEvent';
  /** The agency worker details */
  agencyRegistration?: Maybe<AgencyRegistration>;
  /** Audit Events */
  auditEvents?: Maybe<Array<AuditRosteringEvent>>;
  /** The worker's Events which overlap onto the same day */
  conflictingEventsByDay?: Maybe<Array<RosteringEvent>>;
  /** The worker's Events which overlap in time */
  conflictingEventsByTime?: Maybe<Array<RosteringEvent>>;
  /** Timestamp of Event creation */
  createdAt: Scalars['DateTime'];
  /** Whether a Shift Event was created from Worker Pattern */
  createdFromWorkerPattern?: Maybe<Scalars['Boolean']>;
  /** DateTime of Event end */
  endsAt: Scalars['DateTime'];
  /** The Type of the Event */
  eventType: RosteringEventTypeEnum;
  /** Details of associated RosteringOrganisationRegistration */
  eventWithPublicUserInfo?: Maybe<RosteringEventWithPublicUserInfo>;
  /** Whether a Shift Event has approved exception reports */
  hasApprovedExceptionReport: Scalars['Boolean'];
  /** Event ID */
  id: Scalars['ID'];
  /** Identifier of the associated Bank shift */
  linkedBankShiftId?: Maybe<Scalars['Int']>;
  /** Whether the Event is locked */
  locked: Scalars['Boolean'];
  /** The associated Roster */
  roster: RosteringRoster;
  /** Associated exception reports */
  rosteringExceptionReports?: Maybe<Array<RosteringExceptionReport>>;
  /** The associated worker Schedule */
  schedule?: Maybe<RosteringSchedule>;
  /** Shift details */
  scheduleable?: Maybe<RosteringScheduleable>;
  /** Scheduleable type */
  scheduleableType?: Maybe<Scalars['String']>;
  /** Whether the Event has been sent to Patchwork Agency for agency workers */
  sentToAgency?: Maybe<Scalars['Boolean']>;
  /** Whether the Event has been sent to Patchwork Bank for locums */
  sentToBank?: Maybe<Scalars['Boolean']>;
  /** DateTime of Event start */
  startsAt: Scalars['DateTime'];
};

/** The connection type for RosteringEvent. */
export type RosteringEventConnection = {
  __typename?: 'RosteringEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringEventEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RosteringEventEdge = {
  __typename?: 'RosteringEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringEvent>;
};

/** The type of a given event */
export enum RosteringEventTypeEnum {
  /** An event representing annual leave */
  AnnualLeave = 'annual_leave',
  /** An event representing paid leave */
  PaidLeave = 'paid_leave',
  /** An event representing a rest day */
  Rest = 'rest',
  /** An event representing a shift */
  Shift = 'shift',
  /** An event representing sick leave */
  SickLeave = 'sick_leave',
  /** An event representing special leave */
  SpecialLeave = 'special_leave',
  /** An event representing study leave */
  StudyLeave = 'study_leave',
  /** An event representing work preference */
  WorkPreference = 'work_preference'
}

export type RosteringEventWithPublicUserInfo = {
  __typename?: 'RosteringEventWithPublicUserInfo';
  endsAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['Int']>;
};

/** A rostering exception report */
export type RosteringExceptionReport = {
  __typename?: 'RosteringExceptionReport';
  actionable: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvers?: Maybe<Array<User>>;
  breakActualTime?: Maybe<Scalars['String']>;
  breakScheduledTime?: Maybe<Scalars['String']>;
  /** Determines if a worker can dispute the report rejection */
  canDispute: Scalars['Boolean'];
  complianceBreaches?: Maybe<Array<Rule>>;
  details?: Maybe<Scalars['String']>;
  differenceInWorkedHours?: Maybe<NoYesEnum>;
  disputedAt?: Maybe<Scalars['DateTime']>;
  givenCompensation?: Maybe<ExceptionReportGivenCompensationEnum>;
  id: Scalars['ID'];
  /** The last user that has either approved or rejected an exception report regardless of tier */
  lastApprover?: Maybe<User>;
  location?: Maybe<Scalars['String']>;
  mostSeniorClinician?: Maybe<Scalars['String']>;
  mostSeniorClinicianOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  overtime?: Maybe<Scalars['String']>;
  patientSafetyImpacted?: Maybe<NoYesEnum>;
  reasonsForNotAllowingDispute: Array<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /**
   * The last time a user requested an approval for
   * an exception report. When it was submitted or disputed
   */
  requestedAt: Scalars['DateTime'];
  requestedCompensation?: Maybe<ExceptionReportRequestedCompensationEnum>;
  rosteringEvent?: Maybe<RosteringEvent>;
  rosteringExceptionReportNotes?: Maybe<Array<RosteringExceptionReportNote>>;
  rosteringExceptionReportReasons?: Maybe<Array<RosteringExceptionReportReason>>;
  rosteringOrganisationRegistration: RosteringOrganisationRegistration;
  /** Indicates whether the compensation request has been sent to ESR */
  sentToEsr: Scalars['Boolean'];
  /** The time that the compensation request was sent to ESR */
  sentToEsrAt?: Maybe<Scalars['DateTime']>;
  shiftActualEndTime?: Maybe<Scalars['String']>;
  shiftActualStartTime?: Maybe<Scalars['String']>;
  shiftDate?: Maybe<Scalars['Date']>;
  shiftScheduledEndTime?: Maybe<Scalars['String']>;
  shiftScheduledStartTime?: Maybe<Scalars['String']>;
  status: ExceptionReportStatusEnum;
  submittedAt?: Maybe<Scalars['DateTime']>;
  /**
   * This returns the last approver for an exception report regardless of approve_report or
   * approve_dispute_report type
   * @deprecated Use last_approver instead
   */
  tier1Reviewer?: Maybe<HubUser>;
  timeOffInLieu?: Maybe<RosteringTimeOffInLieu>;
};


/** A rostering exception report */
export type RosteringExceptionReportRosteringExceptionReportNotesArgs = {
  types?: InputMaybe<Array<ExceptionReportNoteTypeEnum>>;
};

/** Autogenerated return type of RosteringExceptionReportApprove */
export type RosteringExceptionReportApprovePayload = {
  __typename?: 'RosteringExceptionReportApprovePayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** Autogenerated return type of RosteringExceptionReportCategoryCreate */
export type RosteringExceptionReportCategoryCreatePayload = {
  __typename?: 'RosteringExceptionReportCategoryCreatePayload';
  errors: Array<ValidationError>;
  /** The exception report category */
  rosteringExceptionReportCategory?: Maybe<RosteringExceptionReportReasonCategory>;
};

/** Autogenerated return type of RosteringExceptionReportCategoryDelete */
export type RosteringExceptionReportCategoryDeletePayload = {
  __typename?: 'RosteringExceptionReportCategoryDeletePayload';
  errors: Array<ValidationError>;
  /** The deleted exception report category */
  rosteringExceptionReportCategory?: Maybe<RosteringExceptionReportReasonCategory>;
};

/** Autogenerated return type of RosteringExceptionReportCategoryUpdate */
export type RosteringExceptionReportCategoryUpdatePayload = {
  __typename?: 'RosteringExceptionReportCategoryUpdatePayload';
  errors: Array<ValidationError>;
  /** The exception report category */
  rosteringExceptionReportCategory?: Maybe<RosteringExceptionReportReasonCategory>;
};

/** Autogenerated return type of RosteringExceptionReportCreate */
export type RosteringExceptionReportCreatePayload = {
  __typename?: 'RosteringExceptionReportCreatePayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** Autogenerated return type of RosteringExceptionReportDelete */
export type RosteringExceptionReportDeletePayload = {
  __typename?: 'RosteringExceptionReportDeletePayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** Autogenerated return type of RosteringExceptionReportDispute */
export type RosteringExceptionReportDisputePayload = {
  __typename?: 'RosteringExceptionReportDisputePayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** An exception report note */
export type RosteringExceptionReportNote = {
  __typename?: 'RosteringExceptionReportNote';
  /** The exception note content */
  content: Scalars['String'];
  /** ExceptionReportNote ID */
  id: Scalars['ID'];
  /** Exception report note type (tier, reason) */
  noteType: ExceptionReportNoteTypeEnum;
};

/** Autogenerated return type of RosteringExceptionReportPermissionsSet */
export type RosteringExceptionReportPermissionsSetPayload = {
  __typename?: 'RosteringExceptionReportPermissionsSetPayload';
  /** Supervise all workers in the organisation */
  allWorkers?: Maybe<Scalars['Boolean']>;
  /** Categories user is allowed to supervise */
  approveCategories?: Maybe<Array<ExceptionReportSupervisorCategory>>;
  errors: Array<ValidationError>;
  /** Categories user is allowed to supervise */
  readonlyCategories?: Maybe<Array<ExceptionReportSupervisorCategory>>;
  /** List of workers supervised by the user */
  workerIds?: Maybe<Array<Scalars['Int']>>;
};

/** An exception report reason */
export type RosteringExceptionReportReason = {
  __typename?: 'RosteringExceptionReportReason';
  /** ExceptionReportReason ID */
  id: Scalars['ID'];
  /** The exception reason name */
  name: Scalars['String'];
  /** Category of the reason */
  rosteringExceptionReportReasonCategory: RosteringExceptionReportReasonCategory;
};

/** An exception report reason category */
export type RosteringExceptionReportReasonCategory = {
  __typename?: 'RosteringExceptionReportReasonCategory';
  /** Compensation available for the category */
  allowCompensation: Scalars['Boolean'];
  /** Existence of an exception report in status 'pending' assigned to the category */
  anyPendingReport?: Maybe<Scalars['Boolean']>;
  /** Timestamp when the model was created */
  createdAt: Scalars['DateTime'];
  /** Categories and their supervisor along with tiers */
  exceptionReportSupervisorCategories?: Maybe<Array<ExceptionReportSupervisorCategory>>;
  /** ExceptionReportReasonCategory ID */
  id: Scalars['ID'];
  /** Category name */
  name: Scalars['String'];
  /** Associated exception report reasons */
  rosteringExceptionReportReasons: Array<RosteringExceptionReportReason>;
  /** Timestamp when the model was updated for the last time */
  updatedAt: Scalars['DateTime'];
};


/** An exception report reason category */
export type RosteringExceptionReportReasonCategoryExceptionReportSupervisorCategoriesArgs = {
  allWorkers?: InputMaybe<Scalars['Boolean']>;
  readonly?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RosteringExceptionReportReject */
export type RosteringExceptionReportRejectPayload = {
  __typename?: 'RosteringExceptionReportRejectPayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** Autogenerated return type of RosteringExceptionReportUpdate */
export type RosteringExceptionReportUpdatePayload = {
  __typename?: 'RosteringExceptionReportUpdatePayload';
  errors: Array<ValidationError>;
  /** Exception report */
  rosteringExceptionReport?: Maybe<RosteringExceptionReport>;
};

/** The leave type of a leave entitlement */
export enum RosteringLeaveEntitlementLeaveTypeEnum {
  /** A annual leave entitlement type */
  AnnualLeave = 'annual_leave',
  /** A study leave entitlement type */
  StudyLeave = 'study_leave'
}

/** The status of a leave request */
export enum RosteringLeaveRequestStatusEnum {
  /** An approved leave request */
  Approved = 'approved',
  /** An archived leave request */
  Archived = 'archived',
  /** A cancelled leave request */
  Cancelled = 'cancelled',
  /** A pending leave request */
  Pending = 'pending',
  /** A leave request which is pending cancellation */
  PendingCancellation = 'pending_cancellation',
  /** A rejected leave request */
  Rejected = 'rejected'
}

/** The type of a leave request */
export enum RosteringLeaveTypeEnum {
  /** A annual leave request type */
  Annual = 'annual',
  /** A paid leave request type */
  Paid = 'paid',
  /** A sick leave request type */
  Sick = 'sick',
  /** A special leave request type */
  Special = 'special',
  /** A study leave request type */
  Study = 'study'
}

export type RosteringLocation = {
  __typename?: 'RosteringLocation';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  site?: Maybe<Site>;
  updatedAt: Scalars['DateTime'];
};

export type RosteringNotifications = {
  __typename?: 'RosteringNotifications';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  notificationGroupId?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation>;
  readAt?: Maybe<Scalars['DateTime']>;
  recipient?: Maybe<User>;
  sender?: Maybe<User>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** The connection type for RosteringNotifications. */
export type RosteringNotificationsConnection = {
  __typename?: 'RosteringNotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringNotificationsEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringNotifications>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RosteringNotificationsEdge = {
  __typename?: 'RosteringNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringNotifications>;
};

export type RosteringOrgRegDeptList = {
  __typename?: 'RosteringOrgRegDeptList';
  departmentList: RosteringDepartmentList;
  id: Scalars['Int'];
  organisationRegistration: RosteringOrganisationRegistration;
};

/** The connection type for RosteringOrgRegDeptList. */
export type RosteringOrgRegDeptListConnection = {
  __typename?: 'RosteringOrgRegDeptListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringOrgRegDeptListEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringOrgRegDeptList>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringOrgRegDeptListDelete */
export type RosteringOrgRegDeptListDeletePayload = {
  __typename?: 'RosteringOrgRegDeptListDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type RosteringOrgRegDeptListEdge = {
  __typename?: 'RosteringOrgRegDeptListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringOrgRegDeptList>;
};

/** Autogenerated return type of RosteringOrgRegDeptListMultiDelete */
export type RosteringOrgRegDeptListMultiDeletePayload = {
  __typename?: 'RosteringOrgRegDeptListMultiDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type RosteringOrganisationActivity = {
  __typename?: 'RosteringOrganisationActivity';
  departments: Array<Department>;
  id: Scalars['Int'];
  locations?: Maybe<Array<Location>>;
  metaActivity: MetaActivity;
  name: Scalars['String'];
  sites?: Maybe<Array<Site>>;
};

/** The connection type for RosteringOrganisationActivity. */
export type RosteringOrganisationActivityConnection = {
  __typename?: 'RosteringOrganisationActivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringOrganisationActivityEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringOrganisationActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringOrganisationActivityCreate */
export type RosteringOrganisationActivityCreatePayload = {
  __typename?: 'RosteringOrganisationActivityCreatePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationActivity?: Maybe<RosteringOrganisationActivity>;
};

/** Autogenerated return type of RosteringOrganisationActivityDelete */
export type RosteringOrganisationActivityDeletePayload = {
  __typename?: 'RosteringOrganisationActivityDeletePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationActivity?: Maybe<RosteringOrganisationActivity>;
};

/** An edge in a connection. */
export type RosteringOrganisationActivityEdge = {
  __typename?: 'RosteringOrganisationActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringOrganisationActivity>;
};

/** Autogenerated return type of RosteringOrganisationActivityUpdate */
export type RosteringOrganisationActivityUpdatePayload = {
  __typename?: 'RosteringOrganisationActivityUpdatePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationActivity?: Maybe<RosteringOrganisationActivity>;
};

export type RosteringOrganisationRegistration = {
  __typename?: 'RosteringOrganisationRegistration';
  active: Scalars['Boolean'];
  associatedDeptsInLastYear?: Maybe<Array<RosteringDepartmentInfo>>;
  authoriser?: Maybe<HubUser>;
  availabilitiesOnRosters: Array<AvailabilityOnRoster>;
  complianceBreach: Scalars['Boolean'];
  contractFileUrl?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  contractedWorkAmount?: Maybe<Scalars['Float']>;
  contractedWorkIsHours?: Maybe<Scalars['Boolean']>;
  costCentre?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentOrLatestRosteringEsrNumber?: Maybe<RosteringEsrNumber>;
  defaultLeaveFlow: Scalars['Boolean'];
  departmentLists?: Maybe<Array<RosteringDepartmentList>>;
  enableRostering?: Maybe<Scalars['Boolean']>;
  eventsOnRoster: Array<RosteringEvent>;
  fixedTerm: Scalars['Boolean'];
  fixedTermEndDate?: Maybe<Scalars['Date']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  lessThanFullTime?: Maybe<Scalars['Boolean']>;
  organisation?: Maybe<Organisation>;
  organisationWorker?: Maybe<OrganisationWorker>;
  outOfHours?: Maybe<Scalars['Float']>;
  preloadedRosteringLeaveRequests: Array<LeaveRequest>;
  /** Picture profile of worker */
  profilePictureUrl?: Maybe<Scalars['String']>;
  rosteringEsrNumbers?: Maybe<Array<RosteringEsrNumber>>;
  rosteringLeaveRequests: Array<LeaveRequest>;
  rosteringOrganisationRegistrationNotes?: Maybe<Array<RosteringOrganisationRegistrationNote>>;
  sendActivationLink?: Maybe<Scalars['Boolean']>;
  shiftEventsInAllRosters: Array<RosteringEvent>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  wholeTimeEquivalent?: Maybe<Scalars['Float']>;
  workPreferences?: Maybe<Array<RosteringWorkPreference>>;
  workedAtOrgInThePastTwelveMonths?: Maybe<Scalars['Boolean']>;
  workerName?: Maybe<Scalars['String']>;
  workingArrangement?: Maybe<Scalars['String']>;
};


export type RosteringOrganisationRegistrationAvailabilitiesOnRostersArgs = {
  rosterUniqueIdentifier?: InputMaybe<Scalars['String']>;
};


export type RosteringOrganisationRegistrationComplianceBreachArgs = {
  rosterUniqueIdentifier?: InputMaybe<Scalars['String']>;
};


export type RosteringOrganisationRegistrationEventsOnRosterArgs = {
  eventTypes?: InputMaybe<Array<RosteringEventTypeEnum>>;
  rosterId: Scalars['Int'];
  startDateFrom?: InputMaybe<Scalars['Date']>;
  startDateUntil?: InputMaybe<Scalars['Date']>;
};


export type RosteringOrganisationRegistrationRosteringLeaveRequestsArgs = {
  endDateFrom?: InputMaybe<Scalars['Date']>;
  startDateUntil?: InputMaybe<Scalars['Date']>;
  statuses?: InputMaybe<Array<RosteringLeaveRequestStatusEnum>>;
};


export type RosteringOrganisationRegistrationShiftEventsInAllRostersArgs = {
  startDateFrom: Scalars['Date'];
  startDateUntil: Scalars['Date'];
};

/** The connection type for RosteringOrganisationRegistration. */
export type RosteringOrganisationRegistrationConnection = {
  __typename?: 'RosteringOrganisationRegistrationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringOrganisationRegistrationEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringOrganisationRegistration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringOrganisationRegistrationCreateForBankWorker */
export type RosteringOrganisationRegistrationCreateForBankWorkerPayload = {
  __typename?: 'RosteringOrganisationRegistrationCreateForBankWorkerPayload';
  errors: Array<ValidationError>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
};

/** Autogenerated return type of RosteringOrganisationRegistrationCreate */
export type RosteringOrganisationRegistrationCreatePayload = {
  __typename?: 'RosteringOrganisationRegistrationCreatePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
};

/** An edge in a connection. */
export type RosteringOrganisationRegistrationEdge = {
  __typename?: 'RosteringOrganisationRegistrationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringOrganisationRegistration>;
};

export type RosteringOrganisationRegistrationNote = {
  __typename?: 'RosteringOrganisationRegistrationNote';
  createdAt?: Maybe<Scalars['DateTime']>;
  hubUser?: Maybe<HubUser>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated return type of RosteringOrganisationRegistrationNoteCreate */
export type RosteringOrganisationRegistrationNoteCreatePayload = {
  __typename?: 'RosteringOrganisationRegistrationNoteCreatePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationRegistrationNote?: Maybe<RosteringOrganisationRegistrationNote>;
};

/** Autogenerated return type of RosteringOrganisationRegistrationSwitch */
export type RosteringOrganisationRegistrationSwitchPayload = {
  __typename?: 'RosteringOrganisationRegistrationSwitchPayload';
  errors: Array<ValidationError>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
};

/** Autogenerated return type of RosteringOrganisationRegistrationUpdate */
export type RosteringOrganisationRegistrationUpdatePayload = {
  __typename?: 'RosteringOrganisationRegistrationUpdatePayload';
  errors: Array<ValidationError>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
};

/** A rostering pay rule */
export type RosteringPayRule = {
  __typename?: 'RosteringPayRule';
  /** rule applies to bank holidays */
  bankHolidays: Scalars['Boolean'];
  /** The time the rule ceases to be in effect */
  endTime: Scalars['String'];
  /** The ESR Pay Element Category */
  esrPayElementCategoryName: Scalars['String'];
  /** The ESR Pay Element Sub-Category */
  esrPayElementSubCategoryName: Scalars['String'];
  /** Is the rule valid for Fridays */
  friday: Scalars['Boolean'];
  /** The grade that the rule applies to */
  grades: Array<Grade>;
  /** table row unique identifier */
  id: Scalars['ID'];
  /** Is the rule valid for Mondays */
  monday: Scalars['Boolean'];
  /** Staff Group */
  organisationStaffGroup: OrganisationStaffGroup;
  /** Name of the rule */
  ruleName: Scalars['String'];
  /** Is the rule valid for Saturdays */
  saturday: Scalars['Boolean'];
  /** Time that the rule comes into effect */
  startTime: Scalars['String'];
  /** Is the rule valid for Sundays */
  sunday: Scalars['Boolean'];
  /** Is the rule valid for Thursdays */
  thursday: Scalars['Boolean'];
  /** Is the rule valid for Tuesdays */
  tuesday: Scalars['Boolean'];
  /** Is the rule valid for Wednesdays */
  wednesday: Scalars['Boolean'];
};

/** Autogenerated return type of RosteringPayRuleArchive */
export type RosteringPayRuleArchivePayload = {
  __typename?: 'RosteringPayRuleArchivePayload';
  errors: Array<ValidationError>;
  /** Whether the mutation was successful */
  success: Scalars['Boolean'];
};

/** The connection type for RosteringPayRule. */
export type RosteringPayRuleConnection = {
  __typename?: 'RosteringPayRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringPayRuleEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringPayRule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Attributes for creating a rostering pay rule */
export type RosteringPayRuleCreateInput = {
  /** Is the rule valid on Bank Holidays */
  bankHolidays?: InputMaybe<Scalars['Boolean']>;
  /** The time the rule ceases to be in effect */
  endTime: Scalars['String'];
  /** The ESR Pay Element Category */
  esrPayElementCategoryName: Scalars['String'];
  /** The ESR Pay Element Sub-Category */
  esrPayElementSubCategoryName: Scalars['String'];
  /** Is the rule valid on a Friday */
  friday?: InputMaybe<Scalars['Boolean']>;
  /** The grade id */
  gradeIds: Array<Scalars['Int']>;
  /** Is the rule valid on a Monday */
  monday?: InputMaybe<Scalars['Boolean']>;
  /** Staff Group ID */
  organisationStaffGroupId: Scalars['Int'];
  /** Name of the rule */
  ruleName: Scalars['String'];
  /** Is the rule valid on a Saturday */
  saturday?: InputMaybe<Scalars['Boolean']>;
  /** Time that the rule comes into effect */
  startTime: Scalars['String'];
  /** Is the rule valid on a Sunday */
  sunday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Thursday */
  thursday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Tuesday */
  tuesday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Wednesday */
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RosteringPayRuleCreate */
export type RosteringPayRuleCreatePayload = {
  __typename?: 'RosteringPayRuleCreatePayload';
  errors: Array<ValidationError>;
  /** Pay Rule */
  rosteringPayRule?: Maybe<RosteringPayRule>;
};

/** An edge in a connection. */
export type RosteringPayRuleEdge = {
  __typename?: 'RosteringPayRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringPayRule>;
};

/** Attributes for updating a rostering pay rule */
export type RosteringPayRuleUpdateInput = {
  /** Is the rule valid on Bank Holidays */
  bankHolidays?: InputMaybe<Scalars['Boolean']>;
  /** The time the rule ceases to be in effect */
  endTime: Scalars['String'];
  /** The ESR Pay Element Category */
  esrPayElementCategoryName: Scalars['String'];
  /** The ESR Pay Element Sub-Category */
  esrPayElementSubCategoryName: Scalars['String'];
  /** Is the rule valid on a Friday */
  friday?: InputMaybe<Scalars['Boolean']>;
  /** The grade id */
  gradeIds: Array<Scalars['Int']>;
  /** Id of the pay rule to update */
  id: Scalars['Int'];
  /** Is the rule valid on a Monday */
  monday?: InputMaybe<Scalars['Boolean']>;
  /** Staff Group ID */
  organisationStaffGroupId: Scalars['Int'];
  /** Name of the rule */
  ruleName: Scalars['String'];
  /** Is the rule valid on a Saturday */
  saturday?: InputMaybe<Scalars['Boolean']>;
  /** Time that the rule comes into effect */
  startTime: Scalars['String'];
  /** Is the rule valid on a Sunday */
  sunday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Thursday */
  thursday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Tuesday */
  tuesday?: InputMaybe<Scalars['Boolean']>;
  /** Is the rule valid on a Wednesday */
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RosteringPayRuleUpdate */
export type RosteringPayRuleUpdatePayload = {
  __typename?: 'RosteringPayRuleUpdatePayload';
  errors: Array<ValidationError>;
  /** Pay Rule */
  rosteringPayRule?: Maybe<RosteringPayRule>;
};

export type RosteringPermission = {
  __typename?: 'RosteringPermission';
  category: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  unserialisedName: Scalars['String'];
};

export type RosteringPermissionSet = {
  __typename?: 'RosteringPermissionSet';
  hubUser: User;
  id: Scalars['ID'];
  organisation: Organisation;
  recordIds: Scalars['String'];
  rosteringPermission: RosteringPermission;
  subRecordIds?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RosteringRemoveWorkerFromSchedule */
export type RosteringRemoveWorkerFromSchedulePayload = {
  __typename?: 'RosteringRemoveWorkerFromSchedulePayload';
  errors: Array<ValidationError>;
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

export type RosteringRoster = {
  __typename?: 'RosteringRoster';
  auditEvents?: Maybe<Array<AuditRosteringEvent>>;
  canPatternsBeModified: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<HubUser>;
  departmentList?: Maybe<RosteringDepartmentList>;
  /** Returns TRUE for Rosters which have WorkerPattern data persisted */
  enablePatternViewToggle: Scalars['Boolean'];
  events?: Maybe<Array<RosteringEvent>>;
  expired: Scalars['Boolean'];
  /** Grades associated with the roster */
  grades: Array<Grade>;
  id: Scalars['Int'];
  /** date when roster is locked for adding shifts */
  lockedTo?: Maybe<Scalars['Date']>;
  rota: RosteringRota;
  rowOrderForCurrentUser?: Maybe<RosterRowOrder>;
  schedules?: Maybe<Array<RosteringSchedule>>;
  status: RosterStatusEnum;
  uniqueIdentifier: Scalars['String'];
  /** unlocked days */
  unlockedDays?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<HubUser>;
};

/** The connection type for RosteringRoster. */
export type RosteringRosterConnection = {
  __typename?: 'RosteringRosterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringRosterEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringRoster>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringRosterDeleteEvent */
export type RosteringRosterDeleteEventPayload = {
  __typename?: 'RosteringRosterDeleteEventPayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type RosteringRosterEdge = {
  __typename?: 'RosteringRosterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of RosteringRosterPublish */
export type RosteringRosterPublishPayload = {
  __typename?: 'RosteringRosterPublishPayload';
  errors: Array<ValidationError>;
  /** The updated roster */
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of RosteringRosterRevertToDraft */
export type RosteringRosterRevertToDraftPayload = {
  __typename?: 'RosteringRosterRevertToDraftPayload';
  errors: Array<ValidationError>;
  /** the updated roster */
  rosteringRoster?: Maybe<RosteringRoster>;
};

/** Autogenerated return type of RosteringRosterUpdateEvent */
export type RosteringRosterUpdateEventPayload = {
  __typename?: 'RosteringRosterUpdateEventPayload';
  errors: Array<ValidationError>;
  /** The updated Rostering Shift Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

export type RosteringRota = {
  __typename?: 'RosteringRota';
  /** Actions which the current_user is permitted to carry out on the Rota.All possible actions are returned for SuperAdmins. */
  currentUserPermittedActions: Array<Scalars['String']>;
  department: Department;
  endDate: Scalars['Date'];
  flowType: RotaFlowTypeEnum;
  hubUser: HubUser;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<RosteringPermissionSet>>;
  rosteringDemandTemplate?: Maybe<RosteringDemandTemplate>;
  rosteringRotaDays?: Maybe<Array<RosteringRotaDay>>;
  rosteringRotaSlots: Array<RotaSlot>;
  rotaDaysCount: Scalars['Int'];
  startDate: Scalars['Date'];
  status: RotaStatusEnum;
  uniqueIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RosteringRotaActivity = {
  __typename?: 'RosteringRotaActivity';
  id: Scalars['ID'];
  order: Scalars['Int'];
  rosteringOrganisationActivity: RosteringOrganisationActivity;
  rosteringRota: RosteringRota;
  rosteringRotaShiftSets?: Maybe<Array<RosteringRotaShiftSet>>;
};

/** The connection type for RosteringRota. */
export type RosteringRotaConnection = {
  __typename?: 'RosteringRotaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringRotaEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringRota>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringRotaCreate */
export type RosteringRotaCreatePayload = {
  __typename?: 'RosteringRotaCreatePayload';
  errors: Array<ValidationError>;
  rosteringRota?: Maybe<RosteringRota>;
};

export type RosteringRotaDay = {
  __typename?: 'RosteringRotaDay';
  date: Scalars['Date'];
  dayOrder?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** associated Rota Days Off scheduled on this RotaDay */
  rosteringRotaDayOffs: Array<RotaDayOff>;
  rosteringRotaShiftSets?: Maybe<Array<RosteringRotaShiftSet>>;
};

/** The connection type for RosteringRotaDay. */
export type RosteringRotaDayConnection = {
  __typename?: 'RosteringRotaDayConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringRotaDayEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringRotaDay>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RosteringRotaDayEdge = {
  __typename?: 'RosteringRotaDayEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringRotaDay>;
};

/** Autogenerated return type of RosteringRotaDelete */
export type RosteringRotaDeletePayload = {
  __typename?: 'RosteringRotaDeletePayload';
  errors: Array<ValidationError>;
  rosteringRota?: Maybe<RosteringRota>;
};

/** An edge in a connection. */
export type RosteringRotaEdge = {
  __typename?: 'RosteringRotaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringRota>;
};

export type RosteringRotaShiftSet = {
  __typename?: 'RosteringRotaShiftSet';
  adminNote?: Maybe<Scalars['String']>;
  grade: Grade;
  id: Scalars['ID'];
  idealQuantity?: Maybe<Scalars['Int']>;
  location?: Maybe<RosteringLocation>;
  locations?: Maybe<Array<RosteringLocation>>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  rosteringRotaActivity?: Maybe<RosteringRotaActivity>;
  rosteringRotaDay: RosteringRotaDay;
  rosteringRotaSlot?: Maybe<RotaSlot>;
  rosteringShiftTemplate: RosteringShiftTemplate;
  rosteringShiftType: RosteringShiftType;
  shiftEndTime?: Maybe<Scalars['DateTime']>;
  shiftStartTime?: Maybe<Scalars['DateTime']>;
  shifts?: Maybe<Array<RosteringShift>>;
  sites?: Maybe<Array<RosteringSite>>;
  skills?: Maybe<Array<RosteringSkill>>;
  unscheduledShiftEvents: Array<RosteringEvent>;
};

/** The connection type for RosteringRotaShiftSet. */
export type RosteringRotaShiftSetConnection = {
  __typename?: 'RosteringRotaShiftSetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringRotaShiftSetEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringRotaShiftSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringRotaShiftSetCreate */
export type RosteringRotaShiftSetCreatePayload = {
  __typename?: 'RosteringRotaShiftSetCreatePayload';
  errors: Array<ValidationError>;
  rosteringRotaShiftSet?: Maybe<RosteringRotaShiftSet>;
};

/** Autogenerated return type of RosteringRotaShiftSetDelete */
export type RosteringRotaShiftSetDeletePayload = {
  __typename?: 'RosteringRotaShiftSetDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type RosteringRotaShiftSetEdge = {
  __typename?: 'RosteringRotaShiftSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringRotaShiftSet>;
};

/** Autogenerated return type of RosteringRotaShiftSetUpdate */
export type RosteringRotaShiftSetUpdatePayload = {
  __typename?: 'RosteringRotaShiftSetUpdatePayload';
  errors: Array<ValidationError>;
  rosteringRotaShiftSet?: Maybe<RosteringRotaShiftSet>;
};

/** Autogenerated return type of RosteringRotaUpdate */
export type RosteringRotaUpdatePayload = {
  __typename?: 'RosteringRotaUpdatePayload';
  errors: Array<ValidationError>;
  rosteringRota?: Maybe<RosteringRota>;
};

export type RosteringSchedule = {
  __typename?: 'RosteringSchedule';
  createdAt: Scalars['DateTime'];
  /** schedules that comes from this Schedule */
  descendantSchedules: Array<RosteringSchedule>;
  events?: Maybe<Array<RosteringEvent>>;
  /** first day of the schedule, for use in Audit Trail */
  firstDay?: Maybe<Scalars['Date']>;
  /** determines if the schedule was split and child schedule was created */
  hasChildSchedule?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isAssignable?: Maybe<Scalars['Boolean']>;
  /** last day of the schedule, for use in Audit Trail */
  lastDay?: Maybe<Scalars['Date']>;
  leaveEvents?: Maybe<Array<LeaveEvent>>;
  /** ID of the original schedule from which this schedule was created; or, the current schedule ID */
  originalScheduleId?: Maybe<Scalars['ID']>;
  /** ID of the parent schedule from which this schedule was created */
  parentScheduleId?: Maybe<Scalars['ID']>;
  roster?: Maybe<RosteringRoster>;
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
  /** Slot number */
  slotNumber?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type RosteringScheduleEventsArgs = {
  startDate?: InputMaybe<Scalars['Date']>;
};


export type RosteringScheduleLeaveEventsArgs = {
  startDate: Scalars['Date'];
};

/** The connection type for RosteringSchedule. */
export type RosteringScheduleConnection = {
  __typename?: 'RosteringScheduleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringScheduleEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RosteringScheduleEdge = {
  __typename?: 'RosteringScheduleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringSchedule>;
};

/** Autogenerated return type of RosteringScheduleSetAssignable */
export type RosteringScheduleSetAssignablePayload = {
  __typename?: 'RosteringScheduleSetAssignablePayload';
  errors: Array<ValidationError>;
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

/** Autogenerated return type of RosteringScheduleSetUnassignable */
export type RosteringScheduleSetUnassignablePayload = {
  __typename?: 'RosteringScheduleSetUnassignablePayload';
  errors: Array<ValidationError>;
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

export type RosteringScheduleable = RosteringShift;

export type RosteringShift = {
  __typename?: 'RosteringShift';
  adminNote?: Maybe<Scalars['String']>;
  endHour?: Maybe<Scalars['Int']>;
  endMinute?: Maybe<Scalars['Int']>;
  event: RosteringEvent;
  eventWithPublicUserInfo?: Maybe<RosteringEventWithPublicUserInfo>;
  filledStatus?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  leadClinician: Scalars['Boolean'];
  linkedBankShiftId?: Maybe<Scalars['Int']>;
  location?: Maybe<Location>;
  locations?: Maybe<Array<Location>>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  organisationActivity?: Maybe<RosteringOrganisationActivity>;
  originalEndHour?: Maybe<Scalars['Int']>;
  originalEndMinute?: Maybe<Scalars['Int']>;
  originalStartHour?: Maybe<Scalars['Int']>;
  originalStartMinute?: Maybe<Scalars['Int']>;
  reasonForTimeChange?: Maybe<Scalars['String']>;
  rosteringShiftTemplate?: Maybe<RosteringShiftTemplate>;
  rotaShiftSet?: Maybe<RosteringRotaShiftSet>;
  sentToAgency?: Maybe<Scalars['Boolean']>;
  sentToBank?: Maybe<Scalars['Boolean']>;
  shiftType?: Maybe<RosteringShiftType>;
  sites?: Maybe<Array<Site>>;
  skills?: Maybe<Array<Skill>>;
  startHour?: Maybe<Scalars['Int']>;
  startMinute?: Maybe<Scalars['Int']>;
  workerNote?: Maybe<Scalars['String']>;
  workingWithXOthers?: Maybe<Scalars['Int']>;
};

export type RosteringShiftSet = {
  __typename?: 'RosteringShiftSet';
  adminNote: Scalars['String'];
  grade: Grade;
  id: Scalars['ID'];
  idealQuantity: Scalars['Int'];
  location?: Maybe<Location>;
  locations?: Maybe<Array<Location>>;
  minimumQuantity: Scalars['Int'];
  rosteringOrganisationActivity: RosteringOrganisationActivity;
  rosteringShiftTemplate: RosteringShiftTemplate;
  rosteringShiftType: RosteringShiftType;
  /** @deprecated Use rostering_shift_type instead */
  rosteringShiftTypeId: RosteringShiftType;
  shiftEndTime?: Maybe<Scalars['DateTime']>;
  shiftName?: Maybe<Scalars['String']>;
  shiftStartTime?: Maybe<Scalars['DateTime']>;
  sites?: Maybe<Array<Site>>;
  skills: Array<Skill>;
};

/** Autogenerated return type of RosteringShiftSetCreate */
export type RosteringShiftSetCreatePayload = {
  __typename?: 'RosteringShiftSetCreatePayload';
  errors: Array<ValidationError>;
  rosteringShiftSet?: Maybe<RosteringShiftSet>;
};

/** Autogenerated return type of RosteringShiftSetDelete */
export type RosteringShiftSetDeletePayload = {
  __typename?: 'RosteringShiftSetDeletePayload';
  errors: Array<ValidationError>;
  rosteringShiftSet?: Maybe<RosteringShiftSet>;
};

/** Autogenerated return type of RosteringShiftSetUpdate */
export type RosteringShiftSetUpdatePayload = {
  __typename?: 'RosteringShiftSetUpdatePayload';
  errors: Array<ValidationError>;
  rosteringShiftSet?: Maybe<RosteringShiftSet>;
};

export type RosteringShiftTemplate = {
  __typename?: 'RosteringShiftTemplate';
  endTime: Scalars['DateTime'];
  grade: Grade;
  id: Scalars['ID'];
  name: Scalars['String'];
  organisationStaffGroup: OrganisationStaffGroup;
  predictedCoreDurationMinutes?: Maybe<Scalars['Int']>;
  predictedUnsocialDurationMinutes?: Maybe<Scalars['Int']>;
  rosteringShiftType: RosteringShiftType;
  startTime: Scalars['DateTime'];
};

/** The connection type for RosteringShiftTemplate. */
export type RosteringShiftTemplateConnection = {
  __typename?: 'RosteringShiftTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RosteringShiftTemplateEdge>>>;
  /** A list of nodes. */
  nodes: Array<RosteringShiftTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated return type of RosteringShiftTemplateCreate */
export type RosteringShiftTemplateCreatePayload = {
  __typename?: 'RosteringShiftTemplateCreatePayload';
  errors: Array<ValidationError>;
  rosteringShiftTemplate?: Maybe<RosteringShiftTemplate>;
};

/** Autogenerated return type of RosteringShiftTemplateDelete */
export type RosteringShiftTemplateDeletePayload = {
  __typename?: 'RosteringShiftTemplateDeletePayload';
  errors: Array<ValidationError>;
  rosteringShiftTemplate?: Maybe<RosteringShiftTemplate>;
};

/** An edge in a connection. */
export type RosteringShiftTemplateEdge = {
  __typename?: 'RosteringShiftTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RosteringShiftTemplate>;
};

/** Autogenerated return type of RosteringShiftTemplateRestore */
export type RosteringShiftTemplateRestorePayload = {
  __typename?: 'RosteringShiftTemplateRestorePayload';
  errors: Array<ValidationError>;
  rosteringShiftTemplate?: Maybe<RosteringShiftTemplate>;
};

/** Autogenerated return type of RosteringShiftTemplateUpdate */
export type RosteringShiftTemplateUpdatePayload = {
  __typename?: 'RosteringShiftTemplateUpdatePayload';
  errors: Array<ValidationError>;
  rosteringShiftTemplate?: Maybe<RosteringShiftTemplate>;
};

export type RosteringShiftType = {
  __typename?: 'RosteringShiftType';
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type RosteringSite = {
  __typename?: 'RosteringSite';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  locations?: Maybe<Array<Location>>;
  longitude?: Maybe<Scalars['Float']>;
  mapsUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organisation: Organisation;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  shifts: Array<Shift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination: Pagination;
};


export type RosteringSiteShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type RosteringSkill = {
  __typename?: 'RosteringSkill';
  id: Scalars['Int'];
  name: Scalars['String'];
  numOfOrgStaffGroup: Scalars['Int'];
};

/** A rostering time off in lieu */
export type RosteringTimeOffInLieu = {
  __typename?: 'RosteringTimeOffInLieu';
  /** Boolean indicating whether TOIL was converted to pay */
  convertedToPay: Scalars['Boolean'];
  /** Timestamp when the TOIL was converted to pay */
  convertedToPayAt?: Maybe<Scalars['DateTime']>;
  /** Hours and minutes of entitlement */
  entitlement: Scalars['String'];
  /** Identifier */
  id: Scalars['ID'];
  /** Related exception reports */
  rosteringExceptionReport: RosteringExceptionReport;
  taken: Scalars['Boolean'];
  /** Timestamp when the TOIL was marked as taken */
  takenAt?: Maybe<Scalars['DateTime']>;
  /** Effective from */
  validFrom: Scalars['Date'];
  /** Effective to */
  validTo: Scalars['Date'];
};

/** Autogenerated return type of RosteringTimeOffInLieuUpdate */
export type RosteringTimeOffInLieuUpdatePayload = {
  __typename?: 'RosteringTimeOffInLieuUpdatePayload';
  errors: Array<ValidationError>;
  /** TOIL record */
  rosteringTimeOffInLieu?: Maybe<RosteringTimeOffInLieu>;
};

/** Attributes for updating a Rostering Shift Event */
export type RosteringUpdateEventInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isServicePlansUpdate?: InputMaybe<Scalars['Boolean']>;
  leadClinician?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  reasonForTimeChange?: InputMaybe<Scalars['String']>;
  rosteringOrganisationActivityId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  workerNote?: InputMaybe<Scalars['String']>;
};

/** Work Preference for a less than full time worker */
export type RosteringWorkPreference = {
  __typename?: 'RosteringWorkPreference';
  createdAt: Scalars['DateTime'];
  /** Effective to */
  endDate: Scalars['Date'];
  /** Identifier */
  id: Scalars['ID'];
  /** Associated Worker */
  rosteringOrganisationRegistration: RosteringOrganisationRegistration;
  /** Effective from */
  startDate: Scalars['Date'];
  updatedAt: Scalars['DateTime'];
  /** Preferences for the week */
  weeklyPreference: RosteringWorkPreferenceWeek;
};

/** Autogenerated return type of RosteringWorkPreferenceCreate */
export type RosteringWorkPreferenceCreatePayload = {
  __typename?: 'RosteringWorkPreferenceCreatePayload';
  errors: Array<ValidationError>;
  /** Work preference */
  rosteringWorkPreference?: Maybe<RosteringWorkPreference>;
};

/** Work preference for a given day of the week - on, off_weekly, off_odd_weeks, off_even_weeks */
export enum RosteringWorkPreferenceDayStatusEnum {
  /** Worker prefers not to work on this day of the week on even weeks */
  OffEvenWeeks = 'off_even_weeks',
  /** Worker prefers not to work on this day of the week on odd weeks */
  OffOddWeeks = 'off_odd_weeks',
  /** Worker prefers not to work on this day of the week */
  OffWeekly = 'off_weekly',
  /** Worker prefers to work on this day of the week */
  On = 'on'
}

/** Autogenerated return type of RosteringWorkPreferenceDelete */
export type RosteringWorkPreferenceDeletePayload = {
  __typename?: 'RosteringWorkPreferenceDeletePayload';
  errors: Array<ValidationError>;
  /** The deleted Preference */
  rosteringWorkPreference?: Maybe<RosteringWorkPreference>;
};

/** Autogenerated return type of RosteringWorkPreferenceUpdate */
export type RosteringWorkPreferenceUpdatePayload = {
  __typename?: 'RosteringWorkPreferenceUpdatePayload';
  errors: Array<ValidationError>;
  /** Work preference */
  rosteringWorkPreference?: Maybe<RosteringWorkPreference>;
};

/** Type for less than full time workers weekly preferences */
export type RosteringWorkPreferenceWeek = {
  __typename?: 'RosteringWorkPreferenceWeek';
  friday: RosteringWorkPreferenceDayStatusEnum;
  monday: RosteringWorkPreferenceDayStatusEnum;
  saturday: RosteringWorkPreferenceDayStatusEnum;
  sunday: RosteringWorkPreferenceDayStatusEnum;
  thursday: RosteringWorkPreferenceDayStatusEnum;
  tuesday: RosteringWorkPreferenceDayStatusEnum;
  wednesday: RosteringWorkPreferenceDayStatusEnum;
};

/** Input for less than full time workers to enter preferences for their week */
export type RosteringWorkPreferenceWeekInput = {
  friday: RosteringWorkPreferenceDayStatusEnum;
  monday: RosteringWorkPreferenceDayStatusEnum;
  saturday: RosteringWorkPreferenceDayStatusEnum;
  sunday: RosteringWorkPreferenceDayStatusEnum;
  thursday: RosteringWorkPreferenceDayStatusEnum;
  tuesday: RosteringWorkPreferenceDayStatusEnum;
  wednesday: RosteringWorkPreferenceDayStatusEnum;
};

export type RosteringWorkerProfile = {
  __typename?: 'RosteringWorkerProfile';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  rosteringOrganisationRegistrations?: Maybe<Array<RosteringOrganisationRegistration>>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

/** Autogenerated return type of RosteringWorkerUpdateGrade */
export type RosteringWorkerUpdateGradePayload = {
  __typename?: 'RosteringWorkerUpdateGradePayload';
  errors: Array<ValidationError>;
  organisationWorker?: Maybe<OrganisationWorker>;
};

/** WorkersSupervisor type used in exception reporting */
export type RosteringWorkersSupervisor = {
  __typename?: 'RosteringWorkersSupervisor';
  /** Categories of exception reports supervised by supervisor. */
  exceptionReportCategories: Array<RosteringExceptionReportReasonCategory>;
  /** Supervisor of the worker */
  supervisor: HubUser;
  /** Categories with tiers supervised by supervisor */
  supervisorsCategories: Array<ExceptionReportSupervisorCategory>;
  /** Worker that's supervised */
  worker: RosteringOrganisationRegistration;
};

/** Represents an unrostered day in a Worker-based Rota */
export type RotaDayOff = {
  __typename?: 'RotaDayOff';
  /** Rostering::RotaDayOff ID */
  id: Scalars['ID'];
  /** associated Rostering::RotaDay on which the DayOff is scheduled */
  rosteringRotaDay: RosteringRotaDay;
  /** associated Rostering::RotaSlot */
  rosteringRotaSlot: RotaSlot;
};

/** Describes the flow through which the Rota was built */
export enum RotaFlowTypeEnum {
  /** A Rota with Activity-based demand */
  Activity = 'activity',
  /** A dummy Rota for service plans */
  ServicePlan = 'service_plan',
  /** A Rota built with a number of staggered worker slots, based on a Worker Pattern */
  WorkerPattern = 'worker_pattern'
}

/** A Rostering::RotaSlot for use in a Worker-Based Rota */
export type RotaSlot = {
  __typename?: 'RotaSlot';
  /** RosteringRotaSlot ID */
  id: Scalars['ID'];
  /** associated Rostering::Rota */
  rosteringRota: RosteringRota;
  /** associated Rota Days Off scheduled within the Slot */
  rosteringRotaDayOffs: Array<RotaDayOff>;
  /** associated RotaShiftSets scheduled within the Slot */
  rosteringRotaShiftSets: Array<RosteringRotaShiftSet>;
  /** associated Rostering::WorkerPattern */
  rosteringWorkerPattern: WorkerPattern;
  /** Rota slot breakdown */
  slotBreakdown: SlotBreakdown;
  /** The position of slot */
  slotOrder: Scalars['Int'];
  /** The day from the WorkerPattern where the slot starts */
  startDay: Scalars['Int'];
};

/** Rota status - Draft, Ready To Roster */
export enum RotaStatusEnum {
  /** Rota in progress */
  Draft = 'DRAFT',
  /** Rota ready to roster - no track grades on the rota  */
  ReadyToRoster = 'READY_TO_ROSTER'
}

/** The inputs required to load a Worker Pattern into a Rota slots */
export type RotaWorkerPatternInput = {
  /** Number of Slots to be added to the Rota (must be between 1 and 50) */
  numberOfSlots: Scalars['Int'];
  /** ID of Rostering::WorkerPattern to be used in the Rota */
  rosteringWorkerPatternId: Scalars['Int'];
  /** Number of weeks by which each Slot will be offset from the previous one */
  staggerPeriodInWeeks?: InputMaybe<Scalars['Int']>;
};

/** Compliance Rule */
export type Rule = {
  __typename?: 'Rule';
  /** Name of Compliance Rule */
  name: Scalars['String'];
  /** Source of Compliance Rule */
  source: Scalars['String'];
};

/** AutoCascade Rule Group Type */
export type RuleGroup = {
  __typename?: 'RuleGroup';
  activatedAt?: Maybe<Scalars['DateTime']>;
  autoSendToAgency?: Maybe<AutoSendToAgency>;
  createdAt: Scalars['DateTime'];
  departments: Array<Department>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  organisation: Organisation;
  organisationStaffGroup: StaffGroup;
  ranges: Array<RuleGroupsRange>;
  rules: Array<AutoCascadeRule>;
  updatedAt: Scalars['DateTime'];
};

/** AutoCascade Rule Groups Range Type */
export type RuleGroupsRange = {
  __typename?: 'RuleGroupsRange';
  createdAt: Scalars['DateTime'];
  daysBeforeShiftStart: Scalars['Int'];
  id: Scalars['Int'];
  ruleGroup: RuleGroup;
  rules: Array<AutoCascadeRule>;
  updatedAt: Scalars['DateTime'];
};

/** Names of Compliance Rule sets */
export enum RuleSetEnum {
  /** Junior Doctor 2016 Contract */
  Jd2016 = 'JD2016',
  /** Working Time Regulations */
  Wtr = 'WTR'
}

/** Autogenerated return type of SailSet */
export type SailSetPayload = {
  __typename?: 'SailSetPayload';
  errors: Array<ValidationError>;
  /** Whether the setting was successfully set. */
  success: Scalars['Boolean'];
};

/** SAM Agency Worker */
export type SamAgencyWorker = {
  __typename?: 'SamAgencyWorker';
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  niNumber?: Maybe<Scalars['String']>;
  regBodyNumber?: Maybe<Scalars['String']>;
  staffGroup?: Maybe<StaffGroup>;
};

/** Autogenerated return type of SaveVersion */
export type SaveVersionPayload = {
  __typename?: 'SaveVersionPayload';
  /** Created DE Contract Version */
  deContractVersion?: Maybe<Version>;
  errors: Array<ValidationError>;
};

export type ScheduleAddEventInput = {
  date: Scalars['Date'];
  eventId?: InputMaybe<Scalars['Int']>;
  eventTypeEnum: RosteringEventTypeEnum;
  rotaShiftSetId?: InputMaybe<Scalars['Int']>;
  scheduleId: Scalars['Int'];
};

/** Autogenerated return type of ScheduleDelete */
export type ScheduleDeletePayload = {
  __typename?: 'ScheduleDeletePayload';
  errors: Array<ValidationError>;
  /** The deleted Schedule */
  rosteringSchedule?: Maybe<RosteringSchedule>;
};

/** Schedules with a common original_schedule_id. These have been created from the same Slot. */
export type ScheduleGroup = {
  __typename?: 'ScheduleGroup';
  /** ID of the original schedule from which all other schedules in the group have descended */
  originalScheduleId?: Maybe<Scalars['ID']>;
  /** Schedules with a common original_schedule_id */
  schedules?: Maybe<Array<RosteringSchedule>>;
};

/** Worker Pattern ID and name, and the Schedules created from the Pattern Slots */
export type SchedulesWithinPattern = {
  __typename?: 'SchedulesWithinPattern';
  /** End date of the pattern on roster */
  patternOnRosterEndDate?: Maybe<Scalars['Date']>;
  /** ID of the PatternOnRoster. Can be "null" if the Schedules are not related to a PatternOnRoster. */
  patternOnRosterId?: Maybe<Scalars['ID']>;
  /** Start date of the pattern on roster */
  patternOnRosterStartDate?: Maybe<Scalars['Date']>;
  /** Schedules relating to the Worker Pattern, grouped by original_schedule_id */
  scheduleGroups?: Maybe<Array<ScheduleGroup>>;
  /**
   * ID of the Worker Pattern. Can be "null" if the Schedules are not related to any pattern.
   * @deprecated Schedules are now grouped by pattern_on_roster_id rather than worker_pattern_id.
   */
  workerPatternId?: Maybe<Scalars['ID']>;
  /** Name of the Worker Pattern */
  workerPatternName?: Maybe<Scalars['String']>;
  /** Unique identifier of the Worker Pattern */
  workerPatternUuid?: Maybe<Scalars['String']>;
};

/** Self booking types */
export enum SelfBookingEnum {
  /** Allow */
  Allow = 'ALLOW',
  /** Inherit from department */
  InheritDepartment = 'INHERIT_DEPARTMENT',
  /** Do not allow */
  NotAllow = 'NOT_ALLOW'
}

/** Autogenerated return type of SendBlockToAgencyTier */
export type SendBlockToAgencyTierPayload = {
  __typename?: 'SendBlockToAgencyTierPayload';
  errors: Array<ValidationError>;
  /** shift block */
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of SendConfirmationInstructions */
export type SendConfirmationInstructionsPayload = {
  __typename?: 'SendConfirmationInstructionsPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SendEnquiry */
export type SendEnquiryPayload = {
  __typename?: 'SendEnquiryPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SendMultipleShiftsToBank */
export type SendMultipleShiftsToBankPayload = {
  __typename?: 'SendMultipleShiftsToBankPayload';
  errors: Array<ValidationError>;
  /** Rostering Events */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of SendResetPasswordInstructions */
export type SendResetPasswordInstructionsPayload = {
  __typename?: 'SendResetPasswordInstructionsPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SendToAgencyTier */
export type SendToAgencyTierPayload = {
  __typename?: 'SendToAgencyTierPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SendToBank */
export type SendToBankPayload = {
  __typename?: 'SendToBankPayload';
  errors: Array<ValidationError>;
  /** Rostering Event */
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of SetDefaultExternalGrade */
export type SetDefaultExternalGradePayload = {
  __typename?: 'SetDefaultExternalGradePayload';
  errors: Array<ValidationError>;
  /** External grade */
  externalGrade?: Maybe<ExternalGrade>;
  success: Scalars['Boolean'];
};

export type Shift = {
  __typename?: 'Shift';
  actualEndTime?: Maybe<Scalars['DateTime']>;
  actualStartTime?: Maybe<Scalars['DateTime']>;
  adminNote?: Maybe<Scalars['String']>;
  agencyBooking?: Maybe<AgencyBooking>;
  agencyLocked?: Maybe<Scalars['Boolean']>;
  agencyRateCardAttached?: Maybe<Scalars['Boolean']>;
  agencyRateSummary?: Maybe<Scalars['String']>;
  agencyRegistration?: Maybe<AgencyRegistration>;
  agencyTier?: Maybe<AgencyTier>;
  agencyWorkerDocsPresent?: Maybe<Scalars['Boolean']>;
  agencyWorkerProposals: AgencyWorkerProposalConnection;
  agencyWorkerRates?: Maybe<Array<AgencyWorkerProposalRates>>;
  agreedFraudStatementAt?: Maybe<Scalars['DateTime']>;
  appliedBy?: Maybe<Array<OrganisationWorker>>;
  appliedByShiftLimits?: Maybe<Array<OrganisationWorkerShiftLimits>>;
  archived?: Maybe<Scalars['Boolean']>;
  auditEvents?: Maybe<Array<AuditEvent>>;
  autoCascadeTriggers?: Maybe<Array<Trigger>>;
  availableActions: Array<Scalars['String']>;
  availableForAgencyUserActions: Array<Scalars['String']>;
  availableShiftActionsToHubUser?: Maybe<Array<Scalars['String']>>;
  bankRateCardAttached: Scalars['Boolean'];
  batchId?: Maybe<Scalars['Int']>;
  batchReference?: Maybe<Scalars['String']>;
  bookedBy?: Maybe<OrganisationWorker>;
  bookedBySecondaryBankWorker?: Maybe<Scalars['Boolean']>;
  breakMinutes: Scalars['Int'];
  breaks?: Maybe<Scalars['Int']>;
  breaksExceptionNotes?: Maybe<Scalars['String']>;
  bulkPaymentApproveUuid?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  confirmedBy?: Maybe<Scalars['String']>;
  confirmedEmail?: Maybe<Scalars['String']>;
  confirmedPosition?: Maybe<Scalars['String']>;
  confirmedSignature?: Maybe<Scalars['String']>;
  coreHours: Scalars['Float'];
  coreTotalRate?: Maybe<Scalars['Float']>;
  costCentre?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dateSentToAgency?: Maybe<Scalars['DateTime']>;
  deContractVersionAssignment?: Maybe<VersionAssignment>;
  deleted?: Maybe<Scalars['Boolean']>;
  department: Department;
  didNotAttend?: Maybe<DidNotAttend>;
  draftPayElements: Array<PayElement>;
  endTime: Scalars['DateTime'];
  enforceFraudDeclarationStatement?: Maybe<Scalars['Boolean']>;
  enhancements?: Maybe<Scalars['Float']>;
  escalatedBy?: Maybe<Scalars['String']>;
  escalationRequested?: Maybe<Scalars['Boolean']>;
  esrNumberId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalNote?: Maybe<Scalars['String']>;
  externalSkills?: Maybe<Array<ExternalSkill>>;
  externalUnit?: Maybe<ExternalUnit>;
  grade: Grade;
  gradeBreakMinutes?: Maybe<Scalars['Int']>;
  gradeBreakRepeatMinutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Rates include benefits (eg WTD) */
  includesBenefit?: Maybe<Scalars['Boolean']>;
  integrationShift?: Maybe<IntegrationShift>;
  ir35: Scalars['String'];
  limitToPreferred?: Maybe<Scalars['Boolean']>;
  locumFirstName?: Maybe<Scalars['String']>;
  locumGrade?: Maybe<Scalars['String']>;
  locumLastName?: Maybe<Scalars['String']>;
  locumType?: Maybe<Scalars['Int']>;
  minimumBreakDuration?: Maybe<Scalars['Int']>;
  minimumRequiredBreak?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  onCall: Scalars['Boolean'];
  onCallWithTimeframes?: Maybe<Scalars['Boolean']>;
  organisation: Organisation;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  paid?: Maybe<Scalars['Boolean']>;
  pamAgencyWorker?: Maybe<AgencyWorker>;
  payElements: Array<PayElement>;
  paymentApproved?: Maybe<Scalars['Boolean']>;
  paymentApproverNotes?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Payment>>;
  payrollAdjustment?: Maybe<AgencyAdjustment>;
  positionManagementCode?: Maybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  rate?: Maybe<Scalars['Float']>;
  rateElements?: Maybe<Array<PayElement>>;
  rateRequest?: Maybe<RateChange>;
  reasonForRequest?: Maybe<Scalars['String']>;
  reasonForRequestId?: Maybe<Scalars['Int']>;
  releasedTo?: Maybe<Array<Scalars['String']>>;
  requestedBy?: Maybe<ShiftRequestor>;
  requiresDeContractToSign?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
  rpaLocked?: Maybe<Scalars['Boolean']>;
  rpaLockedReason?: Maybe<Scalars['String']>;
  samAgencyWorker?: Maybe<SamAgencyWorker>;
  selfBooking?: Maybe<Scalars['Boolean']>;
  sentFromRostering?: Maybe<Scalars['Boolean']>;
  sentToAgency?: Maybe<Scalars['Boolean']>;
  shiftBlock?: Maybe<ShiftBlock>;
  shiftBlockId?: Maybe<Scalars['ID']>;
  shiftGrade?: Maybe<Scalars['String']>;
  shiftType?: Maybe<ShiftTypeEnum>;
  shiftsSeries: ShiftsSeries;
  shouldEnforceBreaks?: Maybe<Scalars['Boolean']>;
  signOffNonAmendedOrRejectedRequests: Scalars['Boolean'];
  signOffRequested?: Maybe<Scalars['Boolean']>;
  site: Site;
  skills: Array<Skill>;
  staffGroup: StaffGroup;
  staffGroupId?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  status: ShiftStatus;
  timeframes?: Maybe<Array<OnCallShiftTimeframe>>;
  timesheetAuthoriser?: Maybe<Scalars['String']>;
  totalAgencyPay?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  totalPay?: Maybe<Scalars['Float']>;
  totalPayPence?: Maybe<Scalars['Int']>;
  totalRateSummary?: Maybe<Scalars['String']>;
  unsocialHours: Scalars['Float'];
  unsocialTotalRate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBookedShift?: Maybe<UserBookedShift>;
  usesFiftyPercentRule: Scalars['Boolean'];
  verified?: Maybe<Scalars['Boolean']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedId?: Maybe<Scalars['Int']>;
  watchingHubUsers?: Maybe<Array<HubUser>>;
  withSecondaryBank: Scalars['Boolean'];
  workerName?: Maybe<Scalars['String']>;
  /** Remote sign off reported and confirmed times */
  workerSignOff?: Maybe<ShiftWorkerSignOff>;
};


export type ShiftAgencyWorkerProposalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Shift Applications - Bank, Agency or Shortlisted Agency */
export enum ShiftApplicationsEnum {
  /** Shift has a proposal from Agency Worker */
  AgencyProposals = 'AGENCY_PROPOSALS',
  /** Shift applied by Bank Worker */
  BankApplications = 'BANK_APPLICATIONS',
  /** Shift has a shortlisted proposal from Agency Worker */
  ShortlistedAgencyProposals = 'SHORTLISTED_AGENCY_PROPOSALS'
}

/** Autogenerated return type of ShiftAssignAgencyRegistration */
export type ShiftAssignAgencyRegistrationPayload = {
  __typename?: 'ShiftAssignAgencyRegistrationPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ShiftAssignAgencyWorker */
export type ShiftAssignAgencyWorkerPayload = {
  __typename?: 'ShiftAssignAgencyWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ShiftAssignProposedAgencyWorker */
export type ShiftAssignProposedAgencyWorkerPayload = {
  __typename?: 'ShiftAssignProposedAgencyWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type ShiftBlock = {
  __typename?: 'ShiftBlock';
  activeTimesheets?: Maybe<Scalars['Int']>;
  adminNotes?: Maybe<Scalars['String']>;
  agencyLocked?: Maybe<Scalars['Boolean']>;
  agencyRateCardAttached?: Maybe<Scalars['Boolean']>;
  agencyTier?: Maybe<AgencyTier>;
  agencyWorkerBlockProposals?: Maybe<Array<AgencyWorkerBlockProposal>>;
  agencyWorkerRates?: Maybe<Array<AgencyWorkerProposalRates>>;
  amendedTimesheets?: Maybe<Scalars['Int']>;
  autoCascadeTriggers?: Maybe<Array<Trigger>>;
  averageHourlyRate?: Maybe<Scalars['Float']>;
  bookedBy?: Maybe<ShiftBlockBookedBy>;
  bookedByAgency?: Maybe<Scalars['Boolean']>;
  bookingPreference?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deContractVersionAssignment?: Maybe<VersionAssignment>;
  department?: Maybe<Scalars['String']>;
  departments?: Maybe<Array<Department>>;
  draftShifts?: Maybe<Array<BlockShift>>;
  endDate?: Maybe<Scalars['DateTime']>;
  grade?: Maybe<Grade>;
  hubUser?: Maybe<HubUser>;
  id: Scalars['ID'];
  modAccommodationExpensesBudget?: Maybe<Scalars['Float']>;
  modApprovalBudgetValue?: Maybe<Scalars['Float']>;
  modApprovedWeeklyHours?: Maybe<Scalars['Int']>;
  modFinancialApprovalCode?: Maybe<Scalars['String']>;
  modOutOfHoursBudget?: Maybe<Scalars['Float']>;
  modOutOfHoursEstimatedWeeklyHours?: Maybe<Scalars['Int']>;
  modPositionNumber?: Maybe<Scalars['Int']>;
  modRegion?: Maybe<ShiftBlockModRegionEnum>;
  modSecurityClearance?: Maybe<ModSecurityClearanceEnum>;
  modSingletonPost?: Maybe<Scalars['Boolean']>;
  modSubsistenceExpensesBudget?: Maybe<Scalars['Float']>;
  modTravelExpensesBudget?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  organisation: Organisation;
  organisationStaffGroup: OrganisationStaffGroup;
  pendingApplicationsCount: Scalars['Int'];
  provisionallyBooked: Scalars['Boolean'];
  publishedBy?: Maybe<User>;
  reasonForBooking?: Maybe<ReasonForRequest>;
  reasonsForDeletion?: Maybe<Array<ReasonForDeletion>>;
  sentToAgency?: Maybe<Scalars['Boolean']>;
  sentToAgencyDate?: Maybe<Scalars['DateTime']>;
  shiftBlockApplications?: Maybe<ShiftBlockApplicationConnection>;
  shiftBlockAuditEvents?: Maybe<ShiftBlockAuditEventConnection>;
  shiftCount: Scalars['Int'];
  shiftIds: Array<Scalars['Int']>;
  shifts?: Maybe<Array<Shift>>;
  simpleSendBlockToAgency?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Site>>;
  skills?: Maybe<Array<Scalars['String']>>;
  staffGroup?: Maybe<StaffGroup>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ShiftBlockStatusEnum>;
  timesheets?: Maybe<Array<ShiftBlockTimesheet>>;
  totalHours?: Maybe<Scalars['Float']>;
  totalRate?: Maybe<Scalars['Float']>;
  uid: Scalars['String'];
  workerNotes?: Maybe<Scalars['String']>;
  workerShifts?: Maybe<Array<WorkerShift>>;
};


export type ShiftBlockShiftBlockApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  withArchived?: InputMaybe<Scalars['Boolean']>;
};


export type ShiftBlockShiftBlockAuditEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShiftBlockApplication = {
  __typename?: 'ShiftBlockApplication';
  applicant: ShiftBlockApplicationApplicant;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  shiftBlock: ShiftBlock;
  status?: Maybe<ShiftBlockApplicationStatusEnum>;
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type ShiftBlockApplicationApplicant = OrganisationRegistration;

/** The connection type for ShiftBlockApplication. */
export type ShiftBlockApplicationConnection = {
  __typename?: 'ShiftBlockApplicationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShiftBlockApplicationEdge>>>;
  /** A list of nodes. */
  nodes: Array<ShiftBlockApplication>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ShiftBlockApplicationEdge = {
  __typename?: 'ShiftBlockApplicationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShiftBlockApplication>;
};

export enum ShiftBlockApplicationStatusEnum {
  /** Approved */
  Approved = 'APPROVED',
  /** Pending and waiting approval */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Revoked by worker */
  Revoked = 'REVOKED'
}

/** Fetch shift block audit events */
export type ShiftBlockAuditEvent = {
  __typename?: 'ShiftBlockAuditEvent';
  /** data that has been modified */
  changesData?: Maybe<Scalars['JSON']>;
  /** when event was generated */
  createdAt: Scalars['DateTime'];
  /** user who initiated the event */
  currentUser: SimpleUser;
  /** which part of the application generated this event */
  event: Scalars['String'];
  /** event id */
  id: Scalars['Int'];
  /** ID of shift block */
  shiftBlockId: Scalars['Int'];
  /** which application generated this event */
  source: Scalars['String'];
  /** when event was last updated */
  updatedAt: Scalars['DateTime'];
};

/** The connection type for ShiftBlockAuditEvent. */
export type ShiftBlockAuditEventConnection = {
  __typename?: 'ShiftBlockAuditEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShiftBlockAuditEventEdge>>>;
  /** A list of nodes. */
  nodes: Array<ShiftBlockAuditEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ShiftBlockAuditEventEdge = {
  __typename?: 'ShiftBlockAuditEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShiftBlockAuditEvent>;
};

export type ShiftBlockBookedBy = AgencyRegistration | AgencyWorker | OrganisationRegistration | Worker;

/** The connection type for ShiftBlock. */
export type ShiftBlockConnection = {
  __typename?: 'ShiftBlockConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShiftBlockEdge>>>;
  /** A list of nodes. */
  nodes: Array<ShiftBlock>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ShiftBlockCreateInput = {
  adminNotes?: InputMaybe<Scalars['String']>;
  bookingPreference?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  gradeId: Scalars['Int'];
  modAccommodationExpensesBudget?: InputMaybe<Scalars['Float']>;
  modApprovalBudgetValue?: InputMaybe<Scalars['Float']>;
  modFinancialApprovalCode?: InputMaybe<Scalars['String']>;
  modOutOfHoursBudget?: InputMaybe<Scalars['Float']>;
  modOutOfHoursEstimatedWeeklyHours?: InputMaybe<Scalars['Int']>;
  modPositionNumber?: InputMaybe<Scalars['Int']>;
  modScheduleOfHours?: InputMaybe<Scalars['Int']>;
  modSecurityClearance?: InputMaybe<ModSecurityClearanceEnum>;
  modSubsistenceExpensesBudget?: InputMaybe<Scalars['Float']>;
  modTravelExpensesBudget?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  organisationId: Scalars['Int'];
  reasonForBookingId?: InputMaybe<Scalars['Int']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']>>;
  staffGroupId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ShiftBlockStatusEnum>;
  workerNotes?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ShiftBlockDelete */
export type ShiftBlockDeletePayload = {
  __typename?: 'ShiftBlockDeletePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** An edge in a connection. */
export type ShiftBlockEdge = {
  __typename?: 'ShiftBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShiftBlock>;
};

export enum ShiftBlockModRegionEnum {
  /** Central and Wessex */
  CentralAndWessex = 'CENTRAL_AND_WESSEX',
  /** DMRC Stanford Hall */
  DmrcStanfordHall = 'DMRC_STANFORD_HALL',
  /** East */
  East = 'EAST',
  /** London and South */
  LondonAndSouth = 'LONDON_AND_SOUTH',
  /** North */
  North = 'NORTH',
  /** Northern Ireland */
  NorthernIreland = 'NORTHERN_IRELAND',
  /** Other */
  Other = 'OTHER',
  /** Overseas */
  Overseas = 'OVERSEAS',
  /** Scotland */
  Scotland = 'SCOTLAND',
  /** Scotland and North */
  ScotlandAndNorth = 'SCOTLAND_AND_NORTH',
  /** South West */
  SouthWest = 'SOUTH_WEST',
  /** Wales and West */
  WalesAndWest = 'WALES_AND_WEST'
}

/** Organisation Registations for Blocks */
export type ShiftBlockOrganisationRegistration = {
  __typename?: 'ShiftBlockOrganisationRegistration';
  /** Email of the worker */
  email: Scalars['String'];
  /** Full name of the worker */
  fullName: Scalars['String'];
  /** ID of the worker */
  id: Scalars['Int'];
  /** Organisation Registration object of the user */
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

/** Paginated list of ShiftBlocks */
export type ShiftBlockPaginatedList = {
  __typename?: 'ShiftBlockPaginatedList';
  /** List of ShiftBlocks */
  list?: Maybe<Array<ShiftBlock>>;
  /** Pagination data */
  pagination?: Maybe<Pagination>;
};

/** Autogenerated return type of ShiftBlockPublish */
export type ShiftBlockPublishPayload = {
  __typename?: 'ShiftBlockPublishPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
  success: Scalars['Boolean'];
};

export enum ShiftBlockStatusEnum {
  /** A Worker or Agency has applied for this Block */
  Applied = 'APPLIED',
  /** Available for Worker and Agency applications */
  Available = 'AVAILABLE',
  /** Booked */
  Booked = 'BOOKED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Draft */
  Draft = 'DRAFT',
  /** Block has an awaiting proposal */
  Proposals = 'PROPOSALS'
}

/** shift block timesheet type */
export type ShiftBlockTimesheet = {
  __typename?: 'ShiftBlockTimesheet';
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  hoursDifference: Scalars['Float'];
  hoursExpected: Scalars['Float'];
  hoursWorked: Scalars['Float'];
  id: Scalars['ID'];
  shiftBlockId: Scalars['ID'];
  shifts?: Maybe<Array<Shift>>;
  startsAt: Scalars['DateTime'];
  status: ShiftBlockTimesheetStatusEnum;
  updatedAt: Scalars['DateTime'];
};

/** list of shift block timesheets statuses */
export enum ShiftBlockTimesheetStatusEnum {
  /** Booked */
  Approved = 'APPROVED',
  /** Booked */
  Booked = 'BOOKED',
  /** Booked */
  InProgress = 'IN_PROGRESS',
  /** Booked */
  Payroll = 'PAYROLL',
  /** Booked */
  SignedOff = 'SIGNED_OFF',
  /** Booked */
  SignOffRequested = 'SIGN_OFF_REQUESTED'
}

export type ShiftBlockUpdateInput = {
  adminNotes?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  gradeId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  organisationStaffGroupId?: InputMaybe<Scalars['Int']>;
  reasonForBookingId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  workerNotes?: InputMaybe<Scalars['String']>;
};

/** Shift details for worker, pro-rata for rotation period */
export type ShiftBreakdownForWorker = {
  __typename?: 'ShiftBreakdownForWorker';
  /** Average weekly hours worked */
  averageWeeklyHours: Scalars['Float'];
  /** Duration of pay period in weeks */
  cycleDurationInWeeks: Scalars['Int'];
  /** Number of long day shifts */
  longDayShiftCount: Scalars['Int'];
  /** Number of night shifts */
  nightShiftCount: Scalars['Int'];
  /** Number of normal day shifts */
  normalDayShiftCount: Scalars['Int'];
  /** Number of weekend shifts */
  weekendShiftCount: Scalars['Int'];
};

/** Autogenerated return type of ShiftCancelAgencyWorker */
export type ShiftCancelAgencyWorkerPayload = {
  __typename?: 'ShiftCancelAgencyWorkerPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Shift event with its clashes */
export type ShiftClashes = {
  __typename?: 'ShiftClashes';
  /** Shift clashes */
  shiftClashes: Array<RosteringEvent>;
  /** Shift event for which clashes are checked */
  shiftEvent: RosteringEvent;
};

/** The connection type for Shift. */
export type ShiftConnection = {
  __typename?: 'ShiftConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShiftEdge>>>;
  /** A list of nodes. */
  nodes: Array<Shift>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Input arguments to create a shift from block pages */
export type ShiftCreateInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  breaks?: InputMaybe<Scalars['Int']>;
  costCentreCode?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['Int'];
  frequency: ShiftFrequencyEnum;
  frequencyEnd?: InputMaybe<Scalars['DateTime']>;
  frequencyStartDates?: InputMaybe<Array<Scalars['DateTime']>>;
  note?: InputMaybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  shiftBlockId: Scalars['Int'];
  shiftType?: InputMaybe<ShiftTypeEnum>;
  siteId: Scalars['Int'];
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** An edge in a connection. */
export type ShiftEdge = {
  __typename?: 'ShiftEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Shift>;
};

/** Enriched shift data */
export type ShiftEnrichedData = {
  __typename?: 'ShiftEnrichedData';
  /** Name of agency */
  agencyName?: Maybe<Scalars['String']>;
  /** Cost code of department */
  costCode: Scalars['String'];
  /** Department name */
  department: Scalars['String'];
  /** Email of worker */
  email: Scalars['String'];
  /** Authorisor of expense */
  expenseAuthorisor: Scalars['String'];
  /** First name of worker */
  firstName: Scalars['String'];
  /** Last name of worker */
  lastName: Scalars['String'];
  /** Location of shift */
  location: Scalars['String'];
  /** Organisation name */
  organisation: Scalars['String'];
  /** ID of approver */
  payrollNumber?: Maybe<Scalars['String']>;
  /** End time of shift */
  shiftEndTime: Scalars['ISO8601DateTime'];
  /** Start time of shift */
  shiftStartTime: Scalars['ISO8601DateTime'];
  /** Subjective code */
  subjectiveCode?: Maybe<Scalars['String']>;
  /** Employment type of worker */
  workerEmploymentType?: Maybe<Scalars['String']>;
  /** Payment type of worker */
  workerPaymentType?: Maybe<Scalars['String']>;
};

/**
 * A Shift which the Worker was due to work during their period of Leave,
 *     and whether reassignable should the Leave be cancelled
 */
export type ShiftForReassignment = {
  __typename?: 'ShiftForReassignment';
  /**
   * Whether the Shift Event can be reassigned to the Worker,
   *           should the associated Leave Request be cancelled
   */
  reassignable: Scalars['Boolean'];
  /** Shift Event which the Worker was assigned to before requesting Leave */
  shiftEvent: RosteringEvent;
};

/** Enum of what frequency a shift series is in */
export enum ShiftFrequencyEnum {
  /** Custom */
  Custom = 'CUSTOM',
  /** Every weekday */
  EveryWeekday = 'EVERY_WEEKDAY',
  /** Single Shift (does not repeat) */
  Single = 'SINGLE',
  /** Weekly */
  Weekly = 'WEEKLY'
}

/** Object to represent a small amount of data about a new shift for performing hours calculations. */
export type ShiftHours = {
  /** Proposed end time */
  proposedEndTime: Scalars['String'];
  /** Proposed start time */
  proposedStartTime: Scalars['String'];
  /** Start date for the shift */
  startDate: Scalars['Date'];
};

/** Shift type */
export enum ShiftKindEnum {
  /** Hourly shift */
  Hourly = 'HOURLY',
  /** Sessional shift */
  Sessional = 'SESSIONAL'
}

/** The ordering of the returned shifts */
export enum ShiftOrderBy {
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC'
}

/** Shift Origin - Roster, Bank or Block */
export enum ShiftOriginEnum {
  /** Shift originates from Bank */
  Bank = 'BANK',
  /** Shift originates from Block */
  Block = 'BLOCK',
  /** Shift originates from Roster */
  Roster = 'ROSTER'
}

/** Autogenerated return type of Shift */
export type ShiftPayload = {
  __typename?: 'ShiftPayload';
  actionsPerformed?: Maybe<Array<Scalars['String']>>;
  errors: Array<ValidationError>;
  shift?: Maybe<Shift>;
  success: Scalars['Boolean'];
};

/** Shift Rate Escalation - Escalated or Non-escalated */
export enum ShiftRateEscalationEnum {
  /** Shift rate has been escalated */
  Escalated = 'ESCALATED',
  /** Shift has had rate escalation requested */
  EscalationRequested = 'ESCALATION_REQUESTED',
  /** Shift rate has not been escalated */
  NonEscalated = 'NON_ESCALATED',
  /** Shift has escalation request at level user can authorise */
  UserAuthLevelRequests = 'USER_AUTH_LEVEL_REQUESTS'
}

/** Organisation's staff group shift release option */
export enum ShiftReleaseOptionEnum {
  AutoRelease = 'AUTO_RELEASE',
  ManualRelease = 'MANUAL_RELEASE',
  TimedRelease = 'TIMED_RELEASE'
}

/** Shift Released To - Primary Bank, Secondary Bank, Agency */
export enum ShiftReleasedToEnum {
  /** Shift has been released to Agency */
  Agency = 'AGENCY',
  /** Shift has been released to Primary Bank */
  PrimaryBank = 'PRIMARY_BANK',
  /** Shift has been released to Secondary Bank */
  SecondaryBank = 'SECONDARY_BANK'
}

export type ShiftRequestor = {
  __typename?: 'ShiftRequestor';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
};

/** The scope of shifts to be returned */
export enum ShiftScope {
  /** Shift is not deleted and not agency locked */
  Active = 'ACTIVE',
  /** Shift is not deleted */
  NotDeleted = 'NOT_DELETED',
  /** No scoped shifts */
  NoScope = 'NO_SCOPE',
  /** Shift has been signed off */
  SignedOff = 'SIGNED_OFF',
  /** Shift is available and urgent */
  Urgent = 'URGENT',
  /** A shift booked by a bank worked that has started in the past */
  WorkedByBank = 'WORKED_BY_BANK'
}

/** The calculated shift status */
export enum ShiftStatus {
  /**
   * A bank locum has applied to work the shift
   * @deprecated not a shift status
   */
  Applied = 'APPLIED',
  /** The shift has been approved for processing by payroll */
  Approved = 'APPROVED',
  /** The shift is available for applications */
  Available = 'AVAILABLE',
  /** A bank locum is booked or agency locum is booked and locked */
  Booked = 'BOOKED',
  /** Shift has been recalled and no longer available */
  Deleted = 'DELETED',
  /** The shift is a draft not ready to be published for applications */
  Draft = 'DRAFT',
  /** The shift has incomplete information to make it available */
  MappingsMissing = 'MAPPINGS_MISSING',
  /** The shift is completed and been processed by payroll */
  Paid = 'PAID',
  /** The shift is booked by an agency worker but still in the cut off period */
  ProvisionallyBooked = 'PROVISIONALLY_BOOKED',
  /** The shift has been finished and signed off */
  SignedOff = 'SIGNED_OFF',
  /** Shift sign off has been requested */
  SignOffRequested = 'SIGN_OFF_REQUESTED',
  /** Shift is booked and cannot be signed off on the app */
  ToSignOffOnHub = 'TO_SIGN_OFF_ON_HUB',
  /** Shift is booked and can be signed off on the app */
  ToSignOffOnHubOrApp = 'TO_SIGN_OFF_ON_HUB_OR_APP',
  /** The shift status is unknown */
  Unknown = 'UNKNOWN',
  /**
   * The shift is marked as urgent
   * @deprecated not a shift status
   */
  Urgent = 'URGENT'
}

/** Shift Type - Hourly, Sessional or OnCall */
export enum ShiftTypeEnum {
  /** Shift is paid hourly */
  Hourly = 'HOURLY',
  /** Shift is paid based on call out and on call rates */
  Oncall = 'ONCALL',
  /** Shift is paid per session */
  Sessional = 'SESSIONAL'
}

/** Attributes able to be updated on shifts in blocks */
export type ShiftUpdateInput = {
  adminNote?: InputMaybe<Scalars['String']>;
  breaks?: InputMaybe<Scalars['Int']>;
  costCentreCode?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endTime: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  reasonForRequestId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  skillIds?: InputMaybe<Array<Scalars['Int']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
};

export type ShiftWorkerSignOff = {
  __typename?: 'ShiftWorkerSignOff';
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['String']>;
  confirmationNotes?: Maybe<Scalars['String']>;
  confirmedBreaks?: Maybe<Scalars['String']>;
  confirmedEndTime?: Maybe<Scalars['String']>;
  confirmedStartTime?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  initialBreaks?: Maybe<Scalars['String']>;
  initialEndTime?: Maybe<Scalars['String']>;
  initialStartTime?: Maybe<Scalars['String']>;
  rejected?: Maybe<Scalars['Boolean']>;
  rejectionReason?: Maybe<Scalars['String']>;
  reportedBreaks?: Maybe<Scalars['String']>;
  reportedEndTime?: Maybe<Scalars['String']>;
  reportedNotes?: Maybe<Scalars['String']>;
  reportedStartTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Paginated list of AgencyUsers */
export type ShiftsPaginatedList = {
  __typename?: 'ShiftsPaginatedList';
  list?: Maybe<Array<Shift>>;
  pagination?: Maybe<Pagination>;
};

export type ShiftsSeries = {
  __typename?: 'ShiftsSeries';
  id: Scalars['ID'];
  series?: Maybe<ShiftFrequencyEnum>;
  seriesDay?: Maybe<Scalars['String']>;
  shiftBlock?: Maybe<ShiftBlock>;
};

export type Sidekiq = {
  __typename?: 'Sidekiq';
  status?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignOffReceipt */
export type SignOffReceiptPayload = {
  __typename?: 'SignOffReceiptPayload';
  errors: Array<ValidationError>;
  /** was the receipt signed off? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SignVersionAssignment */
export type SignVersionAssignmentPayload = {
  __typename?: 'SignVersionAssignmentPayload';
  errors: Array<ValidationError>;
  /** Was the version assignment? */
  success: Scalars['Boolean'];
};

/** Represents Links Signed Agreements */
export type SignedAgreement = {
  __typename?: 'SignedAgreement';
  /** Organisation name */
  organisation?: Maybe<Scalars['String']>;
  /** link to download agreement pdf */
  signedAgreementUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Signup */
export type SignupPayload = {
  __typename?: 'SignupPayload';
  errors: Array<ValidationError>;
  worker?: Maybe<NonAuthorisedUser>;
};

/** Autogenerated return type of SignupWithToken */
export type SignupWithTokenPayload = {
  __typename?: 'SignupWithTokenPayload';
  account?: Maybe<Account>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** user type that provides only basic user information */
export type SimpleUser = {
  __typename?: 'SimpleUser';
  /** user first name */
  firstName?: Maybe<Scalars['String']>;
  /** user id */
  id: Scalars['ID'];
  /** user last name */
  lastName?: Maybe<Scalars['String']>;
  /** one of HubUser, Worker, empty string */
  userType: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  locations?: Maybe<Array<Location>>;
  longitude?: Maybe<Scalars['Float']>;
  mapsUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organisation: Organisation;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  shifts: Array<Shift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination: Pagination;
};


export type SiteShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** The connection type for Site. */
export type SiteConnection = {
  __typename?: 'SiteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SiteEdge>>>;
  /** A list of nodes. */
  nodes: Array<Site>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SiteEdge = {
  __typename?: 'SiteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Site>;
};

/** Site of Activity, with nested Locations also associated with the Activity */
export type SiteOfActivities = {
  __typename?: 'SiteOfActivities';
  /** Site ID */
  id: Scalars['ID'];
  /** Locations associated with the Site and Activity */
  locations: Array<Location>;
  /** Site name */
  name: Scalars['String'];
};

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['Int'];
  name: Scalars['String'];
  numOfOrgStaffGroup: Scalars['Int'];
};

/** The connection type for Skill. */
export type SkillConnection = {
  __typename?: 'SkillConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SkillEdge>>>;
  /** A list of nodes. */
  nodes: Array<Skill>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SkillEdge = {
  __typename?: 'SkillEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Skill>;
};

export type SkillsMatch = {
  __typename?: 'SkillsMatch';
  match: Scalars['Boolean'];
  skillsMissing: Array<Scalars['String']>;
};

/** Total numbers of various types of shifts on the Rota Slot */
export type SlotBreakdown = {
  __typename?: 'SlotBreakdown';
  /** Number of Long Day shifts on Rota Slot */
  longDays: Scalars['Int'];
  /** Number of Night shifts on Rota Slot */
  nights: Scalars['Int'];
  /** Number of Early, Day, Mid, Late and Twilight shifts on Rota Slot */
  normalDays: Scalars['Int'];
  /** Number of weekend shifts on Rota Slot */
  weekendShifts: Scalars['Int'];
};

export type Speciality = {
  __typename?: 'Speciality';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

/** The connection type for Speciality. */
export type SpecialityConnection = {
  __typename?: 'SpecialityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpecialityEdge>>>;
  /** A list of nodes. */
  nodes: Array<Speciality>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SpecialityEdge = {
  __typename?: 'SpecialityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Speciality>;
};

/** Staff Group ID, title, regulation body and specialities */
export type StaffGroup = {
  __typename?: 'StaffGroup';
  agencyTiers?: Maybe<Array<AgencyTier>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Should not be used in conjunction with extended results */
  id: StaffGroupEnum;
  idValue: Scalars['Int'];
  regulationBody?: Maybe<RegulationBody>;
  specialities?: Maybe<Array<Speciality>>;
  title: Scalars['String'];
};

/** The connection type for StaffGroup. */
export type StaffGroupConnection = {
  __typename?: 'StaffGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<StaffGroupEdge>>>;
  /** A list of nodes. */
  nodes: Array<StaffGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StaffGroupEdge = {
  __typename?: 'StaffGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<StaffGroup>;
};

/** Staff Group */
export enum StaffGroupEnum {
  /** Additional Clinical Services */
  AdditionalClinicalServices = 'ADDITIONAL_CLINICAL_SERVICES',
  /** Additional Professional Scientific and Technical */
  AdditionalProfessionalScientificAndTechnical = 'ADDITIONAL_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL',
  /** Allied Health Professionals */
  AlliedHealthProfessionals = 'ALLIED_HEALTH_PROFESSIONALS',
  /** Counselling and Psychotherapy */
  CounsellingAndPsychotherapy = 'COUNSELLING_AND_PSYCHOTHERAPY',
  /** Estates and Ancillary */
  EstatesAndAncillary = 'ESTATES_AND_ANCILLARY',
  /** Estates Facilities Non Clinical */
  EstatesFacilitiesNonClinical = 'ESTATES_FACILITIES_NON_CLINICAL',
  /** Estates & Ancillary */
  EstatesAncillary = 'ESTATES___ANCILLARY',
  /** Healthcare Assistants */
  HealthcareAssistants = 'HEALTHCARE_ASSISTANTS',
  /** Healthcare Scientist */
  HealthcareScientist = 'HEALTHCARE_SCIENTIST',
  /** Medical staff group */
  Medical = 'MEDICAL',
  /** Admin and Clerical staff group */
  NonClinical = 'NON_CLINICAL',
  /** Nursing and Midwifery staff group */
  Nursing = 'NURSING',
  /** Osteopaths */
  Osteopaths = 'OSTEOPATHS',
  /** Pharmacy */
  Pharmacy = 'PHARMACY',
  /** Registered Professional */
  RegisteredProfessional = 'REGISTERED_PROFESSIONAL',
  /** Reservist General Practice Nurse */
  ReservistGeneralPracticeNurse = 'RESERVIST_GENERAL_PRACTICE_NURSE',
  /** Social Work */
  SocialWork = 'SOCIAL_WORK',
  /** Students */
  Students = 'STUDENTS',
  /** Vaccination Delivery */
  VaccinationDelivery = 'VACCINATION_DELIVERY',
  /** Volunteers  */
  Volunteers = 'VOLUNTEERS_'
}

/** Autogenerated return type of StoreRowOrderForUser */
export type StoreRowOrderForUserPayload = {
  __typename?: 'StoreRowOrderForUserPayload';
  errors: Array<ValidationError>;
  /** RosterRowOrder for current user */
  rosterRowOrder?: Maybe<RosterRowOrder>;
};

/** Agency Registration Structured Documentation Type */
export type StructuredDocumentations = {
  __typename?: 'StructuredDocumentations';
  documentation?: Maybe<Documentation>;
  name?: Maybe<Scalars['String']>;
  originalContent?: Maybe<Scalars['JSON']>;
};

/** SubscriptionType */
export type Subscription = {
  __typename?: 'Subscription';
  /** To subscribe to Compliance Check on Worker each time it is updated */
  complianceCheckForAssigningShiftsToWorker: ComplianceCheckForAssigningShiftsToWorkerPayload;
  /** To subscribe to Compliance Check on WorkerPattern each time it is updated */
  complianceCheckForRosteringWorkerPatternUpdated: ComplianceCheckForRosteringWorkerPatternUpdatedPayload;
  /** Subscribe to get Batch CSV URL when batch generated */
  paidPerItemBatchCsvUrlSubscription: BatchCsvUrlSubscriptionPayload;
  /** Subscribe to Preview batch csv generation */
  paidPerItemBatchPreviewSubscription: PreviewSubscriptionPayload;
  /** To subscribe to WorkerPatterns being added to roster */
  rosteringAddMultipleWorkerPatternsToRoster: AddMultipleWorkerPatternsToRosterPayload;
  /** To subscribe to WorkerPattern being added to roster */
  rosteringAddWorkerPatternToRoster: AddWorkerPatternToRosterPayload;
  /** To subscribe to multiple workers being assigned multiple shifts */
  rosteringAssignMultipleWorkersToMultipleShifts: AssignMultipleWorkersToMultipleShiftsPayload;
  /** To subscribe to WorkerPattern review metrics each time it is updated */
  rosteringWorkerPatternUpdated: WorkerPatternUpdatedPayload;
  /** Subscribe to get Batch CSV URL when batch generated */
  tempStaffPaymentsBatchCsvUrlSubscription: TempStaffBatchCsvUrlSubscriptionPayload;
  /** Subscribe to Preview batch csv generation */
  tempStaffPaymentsBatchPreviewSubscription: TempStaffPaymentsBatchPreviewSubscriptionPayload;
};


/** SubscriptionType */
export type SubscriptionComplianceCheckForAssigningShiftsToWorkerArgs = {
  eventIds: Array<Scalars['Int']>;
  rosteringOrganisationRegistrationId: Scalars['Int'];
};


/** SubscriptionType */
export type SubscriptionComplianceCheckForRosteringWorkerPatternUpdatedArgs = {
  workerPatternUniqueIdentifier: Scalars['String'];
};


/** SubscriptionType */
export type SubscriptionPaidPerItemBatchCsvUrlSubscriptionArgs = {
  batchId: Scalars['Int'];
};


/** SubscriptionType */
export type SubscriptionPaidPerItemBatchPreviewSubscriptionArgs = {
  inCollaborativeBank?: InputMaybe<Scalars['Boolean']>;
  receiptIds: Array<Scalars['Int']>;
};


/** SubscriptionType */
export type SubscriptionRosteringAddMultipleWorkerPatternsToRosterArgs = {
  rosterId: Scalars['Int'];
  workerPatternInputs: Array<RosterWorkerPatternInput>;
};


/** SubscriptionType */
export type SubscriptionRosteringAddWorkerPatternToRosterArgs = {
  endDate: Scalars['Date'];
  numberOfSlots: Scalars['Int'];
  rosterId: Scalars['Int'];
  startDate: Scalars['Date'];
  workerPatternId: Scalars['Int'];
};


/** SubscriptionType */
export type SubscriptionRosteringAssignMultipleWorkersToMultipleShiftsArgs = {
  assignmentInputs: Array<AssignmentInput>;
};


/** SubscriptionType */
export type SubscriptionRosteringWorkerPatternUpdatedArgs = {
  rosteringWorkerPatternId: Scalars['Int'];
};


/** SubscriptionType */
export type SubscriptionTempStaffPaymentsBatchCsvUrlSubscriptionArgs = {
  batchId: Scalars['Int'];
};


/** SubscriptionType */
export type SubscriptionTempStaffPaymentsBatchPreviewSubscriptionArgs = {
  shiftIds: Array<Scalars['Int']>;
};

/** An Agency User Permission Type as visible to Super Admins */
export type SuperAdminAgencyUserPermission = {
  __typename?: 'SuperAdminAgencyUserPermission';
  /** The Agency with which the Agency User is associated */
  agency: Agency;
  /** The Agency User to whom the permissions belong */
  agencyUser: AgencyUser;
  /** Agency User can assign workers */
  canAssignWorker: Scalars['Boolean'];
  /** Agency User can propose workers */
  canProposeWorker: Scalars['Boolean'];
  /** The record's ID */
  id: Scalars['ID'];
  /** The Organisation with which the Agency is associated */
  organisation: Organisation;
  /** The Organisation Staff Group with which the Organisation is associated */
  organisationStaffGroup: OrganisationStaffGroup;
};

/** Autogenerated return type of SuspendWorker */
export type SuspendWorkerPayload = {
  __typename?: 'SuspendWorkerPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

/** Autogenerated return type of SwitchAgency */
export type SwitchAgencyPayload = {
  __typename?: 'SwitchAgencyPayload';
  errors?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Grade and RosteringOrganisationRegistrations with this grade */
export type TeamRota = {
  __typename?: 'TeamRota';
  /** Grade */
  grade?: Maybe<Grade>;
  /** Collection of RosteringOrganisationRegistrations */
  rosteringOrganisationRegistrations: Array<RosteringOrganisationRegistration>;
};

/** Temp Staff Batch */
export type TempStaffBatch = {
  __typename?: 'TempStaffBatch';
  /** The date and time when the Batch was created. */
  createdAt: Scalars['DateTime'];
  /** URL to download Batch CSV file. */
  csvUrl?: Maybe<Scalars['String']>;
  /** Batch creator Details */
  hubUser: HubUser;
  /** Batch ID. */
  id: Scalars['Int'];
  /** Indicates whether the Batch is part of a collaborative bank. */
  inCollaborativeBank: Scalars['Boolean'];
  /** Batch Organisation. */
  organisation: Organisation;
  /** Batch Org Staff Group */
  organisationStaffGroup: OrganisationStaffGroup;
  /** Batch Payroll Cycle i.e. monthly / weekly */
  payrollCycle: Scalars['String'];
  /** Batch Id - For accessing batch with ID */
  reference: Scalars['String'];
  /** number of shifts in the Batch. */
  shiftCount: Scalars['Int'];
  /** Lists of the shifts associated with the Batch. */
  shifts: Array<Shift>;
  /** Batch processing Start Time */
  startProcessingTime: Scalars['DateTime'];
  /** URL to download Batch ZIP file. */
  zipUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TempStaffBatchCsvUrlSubscription */
export type TempStaffBatchCsvUrlSubscriptionPayload = {
  __typename?: 'TempStaffBatchCsvUrlSubscriptionPayload';
  /** URL to download batch csv */
  batchCsvUrl?: Maybe<Scalars['String']>;
  /** Errors */
  errors: Array<ValidationError>;
};

/** Enum of payment cycle type */
export enum TempStaffPaymentCycleEnum {
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Weekly */
  Weekly = 'WEEKLY'
}

/** Autogenerated return type of TempStaffPaymentsBatchPreviewSubscription */
export type TempStaffPaymentsBatchPreviewSubscriptionPayload = {
  __typename?: 'TempStaffPaymentsBatchPreviewSubscriptionPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** Preview Temp Staff Batch */
  previewBatch?: Maybe<TempStaffPreviewBatch>;
};

export type TempStaffPreviewBatch = {
  __typename?: 'TempStaffPreviewBatch';
  /** The URL to download the batch CSV from */
  batchCsvUrl?: Maybe<Scalars['String']>;
  /** The shifts included in the preview batch */
  shifts?: Maybe<Array<Shift>>;
};

export type TimesheetAuthoriser = {
  __typename?: 'TimesheetAuthoriser';
  departments?: Maybe<Department>;
  email?: Maybe<Scalars['String']>;
  hasPinSetup?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ToggleSuperAgencyUser */
export type ToggleSuperAgencyUserPayload = {
  __typename?: 'ToggleSuperAgencyUserPayload';
  errors: Array<Scalars['String']>;
  /** Super Agency User status. */
  isSuperUser?: Maybe<Scalars['Boolean']>;
};

/** Rule Trigger Type */
export type Trigger = {
  __typename?: 'Trigger';
  createdAt: Scalars['ISO8601DateTime'];
  fromTier?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  toTier?: Maybe<Scalars['Int']>;
  trashedAt?: Maybe<Scalars['ISO8601DateTime']>;
  triggerType?: Maybe<Scalars['String']>;
  triggerableId: Scalars['Int'];
  triggerableType: Scalars['String'];
  triggeredAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of UnassignWorkerFromShift */
export type UnassignWorkerFromShiftPayload = {
  __typename?: 'UnassignWorkerFromShiftPayload';
  errors: Array<ValidationError>;
  rosteringEvent?: Maybe<RosteringEvent>;
};

/** Autogenerated return type of UnassignWorkersFromShifts */
export type UnassignWorkersFromShiftsPayload = {
  __typename?: 'UnassignWorkersFromShiftsPayload';
  errors: Array<ValidationError>;
  /** Unassigned shift events */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of UndoSuspendWorker */
export type UndoSuspendWorkerPayload = {
  __typename?: 'UndoSuspendWorkerPayload';
  errors: Array<ValidationError>;
  organisationRegistration?: Maybe<OrganisationRegistration>;
};

/** Autogenerated return type of Unreject */
export type UnrejectPayload = {
  __typename?: 'UnrejectPayload';
  errors: Array<ValidationError>;
  /** was the receipt unrejected? */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateActivityOnMultipleShifts */
export type UpdateActivityOnMultipleShiftsPayload = {
  __typename?: 'UpdateActivityOnMultipleShiftsPayload';
  errors: Array<ValidationError>;
  /** Updated shift events */
  rosteringEvents?: Maybe<Array<RosteringEvent>>;
};

/** Autogenerated return type of UpdateAgency */
export type UpdateAgencyPayload = {
  __typename?: 'UpdateAgencyPayload';
  agency?: Maybe<Agency>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateAgencyTierOnBlock */
export type UpdateAgencyTierOnBlockPayload = {
  __typename?: 'UpdateAgencyTierOnBlockPayload';
  errors: Array<ValidationError>;
  /** shift block */
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of UpdateAgencyTier */
export type UpdateAgencyTierPayload = {
  __typename?: 'UpdateAgencyTierPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateAgencyUser */
export type UpdateAgencyUserPayload = {
  __typename?: 'UpdateAgencyUserPayload';
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateAgencyWorkedTimes */
export type UpdateAgencyWorkedTimesPayload = {
  __typename?: 'UpdateAgencyWorkedTimesPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<Shift>;
};

/** Autogenerated return type of UpdateAgencyWorker */
export type UpdateAgencyWorkerPayload = {
  __typename?: 'UpdateAgencyWorkerPayload';
  agencyRegistration?: Maybe<AgencyRegistration>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateAutoSendToAgency */
export type UpdateAutoSendToAgencyPayload = {
  __typename?: 'UpdateAutoSendToAgencyPayload';
  /** Auto Send To Agency */
  autoSendToAgency?: Maybe<AutoSendToAgency>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateBankShift */
export type UpdateBankShiftPayload = {
  __typename?: 'UpdateBankShiftPayload';
  errors: Array<ValidationError>;
  /** Roster Bank Shift */
  rosterBankShift?: Maybe<RosterBankShift>;
};

/** Autogenerated return type of UpdateBookingPreferences */
export type UpdateBookingPreferencesPayload = {
  __typename?: 'UpdateBookingPreferencesPayload';
  errors: Array<ValidationError>;
  organisationWorker?: Maybe<OrganisationWorker>;
};

/** Autogenerated return type of UpdateCollaborativeBank */
export type UpdateCollaborativeBankPayload = {
  __typename?: 'UpdateCollaborativeBankPayload';
  collaborativeBank?: Maybe<CollaborativeBank>;
  errors: Array<ValidationError>;
};

export type UpdateCollaborativeBankStaffGroupInput = {
  id: Scalars['Int'];
  instantBooking: Scalars['Boolean'];
  instantBookingByDefault?: InputMaybe<Scalars['Boolean']>;
  path?: InputMaybe<Scalars['Upload']>;
  staffGroupId: Scalars['Int'];
};

/** Autogenerated return type of UpdateCollaborativeBankStaffGroup */
export type UpdateCollaborativeBankStaffGroupPayload = {
  __typename?: 'UpdateCollaborativeBankStaffGroupPayload';
  collaborativeBankStaffGroup?: Maybe<CollaborativeBankStaffGroup>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateContactsDepartment */
export type UpdateContactsDepartmentPayload = {
  __typename?: 'UpdateContactsDepartmentPayload';
  department?: Maybe<Department>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateContract */
export type UpdateContractPayload = {
  __typename?: 'UpdateContractPayload';
  /** Updated DE Contract */
  deContract?: Maybe<Contract>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateCostCentre */
export type UpdateCostCentrePayload = {
  __typename?: 'UpdateCostCentrePayload';
  costCentre?: Maybe<CostCentre>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateDbsNumber */
export type UpdateDbsNumberPayload = {
  __typename?: 'UpdateDbsNumberPayload';
  document?: Maybe<Document>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateDepartmentListPermissionSets */
export type UpdateDepartmentListPermissionSetsPayload = {
  __typename?: 'UpdateDepartmentListPermissionSetsPayload';
  errors: Array<ValidationError>;
  /** The Permission Sets that were created or updated */
  rosteringPermissionSets?: Maybe<Array<RosteringPermissionSet>>;
};

/** Autogenerated return type of UpdateDepartment */
export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  department?: Maybe<Department>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateDivision */
export type UpdateDivisionPayload = {
  __typename?: 'UpdateDivisionPayload';
  /** division type */
  division?: Maybe<Division>;
  /** division errors */
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UpdateDocumentation */
export type UpdateDocumentationPayload = {
  __typename?: 'UpdateDocumentationPayload';
  /** Updated Documentation */
  documentation?: Maybe<Documentation>;
  errors: Array<ValidationError>;
  /** The success of the mutation */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateDraftShift */
export type UpdateDraftShiftPayload = {
  __typename?: 'UpdateDraftShiftPayload';
  draftShift?: Maybe<BlockShift>;
  errors: Array<ValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateEmploymentInfo */
export type UpdateEmploymentInfoPayload = {
  __typename?: 'UpdateEmploymentInfoPayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateEndDate */
export type UpdateEndDatePayload = {
  __typename?: 'UpdateEndDatePayload';
  errors: Array<ValidationError>;
  /** Updated Rostering::WorkerPatternOnRoster */
  rosteringWorkerPatternOnRoster?: Maybe<WorkerPatternOnRoster>;
};

/** Autogenerated return type of UpdateEsrNumberExpirationDate */
export type UpdateEsrNumberExpirationDatePayload = {
  __typename?: 'UpdateEsrNumberExpirationDatePayload';
  errors: Array<ValidationError>;
  esrNumber?: Maybe<EsrNumber>;
};

/** Autogenerated return type of UpdateEssentialInfo */
export type UpdateEssentialInfoPayload = {
  __typename?: 'UpdateEssentialInfoPayload';
  errors: Array<ValidationError>;
  user?: Maybe<Worker>;
};

/** Input type for updating EventTemplates of type "shift" on a RosteringWorkerPattern */
export type UpdateEventTemplateInput = {
  /** ID of the Activity to assign to the EventTemplate */
  activityId?: InputMaybe<Scalars['Int']>;
  /** ID of the EventTemplate to be updated */
  eventTemplateId: Scalars['Int'];
};

/** Autogenerated return type of UpdateExternalAccount */
export type UpdateExternalAccountPayload = {
  __typename?: 'UpdateExternalAccountPayload';
  errors: Array<ValidationError>;
  /** return values of the external account */
  externalAccount?: Maybe<ExternalAccount>;
};

/** Autogenerated return type of UpdateExternalGrade */
export type UpdateExternalGradePayload = {
  __typename?: 'UpdateExternalGradePayload';
  errors: Array<ValidationError>;
  /** External grade */
  externalGrade?: Maybe<ExternalGrade>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateExternalOrganisation */
export type UpdateExternalOrganisationPayload = {
  __typename?: 'UpdateExternalOrganisationPayload';
  errors: Array<ValidationError>;
  externalOrganisation?: Maybe<ExternalOrganisation>;
};

/** Autogenerated return type of UpdateFilterGrade */
export type UpdateFilterGradePayload = {
  __typename?: 'UpdateFilterGradePayload';
  errors: Array<ValidationError>;
  filterGrade?: Maybe<FilterGrade>;
};

/** Autogenerated return type of UpdateFilters */
export type UpdateFiltersPayload = {
  __typename?: 'UpdateFiltersPayload';
  errors: Array<ValidationError>;
  filters?: Maybe<Filters>;
};

/** Attributes for updating a grade */
export type UpdateGradeInput = {
  agencyCap?: InputMaybe<Scalars['Float']>;
  agencySubjectiveCode?: InputMaybe<Scalars['String']>;
  autoApplyUnsocialNightRates?: InputMaybe<Scalars['Boolean']>;
  bankHolidayPayElement?: InputMaybe<Scalars['String']>;
  bankSubjectiveCode?: InputMaybe<Scalars['String']>;
  basicRate?: InputMaybe<Scalars['Float']>;
  basicRateUnsocial?: InputMaybe<Scalars['Float']>;
  breakRepeatMinutes?: InputMaybe<Scalars['Int']>;
  callOutRate?: InputMaybe<Scalars['Float']>;
  colour?: InputMaybe<Scalars['String']>;
  coreWeekendsAndBankHolidays?: InputMaybe<Scalars['Boolean']>;
  defaultBreakMinutes?: InputMaybe<Scalars['Int']>;
  enhancedRate?: InputMaybe<Scalars['Float']>;
  enhancedRateUnsocial?: InputMaybe<Scalars['Float']>;
  filterGradeId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  nightShiftEndTime?: InputMaybe<Scalars['String']>;
  nightShiftStartTime?: InputMaybe<Scalars['String']>;
  onCallRate?: InputMaybe<Scalars['Float']>;
  saturdayPayElement?: InputMaybe<Scalars['String']>;
  staffGroupId?: InputMaybe<Scalars['Int']>;
  sundayPayElement?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  useDefaultBreaks?: InputMaybe<Scalars['Boolean']>;
  usesFiftyPercentRule?: InputMaybe<Scalars['Boolean']>;
  weekdayDayPayElement?: InputMaybe<Scalars['String']>;
  weekdayNightPayElement?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateGrade */
export type UpdateGradePayload = {
  __typename?: 'UpdateGradePayload';
  errors: Array<ValidationError>;
  grade?: Maybe<Grade>;
  success: Scalars['Boolean'];
};

export type UpdateHubUserInput = {
  accountType: Scalars['String'];
  authorisationLevelIds?: InputMaybe<Array<Scalars['Int']>>;
  authoriserDepartmentIds?: InputMaybe<Array<Scalars['Int']>>;
  budgetHolder?: InputMaybe<Scalars['Boolean']>;
  canAccessAgency?: InputMaybe<Scalars['Boolean']>;
  canAccessEsrTransfer?: InputMaybe<Scalars['Boolean']>;
  canAccessSuperhub?: InputMaybe<Scalars['Boolean']>;
  canAmendRates?: InputMaybe<Scalars['Boolean']>;
  canApproveOverridingRules?: InputMaybe<Scalars['Boolean']>;
  canApproveRosters?: InputMaybe<Scalars['Boolean']>;
  canAuthoriseRateRequests?: InputMaybe<Scalars['Boolean']>;
  canCancelShift?: InputMaybe<Scalars['Boolean']>;
  canControlLocks?: InputMaybe<Scalars['Boolean']>;
  canCreateAdmins?: InputMaybe<Scalars['Boolean']>;
  canCreateAgencyWorkers?: InputMaybe<Scalars['Boolean']>;
  canCreateRateAuthorisers?: InputMaybe<Scalars['Boolean']>;
  canCreateRoster?: InputMaybe<Scalars['Boolean']>;
  canEditShiftCovidRatings?: InputMaybe<Scalars['Boolean']>;
  canEscalateOnBehalfOfRateAuthoriser?: InputMaybe<Scalars['Boolean']>;
  canManageALiveRoster?: InputMaybe<Scalars['Boolean']>;
  canManageAutorostering?: InputMaybe<Scalars['Boolean']>;
  canManageBank: Scalars['Boolean'];
  canManageDaysTemplate?: InputMaybe<Scalars['Boolean']>;
  canManageDemandTemplates?: InputMaybe<Scalars['Boolean']>;
  canManageDeptWorkerList?: InputMaybe<Scalars['Boolean']>;
  canManageExceptionReportApprovers?: InputMaybe<Scalars['Boolean']>;
  canManagePayRules?: InputMaybe<Scalars['Boolean']>;
  canManageRoster: Scalars['Boolean'];
  canManageRosterWorker?: InputMaybe<Scalars['Boolean']>;
  canManageRota?: InputMaybe<Scalars['Boolean']>;
  canManageUsers?: InputMaybe<Scalars['Boolean']>;
  canManageWorkerCovidRatings?: InputMaybe<Scalars['Boolean']>;
  canManageWorkerPatterns?: InputMaybe<Scalars['Boolean']>;
  canManuallySignOff?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier1?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier2?: InputMaybe<Scalars['Boolean']>;
  canOverrideComplianceRulesTier3?: InputMaybe<Scalars['Boolean']>;
  canOverrideOrganisationRules?: InputMaybe<Scalars['Boolean']>;
  canOverrideRulesOnALiveRoster?: InputMaybe<Scalars['Boolean']>;
  canProcessPayments?: InputMaybe<Scalars['Boolean']>;
  canRequestSessionalShifts?: InputMaybe<Scalars['Boolean']>;
  canRequestShifts?: InputMaybe<Scalars['Boolean']>;
  canSeeNiNumber?: InputMaybe<Scalars['Boolean']>;
  canSendCommsToDeptWorkers?: InputMaybe<Scalars['Boolean']>;
  clinicalSupervisor?: InputMaybe<Scalars['Boolean']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  email: Scalars['String'];
  escalationLevelIds?: InputMaybe<Array<Scalars['Int']>>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  jobTitle: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  supervisedWorkerIds?: InputMaybe<Array<Scalars['Int']>>;
};

/** Autogenerated return type of UpdateHubUser */
export type UpdateHubUserPayload = {
  __typename?: 'UpdateHubUserPayload';
  errors: Array<ValidationError>;
  hubUser?: Maybe<HubUser>;
};

/** Autogenerated return type of UpdateItem */
export type UpdateItemPayload = {
  __typename?: 'UpdateItemPayload';
  errors: Array<ValidationError>;
  /** Paid per item Item */
  item?: Maybe<Item>;
};

/** Autogenerated return type of UpdateLeaveEntitlement */
export type UpdateLeaveEntitlementPayload = {
  __typename?: 'UpdateLeaveEntitlementPayload';
  errors: Array<ValidationError>;
  rosteringLeaveEntitlement?: Maybe<LeaveEntitlement>;
};

/** Attributes for updating a PA Category */
export type UpdateLeavePaCategoryInput = {
  endTime?: InputMaybe<Scalars['String']>;
  hoursPerPa?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateLevelGradeRates */
export type UpdateLevelGradeRatesPayload = {
  __typename?: 'UpdateLevelGradeRatesPayload';
  errors: Array<ValidationError>;
  /** Escalation Level Grade Rate */
  levelGradeRate?: Maybe<LevelGradeRates>;
};

/** Autogenerated return type of UpdateLocation */
export type UpdateLocationPayload = {
  __typename?: 'UpdateLocationPayload';
  errors: Array<ValidationError>;
  location?: Maybe<Location>;
};

export type UpdateMetaActivityInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of UpdateMetaActivity */
export type UpdateMetaActivityPayload = {
  __typename?: 'UpdateMetaActivityPayload';
  errors: Array<ValidationError>;
  metaActivity?: Maybe<MetaActivity>;
};

/** Autogenerated return type of UpdateNiAndOrDob */
export type UpdateNiAndOrDobPayload = {
  __typename?: 'UpdateNiAndOrDobPayload';
  errors: Array<ValidationError>;
  /** The amended worker */
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateNotificationSettings */
export type UpdateNotificationSettingsPayload = {
  __typename?: 'UpdateNotificationSettingsPayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateOrganisationAgency */
export type UpdateOrganisationAgencyPayload = {
  __typename?: 'UpdateOrganisationAgencyPayload';
  errors: Array<ValidationError>;
  organisationAgency?: Maybe<OrganisationAgency>;
};

/** Autogenerated return type of UpdateOrganisationComplianceDocument */
export type UpdateOrganisationComplianceDocumentPayload = {
  __typename?: 'UpdateOrganisationComplianceDocumentPayload';
  errors: Array<ValidationError>;
  organisationComplianceDocument?: Maybe<OrganisationComplianceDocument>;
};

/** Autogenerated return type of UpdateOrganisation */
export type UpdateOrganisationPayload = {
  __typename?: 'UpdateOrganisationPayload';
  errors: Array<ValidationError>;
  organisation?: Maybe<Organisation>;
};

/** Autogenerated return type of UpdateOrganisationStaffGroup */
export type UpdateOrganisationStaffGroupPayload = {
  __typename?: 'UpdateOrganisationStaffGroupPayload';
  errors: Array<ValidationError>;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
};

/** Autogenerated return type of Update */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  agencyWorker?: Maybe<AgencyWorker>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateProfile */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateProposalShortlistingStatus */
export type UpdateProposalShortlistingStatusPayload = {
  __typename?: 'UpdateProposalShortlistingStatusPayload';
  errors: Array<ValidationError>;
  /** Shortlisted at value */
  shortlistedAt?: Maybe<Scalars['Boolean']>;
  /** Indicates if the proposal was updated successfully */
  success: Scalars['Boolean'];
};

/** Attributes for updating a Rostering Rate Card */
export type UpdateRateCardInput = {
  /** Basic annual salary */
  basicAnnualSalary?: InputMaybe<Scalars['Float']>;
  /** Is this a Junior Doctor? Yes triggers additional unsocial hours */
  juniorDoctor?: InputMaybe<Scalars['Boolean']>;
  /** Night shift end time in multiples of 30 minutes ("hh:mm") */
  nightShiftEndTime?: InputMaybe<Scalars['String']>;
  /** Night shift hours rate applied only for weekdays (excluding NROC shifts) */
  nightShiftHourlyRate?: InputMaybe<Scalars['Float']>;
  /** Night shift start time in multiples of 30 minutes ("hh:mm") */
  nightShiftStartTime?: InputMaybe<Scalars['String']>;
  /** On-Call annual rate (Flat rate in pounds added to yearly salary) */
  onCallAnnualRate?: InputMaybe<Scalars['Float']>;
  /** RosteringRateCard ID */
  rateCardId: Scalars['ID'];
  /** % uplift on basic salary when on average 1 in 2 weekends worked */
  weekendPercentageRateMin1In2?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 3 weekends worked */
  weekendPercentageRateMin1In3?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 4 weekends worked */
  weekendPercentageRateMin1In4?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 5 weekends worked */
  weekendPercentageRateMin1In5?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 6 weekends worked */
  weekendPercentageRateMin1In6?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 7 weekends worked */
  weekendPercentageRateMin1In7?: InputMaybe<Scalars['Float']>;
  /** % uplift on basic salary when on average 1 in 8 weekends worked */
  weekendPercentageRateMin1In8?: InputMaybe<Scalars['Float']>;
};

/** Autogenerated return type of UpdateRateCard */
export type UpdateRateCardPayload = {
  __typename?: 'UpdateRateCardPayload';
  errors: Array<ValidationError>;
  /** Updated Rostering::RateCard */
  rosteringRateCard?: Maybe<RateCard>;
};

/** Autogenerated return type of UpdateReason */
export type UpdateReasonPayload = {
  __typename?: 'UpdateReasonPayload';
  errors: Array<ValidationError>;
  reason?: Maybe<Reason>;
};

/** Autogenerated return type of UpdateReceipt */
export type UpdateReceiptPayload = {
  __typename?: 'UpdateReceiptPayload';
  errors: Array<ValidationError>;
  /** PPI Receipt */
  receipt?: Maybe<Receipt>;
};

/** Autogenerated return type of UpdateReferee */
export type UpdateRefereePayload = {
  __typename?: 'UpdateRefereePayload';
  errors: Array<ValidationError>;
  referee?: Maybe<Referee>;
};

/** Autogenerated return type of UpdateRegBodyNumber */
export type UpdateRegBodyNumberPayload = {
  __typename?: 'UpdateRegBodyNumberPayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateRegulationBody */
export type UpdateRegulationBodyPayload = {
  __typename?: 'UpdateRegulationBodyPayload';
  errors: Array<ValidationError>;
  regulationBody?: Maybe<RegulationBody>;
};

/** Autogenerated return type of UpdateRosteringOrgRegLeaveFlow */
export type UpdateRosteringOrgRegLeaveFlowPayload = {
  __typename?: 'UpdateRosteringOrgRegLeaveFlowPayload';
  errors: Array<ValidationError>;
  /** Rostering Organisation Registration */
  rosteringOrganisationRegistration?: Maybe<RosteringOrganisationRegistration>;
};

/** Autogenerated return type of UpdateRotaPermissionSets */
export type UpdateRotaPermissionSetsPayload = {
  __typename?: 'UpdateRotaPermissionSetsPayload';
  errors: Array<ValidationError>;
  /** The Permission Sets that were created or updated */
  rosteringPermissionSets?: Maybe<Array<RosteringPermissionSet>>;
};

/** Autogenerated return type of UpdateRuleGroup */
export type UpdateRuleGroupPayload = {
  __typename?: 'UpdateRuleGroupPayload';
  errors: Array<ValidationError>;
  /** Updated Rule Group */
  ruleGroup?: Maybe<RuleGroup>;
};

/** Autogenerated return type of UpdateRuleGroupsRange */
export type UpdateRuleGroupsRangePayload = {
  __typename?: 'UpdateRuleGroupsRangePayload';
  errors: Array<ValidationError>;
  /** Updated Rule Groups Range */
  ruleGroupsRange?: Maybe<RuleGroupsRange>;
};

/** Autogenerated return type of UpdateRule */
export type UpdateRulePayload = {
  __typename?: 'UpdateRulePayload';
  errors: Array<ValidationError>;
  /** Updated Rule */
  rule?: Maybe<AutoCascadeRule>;
};

/** Autogenerated return type of UpdateScheduleEndDate */
export type UpdateScheduleEndDatePayload = {
  __typename?: 'UpdateScheduleEndDatePayload';
  errors: Array<ValidationError>;
  newSchedule?: Maybe<RosteringSchedule>;
  updatedSchedule?: Maybe<RosteringSchedule>;
};

/** Autogenerated return type of UpdateShiftBlock */
export type UpdateShiftBlockPayload = {
  __typename?: 'UpdateShiftBlockPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

/** Autogenerated return type of UpdateSite */
export type UpdateSitePayload = {
  __typename?: 'UpdateSitePayload';
  errors: Array<ValidationError>;
  site?: Maybe<Site>;
};

/** Autogenerated return type of UpdateSkill */
export type UpdateSkillPayload = {
  __typename?: 'UpdateSkillPayload';
  errors: Array<ValidationError>;
  skill?: Maybe<Skill>;
};

/** Autogenerated return type of UpdateSkills */
export type UpdateSkillsPayload = {
  __typename?: 'UpdateSkillsPayload';
  errors: Array<ValidationError>;
  organisationWorker?: Maybe<OrganisationWorker>;
};

/** Autogenerated return type of UpdateSpecialities */
export type UpdateSpecialitiesPayload = {
  __typename?: 'UpdateSpecialitiesPayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UpdateSpeciality */
export type UpdateSpecialityPayload = {
  __typename?: 'UpdateSpecialityPayload';
  errors: Array<ValidationError>;
  speciality?: Maybe<Speciality>;
};

/** Autogenerated return type of UpdateStaffGroup */
export type UpdateStaffGroupPayload = {
  __typename?: 'UpdateStaffGroupPayload';
  errors: Array<ValidationError>;
  staffGroup?: Maybe<StaffGroup>;
};

/** Autogenerated return type of UpdateSuperAdmin */
export type UpdateSuperAdminPayload = {
  __typename?: 'UpdateSuperAdminPayload';
  errors: Array<ValidationError>;
  superAdmin?: Maybe<HubUser>;
};

/** Autogenerated return type of UpdateTempStaffShift */
export type UpdateTempStaffShiftPayload = {
  __typename?: 'UpdateTempStaffShiftPayload';
  errors: Array<ValidationError>;
  /** The shift that was updated */
  shift?: Maybe<Shift>;
  /** Whether the shift was updated successfully */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateVmsAccount */
export type UpdateVmsAccountPayload = {
  __typename?: 'UpdateVmsAccountPayload';
  errors: Array<ValidationError>;
  externalAccount?: Maybe<ExternalAccount>;
};

/** Autogenerated return type of UpdateVmsAgency */
export type UpdateVmsAgencyPayload = {
  __typename?: 'UpdateVmsAgencyPayload';
  /** agency response object */
  agency?: Maybe<Agency>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateVmsAgencyUser */
export type UpdateVmsAgencyUserPayload = {
  __typename?: 'UpdateVmsAgencyUserPayload';
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of UpdateVmsWebhooks */
export type UpdateVmsWebhooksPayload = {
  __typename?: 'UpdateVmsWebhooksPayload';
  errors: Array<ValidationError>;
  externalAccount?: Maybe<ExternalAccount>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateWorkerCollaborativeBank */
export type UpdateWorkerCollaborativeBankPayload = {
  __typename?: 'UpdateWorkerCollaborativeBankPayload';
  errors: Array<ValidationError>;
  workerCollaborativeBank?: Maybe<WorkerCollaborativeBank>;
};

/** Autogenerated return type of UpdateWorkerProfile */
export type UpdateWorkerProfilePayload = {
  __typename?: 'UpdateWorkerProfilePayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UploadAgenciesCsv */
export type UploadAgenciesCsvPayload = {
  __typename?: 'UploadAgenciesCsvPayload';
  errors: Array<ValidationError>;
  /** Indicates where mutation completed successfully */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UploadCollabBankRates */
export type UploadCollabBankRatesPayload = {
  __typename?: 'UploadCollabBankRatesPayload';
  errors: Array<ValidationError>;
  /** Whether the rate card was uploaded successfully */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UploadCompliancePack */
export type UploadCompliancePackPayload = {
  __typename?: 'UploadCompliancePackPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UploadConfigurationWorkbook */
export type UploadConfigurationWorkbookPayload = {
  __typename?: 'UploadConfigurationWorkbookPayload';
  errors: Array<ValidationError>;
  /** Staff and Grade data from the Configuration Workbook */
  workbookData?: Maybe<UploadWorkbook>;
};

/** Autogenerated return type of UploadCv */
export type UploadCvPayload = {
  __typename?: 'UploadCvPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of Upload */
export type UploadPayload = {
  __typename?: 'UploadPayload';
  errors: Array<ValidationError>;
  /** Result of the mutation */
  result?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UploadProfilePicture */
export type UploadProfilePicturePayload = {
  __typename?: 'UploadProfilePicturePayload';
  errors: Array<ValidationError>;
  worker?: Maybe<Worker>;
};

/** Autogenerated return type of UploadRates */
export type UploadRatesPayload = {
  __typename?: 'UploadRatesPayload';
  errors: Array<ValidationError>;
  /** The result of the upload */
  result?: Maybe<Scalars['Boolean']>;
};

/** Staff List parsed from Rota Workbook; Grades which have been created from Workbook */
export type UploadWorkbook = {
  __typename?: 'UploadWorkbook';
  /** Grades data uploaded from Workbook */
  gradesList?: Maybe<Scalars['String']>;
  /** Staff List uploaded from Workbook */
  staffList?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['String']>;
  dbsNumber?: Maybe<Scalars['String']>;
  departments: Array<Department>;
  /** Whether the user has consented to EDI data collection */
  ediConsent?: Maybe<Scalars['Boolean']>;
  /** Edi country of qualification */
  ediCountryOfQualification?: Maybe<CountryOfQualification>;
  /** The disability for EDI data */
  ediDisability?: Maybe<Disability>;
  /** The ethnicity for EDI data */
  ediEthnicity?: Maybe<Ethnicity>;
  /** The gender for EDI data */
  ediGender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  filterGrade?: Maybe<FilterGrade>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<GenderEnum>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  niNumber?: Maybe<Scalars['String']>;
  organisation: Organisation;
  otherEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** Has the user provided EDI data */
  providedEdiData: Scalars['Boolean'];
  regBodyNumber?: Maybe<Scalars['String']>;
  regBodyNumberExpirationDate?: Maybe<Scalars['Date']>;
  rosteringOrganisationRegistrations?: Maybe<Array<RosteringOrganisationRegistration>>;
  rosteringOrganisations?: Maybe<Array<Organisation>>;
  rosteringWorkerProfile?: Maybe<RosteringWorkerProfile>;
  rosteringWorkerProfileId?: Maybe<Scalars['Int']>;
  specialities: Array<Speciality>;
  staffGroup?: Maybe<StaffGroup>;
  useKeycloakAuth: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
};

export type UserBookedShift = {
  __typename?: 'UserBookedShift';
  approvedAt: Scalars['DateTime'];
  approver?: Maybe<HubUser>;
  id: Scalars['ID'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  documentExpiryNotifications: Scalars['Boolean'];
  id: Scalars['ID'];
  targetEarnings?: Maybe<Scalars['Float']>;
  targetShifts?: Maybe<Scalars['Int']>;
  urgentNotifications: Scalars['Int'];
  urgentShiftNotifications: Scalars['Boolean'];
  vacancyNotifications: VacantNotificationEnum;
};

/** Possible values for vacant notification frequency */
export enum VacantNotificationEnum {
  Daily = 'DAILY',
  None = 'NONE',
  Weekly = 'WEEKLY'
}

/** Autogenerated return type of ValidateOneTimeCode */
export type ValidateOneTimeCodePayload = {
  __typename?: 'ValidateOneTimeCodePayload';
  authorisedUser?: Maybe<NonAuthorisedUser>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ValidatePayloadForAssignWorker */
export type ValidatePayloadForAssignWorkerPayload = {
  __typename?: 'ValidatePayloadForAssignWorkerPayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ValidateWorkerShifts */
export type ValidateWorkerShiftsPayload = {
  __typename?: 'ValidateWorkerShiftsPayload';
  errors: Array<ValidationError>;
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  /** Which input value this error came from */
  attribute?: Maybe<Scalars['String']>;
  /** A description of the error */
  message: Scalars['String'];
};

/** DE Contract Version Type */
export type Version = {
  __typename?: 'Version';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deContract: Contract;
  id: Scalars['Int'];
};

/** DE Contract Version Assignment Type */
export type VersionAssignment = {
  __typename?: 'VersionAssignment';
  /** Shift or ShiftBlock */
  assignable?: Maybe<VersionAssignmentAssignable>;
  /** The DE Contract URL */
  contractFileUrl?: Maybe<Scalars['String']>;
  /** The DE Contract Version */
  deContractVersion?: Maybe<Version>;
  /** The ID of the DE Contract Version Assignment */
  id: Scalars['Int'];
  /** The Signature */
  signature?: Maybe<Scalars['String']>;
  /** The Signed At */
  signedAt?: Maybe<Scalars['DateTime']>;
  /** The Worker */
  worker?: Maybe<Worker>;
};

export type VersionAssignmentAssignable = Shift | ShiftBlock;

/** A simple boolean for whether a set of shifts are allowed */
export type VisaLimitsShiftsAllowed = {
  __typename?: 'VisaLimitsShiftsAllowed';
  shiftsAllowed: Scalars['Boolean'];
};

/** Visa Type */
export type VisaType = {
  __typename?: 'VisaType';
  id: Scalars['ID'];
  maxHours: Scalars['Int'];
  name: Scalars['String'];
};

/** Autogenerated return type of VmsAgencyUserCreate */
export type VmsAgencyUserCreatePayload = {
  __typename?: 'VmsAgencyUserCreatePayload';
  /** The newly-created Agency User */
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of VmsAgencyUserUpdate */
export type VmsAgencyUserUpdatePayload = {
  __typename?: 'VmsAgencyUserUpdatePayload';
  agencyUser?: Maybe<AgencyUser>;
  errors: Array<ValidationError>;
};

export type VmsWebhook = {
  __typename?: 'VmsWebhook';
  action: Scalars['String'];
  externalAccount?: Maybe<ExternalAccount>;
  id: Scalars['ID'];
  url: Scalars['String'];
};

/** Percentage uplifts on basic annual salary according to frequency of weekends rostered */
export type WeekendPercentageRates = {
  __typename?: 'WeekendPercentageRates';
  /** % uplift on basic salary when 1 in 2 weekends worked */
  min1In2: Scalars['Float'];
  /** % uplift on basic salary when 1 in 3 weekends worked */
  min1In3: Scalars['Float'];
  /** % uplift on basic salary when 1 in 4 weekends worked */
  min1In4: Scalars['Float'];
  /** % uplift on basic salary when 1 in 5 weekends worked */
  min1In5: Scalars['Float'];
  /** % uplift on basic salary when 1 in 6 weekends worked */
  min1In6: Scalars['Float'];
  /** % uplift on basic salary when 1 in 7 weekends worked */
  min1In7: Scalars['Float'];
  /** % uplift on basic salary when 1 in 8 weekends worked */
  min1In8: Scalars['Float'];
};

/** Autogenerated return type of WithOneTimeCode */
export type WithOneTimeCodePayload = {
  __typename?: 'WithOneTimeCodePayload';
  authorisedUser?: Maybe<NonAuthorisedUser>;
  errors: Array<ValidationError>;
};

/** Autogenerated return type of WithdrawApplication */
export type WithdrawApplicationPayload = {
  __typename?: 'WithdrawApplicationPayload';
  errors: Array<ValidationError>;
  shift?: Maybe<WorkerShift>;
  workerId?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of WithdrawRateChange */
export type WithdrawRateChangePayload = {
  __typename?: 'WithdrawRateChangePayload';
  errors: Array<ValidationError>;
  /** Rate change */
  rateChange?: Maybe<RateChange>;
};

/** Autogenerated return type of WithdrawWorkerApplication */
export type WithdrawWorkerApplicationPayload = {
  __typename?: 'WithdrawWorkerApplicationPayload';
  errors: Array<ValidationError>;
  shiftBlockApplication: ShiftBlockApplication;
};

/** Autogenerated return type of WithdrawWorker */
export type WithdrawWorkerPayload = {
  __typename?: 'WithdrawWorkerPayload';
  errors: Array<ValidationError>;
  reasonForCancellation?: Maybe<ReasonForCancellation>;
  shift?: Maybe<WorkerShift>;
};

/** Autogenerated return type of WithdrawWorkerShiftBlock */
export type WithdrawWorkerShiftBlockPayload = {
  __typename?: 'WithdrawWorkerShiftBlockPayload';
  errors: Array<ValidationError>;
  shiftBlock?: Maybe<ShiftBlock>;
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  employer?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  jobTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type Worker = {
  __typename?: 'Worker';
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  agencyRegistrations?: Maybe<Array<AgencyRegistration>>;
  als: Array<Document>;
  appliedShifts: Array<WorkerShift>;
  avatar?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bls: Array<Document>;
  bookedShifts: Array<WorkerShift>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['String']>;
  dbs: Array<Document>;
  dbsNumber?: Maybe<Scalars['String']>;
  departments: Array<Department>;
  /** Whether the user has consented to EDI data collection */
  ediConsent?: Maybe<Scalars['Boolean']>;
  /** Edi country of qualification */
  ediCountryOfQualification?: Maybe<CountryOfQualification>;
  /** The disability for EDI data */
  ediDisability?: Maybe<Disability>;
  /** The ethnicity for EDI data */
  ediEthnicity?: Maybe<Ethnicity>;
  /** The gender for EDI data */
  ediGender?: Maybe<Gender>;
  email?: Maybe<Scalars['String']>;
  filterGrade?: Maybe<FilterGrade>;
  fireSafety: Array<Document>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: Maybe<GenderEnum>;
  grades: Array<Grade>;
  id: Scalars['ID'];
  informationGovernance: Array<Document>;
  intercomKey?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  libraryItems: Array<LibraryItem>;
  manualHandling: Array<Document>;
  niNumber?: Maybe<Scalars['String']>;
  numberOfSuspendedOrgRegistrations?: Maybe<Scalars['Int']>;
  occupationalHealth: Array<Document>;
  onboardingProfileComplete: Scalars['Boolean'];
  orderedAuditUserEvents?: Maybe<Array<AuditUserEvent>>;
  organisation: Organisation;
  organisationRegistrations: Array<OrganisationRegistration>;
  organisations?: Maybe<Array<Organisation>>;
  other: Array<Document>;
  otherEmail?: Maybe<Scalars['String']>;
  passportInvitations: Array<PassportInvitation>;
  phoneNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  preferences?: Maybe<UserPreferences>;
  preferredName?: Maybe<Scalars['String']>;
  primaryQualifications: Array<Document>;
  profileComplete: Scalars['Boolean'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  proofOfAddress: Array<Document>;
  /** Has the user provided EDI data */
  providedEdiData: Scalars['Boolean'];
  referees: Array<Referee>;
  regBodyNumber?: Maybe<Scalars['String']>;
  regBodyNumberExpirationDate?: Maybe<Scalars['Date']>;
  rightToWork: Array<Document>;
  rosteringOrganisationRegistrations?: Maybe<Array<RosteringOrganisationRegistration>>;
  rosteringOrganisations?: Maybe<Array<Organisation>>;
  rosteringWorkerProfile?: Maybe<RosteringWorkerProfile>;
  rosteringWorkerProfileId?: Maybe<Scalars['Int']>;
  safeGuarding: Array<Document>;
  shifts: Array<WorkerShift>;
  /** Pagination Object for shifts, must be called after shifts */
  shiftsPagination: Pagination;
  specialities: Array<Speciality>;
  staffGroup?: Maybe<StaffGroup>;
  useKeycloakAuth: Scalars['Boolean'];
  username?: Maybe<Scalars['String']>;
  /** Unique identifier string */
  uuid?: Maybe<Scalars['String']>;
  workExperiences: Array<WorkExperience>;
  workerCollaborativeBanks: Array<WorkerCollaborativeBank>;
  workerUploads?: Maybe<Array<WorkerUpload>>;
  workersSupervisors?: Maybe<Array<RosteringWorkersSupervisor>>;
};


export type WorkerBookedShiftsArgs = {
  items?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of WorkerAcceptRequest */
export type WorkerAcceptRequestPayload = {
  __typename?: 'WorkerAcceptRequestPayload';
  errors: Array<ValidationError>;
  /** The shift swap request */
  rosteringShiftSwapRequest?: Maybe<Request>;
  success: Scalars['Boolean'];
};

export type WorkerCollaborativeBank = {
  __typename?: 'WorkerCollaborativeBank';
  active: Scalars['Boolean'];
  collaborativeBank: CollaborativeBank;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  instantBooking?: Maybe<Scalars['Boolean']>;
  numberOfShiftsWorkedAtPrimary?: Maybe<Scalars['Int']>;
  organisation: Organisation;
  worker: Worker;
};

/** The connection type for Worker. */
export type WorkerConnection = {
  __typename?: 'WorkerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkerEdge>>>;
  /** A list of nodes. */
  nodes: Array<Worker>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WorkerDailySummary = {
  __typename?: 'WorkerDailySummary';
  date: Scalars['Date'];
  statuses: Array<ShiftStatus>;
};

/** An edge in a connection. */
export type WorkerEdge = {
  __typename?: 'WorkerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Worker>;
};

export type WorkerOrganisationShifts = {
  __typename?: 'WorkerOrganisationShifts';
  numberOfShifts?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation>;
};

/** A RosteringWorkerPattern for use in a Worker-based Rota */
export type WorkerPattern = {
  __typename?: 'WorkerPattern';
  /** Compliance Rule Sets which will be checked against the WorkerPattern */
  complianceRuleSets?: Maybe<Array<RuleSetEnum>>;
  /** FT pattern on which a LTFT pattern is based */
  correspondingFullTimePattern?: Maybe<WorkerPattern>;
  /** WorkerPattern Creation timestamp */
  createdAt: Scalars['DateTime'];
  /** The HubUser who created the WorkerPattern */
  createdBy?: Maybe<HubUser>;
  /** Departments associated with the WorkerPattern */
  departments: Array<Department>;
  /** WorkerPattern can be FullTime or LessThanFullTime */
  fullTime: Scalars['Boolean'];
  /** RosteringWorkerPattern ID */
  id: Scalars['ID'];
  /** Name of the RosteringWorkerPattern */
  name: Scalars['String'];
  /** Duration in weeks */
  numberOfWeeks: Scalars['Int'];
  /** associated OrganisationStaffGroup */
  organisationStaffGroup: OrganisationStaffGroup;
  /** EventTemplates used in the WorkerPattern */
  rosteringWorkerPatternEventTemplates: Array<WorkerPatternEventTemplate>;
  /** Worker Pattern metrics */
  rosteringWorkerPatternReview?: Maybe<WorkerPatternReview>;
  /** associated Speciality */
  speciality: Speciality;
  /** RosteringWorkerPattern status */
  status: WorkerPatternStatusEnum;
  /** Expected annual leave entitlement JD can take */
  typicalAnnualLeaveEntitlement?: Maybe<Scalars['Float']>;
  /** unique identifier of the WorkerPattern */
  uniqueIdentifier?: Maybe<Scalars['String']>;
  /** WorkerPattern Update timestamp */
  updatedAt: Scalars['DateTime'];
};

/** Worker Pattern Breach */
export type WorkerPatternBreach = {
  __typename?: 'WorkerPatternBreach';
  /** Breach Event Templates by Week */
  breachedEventTemplatesByWeek: Array<BreachedEventTemplatesByWeek>;
  /** Compliance Rule being breached */
  complianceRule: Rule;
};

/** The connection type for WorkerPattern. */
export type WorkerPatternConnection = {
  __typename?: 'WorkerPatternConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkerPatternEdge>>>;
  /** A list of nodes. */
  nodes: Array<WorkerPattern>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WorkerPatternEdge = {
  __typename?: 'WorkerPatternEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WorkerPattern>;
};

/** A RosteringWorkerPatternEventTemplate for use in a Worker Pattern */
export type WorkerPatternEventTemplate = {
  __typename?: 'WorkerPatternEventTemplate';
  /** Day */
  day: Scalars['Int'];
  /** The type of Worker Pattern Event template */
  eventTemplateType: WorkerPatternEventTemplateTypeEnum;
  /** ID of the WorkerPatternEventTemplate */
  id: Scalars['ID'];
  /** associated WorkerPattern */
  rosteringWorkerPattern: WorkerPattern;
  /** associated WorkerPatternShiftDetail */
  rosteringWorkerPatternShiftDetail?: Maybe<WorkerPatternShiftDetail>;
};

/** The type of event template in Worker Pattern */
export enum WorkerPatternEventTemplateTypeEnum {
  /** Day Off */
  DayOff = 'day_off',
  /** Shift */
  Shift = 'shift'
}

/** Attributes for creating a Rostering Worker Pattern */
export type WorkerPatternInput = {
  /** Compliance rules for worker pattern */
  complianceRuleSets: Array<RuleSetEnum>;
  /** Ids for associated departments */
  departmentIds: Array<Scalars['Int']>;
  /** Full time or less than full time */
  fullTime: Scalars['Boolean'];
  /** Id of a corresponding full time pattern */
  fullTimePatternId?: InputMaybe<Scalars['Int']>;
  /** Name of worker pattern */
  name: Scalars['String'];
  /** The length of the worker pattern */
  numberOfWeeks: Scalars['Int'];
  /** Id for organisation staff group */
  organisationStaffGroupId: Scalars['Int'];
  /** Id for speciality */
  specialityId: Scalars['Int'];
  /** Expected annual leave entitlement JD can take */
  typicalAnnualLeaveEntitlement?: InputMaybe<Scalars['Float']>;
};

/** A worker pattern on roster */
export type WorkerPatternOnRoster = {
  __typename?: 'WorkerPatternOnRoster';
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  startDate: Scalars['Date'];
  workerPattern: WorkerPattern;
};

/** A RosteringWorkerPatternReview */
export type WorkerPatternReview = {
  __typename?: 'WorkerPatternReview';
  /** Average weekly hours */
  averageWeeklyHours: Scalars['Float'];
  /** Cycle duration in weeks */
  cycleDurationInWeeks: Scalars['Int'];
  /** Number of Long Day shifts */
  longDayShiftCount: Scalars['Int'];
  /** Number of Night shifts */
  nightShiftCount: Scalars['Int'];
  /** Number of Normal Day [Early Day Mid Late Twilight] shifts */
  normalDayShiftCount: Scalars['Int'];
  /** Leave entitlement per cycle of the pattern */
  proRataLeaveEntitlement?: Maybe<Scalars['Float']>;
  /** Expected annual leave entitlement JD can take */
  typicalAnnualLeaveEntitlement?: Maybe<Scalars['Float']>;
  /** Number of Weekend shifts */
  weekendShiftCount: Scalars['Int'];
};

/** Shift-related details of a WorkerPatternEventTemplate with type "shift" */
export type WorkerPatternShiftDetail = {
  __typename?: 'WorkerPatternShiftDetail';
  /** End Time */
  endTime: Scalars['DateTime'];
  /** the core duration of the shift */
  predictedCoreDurationMinutes?: Maybe<Scalars['Int']>;
  /** the unsocial duration of the shift */
  predictedUnsocialDurationMinutes?: Maybe<Scalars['Int']>;
  /** associated OrganisationActivity */
  rosteringOrganisationActivity?: Maybe<RosteringOrganisationActivity>;
  /** associated ShiftTemplate */
  rosteringShiftTemplate: RosteringShiftTemplate;
  /** associated ShiftType */
  rosteringShiftType: RosteringShiftType;
  /** Start Time */
  startTime: Scalars['DateTime'];
};

/** The status of a RosteringWorkerPattern */
export enum WorkerPatternStatusEnum {
  /** Completed and ready to be used in a Rota */
  Complete = 'complete',
  /** Draft Status */
  Draft = 'draft',
  /** Currently being used in a Rota */
  InUse = 'in_use'
}

/** Autogenerated return type of WorkerPatternUpdated */
export type WorkerPatternUpdatedPayload = {
  __typename?: 'WorkerPatternUpdatedPayload';
  /** Errors */
  errors: Array<ValidationError>;
  /** WorkerPattern review metrics */
  rosteringWorkerPatternReview: WorkerPatternReview;
};

export type WorkerPrimaryOrganisationBasicInfo = {
  __typename?: 'WorkerPrimaryOrganisationBasicInfo';
  dateJoined?: Maybe<Scalars['DateTime']>;
  numberOfShifts?: Maybe<Scalars['Int']>;
  primaryOrganisation?: Maybe<Organisation>;
};

/** Autogenerated return type of WorkerRejectRequest */
export type WorkerRejectRequestPayload = {
  __typename?: 'WorkerRejectRequestPayload';
  errors: Array<ValidationError>;
  /** The shift swap request */
  rosteringShiftSwapRequest?: Maybe<Request>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerResetPassword */
export type WorkerResetPasswordPayload = {
  __typename?: 'WorkerResetPasswordPayload';
  errors: Array<ValidationError>;
  /** reset password success status */
  success: Scalars['Boolean'];
};

export type WorkerShift = {
  __typename?: 'WorkerShift';
  /**
   * Core hourly benefit for hourly shifts or total benefit
   *                                        for sessional shifts (eg WTD)
   */
  actualCoreBenefit?: Maybe<Scalars['Float']>;
  actualCoreHours: Scalars['Float'];
  actualCoreRate?: Maybe<Scalars['Float']>;
  actualEndTime?: Maybe<Scalars['DateTime']>;
  actualStartTime?: Maybe<Scalars['DateTime']>;
  /** Total benefit pay for the shift (eg WTD) */
  actualTotalBenefit?: Maybe<Scalars['Float']>;
  actualTotalPay?: Maybe<Scalars['Float']>;
  /**
   * Unsocial hourly benefit for hourly shifts or 0 for
   *                                            sessional shifts (eg WTD)
   */
  actualUnsocialBenefit?: Maybe<Scalars['Float']>;
  actualUnsocialHours: Scalars['Float'];
  actualUnsocialRate?: Maybe<Scalars['Float']>;
  actualUsesFiftyPercentRule: Scalars['Boolean'];
  adminNote?: Maybe<Scalars['String']>;
  agencyBooking?: Maybe<AgencyBooking>;
  agencyLocked?: Maybe<Scalars['Boolean']>;
  agencyRateCardAttached?: Maybe<Scalars['Boolean']>;
  agencyRateSummary?: Maybe<Scalars['String']>;
  agencyRegistration?: Maybe<AgencyRegistration>;
  agencyTier?: Maybe<AgencyTier>;
  agencyWorkerDocsPresent?: Maybe<Scalars['Boolean']>;
  agencyWorkerProposals: AgencyWorkerProposalConnection;
  agencyWorkerRates?: Maybe<Array<AgencyWorkerProposalRates>>;
  agreedFraudStatementAt?: Maybe<Scalars['DateTime']>;
  appliedBy?: Maybe<Array<OrganisationWorker>>;
  appliedByShiftLimits?: Maybe<Array<OrganisationWorkerShiftLimits>>;
  archived?: Maybe<Scalars['Boolean']>;
  auditEvents?: Maybe<Array<AuditEvent>>;
  autoCascadeTriggers?: Maybe<Array<Trigger>>;
  availableActions: Array<Scalars['String']>;
  availableForAgencyUserActions: Array<Scalars['String']>;
  availableShiftActionsToHubUser?: Maybe<Array<Scalars['String']>>;
  bankRateCardAttached: Scalars['Boolean'];
  batchId?: Maybe<Scalars['Int']>;
  batchReference?: Maybe<Scalars['String']>;
  bookedBy?: Maybe<OrganisationWorker>;
  bookedBySecondaryBankWorker?: Maybe<Scalars['Boolean']>;
  bookingAction: BookingAction;
  breakMinutes: Scalars['Int'];
  breaks?: Maybe<Scalars['Int']>;
  breaksExceptionNotes?: Maybe<Scalars['String']>;
  bulkPaymentApproveUuid?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  confirmedBy?: Maybe<Scalars['String']>;
  confirmedEmail?: Maybe<Scalars['String']>;
  confirmedPosition?: Maybe<Scalars['String']>;
  confirmedSignature?: Maybe<Scalars['String']>;
  coreHours: Scalars['Float'];
  coreTotalRate?: Maybe<Scalars['Float']>;
  costCentre?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dateSentToAgency?: Maybe<Scalars['DateTime']>;
  deContractVersionAssignment?: Maybe<VersionAssignment>;
  deleted?: Maybe<Scalars['Boolean']>;
  department: Department;
  didNotAttend?: Maybe<DidNotAttend>;
  draftPayElements: Array<PayElement>;
  endTime: Scalars['DateTime'];
  enforceFraudDeclarationStatement?: Maybe<Scalars['Boolean']>;
  enhancements?: Maybe<Scalars['Float']>;
  escalatedBy?: Maybe<Scalars['String']>;
  escalationRequested?: Maybe<Scalars['Boolean']>;
  esrNumberId?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalNote?: Maybe<Scalars['String']>;
  externalSkills?: Maybe<Array<ExternalSkill>>;
  externalUnit?: Maybe<ExternalUnit>;
  grade: Grade;
  gradeBreakMinutes?: Maybe<Scalars['Int']>;
  gradeBreakRepeatMinutes?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  /** Rates include benefits (eg WTD) */
  includesBenefit?: Maybe<Scalars['Boolean']>;
  integrationShift?: Maybe<IntegrationShift>;
  ir35: Scalars['String'];
  limitToPreferred?: Maybe<Scalars['Boolean']>;
  locumFirstName?: Maybe<Scalars['String']>;
  locumGrade?: Maybe<Scalars['String']>;
  locumLastName?: Maybe<Scalars['String']>;
  locumType?: Maybe<Scalars['Int']>;
  minimumBreakDuration?: Maybe<Scalars['Int']>;
  minimumRequiredBreak?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  onCall: Scalars['Boolean'];
  onCallWithTimeframes?: Maybe<Scalars['Boolean']>;
  organisation: Organisation;
  organisationStaffGroup?: Maybe<OrganisationStaffGroup>;
  paid?: Maybe<Scalars['Boolean']>;
  pamAgencyWorker?: Maybe<AgencyWorker>;
  payElements: Array<PayElement>;
  paymentApproved?: Maybe<Scalars['Boolean']>;
  paymentApproverNotes?: Maybe<Scalars['String']>;
  payments?: Maybe<Array<Payment>>;
  payrollAdjustment?: Maybe<AgencyAdjustment>;
  positionManagementCode?: Maybe<Scalars['String']>;
  proposedEndTime: Scalars['DateTime'];
  proposedStartTime: Scalars['DateTime'];
  rate?: Maybe<Scalars['Float']>;
  rateElements?: Maybe<Array<PayElement>>;
  rateNotApplicable: Scalars['Boolean'];
  rateRequest?: Maybe<RateChange>;
  reasonForRequest?: Maybe<Scalars['String']>;
  reasonForRequestId?: Maybe<Scalars['Int']>;
  releasedTo?: Maybe<Array<Scalars['String']>>;
  requestedBy?: Maybe<ShiftRequestor>;
  requiresDeContractToSign?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
  rpaLocked?: Maybe<Scalars['Boolean']>;
  rpaLockedReason?: Maybe<Scalars['String']>;
  samAgencyWorker?: Maybe<SamAgencyWorker>;
  selfBooking?: Maybe<Scalars['Boolean']>;
  sentFromRostering?: Maybe<Scalars['Boolean']>;
  sentToAgency?: Maybe<Scalars['Boolean']>;
  shiftBlock?: Maybe<ShiftBlock>;
  shiftBlockId?: Maybe<Scalars['ID']>;
  shiftGrade?: Maybe<Scalars['String']>;
  /** Any limits for this worker on this shift */
  shiftLimits?: Maybe<OrganisationWorkerShiftLimits>;
  shiftType?: Maybe<ShiftTypeEnum>;
  shiftsSeries: ShiftsSeries;
  shouldEnforceBreaks?: Maybe<Scalars['Boolean']>;
  signOffNonAmendedOrRejectedRequests: Scalars['Boolean'];
  signOffRequested?: Maybe<Scalars['Boolean']>;
  site: Site;
  skills: Array<Skill>;
  staffGroup: StaffGroup;
  staffGroupId?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  status: ShiftStatus;
  timeframes?: Maybe<Array<OnCallShiftTimeframe>>;
  timesheetAuthoriser?: Maybe<Scalars['String']>;
  totalAgencyPay?: Maybe<Scalars['Float']>;
  totalHours: Scalars['Float'];
  totalPay?: Maybe<Scalars['Float']>;
  totalPayPence?: Maybe<Scalars['Int']>;
  totalRateSummary?: Maybe<Scalars['String']>;
  unsocialHours: Scalars['Float'];
  unsocialTotalRate?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBookedShift?: Maybe<UserBookedShift>;
  usesFiftyPercentRule: Scalars['Boolean'];
  verified?: Maybe<Scalars['Boolean']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedId?: Maybe<Scalars['Int']>;
  watchingHubUsers?: Maybe<Array<HubUser>>;
  withSecondaryBank: Scalars['Boolean'];
  workerName?: Maybe<Scalars['String']>;
  /** Remote sign off reported and confirmed times */
  workerSignOff?: Maybe<ShiftWorkerSignOff>;
};


export type WorkerShiftAgencyWorkerProposalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Shifts grouped by Worker */
export type WorkerShiftsFromOtherRosters = {
  __typename?: 'WorkerShiftsFromOtherRosters';
  /** ID of the RosteringOrganisationRegistration */
  rosteringOrganisationRegistrationId: Scalars['ID'];
  /** Shift events assigned to Worker on other rosters */
  shifts: Array<RosteringEvent>;
};

/** Autogenerated return type of WorkerSignOffCreate */
export type WorkerSignOffCreatePayload = {
  __typename?: 'WorkerSignOffCreatePayload';
  /** Errors */
  errors?: Maybe<Array<Error>>;
  /** Success */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerSignOff */
export type WorkerSignOffPayload = {
  __typename?: 'WorkerSignOffPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerSignOffRequestedMultipleOnCall */
export type WorkerSignOffRequestedMultipleOnCallPayload = {
  __typename?: 'WorkerSignOffRequestedMultipleOnCallPayload';
  errors: Array<ValidationError>;
  /** Whether all shifts sign off has been requested */
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerSignOffRequestedMultiple */
export type WorkerSignOffRequestedMultiplePayload = {
  __typename?: 'WorkerSignOffRequestedMultiplePayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerSignOffRequested */
export type WorkerSignOffRequestedPayload = {
  __typename?: 'WorkerSignOffRequestedPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerSignOffWithPin */
export type WorkerSignOffWithPinPayload = {
  __typename?: 'WorkerSignOffWithPinPayload';
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of WorkerUpdateGrade */
export type WorkerUpdateGradePayload = {
  __typename?: 'WorkerUpdateGradePayload';
  errors: Array<ValidationError>;
  organisationWorker?: Maybe<OrganisationWorker>;
};

export type WorkerUpload = {
  __typename?: 'WorkerUpload';
  documentNumber?: Maybe<Scalars['String']>;
  documentTitle?: Maybe<Scalars['String']>;
  documentTypeId: Scalars['Int'];
  documentTypeName?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  fileFormat?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  qualificationName?: Maybe<Scalars['String']>;
  trashedAt?: Maybe<Scalars['String']>;
};

/** Schedules grouped by Worker Pattern, and Bank Schedules, on Worker x Date view */
export type WorkerViewDataStructure = {
  __typename?: 'WorkerViewDataStructure';
  /** Schedules for agency staff */
  agencySchedules?: Maybe<Array<RosteringSchedule>>;
  /** Schedules for bank staff */
  bankSchedules?: Maybe<Array<RosteringSchedule>>;
  /** Schedules grouped by Worker Pattern */
  schedulesByPattern?: Maybe<Array<SchedulesWithinPattern>>;
};
